import React, { Component }               from 'react';
import PropTypes                          from 'prop-types';
import ImmutablePropTypes                 from 'react-immutable-proptypes';
import _                                  from 'lodash';
import { connect }                        from 'react-redux';

// Import store actions
import { learn }                          from 'store/actions/knowledge';
import { getUserViewItemFromModel }       from 'store/actions/userView';
import { emitEvent }                      from 'store/actions/sockets';

// Import display components
import {
    Icon,
    Element,
    Row,
    Col,
    CssLoader
}                                         from 'helpers';
import { stripTags, makeStrClassName }    from 'utils/text';
import { getIdcardSections }              from 'utils/elements';
import { requestTimeout }                 from 'utils/requestTimeout';

// Import headers
import OrgunitHeader                      from './IdCard/Header/Orgunit';
import ExpertHeader                       from './IdCard/Header/Expert';
import CollaborationHeader                from './IdCard/Header/Collaboration';

// Import Informations
import OrgunitInformations                from './IdCard/Informations/Orgunit';

import Tags                               from '../Tags';

// Import orgunit actions button
import ModelAction                        from 'helpers/Action/Model';

import Tabs                               from './tabs';

// Import CSS
import './assets/idcard.less';

const components = {
    header: {
        orgunit              : OrgunitHeader,
        expert               : ExpertHeader,
        orgunit_collaboration: CollaborationHeader,
    },
    informations: {
        orgunit: OrgunitInformations
    }
};


/**
 * Render IdCard entities
 */
class IdCard extends Component {

    /**
    * Class constructor
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onSectionColumnLoaded', 'updateViewport', 'addToClipboard',
            'onScroll', 'handleMediaQueryChange', 'onNoKpiData', 'renderTags', 'onFeedsLoaded',
            'renderEntityHeader', 'registerCallbacks', 'goToTop', 'onChangeMainSection',
            'onChangeActiveSectionKey', 'navigateToSectionFromWhy'
        );

        this.state = {
            // Disables those contents when there's no data
            disabledColumnsIds: [],
            showOneColumn     : false,
            noKpiData         : false,
            noFeedData        : false,
            loadedSections    : [],
            activeSectionKey  : false,
            activeGroupSection: false,
            firstRender       : true,
            callbacks         : {},
            viewport          : {
                visible: [],
            },
        };

        this.tabsRef          = React.createRef();
        this.idCardRef        = React.createRef();
        this.idcardContentRef = React.createRef();
        this.sectionsRef      = React.createRef();
    }

    /**
    * Learn the knowledge
    */
    componentDidMount() {
        const { learnKnowledge, modalRef } = this.props,
            { context }                    = this.props,
            activeGroupSection             = context ? 'main-section-why' : 'main-section-profile';

        learnKnowledge(['resources', 'entities', 'tags', 'org_classes'])
            .then(this.setState.bind(this));

        // Match media when width < 1400px
        this.mediaQuery = window.matchMedia('(max-width: 1400px)');
        this.mediaQuery.addEventListener('change', this.handleMediaQueryChange);
        this.handleMediaQueryChange(this.mediaQuery);

        this.setState({ activeGroupSection });

        this.goToTop();

        requestTimeout(() => {
            this.setState({ firstRender: false });
        }, 100);

        requestTimeout(() => {
            modalRef?.current?.addEventListener('scroll', this.onScroll);
        }, 500);
    }


    /**
    * Component will unmount
    *
    * @return void
    */
    componentWillUnmount() {
        const { modalRef } = this.props;

        modalRef?.current?.removeEventListener('scroll', this.onScroll);
        this.mediaQuery.removeEventListener('change', this.handleMediaQueryChange);
    }

    /**
    * Handle main section
    *
    * @param {string} mainSection
    */
    onChangeMainSection(activeGroupSection) {
        const { viewport } = this.state,
            { modalRef }   = this.props;

        modalRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });

        this.setState({
            activeGroupSection,
            viewport: {
                ...viewport,
                scrollTop: 0,
            }
        });
    }

    /**
     * MediaQuery observer
     * @param {event} event
     */
    handleMediaQueryChange(event) {
        const { matches } = event;
        this.setState({ showOneColumn: matches });
    }

    /**
    * On scroll
    *
    * @param {DocumentEvent} e
    *
    * @return void
    */
    onScroll() {
        clearTimeout(this.scrollTimeout);

        this.scrollTimeout = setTimeout(() => {
            requestAnimationFrame(this.updateViewport);
        }, 100);
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb, element) {
        const { callbacks } = this.state,
            /** Return empty object */
            defaultCb       = () => {};

        // Store only element callbacks
        if (element === null) { return; }

        if (!callbacks[element.id]) {
            callbacks[element.id] = {};
        }

        callbacks[element.id][action] = cb || defaultCb;

        this.setState({ callbacks });
    }


    /**
    * Scroll to top
    *
    * @return void
    */
    goToTop() {
        const { modalRef } = this.props,
            sections       = this.getSectionDomInformation(),
            firstSection   = _.first(_.filter(sections, section => section.top > 0));

        modalRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });

        this.setState({
            viewport: {
                scrollTop: 0,
                visible  : sections,
            },
            activeSectionKey: firstSection && firstSection.id,
        });
    }

    /**
    * Manage section's contents when the data has been loaded
    *
    * @return void
    */
    onSectionColumnLoaded(element, fetchedData, allRequestsAreEmpty) {
        const { disabledColumnsIds, loadedSections } = this.state,
            { id }                                   = element;

        // The sections has been loaded at least once.
        if (loadedSections.indexOf(id) === -1) {
            loadedSections.push(id);
            this.setState({
                loadedSections
            });
        }

        requestAnimationFrame(this.updateViewport);

        // Manage requested/empty data
        if (!allRequestsAreEmpty) {
            return;
        }

        // Disable the column if data are empty
        disabledColumnsIds.push(id);
        this.setState({
            disabledColumnsIds
        });
    }


    /**
    * Open or Close kpi
    *
    * @return void
    */
    onNoKpiData() {
        this.setState({
            noKpiData: true
        });
    }

    /**
    * Open or Close kpi
    *
    * @return void
    */
    onFeedsLoaded(data) {
        this.setState({ noFeedData: data && data.length === 0 });

        requestAnimationFrame(this.updateViewport);
    }

    /**
    * Return the entity definition from knowledge
    *
    * @return object
    */
    getEntityDefinition() {
        const { entities } = this.state,
            { type }       = this.props;

        if (!entities) {
            return null;
        }

        return entities.find((entity) => entity.id === type);
    }

    /**
    * Returns  main section definition
    *
    * @returns {array}
    */
    getGroupsSections() {
        const { type, context }  = this.props,
            { entity, elements } = this.props;

        return getIdcardSections({ type, context, elements }, 'Group/Section').filter((section => {
            const { entityCondition } = section?.configuration || {},
                { org_class }         = entityCondition  || {};

            // Remove not included entities
            return org_class
                ? org_class.includes(entity.org_class)
                : true;
        }));
    }

    /**
    * Returns section definition to iterate and create metrics
    *
    * @returns {array}
    */
    getSections() {
        const { type, context }    = this.props,
            { entity, elements }   = this.props,
            { activeGroupSection } = this.state,
            mainSections           = this.getGroupsSections(),
            mainSection            = _.find(mainSections, mainSection => mainSection.key === activeGroupSection),
            { configuration }      = mainSection || {},
            { sections }           = configuration || {};

        return getIdcardSections({ type, context, elements }, 'Area/Section').filter((section => {
            const { entityCondition } = section?.configuration || {},
                { org_class }         = entityCondition  || {},
                belongsToMainSection  = mainSection ? _.includes(sections, section.id) : true;

            if (!belongsToMainSection) {
                return false;
            }

            // Remove not included entities
            if (org_class) {
                return org_class.includes(entity.org_class);
            }

            return true;
        }));
    }

    /**
    * Returns Right Sections
    *
    * @return array
    */
    getSectionsWithPosition(position) {
        const sections = this.getSections() || [];
        return sections.filter(section => section?.configuration?.position === position);
    }

    /**
    * Return content which will be displayed
    *
    * @param string section
    *
    * @return
    */
    getFilteredSectionMetrics(section) {
        const { disabledColumnsIds } = this.state;

        if (!section.content) {
            return [];
        }

        return section.content.filter(
            (content) => disabledColumnsIds.indexOf(content.metric) === -1
        );
    }

    /**
    * Get section information from HTML DOM
    *
    * @return void
    */
    getSectionDomInformation() {
        if (!this.idCardRef.current || !this.sectionsRef.current) {
            return [];
        }

        const sectionsNode = this.sectionsRef.current,
            idCardNode     = this.idCardRef.current,
            sectionsStyle  = window.getComputedStyle(sectionsNode),
            sectionsNodes  = sectionsNode.querySelectorAll('.idcard-section, .idcard-left'),
            headerNodeList = idCardNode.querySelectorAll('.header'),
            headerHeight   = (headerNodeList[0] ? headerNodeList[0].offsetHeight : 0) + parseInt(sectionsStyle.paddingTop);

        return _.map(
            sectionsNodes,
            (sectionNode, index) => {
                const sectionId = _.get(sectionNode, 'attributes.dataid.value'),
                    h3Node      = sectionNode.querySelector('.heading h3'),
                    h3Style     = h3Node ? window.getComputedStyle(h3Node) : 'none';

                return {
                    index,
                    id    : sectionId,
                    top   : sectionNode.offsetTop - headerHeight - 60,
                    height: sectionNode.clientHeight,
                    color : h3Style.backgroundColor
                };
            }
        );
    }

    /**
    * Update the tab indicator
    *
    * @return void
    */
    updateViewport() { // eslint-disable-line
        const { modalRef } = this.props;

        if (!this.idCardRef.current) {
            return;
        }

        const {
                viewport, activeSectionKey,
            }                         = this.state,
            { scrollTop }             = modalRef?.current || {},
            { clientHeight }          = this.idcardContentRef?.current || {},
            idCardEl                  = this.idCardRef.current,
            headerEl                  = idCardEl ? idCardEl.querySelector('.header') : null,
            tabsHeight                = idCardEl ? idCardEl.querySelector('.tabs').clientHeight : 0,
            headerClient              = headerEl ? headerEl.getBoundingClientRect() : {},
            headerHeight              = headerClient?.height || 0,
            // Add an y offset
            globalHeader              = document.querySelector('#global-header'),
            headerOffset              = headerHeight + (globalHeader ? globalHeader.clientHeight : 0),
            viewHeight                = clientHeight - headerOffset,
            sectionsInfo              = this.getSectionDomInformation(),
            visibleSections           = sectionsInfo.filter(section => section.top < scrollTop + viewHeight
                    && section.height + section.top + headerHeight - tabsHeight > scrollTop),
            scrollSection             = _.last(_.filter(sectionsInfo, sectionTop => sectionTop.top <= scrollTop + viewHeight)),
            // The true active section
            anchorOffset              = scrollSection ? scrollSection.height / 5 : 0, // Section offset
            lastSection               = _.last(visibleSections.filter(
                sectionTop => sectionTop?.top <= scrollTop + anchorOffset
            )),
            visibleSectionsAreUpdated = _.xor(
                visibleSections.map(section  => section?.id),
                viewport.visible.map(section => section?.id)
            ).length > 0,
            firstSection              = _.first(visibleSections);

        if (!visibleSectionsAreUpdated && lastSection?.id === activeSectionKey) {
            return;
        }

        this.setState({
            viewport: {
                scrollTop,
                visible: visibleSections,
            },
            activeSectionKey: firstSection && firstSection.id
        });
    }

    /**
    * Return the loading boolean
    *
    * @return false
    */
    isLoading() {
        const { entity }     = this.props,
            entityDefinition = this.getEntityDefinition();

        return !entity || !entityDefinition;
    }

    /**
    * Render the loading state of the sections.
    *
    * @return JSX
    */
    renderSections(position) {
        const {
                entity,
                navigateTo,
                search
            }                      = this.props,
            {
                disabledColumnsIds,
                showOneColumn
            }                      = this.state,
            isCollaboration        = this.isCollaboration(),
            sections               = position
                ? this.getSectionsWithPosition(position)
                : this.getSections();

        // No display when there's no entity or its definition.
        if (this.isLoading()) {
            return false;
        }

        return sections && _.map(sections, (section) => {
            const sectionPosition = section.configuration?.position;

            if (disabledColumnsIds.indexOf(section.id) !== -1) {
                return false;
            }

            if(!showOneColumn && position !== 'left' && sectionPosition === 'left') {
                return false;
            }

            if (_.includes(section.key, 'section-about')) {
                return false;
            }

            if (section.key === 'section-why' && !isCollaboration) {
                return this.renderWhySection();
            }

            const { configuration } = section,
                { useContext }      = configuration || {};

            return (
                <Element
                    key={section.id}
                    model={entity}
                    context={useContext && search ? search : null}
                    element={section}
                    navigateTo={navigateTo}
                    onDataLoaded={this.onSectionColumnLoaded}
                    registerCallbacks={this.registerCallbacks}
                />
            );
        });
    }

    /**
    * Add all items entity to the clipboard
    *
    * return Graph Component
    */
    addToClipboard() {
        const {
                makeClipboardItem, addToClipboard, entities,
                entity, elements,
            }                                 = this.props,
            { disabledColumnsIds, callbacks } = this.state,
            entityDefinition                  = entities.find((e) => e.id === entity.type),
            sections                          = _.clone(this.getSections()),
            sectionsToAdd                     = sections.filter((section) => disabledColumnsIds.indexOf(section.id) === -1),
            sectionsCallbacks                 = sectionsToAdd.map(
                section => callbacks[section.id] || null
            ).filter((cb) => cb !== null),
            addToClipboardCallbacks           = sectionsCallbacks.map(
                elementCallbacks => elementCallbacks.addToClipboard || null
            ).filter((cb) => cb !== null),
            labelPrefix                       = `${stripTags(entity.label).substring(0, 50)} `,
            source                            = `${_.capitalize(entityDefinition.label)} profile`,
            partOfElement                     = elements.find((el) => el.category === 'Area/Entity/PartOf'),
            acquiredByElement                 = elements.find((el) => el.category === 'Area/Entity/AcquiredBy'),
            relatedToElement                  = elements.find((el) => el.category === 'Area/Entity/Authority');

        // Add simple model list export
        addToClipboard(makeClipboardItem());

        // Trigger partOf list addToClipboard
        if (callbacks[partOfElement.id]) {
            callbacks[partOfElement.id].addToClipboard({ type: 'list', source, labelPrefix });
        }

        // Trigger acquiredBy list addToClipboard
        if (callbacks[acquiredByElement.id]) {
            callbacks[acquiredByElement.id].addToClipboard({ type: 'list', source, labelPrefix });
        }

        // Trigger relatedTo list addToClipboard
        if (callbacks[relatedToElement.id]) {
            callbacks[relatedToElement.id].addToClipboard({ type: 'list', source, labelPrefix });
        }

        // Trigger element images addToClipboard for each sections
        addToClipboardCallbacks.forEach((cb) => cb({ type: 'element', source, labelPrefix }));

        // Trigger list addToClipboard for each sections
        addToClipboardCallbacks.forEach((cb) => cb({
            type: 'list', limitMaxForced: 1000, source, labelPrefix
        }));
    }

    /**
    * Add all items entity to the clipboard
    *
    * return Graph Component
    */
    isLoaded() {
        const { loadedSections } = this.state,
            sections             = _.clone(this.getSections()),
            ignoreSections       = ['section-about-why', 'section-about-profile', 'section-why'],
            sectionsAreLoaded    = sections.reduce(
                (accumulator, section) => accumulator
                    && (
                        loadedSections.indexOf(section.id) !== -1 || _.includes(ignoreSections, section.key)
                    ),
                true
            );

        // TODO manage why is loaded
        return sectionsAreLoaded;
    }

    /**
    * Go to section (change the scroll top)
    *
    * @param {string} sectionKey
    *
    * @returns {void}
    */
    navigateToSectionFromWhy(sectionKey) {
        const { modalRef } = this.props,
            modalEl        = modalRef?.current, // Use tabs as a start point to navigate DOM
            tabsEl         = modalEl?.querySelector('.tabs-row:last-child'),
            tabs           = modalEl?.querySelector('.tabs'),
            targetNode     = modalEl?.querySelector(`.idcard-section[dataid="${sectionKey}"]`),
            top            = targetNode?.offsetTop - tabsEl?.clientHeight - tabs?.clientHeight;

        this.onChangeActiveSectionKey(sectionKey);

        // No section ?
        if (!targetNode) {
            return;
        }

        modalRef?.current?.scrollTo({
            top,
            behavior: 'smooth',
        });
    }

    /**
     * Render Row with Element Componant
     */
    renderWhyElementRow() {
        const {
                elements, search,
                entity,
            }          = this.props,
            whyElement = elements.find(el => el.category === 'Area/Entity/Why');

        return (
            <Row className="graphs-display">
                <Col>
                    <Element
                        model={entity}
                        element={whyElement}
                        navigateTo={this.navigateToSectionFromWhy}
                        context={search}
                        onNoKpiData={this.onNoKpiData}
                        registerCallbacks={this.registerCallbacks}
                    />
                </Col>
            </Row>
        );
    }


    /**
     *
     * @returns
     */
    renderLeftSide() {
        const className = "idcard-left entity-sections scrollable"; // eslint-disable-line
        return (
            <Col
                key="informations"
                className={className}
            >
                {this.renderOverview()}
                {this.renderSections('left')}
            </Col>
        );
    }


    /**
    * Render the Why Element
    *
    * @return JSX
    */
    renderWhySection() {
        return (
            <Row
                key="why"
                className="why-container idcard-section with-context"
                dataid="section-why"
            >
                <Col
                    className="why-entity section-why"
                    key="why"
                >
                    {this.renderWhyElementRow()}
                </Col>
            </Row>
        );
    }

    /**
    * Render entity overview part
    *
    * @return JSX
    */
    renderOverview() {
        const {
                activeGroupSection,
            }          = this.state,
            dataId     = activeGroupSection === 'main-section-why' ? 'section-about-why' : 'section-about-profile',
            classNames = [
                'about-entity',
                'idcard-section',
                'section-about',
                'idcard-section-left',
                { 'with-context': activeGroupSection === 'main-section-why' },
            ];

        return (
            <div
                key="informations"
                className={makeStrClassName(classNames)}
                dataid={dataId}
            >
                <div className="heading">
                    <h3>
                        <span className="anchor" />
                        <span className="text">Overview</span>
                    </h3>
                </div>
                {this.renderOverviewContent()}
            </div>
        );
    }


    /**
     * Render the content of overview
     *
     * @returns JSX
     */
    renderOverviewContent() {
        const { entity, type } = this.props,
            authorities        = entity.authority || [],
            clickableAuthority = authorities.find((auth) => auth.clickable),
            entitiesToShow     = [];

        // Show information for the first expert authority
        if (type === 'expert' && clickableAuthority) {
            entitiesToShow.push(clickableAuthority);
        }

        // Show the information of the orgunit entity
        if (type === 'orgunit') {
            entitiesToShow.push(entity);
        }

        // Show the information of the collaboration entities
        if (this.isCollaboration()) {
            const { model, withModel } = entity;
            entitiesToShow.push(model);
            entitiesToShow.push(withModel);
        }

        return (
            <div className="content rel">
                {this.renderRelatedTo()}
                {entitiesToShow.map(entityToShow => this.renderEntityInformation({
                    entity         : entityToShow,
                    showEntityLabel: clickableAuthority || entitiesToShow.length > 1
                }))}
            </div>
        );
    }


    /**
     * Render entity tags
     */
    renderTags(entity) {
        const { tags, search } = this.props;

        return (
            <Tags
                entity={entity}
                search={search}
                tags={tags}
            />
        );
    }


    /** Render a Information component
     *
     * @returns JSW
    */
    renderEntityInformation({entity, showEntityLabel}) {  // eslint-disable-line  max-lines-per-function
        const { orgClasses } = this.state,
            {
                navigateTo,
                getUserViewItemFromModel
            }                = this.props,
            Informations     = components.informations.orgunit,
            hasBookmark      = getUserViewItemFromModel(entity, 'bookmark'),
            /** Navigate to the entity */
            navigateToEntity = () => navigateTo(entity);

        return entity && (
            <div key={entity.id} className={`entity-information${entity.clickable && showEntityLabel ? ' is-clickable' : ''}`}>
                {showEntityLabel && (
                    <div className="entity-label">
                        <div className="logo">
                            <Icon
                                className="orgunit-logo"
                                id={entity.type}
                                folder="/entities/"
                                orgunitLogo={entity.id}
                                width={58}
                                height={58}
                                borderSize={0}
                                discShaped
                            />
                            {hasBookmark && (
                                <Icon
                                    className="bookmark"
                                    type="bookmark"
                                    width={16}
                                    color="#FFB400"
                                    discShaped
                                />
                            )}
                        </div>
                        <div className="authority-label" title={entity.label}>
                            <span onClick={navigateToEntity}>
                                {entity.label}
                            </span>
                            {this.renderTags(entity)}
                        </div>
                        {entity.clickable && (
                            <ModelAction
                                onClick={navigateToEntity}
                                entity={entity}
                            />
                        )}
                        {entity.clickable && (
                            <Icon
                                folder="/actions/"
                                id="link"
                                className="link"
                                width={10}
                                onClick={navigateToEntity}
                            />
                        )}
                    </div>
                )}
                <Informations
                    orgClasses={orgClasses}
                    entity={entity}
                />
            </div>
        );
    }

    /**
    * Render the related to
    * ( all affiliations) only for expert (entity.authority)
    *
    * @return JSX
    */
    renderRelatedTo() {
        const {
                entity, onClick, elements,
            }            = this.props,
            filters      = { type: 'orgunit' },
            element      = elements.find((el) => el.category === 'Area/Entity/Authority'),
            orgunits     = entity && entity.authority ? entity.authority.filter((e) => e.type === 'orgunit') : null;

        if (!orgunits || orgunits.length === 0) {
            return null;
        }

        return element ? (
            <Col className="related-to-entity rel">
                <h2>Related to:</h2>
                <Element
                    element={element}
                    model={entity}
                    parameters={{
                        uri              : `/${entity.type}s/${entity.id}`,
                        label            : 'Related to',
                        attributePath    : 'authority',
                        noExpertAuthority: true,
                        filters
                    }}
                    showMore
                    onClick={onClick}
                    registerCallbacks={this.registerCallbacks}
                />
            </Col>
        ) : null;
    }


    /**
    * Get Header component
    */
    getHeaderComponent() {
        const { type } = this.props;

        return components.header[type] || false;
    }

    /**
    * Render the header content of the ca(@return JSX
    */
    renderEntityHeader() {
        const {
                elements, entity, onClick, type,
                registerMetadata, tags, search,
            }                      = this.props,
            { activeGroupSection } = this.state,
            Header                 = this.getHeaderComponent(),
            sections               = this.getGroupsSections(),
            groupSection           = _.find(sections, section => section.key === activeGroupSection),
            classNames             = ['header', type];

        if (!Header || this.isCollaboration()) {
            return null;
        }

        return (
            <Row key="header" className={makeStrClassName(classNames)}>
                <Header
                    entity={entity}
                    addToClipboard={this.isLoaded() ? this.addToClipboard : null}
                    tags={tags}
                    onClick={onClick}
                    registerCallbacks={this.registerCallbacks}
                    elements={elements}
                    registerMetadata={registerMetadata}
                    emitEvent={emitEvent}
                    search={search}
                    activeGroupSection={groupSection?.label}
                />
            </Row>
        );
    }

    /**
    * Render the main section tabs: why, profile
    *
    * @returns {JSX}
    */
    renderMainTabs() {
        const { modalRef }   = this.props,
            { viewport }     = this.state,
            { scrollTop }    = viewport,
            mainSections     = this.getGroupsSections(),
            firstMainSection = _.first(mainSections);

        return (
            <Tabs
                classNames={['main']}
                firstSection={firstMainSection?.key}
                onChangeActiveSectionKey={this.onChangeMainSection}
                parentRef={modalRef}
                scrollTop={scrollTop}
                sections={mainSections}
                tabSeparator
            />
        );
    }

    /**
    * On change active section key
    *
    * @param {string} activeSectionKey
    */
    onChangeActiveSectionKey(activeSectionKey) {
        this.setState({ activeSectionKey });
    }

    /**
    * Render tabs links
    *
    * @return JSX
    */
    renderTabs() {
        const { modalRef }               = this.props,
            { disabledColumnsIds }       = this.state,
            { viewport, loadedSections } = this.state,
            { activeGroupSection }       = this.state,
            { scrollTop, visible }       = viewport,
            firstSection                 = _.first(visible),
            sections                     = _.clone(this.getSections()),
            classNames                   = [
                {'with-context': activeGroupSection === 'main-section-why'}
            ];

        return (
            <Tabs
                classNames={classNames}
                sections={sections}
                disabledColumnsIds={disabledColumnsIds}
                loadedSections={loadedSections}
                goToTop={this.goToTop}
                parentRef={modalRef}
                scrollTop={scrollTop}
                onChangeActiveSectionKey={this.onChangeActiveSectionKey}
                firstSection={firstSection?.id}
            />
        );
    }


    /**
    * Render information, concept, authority
    *
    * @return JSX
    */
    renderContent() {
        const { entity }    = this.props,
            { firstRender } = this.state;

        // Make sure we are ready to display the entity
        if (!entity) {
            return null;
        }

        if (firstRender) {
            return (
                <div className="loader">
                    <CssLoader type="ring" size={100}
                        thickness={2} color="#dddddd"
                    />
                </div>
            );
        }

        return (
            <Col
                key="content"
                className="idcard-right entity-sections tab-container"
                forwardedRef={this.sectionsRef}
            >
                <Col key="tabs" className="tabs">
                    {this.renderMainTabs()}
                    {this.renderTabs()}
                </Col>
                {this.renderOverview()}
                {this.renderSections()}
            </Col>
        );
    }


    /**
     * Test if the entity is a collaboration model
     *
     * @return boolean
     */
    isCollaboration() {
        const { entity } = this.props,
            { type }     = entity;

        return type.indexOf('_collaboration') >= 0;
    }


    /**
    * Produce the proper display of the document content
    *
    * @return JSX
    */
    render() {
        const { elements }    = this.props,
            { entity }        = this.props,
            { type }          = entity || {},
            { showOneColumn } = this.state,
            classNames        = ['idcard', type, 'sheet', 'rel'];

        if (!elements) {
            return false;
        }

        if (this.getHeaderComponent() && !this.isCollaboration()) {
            classNames.push('has-header');
        }

        return [
            this.renderEntityHeader(),
            <Row key="content">
                <Col
                    className={classNames.join(' ')}
                    forwardedRef={this.idCardRef}
                >
                    <Row forwardedRef={this.idcardContentRef} className="idcard-content">
                        {!showOneColumn && this.renderLeftSide()}
                        {this.renderContent()}
                    </Row>
                </Col>
            </Row>
        ];
    }

}

/* eslint-disable react/no-unused-prop-types */
IdCard.propTypes = {
    addToClipboard   : PropTypes.func,
    elements         : ImmutablePropTypes.list,
    entities         : ImmutablePropTypes.list,
    learnKnowledge   : PropTypes.func.isRequired,
    makeClipboardItem: PropTypes.func,
    navigateTo       : PropTypes.func,
    onClick          : PropTypes.func,
    registerMetadata : PropTypes.func,
    tags             : PropTypes.any,
    modalRef         : PropTypes.any,
    search           : PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    type             : PropTypes.oneOf(['orgunit', 'expert', 'orgunit_collaboration']).isRequired,
    context          : PropTypes.shape({}),
    entity           : PropTypes.shape({
        acquiredBy: PropTypes.array,
        authority : PropTypes.array,
        id        : PropTypes.string,
        label     : PropTypes.string,
        org_class : PropTypes.number,
        partOf    : PropTypes.array,
        type      : PropTypes.string,
        model     : PropTypes.shape(),
        withModel : PropTypes.shape(),
    }),
    model: PropTypes.shape({
        id   : PropTypes.string,
        label: PropTypes.string,
        type : PropTypes.string
    }),
    newslettersStream       : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    getUserViewItemFromModel: PropTypes.func,
};

IdCard.defaultProps = {
    entity : null,
    width  : false,
    onClick: _.noop,
    search : false
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    return {
        knowledge        : state.getIn('knowledge'),
        bookmarksList    : state.getIn(['userView', 'bookmark', 'list']),
        newslettersStream: state.getIn(['userView', 'newsletter', 'list']),
    };
};

export default connect(mapStateToProps, {
    learnKnowledge: learn,
    getUserViewItemFromModel,
    emitEvent,
})(IdCard);

