/**
 * SEARCH Actions Creator: create actions to fetch search, etc..
 *
 */
import { get, del }                from 'utils/api';
import { requestTimeout }          from 'utils/requestTimeout';
import * as types                  from '../types/auth';
import { getDefaultSharePlatform } from '../auth';

let fetchTokenTimeout     = null;
let fetchPlatformsTimeout = null;
let fetchTokenCbs         = [];

/**
 * Get all outer Share
 */
export const getOuterShareByExportId = (sharedId) => {
    return new Promise((resolve) => {
        get('/outer-share?shared_type=export&shared_id='+ sharedId+'&info=2')
            .then(({ body }) => {
                resolve(body);
            });
    });
};

/**
* Fetch all platforms
*
* @return {promise}
*/
export const fetchPlatforms = (cb) => (dispatch) => {
    if (fetchPlatformsTimeout) {
        fetchPlatformsTimeout.cancel();
    }

    fetchPlatformsTimeout = requestTimeout(
        () => {
            get('/platforms')
                .then(({ body }) => {
                    dispatch({ type: types.PLATFORMS_FETCH, payload: body });
                    cb && cb();
                });
        },
        100
    );
};

/**
* Get All tokens
*
* @return {promise}
*/
export const fetchPlatformsTokens = (cb) => (dispatch) => {
    fetchTokenCbs.push(cb);
    if (fetchTokenTimeout) {
        fetchTokenTimeout.cancel();
    }

    fetchTokenTimeout = requestTimeout(
        () => {
            get('/tokens')
                .then(({ body }) => {
                    dispatch({ type: types.PLATFORMS_TOKEN, payload: body });
                    fetchTokenCbs.forEach(callback => { callback && callback(); });
                    fetchTokenCbs = [];
                });
        },
        100
    );
};


/**
* Delete the token
*
* @return {promise}
*/
export const deleteTokensPlatforms = (tokenId) => (dispatch) => {
    del('/token/'+tokenId)
        .then(({ body }) => {
            dispatch({ type: types.PLATFORMS_DELETE_TOKEN, payload: {tokenId, isDelete: body} });
        });
};


/**
 * Get yoomap platforms
 *
 * @param {string} service
 *
 * @returns array
 */
export const getPlatformsOfService = (serviceName) => (dispatch, getState) => {
    const state         = getState(),
        auth            = state.get('auth'),
        platforms       = auth.get('platforms'),
        platformsObject = platforms && platforms.toJS  ? platforms.toJS() : {},
        platformsArray  = Object.values(platformsObject);

    return platformsArray.filter(service => service.key === serviceName);
};


/**
* Get the yoomap platform
*
* @returns boolean
*/
export const getDefaultPlatformOfService = (serviceName) => (dispatch, getState) => {
    const yoomapPlatforms  = getPlatformsOfService(serviceName)(dispatch, getState),
        defaultPlatformUrl = getDefaultSharePlatform()(dispatch, getState);

    if (!yoomapPlatforms) {
        return null;
    }

    return yoomapPlatforms.find(platform => platform.url === defaultPlatformUrl)
        ||  yoomapPlatforms[0];
};


// Export default
export default {
    fetchPlatforms,
    fetchPlatformsTokens,
    getPlatformsOfService,
    getDefaultPlatformOfService,
};

