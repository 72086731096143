/**
* Manage components collections from uri
*
* @imports:
*/
import React, { PureComponent } from 'react';
import { connect }              from 'react-redux';
import PropTypes                from 'prop-types';
import _                        from 'lodash';
import {
    stripTags, renderUnit, pluralize,
}                               from 'utils/text';
import { normalizeDateString }  from 'utils/date';
import { Icon }                 from 'helpers';
import { learn }                from '../store/actions/knowledge';
import { compileTagsSettings }  from 'store/actions/navigation';
import { emitEvent }            from 'store/actions/sockets';
import ModalBookmark            from 'helpers/Renderer/Bookmarks/ModalBookmark';
// Import entities components
import List                     from './Collection/List';
import DetailedList             from './Collection/DetailedList';
import Grid                     from './Collection/Grid';
import Inline                   from './Collection/Inline';
import Block                    from './Collection/Block';
import Action                   from './Action';
import CatchMount               from './CatchMount';
// Import display CSS
import './Collection/assets/inline.less';
import './Collection/assets/list.less';

const KNOWLEDGE_POOL         = {};
const VALID_BOOKMARKS_TO_ADD = ['patent', 'scidoc', 'project', 'clinicaltrial', 'orgunit'];
const VALID_COLLECTION_TYPE  = ['list'];
/**
* Collection Helper, manage to display collection provided by API
*/
class Collection extends PureComponent {

    /**
    * Prepare the component to be used.
    */
    constructor(props) {
        super(props);

        _.bindAll(
            this,                                                         // Bind this to:
            'onClick', 'onDelete', 'registerCallbacks', 'onSelectEntity', // Collection events
            'toggleAllSelection', 'getSelectedEntities', 'getEntities',
            'resetSelectedEntities', 'getTypeDefinition',
            'openModalBookmark', 'closeModalBookmark', 'renderModalBookmark', 'onAddBookmark'
        );

        this.nodeRef = React.createRef();

        this.state   = {
            entities                    : [],
            totalIsReached              : false,
            modalBookmarkAction         : false,
            collectionComponentCallbacks: {},
            selectedEntities            : [],
            tags                        : KNOWLEDGE_POOL.tags               || null,
            entitiesDefinition          : KNOWLEDGE_POOL.entitiesDefinition || null,
            exportKnowledges            : KNOWLEDGE_POOL.exportKnowledges   || null,
            parentWidth                 : false,
            parentHeight                : false,
            compiledTags                : false,
            collectionComponents        : {
                list  : this.isDetailedList() ? DetailedList : List,
                grid  : Grid,
                inline: Inline,
                block : Block,
            },
        };

        this.allowListRemovalActions = {
            delete: {
                label: 'Delete',
                cb   : this.onDelete,
                icon : <Icon id="delete" height={16} width={16} /> // eslint-disable-line react/jsx-max-props-per-line
            }
        };
    }

    /**
    * Triggered when the component is ready
    */
    componentDidMount() {
        const { registerCallbacks, entities } = this.props;

        this.learnAllKnowledge();

        // Register callbacks
        registerCallbacks && registerCallbacks('getEntities', this.getEntities);
        registerCallbacks && registerCallbacks('getSelectedEntities', this.getSelectedEntities);
        registerCallbacks && registerCallbacks('resetSelectedEntities', this.resetSelectedEntities);
        registerCallbacks && registerCallbacks('getTypeDefinition', this.getTypeDefinition);

        this.setEntities(entities);
    }

    /**
    * Make sure the component has loaded the data.
    * If not, try to requeue the ajax call.
    */
    componentDidUpdate(prevProps) {
        const { entities, mode }     = this.props,
            { memberSettings }       = this.props,
            { collectionComponents } = this.state,
            { list_view_mode }       = memberSettings || {},
            isListMode               = mode === 'list';

        if (entities !== prevProps.entities) {
            this.setEntities(entities);
        }
        if (list_view_mode !== prevProps.memberSettings?.list_view_mode) {
            this.setState({
                collectionComponents: {
                    ...collectionComponents,
                    list: this.isDetailedList() ? DetailedList : List,
                }
            });
        }

        if (isListMode) {
            this.updateCompiledTags();
        }
    }

    /**
    * Component will unmount
    *
    * @return void
    */
    componentWillUnmount() {
        this.cleanup && typeof this.cleanup === 'function' && this.cleanup();
    }

    /**
    * Is detailed list mode ?
    */
    isDetailedList() {
        const { memberSettings, type } = this.props,
            { showAllEntities }        = this.props,
            { list_view_mode }         = memberSettings || {},
            isDetailedList             = list_view_mode === 'DetailedList' && type === 'orgunit';

        return isDetailedList && showAllEntities;
    }

    /**
     * Set parent width/height in state
     *
     * @param {*} size
     */
    setParentSize = (size) => {
        const {width, height} = size;
        this.setState({
            parentWidth : width,
            parentHeight: height
        });
    };

    /**
    * Return selectedEntities from state
    *
    * @return array
    */
    getSelectedEntities() {
        const { selectedEntities } = this.state;

        return selectedEntities;
    }

    /**
    * Resets selectedEntities from state
    */
    resetSelectedEntities() {
        this.setState({
            selectedEntities: []
        });
    }

    /**
    * On open modal
    *
    * @param {object} entity
    * @param {array} entities
    *
    * @return void
    */
    onClick(entity, entities) {
        const { onClick }         = this.props,
            { type }              = entity,
            isCollaborationModels = type === 'orgunit_collaboration';

        // Check if the onClick callback control the workflow
        if (!onClick) {
            return;
        }

        // Do not pass collection entities for collaboration models (break the collection navigation)
        if (isCollaborationModels) {
            return onClick(entity);
        }

        // The click has been provided by a sub-collection.
        if (entities) {
            return onClick(entity, entities);
        }

        const currentEntities = this.getEntities(true),
            { id }            = entity,
            // Get firstEntity to map authority if need
            firstEntity       = _.first(currentEntities) || {},
            mustMapAuthority  = _.isUndefined(firstEntity.id) && !_.isUndefined(firstEntity.authority),
            entitiesToPass    = mustMapAuthority ? currentEntities.map((e) => e.authority) : currentEntities,
            cleanedEntities   = _.uniqBy(entitiesToPass, (item) => item.id);

        return id ? onClick(entity, cleanedEntities) : null;
    }

    /**
     * Delete the entity from the list using the entity id
     *
     * @param {*} entityId
     */
    onDelete(entityId) {
        const {updateListEntities} = this.props,
            { entities }           = this.state,
            newEntities            = entities.filter(entity => entity.id !== entityId);

        this.setState({ entities: newEntities });
        updateListEntities && updateListEntities(newEntities);
    }

    /**
    * Return the entities array to work with
    *
    * @param Boolean clickable Wants only clickable entities?
    *
    * @return array
    */
    getEntities(clickable = false) {
        const { entities:entitiesFromProps } = this.props,
            { entities:entitiesFromState }   = this.state,
            entitiesToReturn                 = entitiesFromState || entitiesFromProps; // Injected or Loaded Entities

        return clickable ? entitiesToReturn.filter(
            (entity) => entity.clickable
                || _.get(entity, 'authority.clickable', false)
                || (entity.type !== 'orgunit' && entity.type !== 'person')
        )
            : entitiesToReturn;
    }

    /**
    * Get the entities array
    *
    * @param {array} entities
    *
    * @return array
    */
    setEntities(entities) {
        const mappedEntities = entities
            ? entities.map(entity => {
                const canHandleAuthority = [
                    'orgunit', 'person', 'expert',
                    'project', 'clinicaltrial', 'patent',
                    'scidoc', 'webdoc', 'corporate_activity_event',
                    'news_link'
                ].indexOf(entity.type) === -1;

                return canHandleAuthority && entity.authority
                    ? entity.authority
                    : entity;
            })
            : [];

        this.setState({entities: mappedEntities});
    }

    /**
    * Deduce a value of a field from its definition.
    *
    * @return mixed
    */
    getFieldValue(content, field) {
        let fieldValue = _.get(content, field.id, null);

        // Manage empty values
        if (fieldValue === null) {
            return '';
        }

        // Normalize date values
        if (field.format && field.format.type === 'date') {
            fieldValue = normalizeDateString(fieldValue);
        }

        // Values that are non human readable
        if (field.format && field.format.type === 'humanize') {
            fieldValue = _.startCase(fieldValue);
        }

        // Flatten the array
        if (field.format && field.format.type === 'multivalue') {
            fieldValue = fieldValue.join(field.format.separator);
        }

        // Flatten a CSV-like value ie. thing<br/>junk<br/>trash
        if (field.format && field.format.type === 'flatmultivalue') {
            fieldValue = fieldValue.split(field.format.splitter).join(field.format.separator);
        }

        // Turn authorities to a flat list of strings
        if (field.format && field.format.type === 'authority') {
            fieldValue = _.map(fieldValue, 'label').join(field.format.separator);
        }

        // Boolean value to yes/no
        if (field.format && field.format.type === 'boolean') {
            fieldValue = fieldValue ? 'Yes' : 'No';
        }

        // Swap knowledge id(s) with label
        if (field.format && field.format.type === 'knowledge') {
            const { exportKnowledges } = this.state,
                book = _.get(exportKnowledges, _.camelCase(field.format.book), null),
                values = _.isArray(fieldValue) ? fieldValue : [fieldValue];

            if (book) {
                const bookValue = _.filter(book.toArray(), (page) => values.indexOf(page.id) !== -1);
                if (bookValue.length > 0) {
                    fieldValue = _.map(bookValue, 'label').join(field.format.separator || ',');
                }
            }
        }

        // Render a currency value
        if (field.format && field.format.type === 'currency') {
            fieldValue = fieldValue !== null ? renderUnit(fieldValue, _.get(content, field.format.currency, ''), true) : '';
        }

        return _.isString(fieldValue) ? stripTags(fieldValue) : fieldValue;
    }

    /**
    * Find the definition of the current metric.
    *
    * @return array || null
    */
    getTypeDefinition(specifiedType = null) {
        const { entitiesDefinition } = this.state,
            typeString               = specifiedType || this.getType(),
            typeDefinition           = entitiesDefinition
                && entitiesDefinition.filter((def) => def.id === typeString);

        return !typeDefinition || typeDefinition.size === 0 ? { label: '' } : typeDefinition.toArray()[0];
    }


    /**
     * Get type from props or from the first entities
     *
     * @returns {string}
     */
    getType() {
        const { type } = this.props,
            {
                entities: entitiesFromProps
            }          = this.props,
            {
                entities: entitiesFromState,
            }          = this.state,
            entities   = entitiesFromProps || entitiesFromState;

        return  type || entities.length > 0 && entities[0].type;
    }


    /**
    * Learn all the required knowledges for exports
    */
    learnAllKnowledge() {
        const { learnKnowledge } = this.props,
            {
                tags, entitiesDefinition, exportKnowledges,
                filters,
            }                    = this.state;

        if (!tags) {
            learnKnowledge(['tags']).then(({ tags }) => {
                KNOWLEDGE_POOL.tags = tags;
                this.setState({
                    tags,
                });
            });
        }

        if (!filters) {
            learnKnowledge(['filters']).then(({ filters }) => {
                KNOWLEDGE_POOL.filters = filters;
                this.setState({
                    filters
                });
            });
        }

        if (!entitiesDefinition || !exportKnowledges) {
            learnKnowledge('entities')
                .then((knowledgeEntities) => {
                    const entities = knowledgeEntities.entities.toArray(),
                        otherKnowledgesId = [];

                    // Find book names in entities
                    _.each(entities, (entity) => {
                        if (!entity.exports) {
                            return;
                        }

                        _.each(entity.exports.columns, (field) => {
                            if (_.get(field, 'format.type', null) === 'knowledge') {
                                otherKnowledgesId.push(field.format.book);
                            }
                        });
                    });

                    // Learn and update the state with all the books
                    learnKnowledge(otherKnowledgesId)
                        .then((otherKnowledges) => {
                            KNOWLEDGE_POOL.entitiesDefinition = knowledgeEntities.entities;
                            KNOWLEDGE_POOL.exportKnowledges   = otherKnowledges;

                            this.setState({
                                entitiesDefinition: KNOWLEDGE_POOL.entitiesDefinition,
                                exportKnowledges  : KNOWLEDGE_POOL.exportKnowledges
                            });
                        });
                });
        }
    }


    /**
    * Get sorts options to add actions
    *
    * @returns array
    */
    getSortOptions() {
        const { sorts, setActiveSortId, sortThresholdReached } = this.props;

        if (!sorts || !setActiveSortId || sorts?.length === 0) {
            return [];
        }

        const sortsOptions = sorts.map((sort) => {
            const {
                    id, label, active,
                    direction, labelDirectionInverse,
                }                             = sort,
                ascDirection = direction === 'asc',
                iconType     =  (labelDirectionInverse ? !ascDirection : ascDirection) ? 'sort-ascending' : 'sort-descending';
            return {
                label,
                icon: <Icon
                    id={iconType}
                    height={16}
                />,
                options : id,
                cb      : setActiveSortId,
                selected: active
            };
        });

        return sortThresholdReached ?  [{
            icon: <Icon id="ordered-list" height={16}
                color="inherit" className="icon"
            />,
            label   : 'Sort is not available on this list',
            title   : 'Because of a large result number (>10000), sort is not available for this list',
            disabled: true,
        }] : [{
            icon: <Icon id="ordered-list" height={16}
                className="icon"
            />,
            label  : 'Sort by',
            actions: sortsOptions
        }];
    }

    /**
     * Get actions for collection
     *
     * @returns {array}
     */
    getSelfActions() {
        const { addToClipboard, entities } = this.props,
            { selectedEntities }           = this.state,
            actionOptions                  = this.getActionOptions(),
            hasSelection                   = selectedEntities.length > 0,
            nbEntities                     = hasSelection ? selectedEntities.length : entities.length,
            typeString                     = this.getTypeString();

        return [
            {
                icon: <Icon id="xls" folder="/file-type/"
                    height={18} className="add-to-clipboard"
                />,
                options: actionOptions,
                cb     : addToClipboard,
                label  : `${!hasSelection ? 'First ' : ''}`
                    + `${nbEntities} ${pluralize(typeString, nbEntities)}`
                    + `${hasSelection ? ' selected' : ''}`,
                exportNb: nbEntities,
            },
        ];
    }


    /**
     * Get options for passing to Option Componant
     *
     * @returns object
     */
    getActionOptions() {
        const  { entities }                          = this.props,
            { entitiesDefinition, selectedEntities } = this.state,
            typeDefinition                           = this.getTypeDefinition();

        return {
            type: 'list', entitiesDefinition, selectedEntities, entities, typeDefinition
        };
    }


    /**
     * Get type
     *
     * @returns string
     */
    getTypeString() {
        const typeDefinition = this.getTypeDefinition();

        return typeDefinition && typeDefinition.label;
    }


    /**
    * Get default exports
    *
    * @returns {array}
    */
    getDefaultExports() {
        const {
                total, entities,
                addToClipboard,
            }                    = this.props,
            { selectedEntities } = this.state,
            hasSelection         = selectedEntities.length > 0,
            exportNbItems        = [50, 100, 500, 1000],
            // Use total for max value
            exportNbItemsClean   = _.uniq(exportNbItems.map(exportNb => exportNb <= total ? exportNb : total)),
            typeString           = this.getTypeString(),
            actionOptions        = this.getActionOptions(),
            selfActions          = this.getSelfActions(),
            exports              = exportNbItemsClean
                .filter(exportNb => (
                    !hasSelection && exportNb !== entities.length || hasSelection
                ) && exportNb)  // Remove duplicate
                .map(exportNb => (
                    {
                        label: `${exportNb === total ? 'All' : 'First'} ${exportNb} ${pluralize(typeString, exportNb)}`,
                        icon : <Icon id="xls" folder="/file-type/"
                            height={18} className="add-to-clipboard"
                        />,
                        options: { ...actionOptions, limitMaxForced: exportNb },
                        cb     : addToClipboard,
                        exportNb,
                    }
                ));

        return _.concat(selfActions, exports);
    }

    /**
     * Get default pdf exports
     *
     * @param {array} defaultExports
     */
    getDefaultPdfExports(defaultExports) {
        const defaultPdfExports = _.map(defaultExports, defaultExport => {
            return {
                ...defaultExport,
                options: {
                    ...defaultExport.options,
                    type: 'booklet',
                }
            };
        });

        return _.filter(defaultPdfExports, defaultPdfExport => defaultPdfExport.exportNb <= 100);
    }

    /**
    * Get icon clipboard add
    */
    getIconClipboardAdd() {
        return (
            <Icon id="clipboard-add" folder="/shortcuts/"
                height={18} className="add-to-clipboard"
            />
        );
    }

    /**
    * Get exports options to add actions
    *
    * @returns array
    */
    getExportsOptions() {
        const { allowExport } = this.props;

        if (!allowExport) { return []; }

        const {
                element, mode,
                addToClipboard,
                model,
            }                  = this.props,
            { type }           = model || {},
            typeString         = this.getTypeString(),
            actionOptions      = this.getActionOptions(),
            defaultXlsxExports = this.getDefaultExports(),
            defaultPdfExports  = this.getDefaultPdfExports(defaultXlsxExports),
            elementActions     = [
                {
                    label: 'Add to export clipboard',
                    icon : <Icon id="png" folder="/file-type/"
                        height={18} className="add-to-clipboard"
                    />,
                    options: { ...actionOptions, type: 'element' },
                    cb     : addToClipboard,
                },
            ],
            exportXlsxOptions = element ? elementActions : defaultXlsxExports,
            exportPdfOptions  = element ? elementActions : defaultPdfExports;

        // For now in inline mode only xlsx export is concerned
        if (mode === 'inline') { return exportXlsxOptions; }

        const iconClipboardAdd = this.getIconClipboardAdd(),
            xlsxExport         = {
                icon   : iconClipboardAdd,
                label  : 'Add to xlsx export clipboard',
                actions: exportXlsxOptions,
            },
            pdfExports         = {
                icon   : iconClipboardAdd,
                label  : 'Add to pdf export clipboard',
                actions: exportPdfOptions,
            };

        // Export patents in pdf only in query context
        if (typeString === 'invention' && type === 'query') {
            return [
                ...(exportXlsxOptions.length > 0 ? [xlsxExport] : []),
                ...(exportPdfOptions.length > 0 ? [pdfExports] : []),
            ];
        }

        return exportXlsxOptions.length > 0 ? [xlsxExport] : [];
    }

    /**
     *
     */
    openModalBookmark() {
        this.setState({modalBookmarkAction: true});
    }

    /**
     *
     */
    closeModalBookmark() {
        this.setState({modalBookmarkAction: false});
    }

    /**
     * Add bookmark(s)
     */
    onAddBookmark() {
        this.resetSelectedEntities();
        this.closeModalBookmark();
    }

    /**
     * Render the modal action
     *
     * @returns self
     */
    renderModalBookmark() {
        const { selectedEntities } = this.state;

        return (
            <ModalBookmark
                title="Add to bookmarks"
                entities={selectedEntities}
                onCancel={this.closeModalBookmark}
                onSubmit={this.onAddBookmark}
            />
        );
    }

    /**
    *
    *
    * @returns array
    */
    getBookmarkOptions() {
        const { mode:collectionType } = this.props,
            {
                selectedEntities,
                entities
            }                         = this.state,
            { type:entityType }       = (entities && entities[0]) || {},
            maxElements               = 50,
            validEntityType           = VALID_BOOKMARKS_TO_ADD.find(t => t === entityType),
            validCollectionType       = VALID_COLLECTION_TYPE.find(t => t === collectionType);

        // No actions if entity type or collection type isn't exists in the valid list
        if(!validEntityType || !validCollectionType) {
            return [];
        }

        const typeDefinition  = this.getTypeDefinition(entityType),
            numSelected       = selectedEntities.length,
            entityString      = typeDefinition && typeDefinition[numSelected > 1 ? 'label_plural': 'label'] || '',
            cb                = this.openModalBookmark,
            exceededMaxNumber = numSelected > maxElements,
            disabled          = numSelected === 0 || exceededMaxNumber,
            label             = exceededMaxNumber
                ? `Please add a maximum of ${maxElements} elements`
                : `Add ${numSelected || ''} ${entityString} to bookmarks`;

        return [{
            icon: <Icon type="star" color="current"
                height={16} className="icon"
            />,
            cb,
            label,
            disabled,
        }];
    }

    /**
    * Decorate the collection with actions
    *
    * @return JSX
    */
    decorateWithActions(content) {
        const { actions, mode, disableActions } = this.props,
            { disableSort, bookmarkable }       = this.props,
            {
                entitiesDefinition,
                modalBookmarkAction,
            }                   = this.state,
            sortsOptions        = !disableSort ? this.getSortOptions()     : [],
            exportsOptions      = !disableActions ? this.getExportsOptions()  : [],
            clipboardOptions    = !disableActions && bookmarkable ? this.getBookmarkOptions() : [],
            type                = this.getType(),
            dataQaKey           = `${type}-${mode}`,
            from                = { componentName: mode === 'inline' ? 'Collection/Inline' : 'Collection/List' },
            actionsList         = _.concat(
                mode === 'inline' ? [] : sortsOptions,
                exportsOptions,
                clipboardOptions,
                actions,
            );

        return (
            <div
                className="Collection"
                data-qa-key={dataQaKey}
            >
                {content}
                {entitiesDefinition && (
                    modalBookmarkAction
                        ? this.renderModalBookmark()
                        : (
                            <Action
                                from={from}
                                actions={actionsList}
                                emitEvent={emitEvent}
                                dataQaKey={`${dataQaKey}-actions-button`}
                            />
                        )
                )}
            </div>
        );
    }

    /**
    * On select row => set selectedEntities in state
    *
    * @return void
    */
    onSelectEntity(entity) {
        const { selectedEntities } = this.state,
            newSelectedEntities    = _.xor(selectedEntities, [entity]);

        this.setState({ selectedEntities: newSelectedEntities });
    }

    /**
    * Toggle all selection
    *
    * @return void
    */
    toggleAllSelection() {
        const {
                selectedEntities,
                entities,
            }              = this.state,
            emptySelection = selectedEntities.length === 0,
            loadedEntities = _.filter(entities, entity => !entity.isLoading);

        this.setState({ selectedEntities: emptySelection ? _.clone(loadedEntities) : [] });
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { collectionComponentCallbacks } = this.state,
            /**
             * If cb is undefined the default cd return a empty object
             */
            defaultCb                          = () => {};

        collectionComponentCallbacks[action] = cb || defaultCb;

        this.setState({ collectionComponentCallbacks });
    }

    /**
     * Update compiledTags in state
     */
    updateCompiledTags() {
        const {
                model, compileTagsSettings,
                bookmarksStats, foldersStats
            }                     = this.props,
            { compiledTags }      = this.state,
            bookmarkFolderForTags = _.get(model, 'settings.bookmarkFolderForTags', false),
            compiledTagsPromise   = compileTagsSettings({ folderId: bookmarkFolderForTags });

        if(
            !bookmarksStats?.get('modelsAreLoaded')
            || !foldersStats?.get('modelsAreLoaded')
        ) {
            return;
        }

        compiledTagsPromise.then((newCompiledTags) => {
            if (JSON.stringify(newCompiledTags?.tags) === JSON.stringify(compiledTags)) {
                return;
            }

            this.setState({
                compiledTags: newCompiledTags?.tags || false
            });
        });
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {      // eslint-disable-line max-lines-per-function
        const {
                mode, entityClassName, bindClickOnComponent, logoSize, useLoading, groupByDate,
                disabledEntities, type, model, allowListRemoval, renderSuffix, className,
                allowExport, context, forwardedRef, disableModal, forceLogo, useSeparator, color,
                renderActionsInline, draggable, disableInteractivity, isCapture, openedModalId, showAllEntities,
                getScrollSelectors, columns,
            }                                    = this.props,
            { tags, filters }                    = this.state,
            { collectionComponents }             = this.state,
            { totalIsReached, selectedEntities } = this.state,
            { parentWidth, parentHeight }        = this.state,
            { entities, compiledTags }           = this.state,
            CollectionComponent                  = collectionComponents[mode],
            overwriteEntityActions               = allowListRemoval && this.overwriteEntityActions;

        if (!CollectionComponent) {
            throw new Error(`Collection component: "${mode}" does not exists.`);
        }

        if (!tags) {
            return false;
        }

        return this.decorateWithActions(
            <CatchMount>
                <CollectionComponent
                    columns={columns}
                    reference={this.nodeRef}
                    width={parentWidth}
                    height={parentHeight}
                    disabledEntities={disabledEntities}
                    model={model}
                    context={context}
                    type={type}
                    entityClassName={entityClassName}
                    bindClickOnComponent={bindClickOnComponent}
                    logoSize={logoSize}
                    renderSuffix={renderSuffix}
                    useLoading={useLoading}
                    groupByDate={groupByDate}
                    className={className}
                    allowExport={allowExport}
                    forwardedRef={forwardedRef}
                    disableModal={disableModal}
                    openedModalId={openedModalId}
                    entities={entities}
                    totalIsReached={totalIsReached}
                    onClick={this.onClick}
                    onSelectEntity={this.onSelectEntity}
                    toggleAllSelection={this.toggleAllSelection}
                    selectedEntities={selectedEntities}
                    registerCallbacks={this.registerCallbacks}
                    overwriteEntityActions={overwriteEntityActions}
                    tags={tags}
                    filters={filters}
                    compiledTags={compiledTags}
                    forceLogo={forceLogo}
                    useSeparator={useSeparator}
                    color={color}
                    renderActionsInline={renderActionsInline}
                    draggable={draggable}
                    disableInteractivity={disableInteractivity}
                    isCapture={isCapture}
                    showAllEntities={showAllEntities}
                    getScrollSelectors={getScrollSelectors}
                />
            </CatchMount>
        );
    }

}

Collection.propTypes = {
    actions             : PropTypes.array,
    addToClipboard      : PropTypes.func,
    allowExport         : PropTypes.bool,
    bookmarkable        : PropTypes.bool,
    bookmarksStats      : PropTypes.any,
    columns             : PropTypes.object,
    foldersStats        : PropTypes.any,
    element             : PropTypes.any,
    entities            : PropTypes.array,
    disabledEntities    : PropTypes.array,
    learnKnowledge      : PropTypes.func,
    mode                : PropTypes.string,
    model               : PropTypes.object,
    context             : PropTypes.object,
    onClick             : PropTypes.func,
    registerCallbacks   : PropTypes.func,
    setActiveSortId     : PropTypes.func,
    sortThresholdReached: PropTypes.bool,
    disableActions      : PropTypes.bool,
    disableSort         : PropTypes.bool,
    allowListRemoval    : PropTypes.bool,
    updateListEntities  : PropTypes.any,
    forwardedRef        : PropTypes.any,
    total               : PropTypes.number,
    logoSize            : PropTypes.number,
    bindClickOnComponent: PropTypes.bool,
    useLoading          : PropTypes.bool,
    groupByDate         : PropTypes.bool,
    disableModal        : PropTypes.bool,
    forceLogo           : PropTypes.bool,
    useSeparator        : PropTypes.bool,
    type                : PropTypes.string,
    entityClassName     : PropTypes.string,
    className           : PropTypes.string,
    renderSuffix        : PropTypes.func,
    color               : PropTypes.any,
    renderActionsInline : PropTypes.bool,
    draggable           : PropTypes.bool,
    disableInteractivity: PropTypes.bool,
    isCapture           : PropTypes.bool,
    openedModalId       : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    sorts               : PropTypes.oneOfType([PropTypes.shape({map: PropTypes.func}), PropTypes.array]),
    memberSettings      : PropTypes.object,
    compileTagsSettings : PropTypes.func,
    showAllEntities     : PropTypes.bool,
    getScrollSelectors  : PropTypes.func,
};

Collection.defaultProps = {
    allowExport     : true,
    bookmarkable    : true,
    entities        : false,
    disableActions  : false,
    disableSort     : false,
    disabledEntities: [],
    mode            : 'list',
    actions         : [],
    onClick         : () => true,
    showAllEntities : true,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = state => ({
    knowledge     : state.get('knowledge'),
    bookmarksStats: state.getIn(['userView', 'bookmark', 'stats']),
    foldersStats  : state.getIn(['userView', 'bookmark_folder', 'stats']),
    memberSettings: state.getIn(['auth', 'member', 'settings']),
});

export default connect(mapStateToProps, {
    learnKnowledge: learn,
    emitEvent,
    compileTagsSettings,
})(Collection);
