/**
 * Display a sidebar to improve Search UX
 *
 * @return Component
 */

import _                                                  from 'lodash';
import React, { Component }                               from 'react';
import PropTypes                                          from 'prop-types';
import { connect }                                        from 'react-redux';
import { Modal }                                          from 'antd';
import ModalBookmark                                      from 'helpers/Renderer/Bookmarks/ModalBookmark';
import { Action, Icon }                                   from 'helpers';
import {
    capitalize, str2DomFormat,  makeStrClassName,
    formatNumberWithMagnitude, sanitizeFilename, pluralize
}                                                         from 'utils/text';
import ImmutablePropTypes                                 from 'react-immutable-proptypes';
import { learn }                                          from 'store/actions/knowledge';
import { emitEvent }                                      from 'store/actions/sockets';
import { dataPatch, getEntitiesModelsPromise }            from 'utils/api';
import YoomapSendOrganization                             from 'helpers/Yoomap/SendOrganization';
import {
    entityIsInNewsletter, getUserViewItemFromModel,
    toggleEntityInNewsletter, getModelsOfType, getDataEntity, addItems
}                                                         from 'store/actions/userView';

import {
    addBookmark, removeBookmarkFromFolder, updateBookmark, moveBookmark
}                                               from 'store/actions/userView/bookmarks';
import {
    removeBookmarkFolder, getRootFolder, getFolderBranch, getBookmarksOfBranches
}                                               from 'store/actions/userView/bookmarksFolders';

import './assets/model.less';


/**
* Class ModelAction
*
*/
class ModelAction extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'render', 'toggleTag', 'toggleEntityToNewsletter', 'updateLabel',
            'addEntityOpenAction', 'addBaseActions', 'addNewsletterActions', 'onActionToggleDisplay',
            'addBookmarksActions', 'addAddToClipBoardAction', 'getClickAction', 'getActions', 'setYoomapActions',
            'addBookmarkFolderActions', 'removeBookmarksFolder', 'closeModalRemoveBookmarkFolder', 'exportBookmarkFolder',
            'modalRemoveBookmarkFolder', 'updateQuery', 'closeRenameQuery', 'closeModalBookmark', 'onAddBookmark'
        );

        this.state = {
            menuVisible: false,
        };
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['tags', 'entities']).then(this.setState.bind(this));
    }


    /**
    * A React lifecycle method that is called after the component receives new props.
    *
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { entity }   = nextProps,
            { cachedTags } = prevState;

        // Reset the cachedTags when cachedTags is same tags entity
        if (_.isArray(cachedTags) && _.xor(entity?.tags, cachedTags).length === 0) {
            prevState.cachedTags = null;
        }

        return {
            prevState
        };
    }


    /**
    * If the menu is visible, then the component should update
    *
    * @returns The return value is a boolean value that tells React component should update.
    */
    shouldComponentUpdate(nextProps, nextState) {
        const { forceRender } = nextProps,
            { menuVisible }   = nextState;

        return menuVisible || forceRender;
    }


    /**
    * A boolean value that is set to true if the current item is a bookmark folder.
    */
    isBookmarkFolder() {
        const { entity } = this.props,
            { type }     = entity;

        return type === 'bookmark_folder';
    }


    /**
     * If the entity is a data entity, fetch the web entity
     *
     * @returns Void
     */
    manageWebEntity() {
        const { entity }     = this.props,
            isBookmarkFolder = this.isBookmarkFolder(),
            isDataEntity     = _.isUndefined(entity.entity)
                && entity.id
                && !isBookmarkFolder;

        if (!isDataEntity) {
            this.setState({ webEntity: false });
            return;
        }

        // Is a dataEntity
        this.fetchWebEntity();
    }


    /**
     * Fetch web entity (when entity is a dataEntity)
     *
     * @returns Void
     */
    async fetchWebEntity() {
        const { entity } = this.props;

        if (this.webEntityFetchCalled) {
            return;
        }

        this.webEntityFetchCalled = true;

        const models = await getEntitiesModelsPromise([entity.id], {service_data_model_size: 'short'});

        models.entities[0] && this.setState({ webEntity: models.entities[0] });
    }


    /**
    * Get the entity
    *
    * @returns object
    */
    getEntity() {
        const { entity }  = this.props,
            { webEntity } = this.state;

        return webEntity || entity;
    }

    /**
    * Open the Classify popin
    *
    * @param object entity The entity to classify
    * @param object entity The tag to toggle
    *
    * @return void
    */
    toggleTag(userViewItem, tag, folder_id) {
        const {
                updateBookmark,
                emitEvent
            }              = this.props,
            { cachedTags } = this.state,
            bookmark       = userViewItem.model || {},
            realTags       = cachedTags || bookmark.tags,
            tags           = folder_id
                ? realTags
                : _.xor((realTags), [tag.id]),
            disabled_tags_by_folders =  this.computeDisabledTagsByFolders(bookmark, tag, folder_id);

        // Got tags
        if (!_.isNull(tags)) {
            emitEvent({
                module: 'bookmark',
                name  : 'update-disabled-tags',
                data  : {
                    disabled_tags: disabled_tags_by_folders[folder_id],
                    model        : bookmark.entity.entity,
                    folder       : { id: folder_id }
                },
                attributesFilter: ['model.id', 'model.type', 'disabled_tags', 'folder.id']
            });

            // Store it in state (cachedTags)
            this.setState({ cachedTags: tags });
            // Update tags to API
            // TODO: make attach/detach ??
            updateBookmark(userViewItem, {
                tags,
                disabled_tags_by_folders
            });
        }
    }

    /**
     *
     * @param {object} disabled_tags_by_folders
     * @param {string} tag
     * @param {string} folder_id
     * @returns
     */
    computeDisabledTagsByFolders(bookmark, tag, folder_id) {
        const {
            disabled_tags_by_folders
        } = bookmark || {};

        if(folder_id) {
            const tags = disabled_tags_by_folders && disabled_tags_by_folders[folder_id];
            return  {
                ...disabled_tags_by_folders || {},
                [folder_id]: _.xor(
                    tags || [],
                    [tag.id]
                )
            };
        }

        if(!folder_id) {
            return _.mapValues(
                disabled_tags_by_folders || {},
                (tags) => tags.filter(disabledTag => disabledTag !== tag.id)
            );
        }

        return {};
    }

    // TODO: check & refact tag folders
    /**
    * Classify actions
    *
    * @return self
    */
    getClassifyActions(userViewItem, parentFolderItems) { // eslint-disable-line max-lines-per-function
        const{ tags, cachedTags } = this.state,
            bookmark              = userViewItem.model,
            classifyActions       = {},
            {
                tags:bookmarkTags,
                disabled_tags_by_folders
            }                     = bookmark || {},
            bookmarkedEntity      = bookmark?.entity || {},
            { type }              = bookmarkedEntity.entity || {},
            orderedFolders        = this.orderFolders(parentFolderItems),
            isOrgunit             = type === 'orgunit';

        if (!tags || !bookmarkTags || !isOrgunit) {
            return {};
        }

        tags.forEach((tag) => {
            const checked    = (cachedTags || bookmarkTags).indexOf(tag.id) !== -1,
                subActions   = [],
                icon         = (
                    <Icon
                        type={tag.icon}
                        theme="filled"
                        style={{ color: tag.color }}
                        className="icon"
                        height={14}
                        color={tag.color}
                    />
                );

            checked && orderedFolders.forEach(
                folder => {
                    const { entity_id: folder_id, model } = folder,
                        { label }        = model || {},
                        folderTags       = disabled_tags_by_folders && disabled_tags_by_folders[folder_id] || [],
                        selected         = !folderTags.includes(tag.id);

                    subActions[folder_id] = {
                        label,
                        selected,
                        icon     : <Icon type="folder" height={14} />,
                        cb       : () => { this.toggleTag(userViewItem, tag, folder_id); },
                        openAfter: ['classify', 'sub-classify', `sub-classify::${tag.id}`]

                    };
                }
            );

            classifyActions[tag.id] = {
                icon,
                preserveIconColor: true,
                cb               : () => { this.toggleTag(userViewItem, tag); },
                openAfter        : ['classify', 'sub-classify'],
                key              : tag.id,
                label            : tag.label,
                actions          : subActions,
                selected         : checked,
            };
        });

        return classifyActions;
    }


    /**
     * Get newsletter key by entity type
     *
     * @param {object} dataEntity
     * @returns
     */
    getNewsletterKeyByEntity(entity) {
        const type              = this.getDataEntityType(entity),
            newsletterKeyByType = {
                orgunit: 'competitive-insights',
                query  : 'topic-insights',
            };

        return newsletterKeyByType[type];
    }


    /**
    * Add/remove search to newsletter (topic insight)
    *
    * @param {object} entity
    */
    toggleEntityToNewsletter(entity) {
        const {
                toggleEntityInNewsletter
            }             = this.props,
            dataEntity    = getDataEntity(entity),
            newsletterKey = this.getNewsletterKeyByEntity(entity);

        dataEntity && newsletterKey && toggleEntityInNewsletter(dataEntity, newsletterKey);
    }


    /**
     * Orgunit or search is in insight newsletter
     *
     * @param {object} entity
     *
     * @returns boolean
     */
    isInNewsletter() {
        const {
                entityIsInNewsletter, getModelsOfType
            }             = this.props,
            newsletters   = getModelsOfType('newsletter'),
            entity        = this.getEntity(),
            newsletterKey = this.getNewsletterKeyByEntity(entity),
            newsletter    = newsletters && newsletterKey
                && newsletters.find(newsletter => newsletter.key === newsletterKey);

        return newsletter && entityIsInNewsletter(entity);
    }


    /**
    * When the menu is toggled, the state of the menu is updated
    */
    onActionToggleDisplay({ menuVisible }) {
        requestAnimationFrame(() => {
            const { onActionToggleDisplay } = this.props,
                { menuVisible: menuVisibleInState } = this.state;

            if (menuVisibleInState !== menuVisible) {
                this.setState({ menuVisible });
            }

            if (onActionToggleDisplay) {
                onActionToggleDisplay({ menuVisible });
            }
        });
    }


    /**
    * Get function to make actions object
    *
    * @returns function
    */
    getMakeActions() {
        const isBookmarkFolder = this.isBookmarkFolder(),
            { onlyActions }    = this.props;

        this.actions = {};

        if (isBookmarkFolder) {
            this.addBookmarkFolderActions();
            return;
        }

        if (!onlyActions || onlyActions.includes('entity_open')) { this.addEntityOpenAction(); }
        if (!onlyActions || onlyActions.includes('base'))        { this.addBaseActions(); }
        if (!onlyActions || onlyActions.includes('bookmark'))    { this.addBookmarksActions(); }
        if (!onlyActions || onlyActions.includes('newsletter'))  { this.addNewsletterActions(); }
        if (!onlyActions || onlyActions.includes('clipboard'))   { this.addAddToClipBoardAction(); }
    }


    /**
     *
     */
    getClickAction(userViewItem) {
        const { clickAction } = this.props;

        if(typeof clickAction === 'function') {
            return clickAction;
        }

        if (!userViewItem && clickAction?.includes('add_bookmark')) {
            return (e) => {
                this.setState({ modalBookmarkAction: 'add' });
                e.stopPropagation();
                e.preventDefault();
            };
        }
    }


    /**
     * Get actions
     *
     * returns array
     */
    getActions() {
        const { onlyActions } = this.props,
            {
                webEntity, getActionCalled, menuVisible
            }                = this.state,
            mustManageEntity = _.isUndefined(webEntity);

        if (mustManageEntity || !menuVisible) {
            // Fetch web entity to have information to match with bookmark and newsletters
            mustManageEntity && this.manageWebEntity();

            return !(onlyActions?.length === 0)
                ? [{
                    label    : 'Loading actions',
                    isLoading: true,
                }]
                : [];
        }

        this.getMakeActions();

        if (!getActionCalled) {
            this.setState({ getActionCalled: true });
        }

        return this.actions;
    }


    /**
     * Get base action by model type
     *
     * @return array
     */
    addEntityOpenAction() {
        const { onClick } = this.props,
            entity        = this.getEntity(),
            type          = this.getDataEntityType(entity);

        if (onClick) {
            const isQuery = type === 'query',
                icon      = isQuery
                    ? (
                        <Icon type="caret-right" theme="filled"
                            className="icon" height={14}
                        />
                    )
                    : (
                        <Icon folder="/actions/" id="link"
                            height={12} className="icon"
                        />
                    );

            this.actions.open = {
                icon,
                cb      : () => onClick(entity),
                label   : type === 'query' ? 'Run': 'Open',
                disabled: !type
            };
        }

        return this;
    }


    /**
     * Get base action by model type
     *
     * @return array
     */
    addBaseActions() {
        const entity          = this.getEntity(),
            type              = this.getDataEntityType(entity),
            getActionFuncName = `addBaseActions${capitalize(type)}`;

        if (type && this[getActionFuncName]) {
            this[getActionFuncName]();
        }

        return this;
    }


    /**
     * Get base actions for a query
     *
     * @returns array
     */
    addBaseActionsQuery() {
        this.actions['rename-query'] = {
            icon : <Icon type="edit" height={12} />,
            cb   : () => this.renameQuery(),
            label: 'Rename'
        };

        return this;
    }


    /**
    * Get base actions for a orgunit
    *
    * @returns array
    */
    addBaseActionsOrgunit() {
        const { yoomapActions } = this.state;

        if (yoomapActions) {
            _.forIn(yoomapActions, (yoomapAction, key) => {
                this.actions[key] = yoomapAction;
            });
        }

        return this;
    }


    /**
     * Store yoomapActions in state
     *
     * @param {object} yoomapActions
     */
    setYoomapActions(yoomapActions) {
        this.setState({ yoomapActions });
    }


    /**
    * Store the label for further use
    *
    * @return void
    */
    updateLabel(e) {
        this.setState({
            editedLabel: e.target.value
        });
    }

    /**
    * Edit a search query (web-model)
    *
    * @return void
    */
    renameQuery() {
        this.setState({
            modalQueryRename: true,
            editedLabel     : null
        });
    }


    /**
    * Close the edit popin
    *
    * @return void
    */
    closeRenameQuery() {
        this.setState({
            modalQueryRename: false,
            editedLabel     : null
        });
        this.onActionToggleDisplay({ menuVisible: false });
    }

    /**
    * Close the edit popin for query model
    *
    * @return void
    */
    updateQuery() {
        const {
                updateBookmark,
                getUserViewItemFromModel,
            }               = this.props,
            entity          = this.getEntity(),
            userViewItem    = getUserViewItemFromModel(entity, 'bookmark'),
            { editedLabel } = this.state;

        userViewItem
            // TODO: update all bookmark with the same entity (on multiple folders)
            ? updateBookmark(userViewItem, { label: editedLabel })
            : this.updateQueryModel({ label: editedLabel });

        this.closeRenameQuery();
    }

    /**
     * Close the action popin
     */
    closeModalBookmark() {
        this.setState({
            modalBookmarkAction: null
        });
        this.onActionToggleDisplay({ menuVisible: false });
    }


    /**
    * Patch query model on api
    *
    * @param {query model} query
    * @param {object}      options
    */
    updateQueryModel(options) {
        const { entity, refreshCb } = this.props;

        dataPatch(`/query/${entity.id}`, options)
            .then(() => {
                refreshCb && refreshCb();
            });
    }


    /**
    * Modal popin to ask user for delete a bookmark(s) folder (web-model)
    *
    * @return void
    */
    modalRemoveBookmarkFolder() {
        this.setState({
            modalDeleteBookmarkFolders: true
        });
    }


    /**
    * Close the delete bookmark(s) folder modal popin
    *
    * @return void
    */
    closeModalRemoveBookmarkFolder() {
        this.setState({
            modalDeleteBookmarkFolders: false,
        });

        this.onActionToggleDisplay({ menuVisible: false });
    }


    /**
    * Remove a bookmark(s) folder (web-model)
    *
    * @return void
    */
    removeBookmarksFolder() {
        const {
                getUserViewItemFromModel,
                removeBookmarkFolder
            }            = this.props,
            entity       = this.getEntity(),
            userViewItem = getUserViewItemFromModel(entity, 'bookmark_folder', { ignoreState: true });

        removeBookmarkFolder(userViewItem);

        this.closeModalRemoveBookmarkFolder();
    }


    /**
     * Export bookmark folder
     *
     * @returns void
     */
    exportBookmarkFolder() {
        const {
                getFolderBranch, addItems
            }         = this.props,
            entity          = this.getEntity(),
            { id }          = entity || {},
            branches        = id && getFolderBranch({paramKey: id}),
            bookmarks       = branches && getBookmarksOfBranches(branches),
            bookmarksByType = _.groupBy(bookmarks, bookmark => {
                const entity = getDataEntity(bookmark);
                return entity?.type;
            }),
            clipboardItems = [];

        _.keys(bookmarksByType).forEach(type => {
            if (type === 'query') {
                return;
            }
            const count = bookmarksByType[type].length;
            clipboardItems.push(this.makeBookmarkFolderClipboardItem(type, count));
        });

        addItems(clipboardItems);
    }


    /**
     * Make clipboard item
     *
     * @returns object
     */
    makeBookmarkFolderClipboardItem(type, count) {
        const entity       = this.getEntity(),
            { label, id }  = entity,
            timestamp      = Date.now(),
            date           = new Date(),
            dateString     = `${date.getFullYear()}_`
                + `${formatNumberWithMagnitude(date.getMonth() + 1, 0, false, false, 2)}_`
                + `${formatNumberWithMagnitude(date.getDate(), 0, false, false, 2)}`,
            timeString     = `${formatNumberWithMagnitude(date.getHours(), 0, false, false, 2)}_`
                + `${formatNumberWithMagnitude(date.getMinutes(), 0, false, false, 2)}_`
                + `${formatNumberWithMagnitude(date.getSeconds(), 0, false, false, 2)}`,
            filenameDate   = `${dateString}_${timeString}`,
            filenameBase   = `${label}`,
            typeDefinition = this.getTypeDefinition(type),
            labelType      = pluralize(typeDefinition?.label || (type === 'query' ? 'search' : type), count),
            metadata       = {
                filetype: 'list',
                type    : 'list',
                filename: sanitizeFilename(`${filenameDate}_${filenameBase}_${labelType}`).toLowerCase(),
                label   : labelType,
                source  : `Bookmark folder (${label})`,
                url     : document.location.toString(),
            },
            properties     = {
                uri       : `/bookmark-folder/${id}/bookmarks`,
                path      : '/api',
                parameters: {
                    deep       : 'true',
                    entity_type: type
                },
                model: id && {
                    id,
                    type: 'bookmark_folder'
                }
            };

        // Return the clipboard item
        return {
            type: 'clipboard_item',
            metadata,
            properties,
            timestamp,
        };
    }


    /**
    * Find the definition entity
    *
    * @return object || null
    */
    getTypeDefinition(type) {
        const {
                entities: entitiesDefinition
            }              = this.state,
            typeDefinition = entitiesDefinition
                && entitiesDefinition.find((def) => def.id === type);

        return typeDefinition;
    }


    /**
     * Disable click event bubbling
     *
     * @param {MouseEvent} e
     * @returns
     */
    disableBubbling(e) {
        e.stopPropagation();

        return true;
    }


    /**
    * Render the bookmark edit popin
    *
    * @return self
    */
    renderEditSearch() {
        const { editedLabel } = this.state,
            { entity }        = this.props,
            isBookmarkModel   = entity.type === 'bookmark',
            label             = !_.isNull(editedLabel)
                ? editedLabel
                : _.get(entity, 'label') || _.get(entity, 'entity.label');

        return (
            <Modal
                title="Rename saved search"
                onOk={this.updateQuery}
                onCancel={this.closeRenameQuery}
                className="edit-search-bookmark"
                okText="Rename"
                open
            >
                <p className="name">
                    <span>Name:</span>
                    <input
                        type="text"
                        value={label}
                        onChange={this.updateLabel}
                    />
                </p>
                <p className="concepts">
                    <span>Search:</span>
                    <textarea disabled="disabled">
                        {/* TODO: add sentence !? */}
                        {_.get(entity, isBookmarkModel ? 'entity.entity.entity.label' : 'entity.entity.label')}
                    </textarea>
                </p>
            </Modal>
        );
    }

    /**
     * Add bookmark
     * @returns void
     */
    onAddBookmark() {
        this.closeModalBookmark();
    }

    /**
     * Return the title of the bookmark action
     *
     * @param {string} action
     * @returns string
     */
    getBookmarkActionTitle(action) {
        const {
                userViewBookmarkFolders
            }               = this.props,
            hasFolders      = userViewBookmarkFolders?.size > 1,
            bookmarkActions = {
                add      : hasFolders ? 'Add bookmark to' : 'Add to bookmarks',
                move     : 'Move to folder',
                duplicate: 'Duplicate bookmark'
            };

        return bookmarkActions[action];
    }

    /**
     * Render the modal action
     *
     * @returns self
     */
    renderModalBookmark() {
        const {
                modalBookmarkAction
            }               = this.state,
            entity          = this.getEntity(),
            title           = this.getBookmarkActionTitle(modalBookmarkAction),
            dataEntity      = getDataEntity(entity),
            isQueryModel    = dataEntity?.type == 'query',
            deepEntity      = {
                ...dataEntity,             // Get deepest entity
                ...(isQueryModel ? {label: ''} : {})  // If query remove preset label
            };

        return (
            <ModalBookmark
                title={title}
                entities={[deepEntity]}
                onCancel={this.closeModalBookmark}
                onSubmit={this.onAddBookmark}
            />
        );
    }

    /**
    * Render the remove bookmark(s) folder modal popin
    *
    * @return self
    */
    renderRemoveBookmarkFolder() {
        const { containerRef } = this.props,
            entity             = this.getEntity(),
            { label }          = entity;

        return (
            <Modal
                title="Delete"
                onOk={this.removeBookmarksFolder}
                onCancel={this.closeModalRemoveBookmarkFolder}
                closeIcon={(
                    <Icon className="icon close" height={16}
                        type="close"
                    />
                )}
                className="delete-bookmark"
                okText="Delete"
                zIndex="1051"
                getContainer={containerRef}
                open
            >
                <Icon type="delete" className="icon delete"
                    height={38}
                />
                <h2>Are you sure ?</h2>
                <p>
                    You are about to delete
                    <strong>&ldquo;{label}&ldquo;</strong>
                    folder and all associated subfolders and bookmarks.
                    This action can not be undone.
                </p>
            </Modal>
        );
    }


    /**
     * Get the data entity (the entity at the lowest level)
     *
     * @returns
     */
    getDataEntityType(entity) {
        const { type } = getDataEntity(entity);

        return type;
    }


    /**
     * Get newsletter action (monitor or remove from newsletter
     *
     * @returns object
     */
    addNewsletterActions() {
        const entity            = this.getEntity(),
            type                = this.getDataEntityType(entity),
            typesCanBeMonitored = ['orgunit', 'query'];

        // Can't be in newsletters
        if (!typesCanBeMonitored.includes(type)) {
            return this;
        }

        // Is in newsletter
        if (this.isInNewsletter()) {
            this.actions['remove-from-newsletter'] = {
                icon: <Icon id="newsletters" folder="/shortcuts/"
                    height={16} className="monitor"
                />,
                cb   : () => this.toggleEntityToNewsletter(entity),
                label: 'Unmonitor'
            };

            return this;
        }

        // Is not in newsletter
        this.actions['add-to-newsletter'] = {
            icon: <Icon id="newsletters" folder="/shortcuts/"
                height={16} className="monitor"
            />,
            cb   : () => this.toggleEntityToNewsletter(entity),
            label: 'Monitor'
        };

        return this;
    }


    /**
    *  Importing the addBookmarkFolderActions from the actions folder.
    *
    * @returns object
    */
    addBookmarkFolderActions() {
        const {
                entity, getUserViewItemFromModel, editModeCb,
            }            = this.props,
            userViewItem = getUserViewItemFromModel(entity, 'bookmark_folder', { ignoreState: true });

        if (editModeCb) {
            this.actions['rename-bookmark-folder'] = {
                icon : <Icon type="edit" height={12} />,
                cb   : editModeCb,
                label: 'Rename'
            };
        }

        if (userViewItem) {
            this.actions['remove-bookmark-folder'] = {
                label: 'Delete',
                icon : <Icon type="delete" className="icon delete"
                    height={14}
                />,
                cb: this.modalRemoveBookmarkFolder,
            };

            this.actions.export = {
                label: 'Export',
                icon : <Icon
                    id="clipboard-add"
                    folder="/shortcuts/"
                    className="icon export"
                    height={14}
                />,
                cb: this.exportBookmarkFolder,
            };
        }

        return this;
    }


    /**
     * Get bookmarks actions
     *
     * @returns object
     */
    addBookmarksActions() {
        const {
                getUserViewItemFromModel,
                userViewBookmarkFolders,
            }                 = this.props,
            entity            = this.getEntity(),
            userViewItem      = getUserViewItemFromModel(entity, 'bookmark', { ignoreState: true }),
            isUpdated         = !!userViewItem?.model?.userViewState,
            {
                model: bookmark, parent_attachments
            }                 = userViewItem || {},
            parent_keys       = _.map(parent_attachments, 'key') || [],
            parentFolderItems = userViewBookmarkFolders
                && userViewBookmarkFolders.filter(item => parent_keys && parent_keys.includes(item.key)).toJS();

        // In migration process
        if (userViewBookmarkFolders && userViewBookmarkFolders.size === 0) {
            return this;
        }

        this.addQuickAddBookmarksActions({parentFolderItems, userViewItem, entity, bookmark, isUpdated});
        this.addRemoveBookmarksAction({parentFolderItems, userViewItem, entity, bookmark, isUpdated});
        this.addCopyBookmarksAction({parentFolderItems, userViewItem, entity, bookmark, isUpdated});
        this.addMoveBookmarksAction({parentFolderItems, userViewItem, entity, bookmark, isUpdated});


        return this;
    }


    /**
    * Adding the add bookmarks action
    */
    addQuickAddBookmarksActions({ parentFolderItems, userViewItem, entity, isUpdated }) {
        const { addBookmark } = this.props,
            { id }            = entity,
            type              = this.getDataEntityType(entity),
            isOrgunit         = type === 'orgunit',
            subActions        = this.getCopyOrMoveActions(
                to => {
                    const dataEntity = _.cloneDeep(getDataEntity(entity));
                    addBookmark(dataEntity, {parent_bookmark_folder: to});
                }, userViewItem, parentFolderItems
            );

        if (!id) {
            return;
        }

        if (!userViewItem) {
            this.actions['add-bookmark'] = {
                icon: <Icon id="bookmarked" theme="filled"
                    height={14} className="icon"
                />,
                actions  : subActions,
                openAfter: isOrgunit && ['classify', 'sub-classify'],
                disabled : isUpdated,
                label    : this.getBookmarkActionTitle('add'),
                cb       : () => {
                    this.setState({ modalBookmarkAction: 'add' });
                },
            };
        }

        if (userViewItem && isOrgunit) {
            this.actions.classify = {
                icon: <Icon type="tags" theme="filled"
                    height={14} className="icon"
                />,
                actions : userViewItem && this.getClassifyActions(userViewItem, parentFolderItems),
                disabled: isUpdated,
                label   : 'Classify'
            };
        }
    }

    /**
     * Order folders by label
     */
    orderFolders(folders) {
        return _.sortBy(
            folders,
            item => {
                const label = item.model?.label || item.model?.entity?.entity?.label || '';
                return _.isString(label) ? label.toLowerCase() : label.toString();
            }
        );
    }

    /**
    * Adding a remove bookmark action to the bookmark entity.
    */
    addRemoveBookmarksAction({parentFolderItems, userViewItem, entity, bookmark, isUpdated}) {
        const {
                removeBookmarkFromFolder,
            }                    = this.props,
            { id } = entity;

        if (bookmark && id && parentFolderItems?.length === 1) {
            const folder                 = parentFolderItems[0].model,
                { id: parent_id, label } = folder || {};

            this.actions['remove-bookmark'] = {
                label: `Remove bookmark from ${label}`,
                icon : <Icon type="delete" className="icon delete"
                    height={14}
                />,
                disabled: isUpdated,
                cb      : (() => {
                    /* A this.setState({ cachedTags: null }); */
                    removeBookmarkFromFolder(bookmark.id, parent_id);
                }),
            };
        }

        if (userViewItem && id && parentFolderItems?.length !== 1) {
            const removeFromActions = userViewItem && this.getRemoveFromActions(parentFolderItems, bookmark);
            if (removeFromActions) {
                this.actions['remove-bookmark-from'] = {
                    icon: <Icon type="delete" className="icon delete"
                        height={14}
                    />,
                    actions : removeFromActions,
                    disabled: isUpdated,
                    label   : 'Remove bookmark from'
                };
            }
        }

        return this;
    }

    /**
    * Adding the copy bookmarks action
    */
    addCopyBookmarksAction({ parentFolderItems, userViewItem, entity, bookmark, isUpdated }) {
        const { addBookmark } = this.props,
            { id }            = entity;

        if (bookmark && id && parentFolderItems?.length > 0) {
            const subActions = userViewItem && this.getCopyOrMoveActions(
                to => {
                    const dataEntity = _.cloneDeep(getDataEntity(bookmark));
                    addBookmark(dataEntity, {parent_bookmark_folder: to});
                }, userViewItem, parentFolderItems
            );
            if (subActions) {
                this.actions['copy-bookmark-to'] = {
                    icon: <Icon type="copy" className="icon copy"
                        height={14}
                    />,
                    actions : subActions,
                    disabled: isUpdated,
                    label   : 'Copy bookmark to'
                };
            }
        }
    }


    /**
    * Adding the move bookmarks action
    */
    addMoveBookmarksAction({ parentFolderItems, userViewItem, entity, bookmark, isUpdated }) {
        const { moveBookmark }   = this.props,
            { id, parentFolder } = entity;

        if (bookmark && id && (parentFolder || parentFolderItems?.length === 1)) {
            const removeFromActions = userViewItem && this.getCopyOrMoveActions(
                to => {
                    const from = parentFolder?.id || parentFolderItems[0].model.id;
                    moveBookmark(userViewItem, {from, to});
                }, userViewItem, parentFolderItems
            );
            if (removeFromActions) {
                this.actions['move-bookmark-to'] = {
                    icon    : <Icon type="move-to" height={14} />,
                    actions : removeFromActions,
                    disabled: isUpdated,
                    label   : 'Move bookmark to'
                };
            }
        }
    }


    /**
     *  Return actions to duplicate into folder
    */
    getCopyOrMoveActions(actionCb, userViewItem, parentFolderItems, passedFolder) { // eslint-disable-line max-params
        const {
                getRootFolder,
                userViewBookmarkFolders
            }                      = this.props,
            folder                 = passedFolder || getRootFolder(),
            folderKey              = folder?.key,
            duplicateActions       = {},
            { parent_attachments } = userViewItem || {},
            parent_keys            = _.map(parent_attachments, 'key') || [],
            folders                = userViewBookmarkFolders?.filter(item => _.map(item.parent_attachments, 'key').includes(folderKey)).toJS(), // eslint-disable-line max-len
            orderedFolders         = this.orderFolders(folders),
            icon                   = (
                <Icon type="folder" className="icon folder"
                    height={14}
                />
            );

        orderedFolders.forEach(
            folder => {
                const { key, model } = folder,
                    {
                        label, id: parent_bookmark_folder
                    }                  = model || {},
                    bookmarkIsinFolder = parent_keys.includes(key),
                    isBeingUpdated     = model?.userViewState,
                    disabled           = bookmarkIsinFolder || isBeingUpdated,
                    preserveIconColor  = true,
                    actions            = this.getCopyOrMoveActions(actionCb, userViewItem, parentFolderItems, folder);

                duplicateActions[key] = {
                    label,
                    icon,
                    actions,
                    disabled,
                    preserveIconColor,
                    cb: !bookmarkIsinFolder && (() => {
                        actionCb(parent_bookmark_folder);
                    }),
                };
            }
        );

        return duplicateActions;
    }

    /**
     * Return actions to remove bookmarks from all this parent folders
     *
     * @returns
     */
    getRemoveFromActions(parentFolderItems, bookmark) {
        const {
                removeBookmarkFromFolder
            }                 = this.props,
            removeFromActions = {},
            icon              = (
                <Icon type="folder" className="icon folder"
                    height={14}
                />
            );

        parentFolderItems.forEach(
            folder => {
                const { key, model }          = folder,
                    { label, id: parent_id  } = model || {};

                if (!parent_id) { return; }

                removeFromActions[key] = {
                    icon,
                    cb: (() => {
                        this.setState({ cachedTags: null });
                        removeBookmarkFromFolder(bookmark.id, parent_id);
                    }),
                    preserveIconColor: true,
                    label,
                };
            }
        );

        return removeFromActions;
    }


    /**
     * Get addToClipboard actions
     *
     * @returns object
     */
    addAddToClipBoardAction() {
        const { addToClipboard }   = this.props,
            { activeGroupSection } = this.props,
            groupSection           = activeGroupSection ? `«${activeGroupSection}»` : '';

        if (_.isNull(addToClipboard)) {
            return this;
        }

        const label = `Add ${groupSection} to export clipboard`,
            title        = !addToClipboard ? 'Please wait until all items are loaded' : null,
            icon         = (
                <Icon
                    id="clipboard-add"
                    folder="/shortcuts/"
                    height={14}
                    className="add-to-clipboard"
                    color={addToClipboard ? 'var(--primary-color)' : '#ddd'}
                />
            );

        this.actions['export-to-clipboard'] = {
            icon,
            label,
            title,
            disabled: !addToClipboard,
            cb      : addToClipboard,
        };

        return this;
    }


    /** Return render Modal function depending on state
    *
    * @returns function || null
    */
    renderModal() {
        const {
            modalQueryRename,
            modalDeleteBookmarkFolders,
            modalBookmarkAction
        } = this.state;

        if (modalBookmarkAction) { return this.renderModalBookmark(); }
        if (modalQueryRename) { return this.renderEditSearch(); }
        if (modalDeleteBookmarkFolders) { return this.renderRemoveBookmarkFolder(); }

        return null;
    }


    /**
    * Render actions for elements
    *
    * @param object entity The rendered entity
    *
    * @return JSX
    */
    render() { // eslint-disable-line  max-lines-per-function
        const {
                entity, forceRender,
                getUserViewItemFromModel, dataQaKey, childrenRender
            }              = this.props,
            {
                getActionCalled
            }              = this.state,
            type           = this.getDataEntityType(entity),
            dataEntity     = getDataEntity(entity),
            isOrgunit      = type === 'orgunit',
            label          = dataEntity?.label || entity.label,
            from           = { model: dataEntity, componentName: 'Model' },
            qaKey          = str2DomFormat(`${dataQaKey ? dataQaKey + ' ' : ''}${label || ' '} actions button`),
            userViewItem   = getUserViewItemFromModel(this.getEntity(), 'bookmark', { ignoreState: true }),
            clickAction    = this.getClickAction(userViewItem),
            classNames     = ['action-model', { 'is-bookmarked': !!userViewItem }],
            renderModal    = this.renderModal();

        return (
            <div className={makeStrClassName(classNames)} onClick={this.disableBubbling}>
                { renderModal }
                {
                    (!renderModal || forceRender) && (
                        <Action
                            key="action"
                            {...this.props}
                            dataQaKey={qaKey}
                            from={from}
                            actionCb={clickAction}
                            getActions={!forceRender && this.getActions}
                            onActionToggleDisplay={this.onActionToggleDisplay}
                        >
                            {childrenRender()}
                        </Action>
                    )
                }
                {
                    getActionCalled && isOrgunit
                        && (
                            <YoomapSendOrganization
                                key="send" entity={dataEntity}
                                setActionsCb={this.setYoomapActions}
                            />
                        )
                }
            </div>
        );
    }

}

ModelAction.propTypes = {
    activeGroupSection      : PropTypes.string,
    forceRender             : PropTypes.bool,
    addBookmark             : PropTypes.func.isRequired,
    moveBookmark            : PropTypes.func.isRequired,
    getRootFolder           : PropTypes.func.isRequired,
    removeBookmarkFromFolder: PropTypes.func.isRequired,
    removeBookmarkFolder    : PropTypes.func.isRequired,
    updateBookmark          : PropTypes.func.isRequired,
    userViewBookmarkFolders : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    userViewNewsletters     : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    userViewEntities        : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    newsletters             : PropTypes.array,
    toggleEntityInNewsletter: PropTypes.func.isRequired,
    learnKnowledge          : PropTypes.func,
    entity                  : PropTypes.object.isRequired,
    onClick                 : PropTypes.func,
    refreshCb               : PropTypes.func,
    addToClipboard          : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    getUserViewItemFromModel: PropTypes.func,
    entityIsInNewsletter    : PropTypes.func,
    getModelsOfType         : PropTypes.func,
    editModeCb              : PropTypes.func,
    emitEvent               : PropTypes.func,
    childrenRender          : PropTypes.func,
    getFolderBranch         : PropTypes.func,
    addItems                : PropTypes.func,
    onActionToggleDisplay   : PropTypes.func,
    containerRef            : PropTypes.any,
    dataQaKey               : PropTypes.string,
    context                 : PropTypes.object,
    clickAction             : PropTypes.array,
    onlyActions             : PropTypes.array,
};

ModelAction.defaultProps = {
    activeGroupSection: '',
    forceRender       : false,
    addToClipboard    : null,
    context           : {},
    dataQaKey         : '',
    onlyActions       : null,
    childrenRender    : () => {},
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    const userViewBookmarkFolders = state.getIn(['userView', 'bookmark_folder', 'list']),
        userViewNewsletters     = state.getIn(['userView', 'newsletter', 'list']),   // To rerender on newsletter list change
        userViewEntities        = state.getIn(['userView', 'entity', 'list']);       // To rerender on entities list change

    return {
        userViewBookmarkFolders,
        userViewNewsletters,
        userViewEntities,
    };
};

export default connect(mapStateToProps, {
    toggleEntityInNewsletter,
    updateBookmark,
    removeBookmarkFolder,
    learnKnowledge: learn,
    addBookmark,
    moveBookmark,
    getRootFolder,
    removeBookmarkFromFolder,
    getUserViewItemFromModel,
    entityIsInNewsletter,
    getModelsOfType,
    emitEvent,
    getFolderBranch,
    addItems,
})(ModelAction);
