/**
 * Display a sidebar to improve Search UX
 *
 * @return Component
 */

import _                       from 'lodash';
import React, { PureComponent }    from 'react';
import PropTypes               from 'prop-types';
import ImmutablePropTypes      from 'react-immutable-proptypes';
import { connect }             from 'react-redux';
import { removeItems }         from 'store/actions/userView';
import { resetDownloadsList }  from 'store/actions/downloads';
import { emitEvent }           from 'store/actions/sockets';
import {
    getOuterShareByExportId,
    fetchPlatforms,
    getPlatformsOfService,
    fetchPlatformsTokens,
}                               from 'store/actions/auth/platforms.js';

import { updateMemberSettings } from 'store/actions/auth';

import {
    Checkbox, Button, Dropdown,
    Collapse, Radio, Input,
    Row, Col
}                               from 'antd';

import {
    formatNumberWithMagnitude,
    sanitizeFilename
}                               from 'utils/text';
import { post }                 from 'utils/api';
import {
    Icon,
    LimitChildrenDisplay
}                               from 'helpers';
import YoomapDecorator          from 'helpers/Yoomap/IframeDecorator';
import YoomapExport             from 'helpers/Yoomap/Export';
import notification             from 'helpers/notification.js';
import Empty                    from './Empty';
import ShortcutViewItem         from './ShortcutViewItem';

import './assets/clipboard.less';

const settingsDefinitions = {
    imageType: [
        { value: 'png', label: 'Images as .PNG' },
        { value: 'pdfGroup', label: 'Group images in one .PDF' },
        { value: 'pdf', label: 'Each image as a .PDF file' },
    ],
    groupList: [
        { value: 'oneXlsx', label: 'One .XLSX file for all lists/charts' },
        { value: 'xlsx', label: 'One .XLSX file per list/chart' },
        { value: 'csv', label: 'One .CSV file per list/chart' },
    ],
    booklet: [
        { value: 'pdf', label: 'One .pdf file per booklet' },
    ],
    compress: [
        {
            value  : 'zip',
            checked: true,
            label  : 'Compress all files in a .ZIP'
        },
        {
            value  : 'single',
            checked: false,
            label  : 'Compress all files in a .ZIP'
        },
    ],
};

const defaultSettings = {
    imageType  : 'png',
    groupList  : 'xlsx',
    booklet    : 'pdf',
    compress   : 'zip',
    share      : {},
    showOptions: true
};

const TYPE_SETTING_MAP = {
    imageType: 'image',
    groupList: 'list',
    booklet  : 'booklet',
};

const ITEMS_BLOCK_HEADER_LABELS = {
    image  : 'images',
    list   : 'worksheets',
    booklet: 'booklets',
};

/**
 * Class Clipboard
 *
 */
class Clipboard extends PureComponent {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onToggleItem', 'onOptionClick', 'onChangeRadio', 'onChangeInputText', 'onCollapse',
            'onToggleOptionClick', 'launchExport', 'closeYoomapPopin', 'onChangeYoomapPlatforms', 'removeByTypeFn',
            'selectToggleByTypeFn', 'removeItems', 'removeItemsAction');

        this.sharedExportMonitorInterval = null;

        this.state = {
            selectedItemsIds : [],
            selectedPlatforms: null,
            sharedExportsIds : [],
            customFilename   : '',
            isLoading        : false
        };

        // Create static actions for all items
        this.actions = {
            delete: {
                cb       : this.removeItemsAction,
                className: 'delete',
                key      : 'delete',
            }
        };
    }


    /**
    * Get settings from props or defaultSettings
    *
    * @return void
    */
    getExportSettings() {
        const { member }   = this.props,
            memberSettings = member.get('settings');

        return !_.isNull(memberSettings) && memberSettings.export ? memberSettings.export : defaultSettings;
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { fetchPlatforms, fetchPlatformsTokens, platforms } = this.props;

        // Do not update slice if platforms already fetched
        if (!platforms) {
            // Fetch platform to show or not yoomap button
            fetchPlatforms(fetchPlatformsTokens);
        }
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidUpdate(prevProps, prevState) {
        const { clipboardItems }       = this.props,
            {isLoading: isLoadingPrev} = prevState,
            isLoading                  = clipboardItems
                && !!clipboardItems.find(item => item?.model?.userViewState === 'loading');

        if(isLoading !== isLoadingPrev) {
            this.setState({isLoading});
        }
    }

    /**
     * On component unmount
     *
     * @return void
     */
    componentWillUnmount() {
        if (this.sharedExportMonitorInterval) {
            clearInterval(this.sharedExportMonitorInterval);
        }
    }

    /**
    * Get filtered Downloads
    *
    * @return void
    */
    getClipboardItems() {
        const { clipboardItems } = this.props;
        return clipboardItems && clipboardItems?.filter(item => item?.model?.userViewState !== 'deleted');
    }

    /**
     * Trigger on change select yoomap Platforms
     *
     * @return void
     */
    onChangeYoomapPlatforms(value) {
        const { updateMemberSettingsCb, close } = this.props,
            settings                            = this.getExportSettings(),
            share                               = _.isObject(settings.share) && !_.isArray(settings.share)
                ? settings.share
                : {},
            { defaultPlatform }                 = share;

        if (value === null) {
            return;
        }

        // Store platform is state to render share popup
        this.setState({
            selectedPlatforms: value
        });

        // Must save defaultPlatform to member settings
        if (defaultPlatform !== value) {
            // Change the setting
            share.defaultPlatform = value;
            settings.share        = share;

            // Store settings to the member
            updateMemberSettingsCb({ export: settings });
        }

        // Close clipboard view
        close();
    }

    /**
     * Open Export Report
     *
     * @return void
     */
    closeYoomapPopin() {
        this.setState({
            selectedPlatforms: null
        });
    }


    /**
     * Get items to export
     *
     * @returns array
     */
    getExportOptionsToExport() {
        const {selectedItemsIds } = this.state,
            clipboardItems        = this.getClipboardItems(),
            hasSelection          = selectedItemsIds.length > 0,
            itemsToExport         = hasSelection
                ? clipboardItems.filter((item) => selectedItemsIds.includes(item.entity_id))
                : clipboardItems;

        return itemsToExport;
    }

    /**
     * Get items with mimetype
     *
     * @return array
     */
    getItemWithAdditionalInfo() {
        const itemsToExport     = this.getExportOptionsToExport(),
            clonedItemsToExport = _.cloneDeep(itemsToExport),
            itemsWithAdditionalInfo   = clonedItemsToExport ? _.values(clonedItemsToExport.map((item) => {
                const {model} = item,
                    extention = this.getFileExtention(model);

                model.metadata.extention = extention;
                model.type               = model?.metadata?.type;
                model.properties         = model.properties || model.props;

                delete model?.metadata?.type;
                delete model?.props;

                return model;
            }).toJS()) : [];

        return itemsWithAdditionalInfo;
    }

    /**
     * Get mimetypes to bo exported
     *
     * @return array
     */
    getExportedExtentions() {
        const items                 = this.getItemWithAdditionalInfo(),
            allExtentions           = _.uniq(items.map(item => item.metadata.extention)),
            { imageType, compress } = this.getExportSettings();

        // Just one zip
        if (compress === 'zip') {
            return ['zip'];
        }

        // For grouped pdf : remove png and pdf
        if (imageType === 'pdfGroup') {
            const _return = allExtentions.filter(extention => extention !== 'png');
            _return.push('pdf');
            return _return;
        }

        return allExtentions;
    }

    /**
     * Launch export
     *
     */
    launchExport(e, additionnalSettings) {
        const {
                resetDownloadsList, emitEvent
            }                    = this.props,
            { selectedItemsIds, customFilename } = this.state,
            date                 = new Date(),
            dateString           = `${date.getFullYear()}_${formatNumberWithMagnitude(date.getMonth() + 1, 0, false, false, 2)}_${formatNumberWithMagnitude(date.getDate(), false, false, 2)}`,                                    // eslint-disable-line
            timeString           = `${formatNumberWithMagnitude(date.getHours(), 0, false, false, 2)}_${formatNumberWithMagnitude(date.getMinutes(), 0, false, false, 2)}_${formatNumberWithMagnitude(date.getSeconds(), 0, false, false, 2)}`, // eslint-disable-line
            exportSettings       = this.getExportSettings(),
            settings             = additionnalSettings ? {...exportSettings, ...additionnalSettings} : exportSettings,
            hasOptionZip         = settings?.compress === 'zip',
            hasSelection         = selectedItemsIds.length > 0,
            items                = this.getItemWithAdditionalInfo(),
            itemsToExport        = _.values(_.sortBy(items, ['timestamp'])),
            label                = hasOptionZip && !_.isEmpty(customFilename)
                ? customFilename
                : `${dateString}_${timeString}_INSI_pack`;

        if (items.length === 0) { return; }

        post('/export', {
            settings,
            label,
            content: _.map(itemsToExport, 'id'),
        }).then((response) => {
            const { body } = response,
                { id }     = body || {},
                { shares } = settings;

            // Monitor a shared export
            if (shares) {
                this.addSharedExportMonitor(id);
            }

            emitEvent({
                module          : 'export',
                name            : 'new',
                data            : body,
                attributesFilter: ['id', 'type', 'label', 'settings']
            });

            // Reset files list in download
            setTimeout(resetDownloadsList, 1500);
        });

        // Clean clipboard items
        if (hasSelection) {
            this.removeItems(selectedItemsIds);

            return;
        }

        this.removeByTypeFn()();
    }


    /**
     * Add a shared Export monitor
     *
     * @return self
     */
    addSharedExportMonitor(id) {
        const { sharedExportsIds } = this.state;

        // Shared export already in state
        if(sharedExportsIds.find(exportId => exportId === id)) {
            return this;
        }

        // Adding shared export
        sharedExportsIds.push(id);

        this.setState(
            { sharedExportsIds },
            // Set state callback
            () => {
                // Reset interval
                if (this.sharedExportMonitorInterval) {
                    clearInterval(this.sharedExportMonitorInterval);
                }

                // Set interval to monitor shared export
                this.sharedExportMonitorInterval= setInterval(() => {
                    this.monitorSharedExports();
                }, 10000);
            }
        );

        return this;
    }


    /**
     * Monitor shared export
     *
     * @return void
     */
    async monitorSharedExports() {
        const { sharedExportsIds } = this.state;
        let pos = 0;

        while(pos < sharedExportsIds.length) {  // Usign while to call await in sharedExportsIds iteration
            const element = sharedExportsIds[pos],
                data      = await getOuterShareByExportId(element); // eslint-disable-line no-await-in-loop

            // Outer share not finished
            if(!data || data.length === 0) {
                pos++;
                continue;
            }

            // Remove export id in sharedExportsIds pool
            sharedExportsIds.splice(pos, 1);

            const { return_delivery } = data[0],
                { error } = return_delivery || {};

            // Display notification error
            if (error) {
                notification({
                    message    : 'Oops !',
                    description: 'We encountered an issue sending your files, please try again in a few minutes',
                    type       : 'error',
                    duration   : 20
                });
                continue;
            }

            // Display notification success
            notification({
                message    : 'Great !',
                description: 'Your files have been sent',
                type       : 'success'
            });
        }

        // Store sharedExportsIds pool in state
        this.setState({ sharedExportsIds });

        // Remove interval on empty sharedExportsIds pool
        if(sharedExportsIds.length === 0) {
            if(this.sharedExportMonitorInterval !== null) {
                clearInterval(this.sharedExportMonitorInterval);
            }
        }
    }

    /**
     * Toggle item select status
     * @param {event} key
     * @return false
     */
    onToggleItem(id) {
        const { selectedItemsIds } = this.state,
            newSelectedItems       = _.xor(selectedItemsIds, [id]);

        this.setState({ selectedItemsIds: newSelectedItems });

        return false;
    }

    /**
    * Select option
    * @param {event} key
    * @return false
    */
    onOptionClick(key) {
        return (option) => {
            const { updateMemberSettingsCb } = this.props,
                settings                     = this.getExportSettings();

            // Change the setting
            settings[key] = option.key;

            // Store to the member
            updateMemberSettingsCb({ export: settings });
        };
    }

    /**
    * Toggle an element in options and updates member
    * @param {event} key
    * @return false
    */
    onToggleOptionClick(e) {
        const { updateMemberSettingsCb } = this.props,
            { target }                   = e,
            { name, checked }            = target,
            settings                     = this.getExportSettings(),
            settingsDefinition           = settingsDefinitions[name],
            exportOption                 = settingsDefinition.find((def) => def.checked === checked);

        // Change the setting
        settings[name] = exportOption.value;

        // Store to the member
        updateMemberSettingsCb({ export: settings });

        e.stopPropagation();
    }

    /**
     * Get configuration by Keys
     * @param {string} key
     * @return array
     */
    getConfigByKey(key) {
        const settingsDefinition = settingsDefinitions[key],
            settings             = this.getExportSettings(),
            exportOption         = settings[key];

        return settingsDefinition?.find((option) => option.value === exportOption);
    }


    /**
     * Returns an array of items id filtered by key if needed
     * @param {string} key
     * @return array
     */
    getExportOptionsIdByKey(key) {
        const clipboardItems = this.getClipboardItems(),
            allItems         = clipboardItems ? clipboardItems.toArray() : [];

        if(!key) {
            return allItems.map(e => e?.model?.id);
        }

        return allItems.filter(
            item => item?.model?.metadata?.filetype === TYPE_SETTING_MAP[key]
        ).map(e => e?.model?.id);
    }

    /**
     * Return selected Items ids
     * @param {string} type
     *
     * @return array
     */
    selectedItemsId(key) {
        const { selectedItemsIds } = this.state;
        return key
            ? this.getExportOptionsIdByKey(key).filter(i => selectedItemsIds.includes(i))
            : selectedItemsIds;
    }

    /**
     * Puts in state
     * @param {string} key
     *
     * @return void
     */
    selectToggleByTypeFn(key) {
        return () => {
            const { selectedItemsIds } = this.state,
                selectedByKey          = this.selectedItemsId(key),
                hasSelection           = selectedByKey.length > 0,
                newSelectedItems       = hasSelection
                    ? _.difference(selectedItemsIds, selectedByKey) // Unselect action
                    : _.union(selectedItemsIds, this.getExportOptionsIdByKey(key)); // Select action

            this.setState({ selectedItemsIds: newSelectedItems });
        };
    }

    /**
    * Reset all items from Clipboard
    *
    * @return void
    */
    removeByTypeFn(type) {
        return () => {
            const selectedByKey = this.selectedItemsId(type),
                hasSelection    = selectedByKey.length > 0;

            // Checks if has selection and remove all selected items
            if (hasSelection) {
                this.removeItems(selectedByKey);

                return;
            }

            // If not selected items remove all items by type
            this.removeItems(
                this.getExportOptionsIdByKey(type)
            );
        };
    }


    /**
    * Remove item from clipboard
    *
    * @return void
    */
    removeItems(ids) {
        const { removeItems, close } = this.props,
            { selectedItemsIds }             = this.state,
            itemsIds                         = _.isArray(ids) ? ids : [ids],
            clipboardItems                   = this.getClipboardItems(),
            itemsToRemove                    = clipboardItems
                ?.filter(item => itemsIds.indexOf(item.model.id) !== -1).toArray() ?? [];

        removeItems(itemsToRemove);

        this.setState({
            selectedItemsIds: _.difference(selectedItemsIds, itemsIds),
        });

        // Reset clipboard if hasn't other items
        if(this.getExportOptionsIdByKey().length === 0) {
            close();
        }
    }

    /**
    * Handle Remove item from clipboard
    *
    * @return void
    */
    removeItemsAction(item) {
        this.removeItems(item.id);
    }

    /**

    * Get extention file of a item
    *
    * @param {object} model A clipboard item
    *
    * @return string
    */
    getFileExtention(model) {
        const settings               = this.getExportSettings(),
            { groupList, imageType } = settings,
            { metadata }             = model,
            { filetype }             = metadata;

        return filetype === 'image'
            ? imageType === 'png' ? 'png': 'pdf'
            : (
                groupList === 'csv' ? 'csv'
                    : 'xlsx'
            );
    }

    /**
     * Render item line
     *
     * @return html
     */
    renderItem(item) {
        const { model }          = item,
            { id }               = model,
            { selectedItemsIds } = this.state,
            checked              = selectedItemsIds.includes(id);

        return (
            <ShortcutViewItem
                key={id}
                model={model}
                actions={this.actions}
                checked={checked}
                onToggle={this.onToggleItem}
                clipboardDefaultSettings={defaultSettings}
            />
        );
    }

    /**
    * On collapse toggle showOptions
    *
    * @param {DomEvent} event
    *
    * @return void
    */
    onCollapse() {
        const  { updateMemberSettingsCb } = this.props,
            settings                      = this.getExportSettings(),
            {showOptions}                 = settings;

        // Change the setting
        settings.showOptions = !showOptions;

        // Store to the member
        updateMemberSettingsCb({ export: settings });
    }

    /**
    * OnChange radio button
    *
    * @param {DomEvent} event
    *
    * @return void
    */
    onChangeRadio(event) {
        const { target }               = event,
            { name, value }            = target,
            { updateMemberSettingsCb } = this.props,
            settings                   = this.getExportSettings();

        // Change the setting
        settings[name] = value;

        // Store to the member
        updateMemberSettingsCb({ export: settings });
    }

    /**
     * Set custom label value
     *
     * @param {DomEvent} event
     */
    onChangeInputText(event) {
        const { target} = event,
            {value}     = target;
        this.setState({
            customFilename: sanitizeFilename(value)
        });
    }


    /**
     * Return export options items for antd menu
     * @returns
     */
    getExportOptionsItems() {
        const  header = (
            <div className="options-header">
                <Icon type="actions/filter" className="filter-icon"
                    width={13}
                />
                <span>Export options</span>
            </div>
        );

        return [{
            key     : 1,
            label   : header,
            children: (
                <Row>
                    <Col key="images" span={8}>
                        <div className="divider-title">Images format</div>
                        {this.renderRadioOptions('imageType')}
                    </Col>
                    <Col key="lists" span={8}>
                        <div className="divider-title">Lists format</div>
                        {this.renderRadioOptions('groupList')}
                    </Col>
                    <Col key="others" span={8}>
                        <div className="divider-title">Presets</div>
                        {this.renderCheckboxOption('compress')}
                        {this.renderFilenameInput()}
                    </Col>
                </Row>
            )
        }];
    }



    /**
     * Render radio button group
     * @param {string} key
     */
    renderRadioOptions(key) {
        const settingsDefinition = settingsDefinitions[key],
            settings             = this.getExportSettings(),
            exportOption         = settings[key];

        return (
            <Radio.Group key={key} name={key}
                onChange={this.onChangeRadio} value={exportOption}
            >
                {settingsDefinition.map((option) => (
                    <Radio key={option.value} value={option.value}>
                        {option?.label}
                    </Radio>
                ))}
            </Radio.Group>
        );
    }

    /**
     * Render checkbox option
     * @param {string} key
     *
     * @return html
     */
    renderCheckboxOption(key) {
        const setting = this.getConfigByKey(key);

        return (
            <Checkbox checked={setting.checked} onChange={this.onToggleOptionClick}
                name={key} title={setting.title}
            >
                {setting.label}
            </Checkbox>
        );
    }

    /**
     * Render custom filename input
     * @returns
     */
    renderFilenameInput() {
        const { customFilename } = this.state,
            settings             = this.getExportSettings(),
            disabled             = settings?.compress !== 'zip';

        return  (
            <Input
                value={customFilename}
                disabled={disabled}
                placeholder="Enter a custom filename"
                onChange={this.onChangeInputText}
                style={{ padding: '2px 5px' }}
                allowClear
            />
        );
    }

    /**
     * Render the content
     *
     * @return html
     */
    renderEmptyContent() {
        return (
            <Empty icon="empty-clipboard" height="150">
                <div>
                    Your export clipboard is empty
                </div>
                <div>
                    <span>
                        Use
                    </span>
                    <span className="options">
                        <Icon
                            id="options"
                            height={15}
                            SVG={false}
                        />
                    </span>
                    <span>
                        to add charts and lists
                    </span>
                </div>
            </Empty>
        );
    }

    /**
     * Render the content
     *
     * @return html
     */
    renderContent() {
        const clipboardItems = this.getClipboardItems(),
            allItems         = clipboardItems && clipboardItems.toJS(),
            noItems          = clipboardItems.size === 0,
            ordedItems       = _.orderBy(allItems, 'model.metadata.filename', 'desc'),
            groupedItems     = _.groupBy(ordedItems, 'model.metadata.filetype');

        return (
            <div key="clipboard-content" className={`content${noItems ? ' empty' : ''}`}>
                { noItems
                    ? this.renderEmptyContent()
                    : (
                        [
                            this.renderOptions(),
                            _.map(['entity', 'image', 'list', 'booklet'], (itemsKey) => this.renderItemsBlock(
                                groupedItems[itemsKey])
                            )
                        ]
                    )}
            </div>
        );
    }

    /**
     * Render export options
     *
     * @return html
     */
    renderOptions() { // eslint-disable-line  max-lines-per-function
        const settings     = this.getExportSettings(),
            {showOptions}  = settings,
            items          = this.getExportOptionsItems();

        return (
            <Collapse
                key="collapse" className="clipboard-options"
                active
                defaultActiveKey={showOptions ? ['1'] : null}
                items={items}
                onChange={this.onCollapse}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                    <Icon
                        className={isActive ? 'active' : 'inactive'}
                        width={10}
                        type="retract-arrow"
                    />
                )}
            />
        );
    }

    /**
     * Render items block
     * @param {array} items
     *
     * @return html
     */
    renderItemsBlock(items) {
        const fileType               = items && items[0].model.metadata.filetype,
            invertTypeSettingMap     = _.invert(TYPE_SETTING_MAP),
            key                      = invertTypeSettingMap[fileType],
            typeClass                = ITEMS_BLOCK_HEADER_LABELS[fileType],
            className                = ['items-block', typeClass],
            count                    = items?.length,
            setting                  = this.getConfigByKey(key),
            settingLabel             = setting?.label || settingsDefinitions.booklet[0].label,
            label                    = `${_.upperFirst(typeClass)} (${count}) - ${settingLabel}`;

        return items
            && (
                <div key={key} className={className.join(' ')}>
                    <div className="items-block-header">
                        <div>{label}</div>
                        <div>
                            {count > 1 && this.renderItemsBlockAction('select', key)}
                            {this.renderItemsBlockAction('remove', key)}
                        </div>
                    </div>
                    <div className="items">
                        <LimitChildrenDisplay max={5}>
                            {items.map(item => this.renderItem(item))}
                        </LimitChildrenDisplay>
                    </div>
                </div>
            );
    }

    /**
     * Render items block link actions
     *
     * @param {string} type
     * @param {string} key
     * @return html
     */
    renderItemsBlockAction(type, key) {
        const { isLoading }  = this.state,
            classNames       = [type],
            selectedItemsIds = this.selectedItemsId(key),
            hasSelected      = selectedItemsIds?.length > 0,
            actions          = {
                select: {
                    label: (hasSelected ? 'Unselect all' : 'Select all'),
                    cb   : this.selectToggleByTypeFn
                },
                remove: {
                    label: (hasSelected ? 'Remove selected' : 'Remove all'),
                    cb   : this.removeByTypeFn
                }
            },
            action = actions[type];

        isLoading && classNames.push('disabled');

        return (
            <a className={classNames.join(' ')} onClick={isLoading ? undefined : action.cb(key)}>
                {action.label}
            </a>
        );
    }

    /**
     * Render yoomap component
     *
     * @return html
     */
    renderYoomap() {
        const { selectedPlatforms } = this.state;

        return selectedPlatforms && (
            <YoomapDecorator
                key="clipboard-yoomap"
                url={selectedPlatforms}
                onClose={this.closeYoomapPopin}
            >
                <YoomapExport
                    launchExport={this.launchExport}
                    exportedExtentions={this.getExportedExtentions()}
                />
            </YoomapDecorator>
        );
    }

    /**
     * The platforms menu
     * @param {array} buttonPlatform
     *
     * @return html
     */
    platformsMenu(buttonPlatform) {
        const { getPlatformsOfService } = this.props,
            yoomapPlatforms             = getPlatformsOfService('yoomap'),
            items                       = yoomapPlatforms.map(platform => ({
                key    : platform.url,
                label  : platform.url,
                icon   : <Icon type="check" color={buttonPlatform === platform.url ? 'var(--secondary-color)' : 'transparent'} />,
                onClick: () => this.onChangeYoomapPlatforms(platform.url),
            }));

        return {items};
    }


    /**
    * The send to my other Innosabi app button
    * @param {array}   buttonPlatform
    * @param {boolean} hasItems
    * @param {boolean} multiplePlatform
    *
    * @return html
    */
    sendToPlatformButton(buttonPlatform, hasItems, multiplePlatform) {
        const { isLoading } = this.state;

        return (
            <Button
                type="primary"
                disabled={!hasItems || isLoading}
                className={`send-other-app ${multiplePlatform ? 'with-expand-arrow' : ''}`}
                onClick={() => this.onChangeYoomapPlatforms(buttonPlatform)}
            >
                Send to my other innosabi App
            </Button>
        );
    }

    /**
     * Render yoomap button
     *
     * @return html
     */
    renderYoomapButton() {
        const { getPlatformsOfService } = this.props,
            { isLoading }               = this.state,
            clipboardItems              = this.getClipboardItems(),
            hasItems                    = clipboardItems && clipboardItems.size > 0,
            yoomapPlatforms             = getPlatformsOfService('yoomap'),
            settings                    = this.getExportSettings(),
            { share }                   = settings,
            { defaultPlatform }         = share || {},
            firstPlatformUrl            = yoomapPlatforms[0] && yoomapPlatforms[0].url,
            multiplePlatform            = yoomapPlatforms.length > 1,
            buttonPlatform              = defaultPlatform || firstPlatformUrl,
            menu                        = this.platformsMenu(buttonPlatform),
            button                      = this.sendToPlatformButton(buttonPlatform, hasItems, multiplePlatform),
            expandButton                =  (
                <Button
                    type="primary" disabled={!hasItems || isLoading}
                    className="send-other-app-expand-arrow"
                    onClick={_.noop}
                >
                    <Icon type="expand-arrow" height={8}
                        color="#fff"
                    />
                </Button>
            );

        // No platform => no button
        if (yoomapPlatforms.length < 1) {
            return;
        }

        // Multiple platforms => on over: show menu
        if (multiplePlatform) {
            return (
                <Dropdown overlayClassName="platforms" menu={menu}
                    disabled={!hasItems || isLoading}
                >
                    <span>
                        {button}
                        {expandButton}
                    </span>
                </Dropdown>
            );
        }

        // Just one platform => display simple button
        return button;
    }


    /**
     * Render buttons
     *
     * @return html
     */
    renderButtons() {
        const { close }                     = this.props,
            { selectedItemsIds, isLoading } = this.state,
            clipboardItems                  = this.getClipboardItems(),
            hasItems                        = clipboardItems && clipboardItems.size > 0,
            hasSelection                    = hasItems && selectedItemsIds.length > 0;

        return (
            <div key="clipboard-buttons" className="footer-buttons">
                <Button onClick={close}>
                    Close
                </Button>
                <Button type="primary" disabled={!hasItems || isLoading}
                    onClick={this.launchExport}
                >
                    { hasSelection ? 'Export selected' : 'Export' }
                </Button>
                {this.renderYoomapButton()}
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            [
                this.renderContent(),
                this.renderYoomap(),
                this.renderButtons(),
            ]
        );
    }

}

Clipboard.propTypes = {
    member                : ImmutablePropTypes.map,
    clipboardItems        : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    platforms             : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    close                 : PropTypes.func,
    updateMemberSettingsCb: PropTypes.func,
    resetDownloadsList    : PropTypes.func,
    removeItems           : PropTypes.func,
    fetchPlatforms        : PropTypes.func,
    fetchPlatformsTokens  : PropTypes.func,
    getPlatformsOfService : PropTypes.func,
    emitEvent             : PropTypes.func,
};

Clipboard.defaultProps = {
    clipboardItems: false
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    const clipboardItems = state.getIn(['userView', 'clipboard_item', 'list']),
        member           = state.getIn(['auth', 'member']),
        platforms        = state.getIn(['auth', 'platforms']) || false;

    return {
        clipboardItems,
        member,
        platforms
    };
};

export default connect(mapStateToProps, {
    resetDownloadsList,
    removeItems,
    updateMemberSettingsCb: updateMemberSettings,
    fetchPlatforms,
    getPlatformsOfService,
    fetchPlatformsTokens,
    emitEvent,
})(Clipboard);
