import React, { PureComponent } from 'react';
import PropTypes            from 'prop-types';
import { Skeleton }         from 'antd';
import _                    from 'lodash';

import { Icon, Collection } from 'helpers';

import './assets/expert.less';

/**
* Render Expert Entity
*
*/
class Expert extends PureComponent {

    /**
    * Returns whether the entity is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity }  = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const {
            entity, showExpandedContent,
            onClick, renderOpenableIcon,
            expandRow,
        } = this.props;

        if (showExpandedContent) {
            return false;
        }

        // Real row content
        return (
            <>
                <td className="label clickable" onClick={onClick}>
                    <div className="left">
                        <Icon
                            id="expert"
                            folder="/entities/"
                            width={18}
                            height={18}
                            borderColor="#dddddd"
                            borderSize={1}
                            discShaped
                        />
                        <span className="ellipsis">{entity.label}</span>
                        {renderOpenableIcon()}
                    </div>
                </td>
                {expandRow ? <td> </td> : this.renderAuthorities()}
                {this.renderDocumentsCount()}
            </>
        );
    }


    /**
    * Render Nb Doc
    *
    * @return JSX
    */
    renderDocumentsCount() {
        const { entity } = this.props;

        return (
            <td className="ellipsis">
                {entity.coworks}
            </td>
        );
    }

    /**
    * Render authorities of the entity
    *
    * @return JSX
    */
    renderAuthorities(colSpan) {
        const { entity, onClick } = this.props,
            { authority }         = entity;

        return (
            <td className="ellipsis authorities" colSpan={colSpan}>
                {authority && (
                    <Collection
                        onClick={onClick}
                        entitySource={entity}
                        entities={authority}
                        type="orgunit"
                        mode="inline"
                        allowExport={false}
                    />
                )}
            </td>
        );
    }

    /**
    * Render the expanded content for the list
    *
    * @return JSX
    */
    renderExpandedContent() {
        const {
                showExpandedContent, allowExport,
            }       = this.props,
            colSpan = allowExport ? 5 : 1;

        if (!showExpandedContent) {
            return false;
        }

        return (
            this.renderAuthorities(colSpan)
        );
    }

    /**
    * Render loading patent row
    *
    * @return JSX
    */
    renderLoading() {
        const { columns }   = this.props,
            numberOfColumns = columns.length;

        if (!this.isLoading()) {
            return false;
        }

        return (
            _.map(_.range(numberOfColumns), index => {
                return (
                    <td key={index}>
                        <Skeleton loading active
                            paragraph={false}
                        />
                    </td>
                );
            })
        );
    }

    /**
    * Render the patent row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render patent row
        return (
            <>
                {this.renderContent()}
                {this.renderExpandedContent()}
            </>
        );
    }

}

Expert.propTypes = {
    allowExport        : PropTypes.bool,
    columns            : PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    expandRow          : PropTypes.bool,
    onClick            : PropTypes.func,
    showExpandedContent: PropTypes.bool,
    renderOpenableIcon : PropTypes.func,
    entity             : PropTypes.shape({
        authority: PropTypes.array,
        isLoading: PropTypes.bool,
        label    : PropTypes.string,
        coworks  : PropTypes.number,
    }).isRequired,
};

Expert.defaultProps = {
    expandRow          : false,
    showExpandedContent: false,
    onClick            : () => {},
    renderOpenableIcon : () => {},
};

export default Expert;
