import React, { Component }         from 'react';
import PropTypes                    from 'prop-types';
import { Skeleton }                 from 'antd';

import { Collection, Highlight }    from 'helpers';

import './assets/webdoc.less';

/**
 * Display a list of webdoc
 *
 */
class Webdoc extends Component {

    /**
    * Returns whether the model is loading or not
    *
    * @return bool
    */
    isLoading() {
        const { entity }  = this.props,
            { isLoading } = entity;

        return isLoading;
    }

    /**
    * Render the main content of the document
    *
    * @return JSX
    */
    renderContent() {
        const {
                entity,
                onClick
            }     = this.props,
            title = entity.title || entity.url;

        return (
            <>
                <td className="ellipsis webdoc-title">
                    <a href={entity.url} target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Highlight stripTags title>
                            {title}
                        </Highlight>
                    </a>
                </td>
                <td className="ellipsis webdoc-type">
                    {entity.mime}
                </td>
                <td className="ellipsis webdoc-url">
                    <a href={entity.url} target="_blank"
                        rel="noopener noreferrer"
                    >
                        {entity.url}
                    </a>
                </td>
                <td className="ellipsis autority">
                    {entity.authority && (
                        <Collection
                            onClick={onClick}
                            entitySource={entity}
                            entities={entity.authority}
                            type="orgunit"
                            mode="inline"
                            allowExport={false}
                        />
                    )}
                </td>
            </>
        );
    }

    /**
    * Render loading project row
    *
    * @return JSX
    */
    renderLoading() {
        if (!this.isLoading()) {
            return false;
        }

        return (
            <>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
                <td><Skeleton loading active
                    paragraph={false}
                />
                </td>
            </>
        );
    }

    /**
    * Render the project row for lists diplay
    *
    * @return JSX
    */
    render() {
        // Loading state
        if (this.isLoading()) {
            return this.renderLoading();
        }

        // Render project row
        return (
            <>
                {this.renderContent()}
            </>
        );
    }

}

Webdoc.propTypes = {
    entity: PropTypes.shape({
        authority: PropTypes.shape({
            slice: PropTypes.func
        }),
        isLoading: PropTypes.bool,
        mime     : PropTypes.string,
        title    : PropTypes.string,
        url      : PropTypes.string
    }).isRequired,
    onClick         : PropTypes.func,
    publicationTypes: PropTypes.shape({}).isRequired,
};

export default Webdoc;

