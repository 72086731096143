import React, {useState, useEffect}  from 'react';
import PropTypes                     from 'prop-types';
import { Modal, Select }             from 'antd';
import { Icon, Collection }          from 'helpers';
import BookmarksRenderer             from '../Bookmarks';
import { connect }                   from 'react-redux';
import { learn }                     from 'store/actions/knowledge';
import { isEmpty, chain}             from 'lodash';
import { capitalize }                from 'utils/text';

import { emitEvent }                 from 'store/actions/sockets';
import { addBookmark }               from 'store/actions/userView/bookmarks';
import {
    getUserViewItemFromModel,
    getDataEntity,
    chunkSize
}                           from 'store/actions/userView';


import './assets/modal.less';

/**
 * Render the Modal Bookmark
 * React Functional Components (Hooks)
 *
 * @return JSX
*/
function ModalBookmark({ // eslint-disable-line max-lines-per-function
    title, entities, learnKnowledge, onCancel, onSubmit,
    addBookmark, getUserViewItemFromModel, emitEvent
}) {
    const [tags, setTags]                      = useState([]),
        [values, setValues]                    = useState([]),
        [entitiesList, setEntitiesList]        = useState(entities),
        [invalidEntities, setDisabledEntities] = useState([]),
        [entityTypes, setEntityTypes]          = useState(null),
        [selectedFolder, setSelectedFolder]    = useState(null),
        [canSubmit, setCanSubmit]              = useState(false);

    // Recover tags and entities from knowledge
    useEffect(() => {
        const inputs = getInputs();
        learnKnowledge(['tags', 'entities']).then(
            data     => {
                setTags(data.tags);
                setEntityTypes(data.entities);
            }
        );
        setValues(getInitialValues(inputs));
        setEntitiesList(entities);
    }, []);

    // Check if all required input have a value
    useEffect(() => {
        const invalidEntity = isEmpty(entities) || entities.filter(e => isEmpty(e)).length > 0,
            invalid         = invalidEntity || !values || values.filter(
                value => (isEmpty(value.value)) && value.required
            )?.length;
        setCanSubmit(!invalid && entitiesList.length > 0);
    }, [values, entities, entitiesList]);

    /**
     * Get initial values from inputs
     * @returns
     */
    function getInitialValues(inputs) {
        return inputs && inputs
            .filter(input => input.id !== 'entity') // Entity is not an input
            .map((input) => {
                const {id, value, required} = input;
                return {id, value, required};
            });
    }

    /**
     * Set all entity IDs associated with the bookmark folder into disabledEntities
     * @param {*} bookmark_folder
     */
    const checkBookmarkInFolders = (bookmark_folder) => {
        const disabledEntities = entities.filter(entity => {
            return getUserViewItemFromModel(entity, 'bookmark')
                ?.parent_attachments.find(attach => attach.key === `member/bookmark_folder/${bookmark_folder}`);
        }).map(entity => entity.id);
        setDisabledEntities(disabledEntities);
    };

    /**
    * Change the value in states using input id
    *
    * @param {String} id
    * @param {*} value
    */
    const changeHandler = (id, newValue) => {
        if(id === 'parent_bookmark_folder') {
            checkBookmarkInFolders(newValue);
        }

        setValues(values.map(
            value => (
                value.id === id
                    ? { ...value, value: newValue }
                    : value
            )
        ));
    };

    /**
     * Render TagSelector
     */
    const renderTagSelector = (input) => {
        const { id }   = input,
            { Option } = Select;

        return tags && (
            <Select
                mode="multiple"
                placeholder="Add classifications"
                onChange={(e) => changeHandler(id, e)}
                getPopupContainer={trigger => trigger.parentNode}
            >
                {
                    tags.map((tag) => {
                        return (
                            <Option key={tag.id} className="tag-selector">
                                <div className="tag-element">
                                    <Icon
                                        type={tag.icon}
                                        color={tag.color}
                                        height={14}
                                        theme="filled"
                                        style={{ color: tag.color }}
                                    />
                                    <span>{tag.label}</span>
                                </div>
                            </Option>
                        );
                    })
                }
            </Select>
        );
    };

    /**
     * Return parsed values to onSubmit function
     *
     * @returns
     */
    const onOk = () => {
        const inputValues         = chain(values).keyBy('id').mapValues('value').value(),
            currentFolderBookmark = selectedFolder?.children?.length,
            numBookmarkToAdd      = entitiesList.length;

        // Submit cb
        onSubmit({...inputValues, entities: entitiesList});

        emitEvent({
            module          : 'add-bookmarks-modal',
            name            : 'add-bookmarks',
            data            : entitiesList,
            attributesFilter: ['id', 'type']
        });

        // Send a first element if needed and call cb (prevent 409 errors)
        if(
            currentFolderBookmark === 0
            && numBookmarkToAdd > chunkSize
            && numBookmarkToAdd > 1
        ) {
            const firstBookmark = entitiesList[0];
            addBookmark(
                firstBookmark,
                inputValues,
                addAllBookmarksExceptFirst
            );
            return;
        }

        // Send all elements
        addBookmark(entitiesList, inputValues);
    };

    /**
     *  Add all bookmarks except first element
     */
    const addAllBookmarksExceptFirst = () => {
        const inputValues           = chain(values).keyBy('id').mapValues('value').value(),
            allBookmarksExceptFirst = entitiesList.slice(1);

        addBookmark(allBookmarksExceptFirst, inputValues);
    };

    /**
     * Get the data entity type label (the entity at the lowest level)
     *
     * @returns
     */
    const getDataEntityTypeLabel = () => {
        const type        = getDataEntityType(entities[0]),
            entitiesCount = entitiesList.length,
            entityType    = entityTypes && entityTypes.find((e) => e.id === type);

        if(type == 'query') {
            return 'Search';
        }

        return (entitiesCount == 1)
            ? capitalize(entityType?.label)
            : `${entitiesCount} ${capitalize(entityType?.label_plural)}`;
    };

    /**
     * Get the data entity (the entity at the lowest level)
     *
     * @returns type
     */
    const getDataEntityType = (entity) => {
        const  { type } = getDataEntity(entity);

        return type;
    };

    /**
     * Render entities list
     * @returns
     */
    const renderEntitiesList = () => {
        const label              = getDataEntityTypeLabel(),
            disableInteractivity = entitiesList.length == 1;

        return (
            <div className="element">
                <span className="element-label">{label}</span>
                <div className="input input-entity">
                    <Collection
                        entities={entitiesList}
                        disabledEntities={invalidEntities}
                        mode="block"
                        disableActions
                        allowListRemoval
                        renderActionsInline
                        updateListEntities={setEntitiesList}
                        disableInteractivity={disableInteractivity}
                    />
                </div>
            </div>
        );
    };

    /**
     * Render specific
     *
     * @param {object} entity
     */
    const renderInputContent = (input) => {
        const {inputType, id}    = input,
            value                = values.find(value => (value.id === id))?.value;

        // Type bookmarks create a bookmark tree
        if(inputType === 'bookmarks') {
            return (
                <BookmarksRenderer
                    onSelect={(elementTree) => {
                        changeHandler(id, elementTree?.model.id);
                        setSelectedFolder(elementTree);
                    }}
                    draggable={false}
                />
            );
        }
        // Type tags create a tag selector
        if(inputType === 'tags') {
            return renderTagSelector(input);
        }
        // By default create a html input
        return (
            <input id={id} type="text"
                value={value} onChange={(e) => changeHandler(id, e.target?.value)}
            />
        );
    };

    /**
     * Return the list of inputs based on the entity type
     *
     * @returns JSX
     */
    const getInputs = () => {
        const type = getDataEntityType(entities[0]),
            inputs = [  // Default inputs for all bookmark types
                {id: 'parent_bookmark_folder', inputType: 'bookmarks', label: 'Folder', value: null, required: true}
            ];

        (type === 'query')
            && inputs.unshift({id: 'label', inputType: 'input', label: 'Name', placeholder: '-', value: entities[0].label});
        (type === 'orgunit')
            && inputs.push({id: 'tags', inputType: 'tags', label: 'Classify', placeholder: '-', value: []});

        return inputs;
    };

    /**
     * Render a base input html content
     *
     * @param {object} entity
     * @returns
     */
    const renderInputs = () => {
        return getInputs().map((input) => {
            const {label, required, id } = input,
                renderedInput = renderInputContent(input);
            return renderedInput && (
                <div key={id} className={`element ${input.type ?? ''}`}>
                    <span className="element-label">{label} {required ? '*' : ''}</span>
                    <div className={`input input-${id}`}>
                        {renderedInput}
                    </div>
                </div>
            );
        });
    };

    /**
    * Rendering the bookmarks in a tree with folders
    *
    * @returns JSX
    */
    const render = () => (
        <Modal
            title={title} wrapClassName="bookmark-modal"
            zIndex={1050}
            closeIcon={(
                <Icon className="icon close" height={16}
                    type="close"
                />
            )}
            trigger="click"
            destroyTooltipOnHide
            open
            okText="Done"
            okButtonProps={{disabled: !canSubmit}}
            onOk={onOk}
            onCancel={onCancel}
        >
            {renderEntitiesList()}
            {renderInputs()}
        </Modal>
    );


    return render();
}

ModalBookmark.propTypes = {
    title   : PropTypes.string.isRequired,
    entities: PropTypes.array.isRequired,
    onOk    : PropTypes.func,
    onCancel: PropTypes.func
};


ModalBookmark.defaultProps = {
    entities: []
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn,
    getUserViewItemFromModel,
    addBookmark,
    emitEvent,
})(ModalBookmark);
