import _    from 'lodash';

/**
 * List of months in 3 letter format.
 * Used to format short dates in Feed Component
 */
export const shortMonths = {
    1 : 'Jan',
    2 : 'Feb',
    3 : 'Mar',
    4 : 'Apr',
    5 : 'May',
    6 : 'Jun',
    7 : 'Jul',
    8 : 'Aug',
    9 : 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
};

/**
 * Format an input date like :
 * 15 Apr 2019
 *
 * @return string
 */
export const formatShortDate = (date) => {
    const month = shortMonths[date.getMonth() + 1];

    return `${date.getDate()} ${month} ${date.getFullYear()}`;
};

/**
 * Normalize a date string to the format YYYY-MM-DD
 * Useful for exports to have a consistent format among different sources.
 *
 * @return string
 */
export const normalizeDateString = (dateString) => {
    const date = new Date(dateString),
        year  = date.getFullYear(),
        month = `0${date.getMonth() + 1}`.slice(-2),
        day   = `0${date.getDate()}`.slice(-2);

    return `${year}-${month}-${day}`;
};



/**
* Test if this date are same
*
* @return boolean
*/
export const areSameDate = (date, otherDate) => {
    const otherDateObj = new Date(otherDate),
        dateObj        = new Date(date);

    return dateObj.getDate() == otherDateObj.getDate()
    && dateObj.getMonth() == otherDateObj.getMonth()
    && dateObj.getFullYear() == otherDateObj.getFullYear();
};

/**
* Get the day label
*
* @return string
*/
export const getDayLabel = (fullDate) => {
    const fullDateObj = new Date(fullDate),
        today         = new Date(),
        yesterday     = new Date(),
        formattedDate = fullDateObj.toLocaleString('en-GB', { year: 'numeric', month: 'long', day: '2-digit' });

    // Set yesterday
    yesterday.setDate(today.getDate() - 1);

    // Check today
    if (areSameDate(fullDate, today)) {
        return 'Today';
    }

    // Check yesterday
    if (areSameDate(fullDate, yesterday)) {
        return 'Yesterday';
    }
    // Return formatted day
    return formattedDate;
};


/**
 * Get timeframe label
 */
export const getCustomTimeFrameLabel  = (value, default_timeframes = []) => {
    const timeframe = default_timeframes.find(timeframe => timeframe.id === value),
        label       = timeframe?.label;


    // Custom filter case: custom_1980_2022 or custom_198001_202212
    if (value?.includes('custom_')) {
        const pattern   = /custom_(\d{4})(\d{2})?_(\d{4})(\d{2})?/,
            match       = value.match(pattern),
            startYear   = match[1],
            startMonth  = match[2] ? `-${match[2]}` : '',
            endYear     = match[3],
            endMonth    = match[4] ? `-${match[4]}` : '',
            customLabel = startYear === endYear && startMonth === endMonth
                ? `${startYear}${startMonth}`
                : `From ${startYear}${startMonth} to ${endYear}${endMonth}`;

        return _.lowerFirst(customLabel);
    }

    if (value?.includes('tfy')) {
        const years = value.replace('tfy', '');

        return `Last ${years} years`;
    }

    if (value?.includes('all')) {
        return 'All';
    }

    if (label) {
        return _.lowerFirst(label);
    }

    return false;
};

/**
 * Get timestamp in second
 * @returns int
 */
export const getTimeStamp = () => {
    return ~~(Date.now() / 1000);
};

/**
* Get min and max years from DateRange string
*/
export const getYearsFromCustomString = (daterangeString) => {
    const presetYearExtract = /tfy(\d*)$/.exec(daterangeString),
        customExtract       = /custom_(\d*)_(\d*)$/.exec(daterangeString),
        currentYear         = new Date().getFullYear();

    // Is a year preset range
    if (_.isArray(presetYearExtract) && presetYearExtract.length === 2) {
        return [currentYear - parseInt(presetYearExtract[1]), currentYear];
    }

    // Is a custom range
    if (_.isArray(customExtract) && customExtract.length === 3) {
        return [parseInt(customExtract[1]), parseInt(customExtract[2])];
    }
};
