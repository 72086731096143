import PropTypes                                                     from 'prop-types';
import ImmutablePropTypes                                            from 'react-immutable-proptypes';
import { withRouter }                                                from 'react-router-dom';
import React, { useState, useEffect, useMemo }                       from 'react';
import { connect }                                                   from 'react-redux';
import { Tree as ATree }                                             from 'antd';
import { moveBookmarkFolder, saveNewBookmarkFolder, getRootFolder }  from 'store/actions/userView/bookmarksFolders';
import { moveBookmark }                                              from 'store/actions/userView/bookmarks';
import { removeItemFromModel, getUserViewItemFromModel }             from 'store/actions/userView';
import { Entity, Icon }                                              from 'helpers';
import { capitalize }                                                from 'utils/text';

import './assets/tree.less';
import {
    upperFirst, mapKeys, difference,
    camelCase, first, sortBy, map, xor
}                                         from 'lodash';


/**
* Render the bookmarks in tree or flat style
* React Functional Components (Hooks)
*
* @return JSX
*/
function Tree(props) {  // eslint-disable-line max-lines-per-function
    let currentTargetId   = null;
    let currentDropEffect = 'none';
    let lastUpdateDraggedPositionTime = 0;

    // The useSate block
    const [firstRender, setFirstRender]         = useState(true);
    const [draggedNode, setDraggedNode]         = useState(null);
    const [expandedFolders, setExpandedFolders] = useState([]);

    const rootRef = React.useRef();

    /**
     * Takes all folder in a branch and returns deep all sub folders()
     *
     * @param {array} branch
     * @returns  {array}
     */
    const getFoldersOfBranch = (branch) => {
        let folders = [];

        branch.forEach(elementTree => {
            const { children, model } = elementTree,
                { type }              = model;

            if (type === 'bookmark_folder') {
                folders.push(elementTree);
                folders = folders.concat(getFoldersOfBranch(children));
            }
        });
        return folders;
    };


    /**
     * A function that returns the root folder id.
     *
     * @returns
     */
    const getRootFolderId = () => {
        const { getRootFolder } = props,
            rootFolder          = getRootFolder();

        return rootFolder?.model?.id;
    };


    /**
     * Get deep all folders
     *
     * @returns array
     */
    const computeFlattenFolders = () => {
        const { foldersTree } = props,
            folders           = getFoldersOfBranch(foldersTree);

        return folders;
    };

    const flattenFolders = useMemo(computeFlattenFolders, [props.foldersTree]);

    /**
    * Triggered when the component is ready
    */
    useEffect(() => {
        const { current }     = rootRef,
            {
                draggable, foldersStats
            }                  = props,
            dragAndDropEvents = draggable
                ? ['dragStart', 'dragOver', 'dragLeave', 'dragEnd', 'drop']
                : [],
            /**
             * Bind all drag and drop functions
            */
            getFunctionByName = (eventName) => eval(`on${capitalize(eventName)}`),  // eslint-disable-line no-eval
            {
                modelsAreLoaded: folderAreLoaded
            }                 = foldersStats.toJS();

        if (folderAreLoaded) {
            setFirstRender(false);
        }

        // Register Drag and Drop events
        dragAndDropEvents.forEach(
            eventName => current?.addEventListener(
                eventName.toLowerCase(),
                getFunctionByName(eventName)
            )
        );

        return () => {
            // Unregister Drag and Drop events
            dragAndDropEvents.forEach(
                eventName => current?.removeEventListener(
                    eventName.toLowerCase(),
                    getFunctionByName(eventName)
                )
            );
        };
    });



    /**
     * Make function called by the text edit on (ok and cancel)
     *
     * @param {string} id
     * @returns function
     */
    const makeSaveNewFolderCb = (id) => {
        const { saveNewBookmarkFolder, removeItemFromModel } = props;

        // Trigger on save or cancel
        return (e) => {
            const { event, payload } = e || {},
                folder      = flattenFolders.find(folder => folder.model.id === id),
                { model }   = folder,
                label       = payload;

            // On cancel
            if (event == 'cancel') {
                removeItemFromModel(model);
                return;
            }

            // On Save
            if (event == 'submit' && label) {
                saveNewBookmarkFolder({ ...model, label });
            }
        };
    };


    /**
    * Mapping the foldersTree and add with an icon
    */
    const getTreeData = (branch, parent) => {
        const { foldersTree, tags, addFolder, onClickItem, draggable } = props;

        return (branch || foldersTree).map(elementTree => {
            const {
                    model, children
                }               = elementTree,
                {
                    key,
                    type, label, description,
                    ancestorsIds, id
                }               = model,
                additionalData  = parent
                    ? {
                        parent_id    : parent.model.id,
                        parent_type  : parent.model.type,
                        ancestors_ids: ancestorsIds
                    } : {},
                newFolders      = getNewFolders(),
                newFolderIds    = newFolders.map(newsFolder => newsFolder.model.id),
                isNewFolder     = id && newFolderIds.includes(id),
                forceEditModeCb = isNewFolder && makeSaveNewFolderCb(id);

            elementTree.title = (
                <Entity
                    key={`${key}${label}${description}`}
                    render="block"
                    entity={elementTree.model}
                    tags={tags}
                    additionalData={additionalData}
                    draggable={draggable}
                    disableInteractivity={isNewFolder}
                    addFolder={addFolder}
                    onClick={onClickItem}
                    forceEditModeCb={forceEditModeCb}
                    useLoading
                />
            );

            elementTree.children  = getTreeData(children, elementTree);
            elementTree.className = type;

            return elementTree;
        });
    };


    /**
    * It removes the className class from all the `entity-block` elements
    *
    */
    const removeNodesClass = (classNames) => {
        const { current } = rootRef;

        classNames.forEach(className => current?.classList.remove(className));

        current?.querySelectorAll(classNames.map(className => `.${className}`).join(','))
            .forEach(element => {
                classNames.forEach(className => element.classList.remove(className));
            });
    };


    /**
     * Add class name to the node with data-id = id
     *
     * @param {string}  id
     * @param {string } className
     */
    const addClassToNode = ({id, parentId}, className) => {
        const { current }         = rootRef,
            idSelector          = id ? `[data-id="${id}"]` : '',
            parentIdSelector    = parentId ? `[data-parent-id="${parentId}"]` : '',
            selector            = `.entity-block${idSelector}${parentIdSelector}`,
            folderNode          = current?.querySelector(selector);

        folderNode?.classList.add(className);

        if (id === getRootFolderId()) {
            current?.querySelector('.ant-tree').classList.add(className);
        }
    };


    /**
    * Getting the folder dataset using the event target.
    * And add the folder treeNode DOM element
    */
    const getFolderDatasetFromDOM = (event) => {
        const  { current } = rootRef;
        // Manage bad target
        if (!event.target?.closest) {
            return {};
        }
        const {
                target: rawTarget
            }                    = event,

            // Manage over on tree switcher
            isTreeSwitcher       = rawTarget.closest('.ant-tree-switcher') || rawTarget.closest('.ant-tree-node-content-wrapper'),
            target               = isTreeSwitcher
                ? rawTarget.closest('.ant-tree-treenode').querySelector('.entity-block')
                : rawTarget,
            entityNode           = target.closest('.entity-block, .bookmark-tree'),
            { dataset }          = entityNode || target,
            isBookmark           = dataset?.type === 'bookmark',
            parentTreeNode       = isBookmark && entityNode.closest('.entity-block'),
            parentFolderId       = parentTreeNode && parentTreeNode.dataset.parent_id,
            folderNode           = parentFolderId && current.querySelector(`.entity-block[data-id="${parentFolderId}"]`),
            {
                dataset: folderDataset
            }                    = folderNode || {},
            // Use Object.assign to convert DOMStringMap to object and change pointers
            targetIsRootBookmark = !folderDataset && isBookmark,
            datasetToReturn      = targetIsRootBookmark
                ? { id: getRootFolderId(), type: 'bookmark_folder' }
                : {...(folderDataset || dataset)};

        datasetToReturn.treeNode = isBookmark ? folderNode : entityNode;

        return datasetToReturn
            // Add suffix returned data keys target and transform keys to camel case
            ? mapKeys(datasetToReturn, (value, key) => `target${upperFirst(camelCase(key))}`)
            : {};
    };

    /**
    *  It removes the `dragged-node` class from all the `entity-block` elements
    *
    */
    const removeDraggedNodeClass = () => {
        const { element } = draggedNode || {};

        if (element) {
            element.style.left = null;
            element.style.top  = null;
        }

        removeNodesClass(['dragged-node', 'dragged-element', 'tree-node-hidden']);
    };


    /**
     * Get folders of the bookmark
     *
     * @param {string} id Bookmark id
     * @returns array
     */
    const getFoldersOfBookmark = (id) => {
        const {
                bookmarkFolders, getUserViewItemFromModel
            }                      = props,
            bookmarkItem           = getUserViewItemFromModel({id}, 'bookmark'),
            { parent_attachments } = bookmarkItem || [],
            parent_keys            = map(parent_attachments, 'key') || [],
            parentFolders          = bookmarkFolders.filter(item => parent_keys.includes(item.key)).toJS();

        return parentFolders;
    };


    /**
     * Get depth level of a folder
     *
     * @param {string} folderId
     * @returns {int}
     */
    const getDepthLevel = (folderId) => {
        const children  = flattenFolders.filter(folder => folder.model.ancestorsIds.includes(folderId)),
            deepFolder  = first(sortBy(children, folder => -folder.model.ancestorsIds.length)),
            depth       = deepFolder ? deepFolder.model.ancestorsIds.length - 1 : null,              // -1 to remove root depth
            folderDepth = deepFolder ? deepFolder.model.ancestorsIds.indexOf(folderId) - 1 : null;   // -1 to remove root depth

        if (!deepFolder) {
            return 0;
        }

        return depth - folderDepth;
    };

    /**
    * Setting the `draggedNode` state and add dragged-node class
    *
    */
    const onDragStart = (event) => { // eslint-disable-line no-unused-vars
        const { current }    = rootRef,
            {
                target,
                dataTransfer,
                clientX,
                clientY
            }                = event,
            {
                dataset,
            }                = target,
            element          = target.closest('.entity-block'),
            treeNode         = element.closest('.bookmark'),
            {
                id, type,
                parent_id,
                parent_type
            }                = dataset || {},
            parentFoldersIds = type === 'bookmark'
                ? getFoldersOfBookmark(id).map(item => item.model?.id)
                : (parent_id ? [parent_id] : []),
            depthLevel       = getDepthLevel(id);

        if (!id) {
            return;
        }

        requestAnimationFrame(() => {
            element.classList.add('dragged-node');
            element.classList.add('dragged-element');

            element.style.left = `${clientX - 9}px`;
            element.style.top  = `${clientY - 15}px`;

            requestAnimationFrame(() => {
                element.dispatchEvent(new Event('mouseover'));
            });

            setDraggedNode({ id, type, parent_id, parent_type, parentFoldersIds, depthLevel, element });

            if (dataTransfer) {
                dataTransfer.effectAllowed = 'move';

                dataTransfer?.setDragImage(current.querySelector('.empty-element'), 0, 0);
            }

            if (treeNode) {
                treeNode.classList.add('tree-node-hidden');
            }
        });
    };


    /**
    * On drag end => Clean classes and unset draggedNode
    *
    */
    const onDragEnd = () => {  // eslint-disable-line no-unused-vars
        const { onDrag } = props;

        removeOverNodeClass();
        removeDraggedNodeClass();
        setDraggedNode(null);
        onDrag(null);
    };


    /**
     * Test if the dragged item can be dropped
     *
     * @param {DOMEvent} event
     *
     * @return boolean
     */
    const canMoveItem = (folderDataset) => {
        const { bookmarkFolders, onDrag }  = props,
            {
                targetId, targetAncestorsIds,
            }                              = folderDataset,
            {
                id, type,  parentFoldersIds, depthLevel
            }                              = draggedNode || {},
            sourceIsFolder                 = type === 'bookmark_folder',
            targetIsRoot                   = targetId === getRootFolderId(),
            sourceIsAChildren              = targetAncestorsIds && targetAncestorsIds.indexOf(id) !== -1,
            targetIsParent                 = parentFoldersIds?.includes(targetId),
            ancestorsIds                   = targetAncestorsIds ? targetAncestorsIds.split(',') : [],
            level                          = ancestorsIds.length + 1,
            folderItem                     = bookmarkFolders.find(item => item.model?.id === targetId),
            { userViewState, label }       = folderItem?.model || {},
            isBeingUpdated                 = !!userViewState;

        // Disable move on folder with userViewState
        if (isBeingUpdated) {
            onDrag({ message: 'The destination folder is being updated', type: 'warning' });
            return false;
        }

        // Disable move folder into himself
        if (id === targetId) {
            return false;
        }

        // Disable the folder (level max constrain)
        if (sourceIsFolder && level + depthLevel > 5) {
            onDrag({ message: 'Folders can have 5 levels maximum', type: 'warning' });
            return false;
        }

        // Disable move folder to his sub children
        if (sourceIsFolder && sourceIsAChildren) {
            return false;
        }

        // Disable move at the same place
        if (targetIsParent) {
            const message = sourceIsFolder
                ? 'This folder is already here'
                : 'This bookmark is already in this folder';
            onDrag({ message, type: 'warning' });
            return false;
        }

        // Disable move at root for bookmarks
        if (targetIsRoot && !sourceIsFolder) {
            onDrag({ message: 'You can\'t put bookmark at root', type: 'warning' });
            return false;
        }

        onDrag({
            message: `You will move this ${sourceIsFolder ? 'folder' : 'bookmark'} into folder ${label}`,
            type   : 'info'
        });

        return true;
    };


    /**
    *  It removes the `drag-over-node` class from all the `entity-block` elements
    *
    */
    const removeOverNodeClass = () => {
        removeNodesClass(['drag-over-node', 'target-node', 'bad-target-node']);
    };

    /**
    * Adds classes to DOM elements on onDragOver event
    *
    * @returns void
    */
    const addDragOverClasses = (folderDataset, canMove) => {
        const rootFolderId = getRootFolderId(),
            {
                targetId, targetType,
                targetTreeNode,
            }              = folderDataset,
            { id }   = draggedNode || {},
            targetIsFolder = targetType === 'bookmark_folder',
            targetIsRoot   = targetId === rootFolderId;

        if (targetIsRoot) {
            addClassToNode({id: rootFolderId}, canMove ? 'target-node' : 'bad-target-node');
            return;
        }

        if (targetIsFolder) {
            targetTreeNode?.classList?.add(canMove ? 'target-node' : 'bad-target-node');
        }

        if ((targetIsFolder || targetIsRoot) && id !== targetId) {
            addClassToNode({id: targetId}, 'drag-over-node');
        }
    };


    /**
    * Setting the `dragOverNodeId` state.
    *
    * @return void
    */
    const onDragOver = (event) => {   // eslint-disable-line no-unused-vars
        const
            { clientX, clientY } = event,
            { id, element }      = draggedNode || {},
            leftPosition         = clientX && `${clientX - 9}px`,
            topPosition          = clientY && `${clientY - 15}px`;

        if (
            !element
            || (
                element.style.left === leftPosition
                && element.style.top === topPosition
                && event.dataTransfer
            )
        ) {
            event.dataTransfer.dropEffect = currentDropEffect;
            event.preventDefault();

            return;
        }


        const folderDataset = getFolderDatasetFromDOM(event),
            { targetId }    = folderDataset,
            canMove         = canMoveItem(folderDataset);

        // Update dropEffect
        currentDropEffect = canMove ? 'move': 'none';
        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = currentDropEffect;
        }
        event.preventDefault();

        const time      = new Date().getTime(),
            timeElapsed = time - lastUpdateDraggedPositionTime;

        if (timeElapsed < 40) {
            return;
        }

        lastUpdateDraggedPositionTime = time;

        element.style.left = leftPosition;
        element.style.top  = topPosition;

        // Do once
        if (targetId === currentTargetId) {
            return;
        }

        currentTargetId = targetId;

        if (!id) {
            return false;
        }

        // Clean classes added before (last onDragOver)
        removeOverNodeClass();
        // Add classes on elements
        addDragOverClasses(folderDataset, canMove);
    };


    /**
    *  Triggered when the user drag and leave a element.
    *
    */
    const onDragLeave = (event) => { // eslint-disable-line no-unused-vars
    };


    /**
    *  Triggered when the user drops the dragged item.
    *
    */
    const onDrop = (event) => {  // eslint-disable-line no-unused-vars
        const {
                moveBookmarkFolder, bookmarkFolders,
                moveBookmark, onDrag, getUserViewItemFromModel,
            }                        = props,
            { targetId, targetType } = getFolderDatasetFromDOM(event),
            { id, type, parent_id }  = draggedNode || {};

        if (type === 'bookmark_folder') {
            const bookmarkFolderItem = bookmarkFolders.find(item => item.model.id === id),
                parent_bookmark_folder = targetType === 'bookmark_folder' ? targetId : null;

            moveBookmarkFolder(bookmarkFolderItem, { to: parent_bookmark_folder });
        }

        if (type === 'bookmark') {
            const  bookmarkItem        = getUserViewItemFromModel({id}, 'bookmark'),
                parent_bookmark_folder = targetType === 'bookmark_folder' ? targetId : null;

            moveBookmark(bookmarkItem, { from: parent_id, to: parent_bookmark_folder});
        }

        if (event.dataTransfer) {
            event.dataTransfer.dropEffect = 'none';
        }

        removeOverNodeClass();
        onDrag(null);
    };


    /**
     *
     */
    const onSelectItem = (keys) => {
        const { onSelect } = props;
        onSelect(flattenFolders.find(elementTree => elementTree?.key === keys[0]));
    };


    /**
     * On expanded status change on a folder store it in the state
     *
     * @param {array} expanded
     */
    const onExpand = (expanded) => {
        setExpandedFolders(expanded);
    };

    /**
     * Get folders just created (with a temporary model)
     *
     * @returns array
     */
    const getNewFolders = () => flattenFolders.filter(folder => folder.model.userViewState === 'added');



    /**
     * Find a folder in 'added' state and get keys of ancestors
     */
    const getExpandedKeys = () => {
        const { expandAll }        = props,
            newFolders             = getNewFolders(),
            ancestorsIds           = newFolders.reduce((cumul, newFolder) => cumul.concat(newFolder.model.ancestorsIds), []),
            ancestorsFolders       = flattenFolders.filter(folder => ancestorsIds.includes(folder.model.id)),
            newFolderAncestorsKeys = ancestorsFolders.map(folder => folder.key),
            flattenFoldersKeys     = flattenFolders.map(folder => folder.key),
            newFolderToExpand      = difference(newFolderAncestorsKeys, expandedFolders);

        if (firstRender && expandAll && flattenFoldersKeys && xor(flattenFoldersKeys, expandedFolders).length > 0) {
            setExpandedFolders(flattenFoldersKeys);
            return;
        }

        if (newFolderToExpand.length > 0) {
            setExpandedFolders(expandedFolders.concat(newFolderToExpand));
        }

        return expandedFolders;
    };

    /**
     * Get bookmarks and folders in the root
     *
     * @returns array or false
     *
     */
    const getBookmarksAndFolders = () =>  {
        const treeData  = useMemo(getTreeData, [props.foldersTree]),
            rootItem    = first(treeData),
            isValidRoot = rootItem?.model?.id === getRootFolderId();

        if (props.rootFolderId) {
            return treeData;
        }

        if (isValidRoot) {
            return rootItem?.children;
        }

        return false;
    };


    /**
    * Rendering the bookmarks in a tree with folders
    *
    * @returns JSX
    */
    const render = () => {
        const {
                onSelect, draggable,
            }                   = props,
            expandedKeys        = getExpandedKeys(),
            bookmarksAndFolders = getBookmarksAndFolders();

        return bookmarksAndFolders && (
            <div ref={rootRef} className="bookmark-tree"
                data-id={getRootFolderId()} data-type="bookmark_folder"
            >
                <span className="empty-element" />
                <ATree
                    className={draggable ? 'draggable-tree' : ''}
                    treeData={bookmarksAndFolders}
                    blockNode
                    selectable={!!onSelect}
                    onSelect={onSelect && onSelectItem}
                    onExpand={onExpand}
                    checkable={false}
                    expandedKeys={expandedKeys}
                    switcherIcon={(
                        <Icon id="down" height={10} />
                    )}
                    showLine={{showLeafIcon: false}}
                    virtual={false}
                    motion={false}
                />
            </div>
        );
    };

    // The render call
    return render();
}

Tree.propTypes = {
    bookmarkFolders         : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    newsletters             : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    tags                    : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    filters                 : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    learnKnowledge          : PropTypes.func,
    navigateTo              : PropTypes.func,
    onClickItem             : PropTypes.func,
    onSelect                : PropTypes.func,
    entityIsInNewsletter    : PropTypes.func,
    addFolder               : PropTypes.func,
    getUserViewItemFromModel: PropTypes.func,
    orderBy                 : PropTypes.string,
    textFilter              : PropTypes.string,
    hideBookmarksCount      : PropTypes.bool,
    draggable               : PropTypes.bool,
};

Tree.defaultProps = {
    hideBookmarksCount: false,
    draggable         : true,
};


/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => ({
    bookmarkFolders: state.getIn(['userView', 'bookmark_folder', 'list']),
    foldersStats   : state.getIn(['userView', 'bookmark_folder', 'stats']),
});

export default connect(mapStateToProps, {
    moveBookmarkFolder,
    moveBookmark,
    saveNewBookmarkFolder,
    removeItemFromModel,
    getRootFolder,
    getUserViewItemFromModel,
})(withRouter(Tree));

