import _                         from 'lodash';
import PropTypes                 from 'prop-types';
import React, { Component }      from 'react';
import ImmutablePropTypes        from 'react-immutable-proptypes';
import { connect }               from 'react-redux';
import { learn }                 from 'store/actions/knowledge';
import {
    searchOrganization,
    sendOrganization
}                                from 'store/actions/yoomap';
import {
    IButton, Icon, CssLoader
}                                from 'helpers';
import { makeStrClassName }      from 'utils/text';
import notification              from 'helpers/notification.js';
import YoomapDecorator           from 'helpers/Yoomap/IframeDecorator';

import {
    fetchPlatforms,
    fetchPlatformsTokens,
    deleteTokensPlatforms,
    getDefaultPlatformOfService
}                              from 'store/actions/auth/platforms.js';

const actionDefinitions = {
    open: {
        icon : 'rocket-manage',
        label: 'Manage on innosabi Startup'
    },

    send: {
        icon : 'rocket',
        label: 'Send to innosabi Startup'
    },
    signIn: {
        icon : 'sign-in',
        label: 'Sign in innosabi Startup'
    }
};

/**
* Render the send organization button with auto login yoomap detection
*
* @return JSX
*/
class SendOrganization extends Component {


    /**
    * Constructor, initialize the state
    */
    constructor(props) {
        super(props);

        this.state = {
            sendingEntityToYoomap: false,
        };

        // To avoid multiple send on click (More responsive than setSate)
        this.sendingEntityToYoomap = false;

        this.ref = React.createRef();

        _.bindAll(this, 'openYoomapLogin', 'searchOrganization', 'closeYoomapPopin', 'fetchTokens', 'sendEntityToYoomap');
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const {
                platforms, fetchPlatforms, learnKnowledge
            }     = this.props,
            token = this.getToken();

        // Get knowledge definitions
        learnKnowledge(['org_classes'])
            .then(this.setState.bind(this));


        // Init fetching platforms and token
        if (!platforms) {
            fetchPlatforms(this.fetchTokens);
            return;
        }

        if (!token) {
            return this.fetchTokens();
        }

        // Got a valid token = > fetch yoomap organization
        this.fetchYoomapMatchedOrgByPlatform();
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidUpdate() {
        this.fetchYoomapMatchedOrgByPlatform();
    }





    /**
     * Get matched organization from redux
     *
     * @returns object
     */
    getMatchedOrg() {
        const {
                matchedOrgsByPlatform, entity
            }           = this.props,
            url         = this.getPlatformUrl(),
            matchedOrgs = matchedOrgsByPlatform?.get(url);

        return matchedOrgs?.get(entity.id);
    }

    /**
     * Fetch yoomap matched organizations
     *
     * @return void
     */
    fetchYoomapMatchedOrgByPlatform() {
        const platform  = this.getPlatform(),
            token       = this.getToken(),
            matchedOrg  = this.getMatchedOrg();

        if (!matchedOrg && platform && token) {
            this.searchOrganization();
        }
    }


    /**
     * Fet tokens for platforms
     */
    fetchTokens() {
        const { fetchPlatformsTokens } = this.props;

        this.setState({ tokenFetched: false  });
        fetchPlatformsTokens(() => {
            this.setState({ tokenFetched: true });
        });
    }


    /**
     * Open the login iframe
     */
    openYoomapLogin() {
        this.setState({ yoomapLoginOpened: true });
    }


    /**
     * Get yoomap platform
     *
     * @returns object
     */
    getPlatform() {
        const { getDefaultPlatformOfService } = this.props;

        return getDefaultPlatformOfService('yoomap');
    }


    /**
     * Get yoomap matched orgs
     */
    searchOrganization() {
        const { entity, searchOrganization } = this.props;
        searchOrganization(entity, this.getPlatform());
    }


    /**
     * Get url of the yoomap page of matched organization to open
     *
     * @returns string
     */
    getOpenActionUrl() {
        const matchedOrg  = this.getMatchedOrg(),
            { url }       = matchedOrg || {};

        return url;
    }

    /**
    * Render open button
    *
    * @returns JSX or boolean
    */
    renderOpenButton() {
        const { renderIconMode } = this.props,
            url                  = this.getOpenActionUrl(),
            { open }             = actionDefinitions,
            { label, icon }      = open;

        if (!url) {
            return null;
        }

        if (renderIconMode) {
            return (
                <a
                    href={url}
                    target="_blank" rel="noreferrer"
                >
                    <Icon
                        type={icon} height={14}
                        title={label}
                    />
                </a>
            );
        }

        return (
            <IButton
                label={label}
                icon={icon}
                href={url}
                target="_blank"
                fontSize={12}
            />
        );
    }


    /**
    * On send organization mutation error
    *
    * @param {object} error
    */
    onHttpError(error, action) {
        const { deleteTokensPlatforms } = this.props,
            { response }                = error,
            { status, errors }          = response || {},
            firstError                  = errors && errors[0],
            { message }                 = firstError,
            platform                    = this.getPlatform(),
            { id }                      = platform || {},
            tokenIsInvalid              = status === 401 || status === 403,
            description                 = tokenIsInvalid
                ? 'Token is invalid, please log again'
                : message || `We encountered an issue, please try again in a few minutes ( Error ${status} )`;

        // Delete a invalid token
        if (tokenIsInvalid) {
            deleteTokensPlatforms(id);
        }

        // Return notification to the user
        notification({
            message: `Error on ${action}ing organization to innosabi startup`,
            type   : 'error',
            description
        });
    }

    /**
    * Send entity to yoomap ( graphQl mutation )
    *
    * @returns void
    */
    async sendEntityToYoomap() {  // eslint-disable-line max-lines-per-function
        const {
                sendOrganization, entity
            }        = this.props,
            platform = this.getPlatform();

        if (!platform || this.sendingEntityToYoomap) {
            return;
        }

        // To avoid multiple send on click (More responsive than setSate)
        this.sendingEntityToYoomap = true;

        // Add Loading
        this.setState({ sendingEntityToYoomap: true });

        sendOrganization(
            entity,
            platform,
            () => {
                // To avoid multiple send on click (More responsive than setSate)
                this.sendingEntityToYoomap = false;

                // Remove Loading
                this.setState({ sendingEntityToYoomap: false });
            }
        );
    }


    /**
     * Get the token of the active platform
     *
     * @returns string
     */
    getToken() {
        const platform = this.getPlatform(),
            { token }  = platform || {};

        return token;
    }


    /**
    * Get the url of the active platform
    *
    * @returns string
    */
    getPlatformUrl() {
        const platform = this.getPlatform(),
            { url }    = platform || {};

        return url;
    }


    /**
    * Render send button
    *
    * @returns JSX or boolean
    */
    renderSendButton() {
        const { renderIconMode }      = this.props,
            { sendingEntityToYoomap } = this.state,
            token                     = this.getToken(),
            { send }                  = actionDefinitions,
            { label, icon }           = send,
            matchedOrg                = this.getMatchedOrg(),
            { status }                = matchedOrg || {},
            loader                    = (
                <CssLoader type="ring" size={14}
                    thickness={1} color="#ddd"
                />
            );

        if (!token) {
            return null;
        }

        // Organization status is getting from the platform
        if (status === 'loading') {
            return loader;
        }

        // Just icon render
        if (renderIconMode) {
            return this.sendingEntityToYoomap
                ? loader
                : (
                    <Icon
                        type={icon} height={14}
                        title={label}
                        onClick={this.sendEntityToYoomap}
                    />
                );
        }

        // Button render
        return (
            <IButton
                label={label}
                icon={icon}
                iconColor="var(--primary-color)"
                hoverLabelColor="#ffffff"
                hoverBgColor="var(--primary-color)"
                loading={sendingEntityToYoomap}
                onClick={this.sendEntityToYoomap}
                fontSize={12}
            />
        );
    }


    /**
    * Render sign in button
    *
    * @returns JSX or boolean
    */
    returnSignInButton() {
        const {
                renderIconMode
            }              = this.props,
            {
                yoomapLoginOpened,
                tokenFetched
            }               = this.state,
            platform        = this.getPlatform(),
            url             = this.getPlatformUrl(),
            { signIn }      = actionDefinitions,
            { label, icon } = signIn,
            singInButton    = renderIconMode
                ? (
                    <Icon
                        type={icon} height={16}
                        onClick={this.openYoomapLogin}
                        title={label}
                    />

                ) : (
                    <IButton
                        label={label}
                        icon={icon}
                        onClick={this.openYoomapLogin}
                    />
                );

        // TODO: make a better returns

        // User clicked on signIn
        // Render signIn button and launch iframe login
        if (url && platform && yoomapLoginOpened) {
            return (
                <YoomapDecorator
                    url={url}
                    onClose={this.closeYoomapPopin}
                >
                    {singInButton}
                </YoomapDecorator>
            );
        }

        // Just render th signIn button
        if (platform && tokenFetched && !yoomapLoginOpened) {
            return singInButton;
        }
    }


    /**
    * Open Export Report
    *
    */
    closeYoomapPopin() {
        this.setState({
            yoomapLoginOpened: false
        });
    }

    /**
     *
     * @param {*} actionDefinition
     * @returns
     */
    isLoading() {
        const matchedOrg = this.getMatchedOrg(),
            { status } = matchedOrg || {};

        return status === 'loading';
    }


    /**
     * Get open action
     *
     * @returns object
     */
    getOpenAction() {
        const pageUrl       = this.getOpenActionUrl(),
            { open }        = actionDefinitions,
            { label, icon } = open,
            isLoading       = this.isLoading();

        return {
            yoomapOpen: {
                icon  : <Icon type={icon} height={12} />,
                href  : pageUrl,
                target: '_blank',
                rank  : 100,
                label,
                isLoading,
            }
        };
    }


    /**
     * Get send action
     *
     * @returns object
     */
    getSendAction() {
        const { send }      = actionDefinitions,
            { label, icon } = send,
            isLoading       = this.isLoading();

        return {
            yoomapSend: {
                icon: <Icon type={icon} height={12} />,
                cb  : this.sendEntityToYoomap,
                rank: 100,
                label,
                isLoading,
            }
        };
    }


    /**
     * Get signIn action
     *
     * @returns object
     */
    getSignInAction() {
        const { signIn }    = actionDefinitions,
            { label, icon } = signIn;

        return {
            yoomapSignIn: {
                icon: <Icon type={icon} height={12} />,
                cb  : this.openYoomapLogin,
                rank: 100,
                label,
            }
        };
    }

    /**
     * Call setAction and display none
     *
     * @returns Null
     */
    noRenderAndCallSetAction() {
        const { setActionsCb }    = this.props,
            { yoomapLoginOpened } = this.state,
            pageUrl               = this.getOpenActionUrl(),
            token                 = this.getToken(),
            url                   = this.getPlatformUrl(),
            platform              = this.getPlatform(),
            matchedOrg            = this.getMatchedOrg();

        if(!matchedOrg && token) {
            if (platform) {
                setActionsCb({
                    yoomapStandBy: {
                        isLoading: true,
                        target   : '_blank',
                        label    : 'Search on innosabi Startup',
                        rank     : 100,
                    }
                });
            }

            return null;
        }

        // Open action
        if (pageUrl) {
            setActionsCb(this.getOpenAction());
            return null;
        }

        // Send action
        if (token) {
            setActionsCb(this.getSendAction());
            return null;
        }

        // Sign in action
        if (platform) {
            setActionsCb(this.getSignInAction());

            // Render empty decorator
            if (url && yoomapLoginOpened) {
                return (
                    <YoomapDecorator
                        url={url}
                        onClose={this.closeYoomapPopin}
                    />
                );
            }
        }

        return null;
    }


    /**
     * Render the component
     *
     * @returns JSX
     */
    render() {
        const { setActionsCb } = this.props,
            platform           = this.getPlatform(),
            matchedOrg         = this.getMatchedOrg(),
            url                = this.getOpenActionUrl(),
            { status }         = matchedOrg || {},
            classNames         = [
                'send-organization',
                {hide: !url && status !== 'loading'},
                {openable: url}
            ];

        if(!platform || status === 'error') {
            return null;
        }

        if (setActionsCb) {
            return this.noRenderAndCallSetAction();
        }

        return (
            <div ref={this.ref} className={makeStrClassName(classNames)}>
                {(
                    // Render the button by priority
                    this.renderOpenButton()
                    || this.renderSendButton()
                    || this.returnSignInButton()
                )}
            </div>
        );
    }

}

SendOrganization.propTypes = {
    learnKnowledge             : PropTypes.func,
    fetchPlatforms             : PropTypes.func,
    fetchPlatformsTokens       : PropTypes.func,
    deleteTokensPlatforms      : PropTypes.func,
    platforms                  : ImmutablePropTypes.map,
    matchedOrgsByPlatform      : ImmutablePropTypes.map,
    entity                     : PropTypes.object,
    setActionsCb               : PropTypes.func,
    searchOrganization         : PropTypes.func,
    sendOrganization           : PropTypes.func,
    getDefaultPlatformOfService: PropTypes.func,
    renderIconMode             : PropTypes.bool,
};

SendOrganization.defaultProps = {
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    const matchedOrgsByPlatform = state.getIn(['yoomap', 'matchedOrgsByPlatform']),
        platforms               = state.getIn(['auth', 'platforms']);

    return {
        platforms,
        matchedOrgsByPlatform,
    };
};


export default connect(mapStateToProps, {
    fetchPlatforms,
    fetchPlatformsTokens,
    deleteTokensPlatforms,
    getDefaultPlatformOfService,
    learnKnowledge: learn,
    searchOrganization,
    sendOrganization,
})(SendOrganization);
