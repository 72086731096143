/**
 * Display a sidebar to improve Search UX
 *
 * @return Component
 */

import _                              from 'lodash';
import React, { Component, Fragment } from 'react';
import { connect }                    from 'react-redux';
import PropTypes                      from 'prop-types';
import ImmutablePropTypes             from 'react-immutable-proptypes';

import { Icon, LazyLoad }             from 'helpers';
import notification                   from 'helpers/notification.js';

import { learn }                      from 'store/actions/knowledge';

import { get }                        from 'utils/api';
import { getDayLabel }                from 'utils/date';

import {
    removeDownloads,
    relaunchDownload,
    resetDownloadsList,
    nextDownloadsList,
    getImageBlobById,
}                                     from 'store/actions/downloads';
import Empty                          from './Empty';
import ShortcutViewItem               from './ShortcutViewItem';

import './assets/downloads.less';


/**
 * Class Downloads
 *
 */
class Downloads extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'downloadFile', 'removeDownload', 'relaunchExport', 'getActions', 'getScrollSelectors');

        this.ref = React.createRef();

        this.id = _.uniqueId('ui-downloads');

        this.state = {
            previousActive : false,
            beenActivated  : false,
            beenDeactivated: false,
            downloadedFiles: {},
            fileToDownload : null,
        };
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { learnKnowledge } = this.props;
        learnKnowledge(['mimetypes'])
            .then(this.setState.bind(this));
    }

    /**
    * Get state for props and previous state
    *
    *
    * @params nextProps object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { active }       = nextProps,
            { previousActive } = prevState;

        return {
            ...prevState,
            previousActive : active,
            beenActivated  : active && !previousActive,
            beenDeactivated: !active && previousActive,
        };
    }

    /** Triggered when the component is ready
    *
    * @return void
    */
    componentDidUpdate() {
        const {
                resetDownloadsList
            }            = this.props,
            {
                fileToDownload,
                beenActivated

            }            = this.state;

        // On First update
        if (!this.downloadsInitialized) {
            this.downloadsInitialized = true;
            resetDownloadsList();
            return;
        }

        // On open view
        if (beenActivated) {
            resetDownloadsList();  // Update download files list
        }

        // Launch download file
        if (fileToDownload) {
            const { current } = this.ref,
                linkNode      = current.querySelector(`a.download-link[data-download-id="${fileToDownload}"]`);

            linkNode.click();
        }
    }

    /**
    * Get filtered Downloads
    *
    * @return void
    */
    getDownloads() {
        const { downloads } = this.props;

        return downloads?.filter && downloads.filter(download => download.date_delete === null);
    }


    /**
    * Download file
    *
    * @return void
    */
    downloadFile(e) {
        const  downloads            = this.getDownloads(),
            { downloadedFiles }     = this.state,
            { currentTarget }       = e,
            { attributes, dataset } = currentTarget,
            { downloadId }          = dataset,
            { href }                = attributes,
            download                = downloads.get(downloadId),
            { date_ready }          = download;

        if (date_ready && !href) {
            get(`/files/${download.id}/file`, {}).then((response) => {
                const blob        = response.body,
                    objectURL     = blob ? URL.createObjectURL(blob) : null;

                // Store blob file
                downloadedFiles[downloadId] = objectURL;

                // The file is not found, Do not launch the fileToDownload cycle (auto-click by the script)
                if (!objectURL) {
                    notification({
                        message    : 'Oops !',
                        description: 'We encountered a problem download your files, please try again in a few minutes',
                        type       : 'error',
                        duration   : 20
                    });
                    return;
                }

                // Launch fileToDownload cycle (auto-click to get base64 href on link)
                this.setState({ downloadedFiles, fileToDownload: downloadId });
            });
            return;
        }

        // Remove the auto download file from state
        this.setState({ fileToDownload: null });
    }

    /**
    * Render empty content
    *
    * @return html
    */
    renderEmptyContent() {
        return (
            <Empty icon="downloads" textTop={20}>
                Your download manager is empty
            </Empty>
        );
    }

    /**
    * Remove download
    *
    * @return void
    */
    removeDownload(download) {
        const { removeDownloads } = this.props;
        removeDownloads([download]);
    }

    /**
    * Relaunch download
    *
    * @return void
    */
    relaunchExport(download) {
        const { relaunchDownload } = this.props;
        relaunchDownload(download);
    }

    /**
    * Render download line
    *
    * @return html
    */
    renderDownload(download) {
        const { downloadedFiles }   = this.state,
            fileUrl                 = downloadedFiles[download.id];

        return (
            <ShortcutViewItem
                key={download.id}
                model={download}
                getActions={this.getActions}
                fileUrl={fileUrl}
                downloadFile={this.downloadFile}
            />
        );
    }

    /**
     *
     * @returns
     */
    getActions(download) {
        const  { in_error, metadata } = download,
            { factory_type }          = metadata || {},
            canBeRelaunched           = in_error && factory_type === 'export';

        return  {
            relaunch: {
                cb       : canBeRelaunched && this.relaunchExport,
                className: 'relaunch',
                folder   : '/actions/',
                key      : 'refresh',
            },
            delete: {
                cb       : this.removeDownload,
                className: 'delete',
                key      : 'delete',
            }
        };
    }

    /**
    * Render the day label
    *
    * @return html
    */
    renderSearchBar() {
        return null && (
            <div className="search">
                <Icon id="magnify" height={12}
                    color="#aaa"
                />
                <input placeholder="Search in downloads" />
            </div>
        );
    }


    /**
    * Get the DOM selectors for list or detailed list
    *
    * @returns {object}
    */
    getScrollSelectors() {
        const contentSelector = `.content.lazy-${this.id}`,
            scrollSelector    = `.content.lazy-${this.id}`;

        return {
            contentSelector,
            scrollSelector,
        };
    }


    /**
    * Render the content
    *
    * @return html
    */
    render() {
        const {
                nextDownloadsList
            }                = this.props,
            downloads        = this.getDownloads(),
            downloadsArray   = downloads ? _.values(downloads.toJS()) : [],
            groupedDownloads = _.groupBy(downloadsArray, (download) => download.date_create.substring(0, 10)),
            // Get groupKey sorted by key(date_create)
            sortedGroupKey   = _.keys(groupedDownloads).sort().reverse(),
            noItems          = downloadsArray.length === 0;

        return (
            <div className={`content${noItems ? ' empty' : ''} lazy-${this.id}`}>
                <LazyLoad
                    getScrollSelectors={this.getScrollSelectors}
                    loadNextCb={nextDownloadsList}
                >

                    {noItems ? this.renderEmptyContent() : (
                        <Fragment>
                            {this.renderSearchBar()}
                            <div className="downloads" ref={this.ref}>
                                {sortedGroupKey.map((key) => groupedDownloads[key]).map((downloadsByDate) => {
                                    const dayLabel = getDayLabel(downloadsByDate[0].date_create);
                                    return (
                                        <div className="downloads-by-date items-block" key={dayLabel}>
                                            <div className="items-block-header">
                                                {dayLabel}
                                            </div>
                                            <div className="items">
                                                {_.sortBy(downloadsByDate, 'date_create').reverse()
                                                    .map((download) => this.renderDownload(download))}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Fragment>
                    )}
                </LazyLoad>
            </div>
        );
    }

}

Downloads.propTypes = {
    active            : PropTypes.bool,
    downloads         : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    removeDownloads   : PropTypes.func,
    relaunchDownload  : PropTypes.func,
    resetDownloadsList: PropTypes.func,
    nextDownloadsList : PropTypes.func,
    learnKnowledge    : PropTypes.func,
};

Downloads.defaultProps = {
    downloads: false,
};

/**
 */
const mapStateToProps = (state) => {
    const downloads = state.get('downloads').map;

    return {
        downloads
    };
};

export default connect(mapStateToProps, {
    removeDownloads,
    relaunchDownload,
    resetDownloadsList,
    nextDownloadsList,
    getImageBlobById,
    learnKnowledge: learn
})(Downloads);
