import React, { Component }           from 'react';
import { connect }                    from 'react-redux';
import _                              from 'lodash';
import PropTypes                      from 'prop-types';
import {
    Element, Row, Cell, Guider, Icon
}                                     from 'helpers';
import { Popover }                    from 'antd';
import { htmlize }                    from 'utils/text';
import EntityStats                    from 'helpers/Entity/Stats';
import {
    storeNodePreferences,
    getNodePreferences,
}                                     from 'store/actions/navigation/nodesPreferences';

import './assets/item.less';

/**
* Draw layouts with elements inside
*
*/
class DashboardItem extends Component {

    /**
    * Instantiate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'navigateToMetric', 'onDataLoaded', 'renderLayout', 'decorateWithTooltip', 'extractData',
            'enableTooltip', 'disableTooltip', 'openGuide', 'closeGuide', 'avoidTooltip', 'addToClipboard', 'registerCallbacks',
            'getStatsForInsights', 'onUpdatePaginationOrder',
        );

        this.state = {
            data     : null,
            hovered  : false,
            callbacks: {},
        };
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb, element) {
        const { callbacks } = this.state,
            /** Return empty object */
            defaultCb       = () => {};

        // Store only element callbacks
        if (element === null) { return; }

        if (!callbacks[element.id]) {
            callbacks[element.id] = {};
        }

        callbacks[element.id][action] = cb || defaultCb;

        this.setState({ callbacks });
    }

    /**
    * Create a path to navigate to specific metric
    *
    * @param string elementKey The metric key used to generate the path
    *
    * @return string
    */
    navigateToMetric(e) {
        const { element, navigateTo }       = this.props,
            { allElementsHaveBeenDisabled } = this.state,
            stats                           = this.getSourcesStats(),
            hasEntitiesStats                = _.keys(stats).length > 0;

        e.preventDefault();
        e.stopPropagation();

        if (allElementsHaveBeenDisabled) {
            return false;
        }

        if (navigateTo && hasEntitiesStats) {
            navigateTo(element.key);
        }

        return false;
    }

    /**
    * Extract stats from the first object in data
    *
    * @param object data The data object to extract stats for insight
    *
    * @return object
    */
    extractData(data) {
        const dataKeys      = Object.keys(data),
            hasData         = dataKeys.length > 0,
            firstDataObject = hasData ? data[dataKeys[0]] : {},
            { stats }       = firstDataObject || {},
            objectToReturn  = _.isUndefined(stats) && hasData ? this.extractData(firstDataObject) : firstDataObject;

        return {
            stats   : objectToReturn.stats,
            entities: objectToReturn.entities
        };
    }


    /**
     * Get statsForInsights, usefull for registercallbacks
     */
    getStatsForInsights() {
        const { statsForInsights } = this.state;

        return statsForInsights;
    }

    /**
    * Trigger when function is loaded
    *
    * @return void
    */
    onDataLoaded(element, data, allElementsHaveBeenDisabled) {
        const { onDataLoaded, element: selfElement, registerCallbacks } = this.props,
            { statsForInsights, source_entities }                      = this.state,
            { stats, entities }                                        = this.extractData(data);

        if (statsForInsights && source_entities && !allElementsHaveBeenDisabled) {
            return;
        }

        this.setState({
            statsForInsights: stats || null,
            source_entities : entities,
            allElementsHaveBeenDisabled,
        });

        if (onDataLoaded) {
            onDataLoaded(selfElement, data, allElementsHaveBeenDisabled);
        }

        registerCallbacks('addToClipboard', this.addToClipboard, element);
        registerCallbacks('getStatsForInsights', this.getStatsForInsights, element);
    }

    /**
    * AddToClipboard (specific for dashboard item)
    *
    * @params options
    *
    * @return JSX
    */
    addToClipboard(options) {
        const { callbacks } = this.state;

        // Get only enabled subSections
        const {
                element, addAnalyseListToClipboard
            }                               = this.props,
            { allElementsHaveBeenDisabled } = this.state,
            { type }                        = options;

        // Empty item => no export
        if (allElementsHaveBeenDisabled) {
            return;
        }

        // Add elements images to clipboard
        if (type === 'element') {
            // AddToClipboard on the layout
            _.values(callbacks).forEach((element) => element.addToClipboard(options));
        }

        // Add lists to clipboard
        if (type === 'list') {
            // Use Element addAnalyseListToClipboard (global function)
            addAnalyseListToClipboard(options, [element.id]);
        }
    }

    /**
    * Set avoidTooltip
    *
    * @return JSX
    */
    avoidTooltip(avoidTooltip) {
        this.setState({ avoidTooltip });
    }

    /**
    * On update pagination order
    *
    * @param {object} sorts
    */
    onUpdatePaginationOrder(sorts) {
        const { storeNodePreferences } = this.props,
            { element }                 = this.props,
            { key }                     = element || {},
            { hash }                    = window.location,
            idWithModule                = hash.substring(4),
            path                        = `${idWithModule}/${key}`;

        storeNodePreferences(path, { sorts });
    }

    /**
    * Get sort of this analyse from the store
    *
    * @returns {object}
    */
    getPreferences() {
        const { getNodePreferences } = this.props,
            { element }              = this.props,
            { key }                  = element || {},
            { hash }                 = window.location,
            idWithModule             = hash.substring(4),
            path                     = `${idWithModule}/${key}`,
            preferences              = getNodePreferences && getNodePreferences(path);

        return preferences;
    }

    /**
    * Render the group Layout
    *
    * @return JSX
    */
    renderLayout() {
        const {
                element, model, onBeforeLoad,
                tags, bookmarks, storeElementInstances
            }                        = this.props,
            { configuration, label } = element,
            { elements }             = configuration,
            { sorts }                = this.getPreferences();

        return (
            <Element
                key={element.id}
                className="content"
                model={model}
                element={elements}
                onDataLoaded={this.onDataLoaded}
                onBeforeLoad={onBeforeLoad}
                parentLabel={label}
                tags={tags}
                bookmarks={bookmarks}
                storeElementInstances={storeElementInstances}
                avoidTooltip={this.avoidTooltip}
                {...configuration}
                registerCallbacks={this.registerCallbacks}
                onUpdatePaginationOrder={this.onUpdatePaginationOrder}
                sort={sorts}
            />
        );
    }

    /**
    * Return the metric tooltip as soon as it's has previously been loaded
    *
    * @return string
    */
    getSourcesStats() {
        const{ state, module, element } = this.props,
            { source_entities }         = this.state,
            sourceStats                 = {},
            useGeneralStats             = element.configuration.globalSourceEntitiesStats || false,
            searchState                 = state || null,
            searchStats                 = searchState
                ? (useGeneralStats ? searchState.stats : searchState.statsByType[module] || searchState.stats)
                : {};

        _.each(source_entities, (entityId) => {
            if (!searchStats[entityId]) {
                return;
            }
            sourceStats[entityId] = searchStats[entityId];
        });

        return sourceStats;
    }

    /**
     * Return available datasets
     *
     * @returns {Array}
     */
    getAvailableDatasets() {
        const { state }       = this.props,
            availableDatasets = state ? state.availableDatasets : [];

        return availableDatasets;
    }

    /**
    * Check if the item has some entity stats
    *
    * @return bool
    */
    hasStats() {
        const stats = this.getSourcesStats();

        return _.keys(stats).length > 0;
    }

    /**
    * Toggle the insight tooltip
    *
    * @return void
    */
    enableTooltip() {
        const { hovered, guideEnabled } = this.state;
        if (!this.hasStats() || hovered || guideEnabled) {
            return;
        }

        this.setState({ hovered: true });
    }

    /**
    * Toggle the insight tooltip
    *
    * @return void
    */
    disableTooltip() {
        const { hovered, guideEnabled } = this.state;
        if (!hovered || guideEnabled) {
            return;
        }


        this.setState({ hovered: false });
    }

    /**
    * Decorate with some component on mouse over
    *
    * @param array Components component to render
    */
    decorateWithTooltip(content) {
        const { color, element } = this.props,
            {
                hovered, statsForInsights, avoidTooltip,
                allElementsHaveBeenDisabled,
            }                  = this.state,
            { key }            = element,
            { filledInsight }  = statsForInsights || {},
            stats              = this.getSourcesStats(),
            availableDatasets  = this.getAvailableDatasets(),
            domElement         = document.querySelector(`.${key}`) || {},
            maxWidth           = domElement.clientWidth || 0;

        return (
            <Popover
                overlayStyle={{ maxWidth }}
                content={statsForInsights
                    ? (
                        <div className="insight">
                            {filledInsight && (
                                <div className="item-insight">
                                    {htmlize(filledInsight)}
                                </div>
                            )}
                            <span className="entity-stats">
                                Generated using:
                                <EntityStats stats={stats} availableDatasets={availableDatasets} />
                            </span>
                        </div>
                    ) : false}
                trigger="click"
                overlayClassName={`insight content-box ${color}`}
                open={hovered && !avoidTooltip && !allElementsHaveBeenDisabled}
                getPopupContainer={trigger => trigger.parentNode.parentNode.parentNode}
                destroyTooltipOnHide
                placement="bottom"
            >
                {content}
            </Popover>
        );
    }

    /**
    * Render the help Modal
    *
    * @return JSX
    */
    renderHelpBtn() {
        const { help } = this.props;

        if (!help) {
            return false;
        }

        return (
            <span className="help" onClick={this.openGuide}>
                <Icon
                    id="info"
                    height={20}
                    title="Help"
                />
            </span>
        );
    }

    /**
    * Render the guide modal
    *
    * @return JSX
    */
    openGuide(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            guideEnabled: true,
            hovered     : false,
        });

        return false;
    }

    /**
    * Render the guide modal
    *
    * @return JSX
    */
    closeGuide(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            guideEnabled: false,
        });

        return false;
    }

    /**
    * Render the guide modal
    *
    * @return JSX
    */
    renderGuide(content) {
        const {
                help,
                color,
                element
            }                    = this.props,
            { statsForInsights } = this.state,
            { guideEnabled }     = this.state,
            { filledInsight }    = statsForInsights || {},
            { label }            = element;

        return (
            <Guider
                closeModal={this.closeGuide}
                help={help}
                label={label}
                insight={filledInsight}
                insightColor={color}
                visible={guideEnabled}
            >
                {content}
            </Guider>
        );
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        const { element, hideRowLabel, className }        = this.props,
            { allElementsHaveBeenDisabled, guideEnabled } = this.state,
            { label, key, configuration }                 = element,
            classNames                                    = [
                'dashboard-item',
                key,
                className,
                allElementsHaveBeenDisabled ? ' disabled ' : '',
            ],
            // Render the content
            renderedLayout = this.renderLayout();

        return this.decorateWithTooltip(
            <Cell
                {...this.props}
                className={classNames.join(' ')}
                onClickCb={this.navigateToMetric}
                onMouseEnterCb={this.enableTooltip}
                onMouseLeaveCb={this.disableTooltip}
                configuration={configuration}
                dataQaKey={key}
            >
                { !hideRowLabel && label && (
                    <Row className="label">
                        <span>{label}</span>
                    </Row>
                )}
                {!guideEnabled && renderedLayout}
                {this.renderHelpBtn()}
                {guideEnabled && this.renderGuide(renderedLayout)}
            </Cell>
        );
    }

}

DashboardItem.propTypes = {
    addAnalyseListToClipboard: PropTypes.func,
    bookmarks                : PropTypes.any,
    className                : PropTypes.string,
    color                    : PropTypes.any,
    getNodePreferences       : PropTypes.func,
    hideRowLabel             : PropTypes.bool,
    forceEmptyRemoval        : PropTypes.bool,
    help                     : PropTypes.any,
    model                    : PropTypes.shape({}),
    module                   : PropTypes.any,
    navigateTo               : PropTypes.func,
    onBeforeLoad             : PropTypes.any,
    onDataLoaded             : PropTypes.func,
    registerCallbacks        : PropTypes.func,
    state                    : PropTypes.any,
    storeNodePreferences     : PropTypes.func,
    storeElementInstances    : PropTypes.any,
    tags                     : PropTypes.any,
    element                  : PropTypes.shape({
        addToClipboard: PropTypes.func,
        configuration : PropTypes.shape({
            elements                 : PropTypes.any,
            globalSourceEntitiesStats: PropTypes.bool
        }),
        id   : PropTypes.any,
        key  : PropTypes.any,
        label: PropTypes.string
    }).isRequired,
};

DashboardItem.defaultProps = {
    forceEmptyRemoval: false,
    model            : false,
    hideRowLabel     : false,
};

/**
* Bind the store to to component
*/
const mapStateToProps = () => ({});

/**
* Bind Dispatcher to the component props
*/
export default connect(mapStateToProps, {
    getNodePreferences,
    storeNodePreferences,
})(DashboardItem);
