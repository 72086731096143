/**
 * Sockets Actions Creator: create actions for the current member
 *
 */
import _                                from 'lodash';
import * as types                       from './types/sockets';
import { io }                           from 'socket.io-client';
import { getAuthorizationHeaders }      from 'store/actions/auth';
import { updateLastSearchesWithModel }  from 'store/actions/navigation';
import {
    setUserView, updateUserViewModels
}                                       from 'store/actions/userView';
import { updateDownload }               from 'store/actions/downloads';



/**
 * Init the socket core function
 *
 * @return void
 */
const initSocketsCore = (cb) => (dispatch, getState) => {
    const authPromise = getAuthorizationHeaders(),
        member        = getState().get('auth').get('member'),
        userId        = member.get('id');

    if (!userId) {
        console.warn('No member founded to start socket handshake');
        return;
    }

    authPromise.then(
        auth => {
            const socket = io('/user', {
                allowEIO3   : true,
                path        : '/ws-api/socket.io/',
                reconnection: false,
                transports  : ['polling', 'websocket'],   // Use WebSocket classic upgrade
                extraHeaders: {
                    Authorization: auth.Authorization
                },
                query: {
                    jwt: auth.Authorization.replace('Bearer ', '')
                }
            });

            console.log('- WebSockets initialized');

            cb(socket);

            dispatch({ type: types.WEB_SOCKETS_INITIALIZED, payload: socket });
        }
    );
};


/**
 * Reconnect socket IO
 */
const reConnect = (dispatch, getState) => {
    setTimeout(() => {
        initSockets()(dispatch, getState);
    }, 5000);
};

/**
 * Init the socket
 *
 * @returns void
 */
export const initSockets = () => (dispatch, getState) => {
    initSocketsCore(socket => {
        socket.on('connect', () => {
        });

        socket.on('disconnect', () => {
            console.log('Socket disconnected');
            reConnect(dispatch, getState);
        });

        socket.io.on('close', () => {
            console.log('Socket closed');
        });

        socket.on('connect_error', (e) => {
            console.log('Socket connection error', e, JSON.stringify(e));
            reConnect(dispatch, getState);
        });

        socket.on('api.user-view.touched', (payload) => {
            setUserView(payload)(dispatch, getState);
        });

        socket.on('api.models.updated', (payload) => {
            updateUserViewModels(payload)(dispatch, getState);

            // TODO: move this code block into updateUserViewModels
            _.forEach(payload, (model) => {
                if (model.type === 'query') {
                    updateLastSearchesWithModel(model)(dispatch, getState);
                }
                if (model.type === 'file') {
                    updateDownload(model)(dispatch, getState);
                }
            });
        });

        socket.on('api.model.created', (payload) => {
            if (payload.type === 'file') {
                updateDownload(payload)(dispatch, getState);
            }
        });
    })(dispatch, getState);
};


/**
 * Takes an event name and a payload and emits the event to event-concentrator
 *
 * @return void
 */
export const emitEvent = (payload) => (dispatch) => {
    dispatch({ type: types.WEB_SOCKETS_EMIT_EVENT, payload });
};


// Export default
export default { initSockets, emitEvent };
