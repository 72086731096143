/**
 * Main Insight layout
 *
 * @return Component
 */

// Import dependencies
import _                               from 'lodash';
import PropTypes                       from 'prop-types';
import React, { PureComponent }        from 'react';
import { HashRouter }                  from 'react-router-dom';
import { connect }                     from 'react-redux';
import { ConfigProvider }              from 'antd';
import CmpUi                           from 'cmp-ui';

import {
    initMember, logoutMember, getAuthorizationHeaders
}                                              from '../store/actions/auth';
import { initSockets }                         from '../store/actions/sockets';

// Helpers & Components
import Header                                  from './Ui/Header';
import routes                                  from './Ui/routes';
import ErrorBoundary                           from '../helpers/ErrorBoundary';
import Router                                  from '../helpers/Router';
import Loader                                  from '../helpers/loader';

// Import the error module
import ErrorModule                             from '../modules/Error';

// CSS
import './Ui/assets/main.less';
import './Ui/assets/reset.less';
import './Ui/assets/functions.less';
import Icon from '../helpers/Icon';


// DOC: https://ant.design/docs/react/customize-theme
const theme = {
    token: {
        colorPrimary  : '#10285d',
        colorPrimaryBg: '#e1e1e1',
        colorText     : '#10285d',
        borderRadius  : 4,
        fontFamily    : 'Helvetica',
        fontSize      : 12,
        marginXS      : 8,
        paddingXS     : 6
    },
    components: {
        Radio: {
            colorPrimary      : '#ffffff',
            colorPrimaryBorder: '#10285d',
            colorWhite        : '#10285d',
            colorBorder       : '#e1e1e1',
            dotSize           : 8,
            radioSize         : 16,
        },
        Checkbox: {
            borderRadiusSM: 2
        },
        DatePicker: {
            controlItemBgHover: '#e1e1e1',
        },
        Skeleton: {
            borderRadiusSM: 2
        },
        Dropdown: {
            colorText        : '#10285d',
            motionDurationMid: 0,
        },
        Select: {
            controlItemBgActive: '#fafafa'
        },
        Tree: {
            colorText      : '#10285d',
            lineHeight     : 2.25,
            controlHeightSM: 27,
            paddingXS      : 0,
        },
        Popover: {
            fontSize          : 13,
            colorText         : '#10285d',
            paddingXS         : 6,
            motionDurationSlow: 0.1
        },
        Modal: {
            borderRadiusLG: 10,
        }
    },
};


/**
* Main UI class for the Insight
*
* @param history The history from browser
*/
class Ui extends PureComponent {

    /**
     * Construct
     */
    constructor(props) {
        super(props);
        this.state = {
            auth: null
        };
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    async componentDidMount() {
        const { initMember } = this.props;

        // Once the UI is initialized, fetch the member.
        initMember();
    }

    /**
    * When component has been updated
    *
    * @return void
    */
    componentDidUpdate() {
        const {
            memberIsLoaded,
            memberIsGuest,
            initSockets,
        } = this.props;

        if (memberIsLoaded && !memberIsGuest  && !this.storeInitialized) {
            this.storeInitialized = true;

            initSockets();

            this.storeAuthorization();

            document.body.classList.add('app-initialized');
        }
    }

    /**
     * Store authorization in the state
     */
    storeAuthorization() {
        const { auth }  = this.state,
            authPromise = getAuthorizationHeaders();

        if (auth || !authPromise) {
            return;
        }

        authPromise.then(auth => {
            this.setState({ auth });
        });
    }


    /**
    * Catch errors
    *
    */
    componentDidCatch() {
        this.error = true;
    }

    /**
     * Render loading application
     */
    renderLoading() {
        return (
            <div className="init-application">
                <Icon id="logo-insight-without-background" width={50} />
                <Loader label="Initializing Insight" />
            </div>
        );
    }


    /**
     * Render the application
     */
    renderApp() {
        const
            { logout }       = this.props,
            disableSidebars  = _.isFunction(window.disableSidebars) && window.disableSidebars();

        return (
            <ErrorBoundary>
                <HashRouter basename="/">
                    <ConfigProvider theme={theme}>
                        {!disableSidebars && <Header logout={logout} />}
                        {/* Manage module display from URL */}
                        <Router
                            key="ui-router"
                            routes={routes}
                        />
                    </ConfigProvider>
                </HashRouter>
            </ErrorBoundary>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        // Extract history from properties
        const { auth } = this.state,
            {
                memberIsLoaded,
                memberIsGuest,
                memberId,
            }             = this.props,
            { hash }      = window.location;


        if (this.error) { return false; }

        // 0) Auth error page
        const error = hash.match(/^#\/ae\/([45][01]\d+)$/);
        if (error) {
            return <ErrorModule code={error[1]} />;
        }
        // 1) Member is not fetched.
        if (!memberIsLoaded || memberIsGuest || memberId === false || !auth) {
            return this.renderLoading();
        }

        // 2) Member is logged, load the APP.

        // * Prevent CMP-UI render for development environment
        if(process.env.NODE_ENV === 'development') {
            return this.renderApp();
        }

        const params = {
            render  : this.renderApp(),
            url     : '/cmp-api',
            language: 'en',
            headers : {Authorization: auth.Authorization}
        };

        // * Render APP with CMP-UI
        return <CmpUi {...params} />;
    }

}

/**
* Validate properties for current Component
*/
Ui.propTypes = {
    memberIsGuest : PropTypes.bool,
    memberIsLoaded: PropTypes.bool,
    memberId      : PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    initMember    : PropTypes.func.isRequired,
    logout        : PropTypes.func.isRequired,
    initSockets   : PropTypes.func.isRequired,
};

Ui.defaultProps = {
};

/**
 */
const mapStateToProps = (state) => {
    const auth = state.get('auth'),
        member = auth.get('member');

    return {
        // Member part
        memberIsLoaded: !!member,
        memberId      : member ? member.get('id')      : false,
        memberIsGuest : member ? member.get('isGuest') : false,
    };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    logout: logoutMember,
    initMember,
    initSockets,
})(Ui);

