import React, { Component }    from 'react';
import _                       from 'lodash';
import { Skeleton, Popover }   from 'antd';
import PropTypes               from 'prop-types';
import ImmutablePropTypes      from 'react-immutable-proptypes';
import {
    Element, Row, Col, Icon
}                              from 'helpers';
import {
    pluralize,
    makeStrClassName,
    formatNumberWithMagnitude,
}                              from 'utils/text';

/**
* Draw Id cards sections
*
*/
class Section extends Component {

    /**
    * Instantiate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'navigateToAnalyse', 'onDataLoaded', 'registerCallbacks', 'addToClipboard');

        this.state = {
            // Store there disabled elements
            disabledElements: [],
            loadedElements  : {},
            callbacks       : {},
        };

        this.LAYOUT = {
            row: Row,
            col: Col,
        };
    }

    /**
    * Component did update
    *
    * @return void
    */
    componentDidUpdate() {
        const { element, onDataLoaded }          = this.props,
            { loadedElements, disabledElements } = this.state,
            { configuration }                    = element,
            { elements }                         = configuration,
            nbElementsToLoad                     = elements?.length,
            allElementsHaveBeenDisabled          = nbElementsToLoad === disabledElements.length;

        // All elements are disabled
        if (onDataLoaded && nbElementsToLoad === _.keys(loadedElements).length) {
            onDataLoaded(element, loadedElements, allElementsHaveBeenDisabled);
        }
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb, element) {
        const { callbacks } = this.state,
            /** Return empty object */
            defaultCb       = () => {};

        // Store only element callbacks
        if (element === null) { return; }

        if (!callbacks[element.id]) {
            callbacks[element.id] = {};
        }

        callbacks[element.id][action] = cb || defaultCb;

        this.setState({ callbacks });
    }

    /**
    * Navigate to the Analyse element
    *
    * @return void
    */
    navigateToAnalyse(e, componentProps) {
        const { navigateTo } = this.props,
            { className } = componentProps || {};

        if (navigateTo && className) {
            navigateTo(className.replace('content-section ', ''));
        }
    }

    /**
    * When a section element has been loaded
    *
    * @param object element                     The layout which has been loaded
    * @param object data                        Data loaded by the layout
    * @param bool   allElementsHaveBeenDisabled Specify if all elements contained by layout has been disabled
    *
    * @return void  @type {[type]}
    */
    onDataLoaded(element, data, allElementsHaveBeenDisabled) {
        const { registerCallbacks }              = this.props,
            { disabledElements, loadedElements } = this.state;

        loadedElements[element.id] = data;
        this.setState({ loadedElements });

        if (allElementsHaveBeenDisabled) {
            disabledElements.push(element.id);
            this.setState({ disabledElements });
        }

        registerCallbacks('addToClipboard', this.addToClipboard, element);
    }


    /**
     * Get configuration header (for sub analyses of section)
     *
     * @returns array ot Null
     */
    getHeader() {
        const { element }     = this.props,
            { configuration } = element,
            { header }        = configuration;

        return header || {};
    }

    /**
     * Get pagination from data
     *
     * @returns object
     */
    getPagination() {
        const { data }     = this.props,
            { pagination } = data || {};

        return pagination || {};
    }

    /**
    * AddToClipboard (specific for section)
    *
    * @params options
    *
    * @return JSX
    */
    addToClipboard(options) {
        // Get only enabled subSections
        const {
                element, addAnalyseListToClipboard
            }                     = this.props,
            { disabledElements }  = this.state,
            { type }              = options,
            { configuration }     = element,
            { analyses }          = this.getHeader(),
            subSectionElementsIds = configuration.elements,
            elementsIdsToAdd      = _.xor(subSectionElementsIds, disabledElements),
            { totalbytype }       = this.getPagination(),
            subAnalysesKeys       = [];

        // Add elements images to clipboard
        if (type === 'element') {
            this.addElementsToClipboard(options, elementsIdsToAdd);
        }

        // Add lists to clipboard
        if (type === 'list') {
            // Add lists of subsection analyse (from configuration.header)
            analyses?.forEach(analyse => {
                const { type } = analyse;
                if (totalbytype[type]) {
                    subAnalysesKeys.push(analyse.key);
                }
            });

            // Add list of the section
            addAnalyseListToClipboard(options, elementsIdsToAdd, subAnalysesKeys);
        }
    }

    /**
    * AddToClipboard for elements
    *
    * @params options
    *
    * @return JSX
    */
    addElementsToClipboard(options, elementsIdsToAdd) {
        const { callbacks }         = this.state,
            flattenCallbacks        = elementsIdsToAdd.map((id) => callbacks[id] || null).filter((cbs) => cbs !== null),
            addToClipboardCallbacks = flattenCallbacks.map(
                elementCallbacks => elementCallbacks.addToClipboard || null
            ).filter((cb) => cb !== null);

        // For element (image export) use standard export of sub section Element
        addToClipboardCallbacks.forEach((addToClipboard) => addToClipboard(options));
    }


    /**
     * Render Element Componant
     *
     * @param {object} options
     */
    renderElement(options) {
        const { model, element, context } = this.props,
            { contentElement, content }   = options;

        return (
            <Element
                model={model}
                context={context}
                forceEmptyRemoval
                className="graph-entity"
                key={contentElement.id}
                element={contentElement}
                parentLabel={element.label}
                globalOptions={content.expandable}
                onDataLoaded={this.onDataLoaded}
                registerCallbacks={this.registerCallbacks}
            />
        );
    }

    /**
    * Render a section
    *
    * @return JSX
    */
    renderSectionContent() {
        const { disabledElements } = this.state,
            {
                element, elements
            }                      = this.props,
            { configuration }      = element,
            {
                elements: filteredElements, style, information,
                layout
            }                      = configuration,
            Layout                 = this.LAYOUT[layout || 'col'];

        if (!filteredElements?.length) { return false; }

        const renderedContent = _.map(filteredElements, (content) => {
            const contentElement = elements.find(el => el.id === content),
                withLabel        = contentElement.label && filteredElements.length > 1;

            if (disabledElements.indexOf(contentElement.id) !== -1) {
                return;
            }

            return (
                <Row
                    className={`content-section ${contentElement.key}`}
                    style={{ color: style?.color }}
                    onClickCb={this.navigateToAnalyse}
                    dataQaKey={contentElement.key}
                    key={`${contentElement.key}-${contentElement.id}`}
                >
                    <Col className={withLabel ? 'with-label' : ''}>
                        {withLabel && (
                            <Row>
                                <h4>
                                    {_.toUpper(contentElement.label)}
                                </h4>
                            </Row>
                        )}
                        {this.renderElement({ contentElement, content })}
                    </Col>
                </Row>
            );
        });

        return (
            <Layout information={information}>
                {this.renderSectionHeader()}
                {renderedContent}
            </Layout>
        );
    }


    /**
     * Render section header
     */
    renderSectionHeader() {
        const { element, navigateTo }     = this.props,
            { configuration }             = element,
            { total, totalbytype }        = this.getPagination(),
            { header, useContext, style } = configuration || {},
            { color }                     = style || {},
            { analyse, analyses }         = this.getHeader();

        return header && (
            <Col
                className={`section-header${useContext ? ' in-context' : ''}`}
            >
                <div className="big-total">
                    <span className="total" onClick={total && (() => navigateTo(analyse))}
                        style={{ color }} title={total}
                    >
                        {
                            total
                                ? formatNumberWithMagnitude(total)
                                : (<Skeleton active paragraph={false} />)
                        }
                    </span>
                </div>
                {analyses && _.map(analyses, definition => {
                    const analyseTotal    = totalbytype && totalbytype[definition.type],
                        /** Navigate to analyse of the type */
                        analyseNavigation = () => navigateTo(definition.key),
                        nb                = formatNumberWithMagnitude(analyseTotal, 1),
                        label             = pluralize(definition.entityLabel, analyseTotal),
                        title             = `${analyseTotal} ${label}`;

                    return (_.isUndefined(nb) || analyseTotal > 0) && (
                        <span key={definition.key} className="analyse-link-block">
                            <span className="analyse-link" onClick={nb && analyseNavigation}
                                title={title}
                            >
                                <span className="total">
                                    {nb || (<Skeleton active paragraph={false} />)}
                                </span>
                                &nbsp;
                                <span
                                    className="label"
                                >
                                    {label || (<Skeleton active paragraph={false} />)}
                                </span>
                            </span>
                        </span>
                    );
                })}
            </Col>
        );
    }

    /**
    * Render help icon with tooltip
    *
    * @param {string} helpText
    * @returns
    */
    renderHelpIcon() {
        const { element }      = this.props,
            { configuration }  = element || {},
            { title, content } = configuration?.help || {};

        return (
            (title || content) && (
                <Popover
                    overlayClassName="section-help-icon"
                    placement="top"
                    title={title}
                    content={content}
                    getPopupContainer={trigger => trigger.parentNode.parentNode}
                >
                    <span className="info-icon">
                        <Icon id="info-solid" height={14}
                            width={14}
                            color="var(--secondary-color)"
                        />
                    </span>
                </Popover>
            )
        );
    }

    /**
    * Render the area
    *
    * @return Component
    */
    render() {
        const { element, forwardedRef }     = this.props,
            { configuration, label, key }   = element,
            { useContext, style, position } = configuration || {},
            {
                headingBackgroundColor,
                headerLink
            }                               = style || {},
            anchorStyle                     = useContext && headingBackgroundColor
                ? { backgroundColor: headingBackgroundColor }
                : {},
            classNames = makeStrClassName([
                key,
                'idcard-section',
                {'with-context': useContext},
                !_.isEmpty(position) ? `idcard-section-${position}` : ''
            ]);

        return (
            <div
                ref={forwardedRef}
                className={classNames}
                dataid={key}
                data-qa-key={key}
            >
                <div className="heading">
                    <h3>
                        <span className="anchor" style={anchorStyle} />
                        <span className="text">{label}</span>
                        {this.renderHelpIcon()}
                    </h3>
                    {headerLink && (
                        <a className="header-link" onClick={(e) => this.navigateToAnalyse(e, {className: key})}>
                            {headerLink}
                        </a>
                    )}
                </div>
                <Row className="graphs-display rel">
                    {this.renderSectionContent()}
                </Row>
            </div>
        );
    }

}

Section.propTypes ={
    forwardedRef             : PropTypes.any,
    addAnalyseListToClipboard: PropTypes.func,
    elements                 : ImmutablePropTypes.list,
    model                    : PropTypes.shape({}),
    context                  : PropTypes.shape({}),
    navigateTo               : PropTypes.func,
    onDataLoaded             : PropTypes.func,
    registerCallbacks        : PropTypes.func,
    data                     : PropTypes.shape({}),
    element                  : PropTypes.shape({
        resource     : PropTypes.shape(),
        configuration: PropTypes.shape({
            information: PropTypes.shape({
                title  : PropTypes.string,
                content: PropTypes.string,
            }),
            position  : PropTypes.string,
            crunchbase: PropTypes.any,
            elements  : PropTypes.array,
            ratio     : PropTypes.any,
            header    : PropTypes.any,
            useContext: PropTypes.bool,
            layout    : PropTypes.string,
            style     : PropTypes.shape({
                color                 : PropTypes.string,
                headingBackgroundColor: PropTypes.string,
                icon                  : PropTypes.string
            })
        }),
        key  : PropTypes.string,
        label: PropTypes.string
    }).isRequired,
};

Section.defaultProps = {
    forceEmptyRemoval: false,
    model            : false,
};

export default Section;

