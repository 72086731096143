/* eslint-disable */
import React, { Component } from 'react';
import { connect }          from 'react-redux';
import PropTypes            from 'prop-types';
import ImmutablePropTypes   from 'react-immutable-proptypes';
import _                    from 'lodash';
import { Modal, Skeleton }  from 'antd';
import {
    hashString
}                           from 'utils/text';
import { learn }            from 'store/actions/knowledge';
import { emitEvent }        from 'store/actions/sockets';
import {
    Graph,
    Collection,
    Action,
    Icon,
    NoData,
    Row, Col
}                           from 'helpers';

import './assets/metrics.less';

// Store Knowledges in a pool for further usage
let KNOWLEDGE_POOL = {};

/**
 * General dashboard item component
  spacing: 0, padding: 0*
 */
class Metrics extends Component {

    /**
    * Initialize the component
    *
    * @return void
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'renderCollection',  'onClickResetFilter', 'onClickExpandedModal', 'getFilterKey',
            'shouldRenderAction', 'renderFiltersButton', 'registerCallbacks', 'triggerAction',
            'onCloseExpandedModal', 'onDataFetched', 'excludeMetricsFromRender', 'updateFilters', 'resetFilters');

        this.state = {
            ...KNOWLEDGE_POOL,
            // Data
            dataByConfig       : {},
            excludedDefinitions: [],
            actualSearchId     : false,
            dataIsLoading      : false,
            dataIsLoaded       : false,
            modalVisible       : false,
            resettingFilter    : false,
            currentFilters     : {},
            graphCallbacks     : {}
        };

        // The empty filter array
        this.emptyFilters = [];
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const { metrics, tags, registerCallback } = this.props,
            { learnKnowledge }                    = this.props;

        if (!metrics || !tags) {
            learnKnowledge(['tags', 'metrics', 'filters']).then(
                (knowledge) => {
                    KNOWLEDGE_POOL = knowledge;
                    this.setState({
                        ...KNOWLEDGE_POOL
                    });
                }
            );
        }

        registerCallback(this.triggerAction);
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidUpdate() {
        const { resettingFilter } = this.state;

        if (resettingFilter) {
            this.setState({ resettingFilter: false });
        }
    }

    /**
    *
    * Get derivated state from props
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { actualSearchId, metricsData } = prevState,
            { search }                        = nextProps,
            searchId                          = search ? search.get('id') : null,
            searchIdHasBeenChanged            = actualSearchId && searchId && (actualSearchId === searchId);

        return {
            ...prevState,
            actualSearchId: searchId || false,
            metricsData   : searchIdHasBeenChanged ? metricsData : false,
        };
    }

    /**
    * Stop click propagation
    *
    * return false
    */
    onClickExpandedModal(e) {
        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
    * On close modal
    *
    * return false
    */
    onCloseExpandedModal(e) {
        this.setState({ expandedMetricConfig: false });

        e.preventDefault();
        e.stopPropagation();

        return false;
    }

    /**
    * When the fetch has fetched some data
    *
    * @param object definition The configuration object which provided resource
    * @param object data       Some fetched data
    */
    onDataFetched(definition, data, allRequestsAreEmpty) {
        const { dataByConfig }           = this.state,
            { metricsKey, onDataLoaded } = this.props,
            key                          = JSON.stringify(definition);

        dataByConfig[key] = data;

        this.setState({
            dataByConfig
        });

        if (!this.onDataLoadedOnce && onDataLoaded) {
            this.dataLoadedOnce = true;
            onDataLoaded(metricsKey, data, allRequestsAreEmpty, definition);
        }
    }

    /**
    * Return the knowledge definition for the current metric
    *
    * @return bool|object
    */
    getDefinition(root = false) {
        const { metrics } = this.state;

        // Prevent non loaded Knowledges
        if (!metrics) {
            return {};
        }

        const  { metricsFrom, forcedDefinition, expanded } = this.props,
            metric           = this.getMetric(),
            globalDefinition = metric
                // Get full expanded definition (no merge)
                ? expanded && metric[`${metricsFrom}-expanded`] ? metric[`${metricsFrom}-expanded`]  : metric[metricsFrom]
                : null,
            metricDefinition = globalDefinition ? (
                metricsFrom !== 'analyse' ? globalDefinition : globalDefinition.graph[0]
            ) : false,
            // No definition returns an empty object
            returnedDefinition = forcedDefinition || this.getDefinitionFromArray(metricDefinition, root);

        // Merge expand definition
        return expanded ? this.makeExpandMerge(_.cloneDeep(returnedDefinition)) : returnedDefinition;
    }

    /**
    * Extract definition from an array of definition
    *
    */
    getDefinitionFromArray(metricDefinition, root = false) {
        if (root) {
            return metricDefinition;
        }

        const metricDefinitionContent = metricDefinition.content || metricDefinition,
            isArrayOfDefinitions      = _.isArray(metricDefinitionContent);

        return isArrayOfDefinitions ? this.getDefinitionFromArray(metricDefinitionContent[0])
            : metricDefinitionContent;
    }

    /**
    * Return the Total Metric without metricsFrom
    *
    * @return object
    */
    getMetric() {
        const { metrics }  = this.state,
            { metricsKey } = this.props;

        return metrics ? metrics.find((obj) => obj.id === metricsKey) : null;
    }

    /**
    * Get label content
    *
    * return Graph Component
    */
    getLabelContent(label, labelHeight, style) {
        return (
            <div
                className="label"
                style={{
                    textAlign : 'center',
                    fontSize  : labelHeight ? `${labelHeight / 1.5}px` : null,
                    lineHeight: labelHeight ? `${labelHeight}px` : null,
                    ...style
                }}
            >
                {label}
            </div>
        );
    }

    /**
    * Return the bookmark from the entity
    *
    * @return object
    */
    getTaggedEntitiesIds() {
        const { bookmarks }   = this.props,
            { tags }          = this.state;

        this.taggedEntitiesIds = this.taggedEntitiesIds || {};

        if (bookmarks) {
            _.each(tags.toJS(), (tag) => {
                const bookmarksInTag = bookmarks.filter((bookmark) => bookmark.tags.includes(tag.id));
                this.taggedEntitiesIds[tag.id] = {
                    ids: _.values(bookmarksInTag.map((bookmark) => bookmark.entity.id).toJS()),
                    tag
                };
            });
        }

        return this.taggedEntitiesIds;
    }

    /**
    * Return metric series & stats from config
    *
    * @return JSX
    */
    getDataFromDefinition(definition) {
        const { dataByConfig } = this.state,
            key                = JSON.stringify(definition),
            isLoading          = this.metricIsLoadingFromDefinitions(definition);

        return isLoading ? false : (dataByConfig[key] || null);
    }

    /**
    * Trigger graph callbacks
    *
    * @return void
    */
    triggerAction(action) {
        const { graphCallbacks } = this.state;

        if (_.isUndefined(graphCallbacks[action]) || !graphCallbacks[action].length) {
            return;
        }

        graphCallbacks[action].forEach((cb) => cb());

        // Reset current filters
        if (action === 'reset') {
            this.resetFilters();
        }
    }

    /**
    * Register callbacks for each graphs
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { graphCallbacks } = this.state;

        graphCallbacks[action] = graphCallbacks[action] || [];
        graphCallbacks[action].push(cb);

        this.setState({
            graphCallbacks
        });
    }

    /**
    * Render Graph
    *
    * return Graph Component
    */
    renderMetric(definition, graphKey, ref, size = {}) { //eslint-disable-line
        const {
                type,
                settings,
                filters,
                labelHeight,
            }                                              = _.cloneDeep(definition), // Protect definition (interactive in setting)
            { expanded, globalOptions, forceEmptyRemoval } = this.props,
            { entity, metricsFrom, onUpdateFilters }       = this.props,
            { parameters, fixedBySelection }               = this.props,
            { excludedDefinitions, tags, currentFilters }  = this.state,
            { resettingFilter }                            = this.state,
            filterKey                                      = this.getFilterKey(definition),
            labelHeightApplied                             = labelHeight || (expanded ? 40 : 20),
            data                                           = this.getDataFromDefinition(definition),
            dataIsLoaded                                   = data !== false,
            noData                                         = !data || (!data.content && !data.stats),
            actions                                        = this.getActions(definition, ref),
            isCollection                                   = type === 'Grid' || type === 'List' || type === 'Inline',
            options                                        = {
                definition,
                size,
                data,
                ref,
            };

        if (!isCollection && _.isNull(data) && forceEmptyRemoval) {
            const key = JSON.stringify(definition);
            excludedDefinitions.push(key);
            this.setState({
                excludedDefinitions
            });
            return false;
        }

        if (metricsFrom === 'dashboard' && _.isUndefined(settings.interactive) && !expanded) {
            settings.interactive = false;
        }

        const content = isCollection
            ? this.renderCollection(definition, size, data, ref)
            : this.decorateWithLabel(dataIsLoaded && noData
                ? (
                    <NoData
                        color={settings.color}
                        text={_.get(definition, 'noData.text')}
                        icon={_.get(definition, 'noData.icon')}
                        iconSize={_.get(definition, 'noData.iconSize')}
                    />
                )
                : [
                    <Graph
                        key={graphKey}
                        type={type}
                        data={data}
                        entitySource={entity}
                        filterKey={filterKey}
                        filterValues={currentFilters[filterKey] || this.emptyFilters}
                        resettingFilter={resettingFilter}
                        fixedBySelection={fixedBySelection}
                        filterCb={filters && !expanded ? this.updateFilters : null}
                        {...settings}
                        registerCallbacks={this.registerCallbacks}
                        featuredIds={this.getTaggedEntitiesIds()}
                        width={size ? size.width : null}
                        height={size ? size.height - (definition.label ? labelHeightApplied : 0) : null}
                        dataIsLoaded={data !== false}
                    />,
                    globalOptions && !this.containsCollection(this.getDefinition(true))
                        ? null
                        : this.renderActions(actions, definition)
                ],
            options);

        return content;
    }

    /**
    * Get filtersValues
    *
    * return Graph Component
    */
    getFilterKey(definition) {
        const { parameters } = this.props,
            { filters }      = definition,
            filter           = filters ? filters[0] : null; // Todo map multi filters

        if (!filter) {
            return null;
        }

        return filter.key;
    }

    /**
    * Make a array if it's not a array
    *
    * return Graph Component
    */
    prepareFilterValues(value, filter = {}) {
        const { template, key } = filter,
            values              = _.isArray(value) ? value : [value];

        return values;
    }

    /**
    * Interpolate vars with filter template
    *
    * @return string
    */
    interpolateValuesAndTemplate(values, template) {
        let interpolatedValue = template;

        _.keys(values).forEach((key) => {
            interpolatedValue = interpolatedValue.replace(`%${key}%`, values[key]);
        });

        return interpolatedValue;
    }

    /**
    * Update filters from graph
    *
    * return Graph Component
    */
    updateFilters(filterKey, values) {
        const { onUpdateFilters }       = this.props,
            { filters, currentFilters } = this.state,
            filter                      = filters.find((filter) => filter.id === filterKey),
            filterValue                 = _.isArray(values) ? values : (
                _.isObject(values) ? this.interpolateValuesAndTemplate(values, filter.template) : []
            );

        // Update the local filter
        currentFilters[filterKey] = filterValue;

        this.setState({
            currentFilters
        });

        // Update parent filters
        onUpdateFilters(currentFilters);
    }

    /**
    * Reset filters from graph
    *
    * return Graph Component
    */
    resetFilters() {
        const { onUpdateFilters, parameters }  = this.props,
            { currentFilters, graphCallbacks } = this.state,
            newFilters                         = _.omit(parameters, _.keys(currentFilters));

        this.setState({
            currentFilters : {},
            resettingFilter: true,
        });

        onUpdateFilters({
            ...newFilters
        });
    }

    /**
    * On click on reset filters
    *
    * @return void
    */
    onClickResetFilter() {
        const { graphCallbacks } = this.state;

        this.triggerAction('reset');
    }

    /**
    * Open expanded metrics in a modal
    *
    * return Graph Component
    */
    openExpand(definition) {
        this.setState({
            expandedMetricConfig: definition
        });
    }

    /**
    * Definition & data allow to render actions
    *
    * @param array|object definitions
    *
    * @return boolean
    */
    shouldRenderAction(definitions) {
        const definitionsContent = definitions.content || definitions;

        // Some definitions metrics are loading
        if (this.metricIsLoadingFromDefinitions(definitions)) {
            return false;
        }

        // Single definitions
        if (!_.isArray(definitions)) {
            const data = this.getDataFromDefinition(definitionsContent);

            return !_.isNull(data) && (
                _.isUndefined(definitionsContent.serie)
                        || (definitionsContent.serie && _.isArray(data.content) && data.content.length > 0)
            );
        }

        // Multi definitions, at least one not empty.
        const notEmptyData = definitions.map(this.shouldRenderAction).filter(
            (shouldRenderAction) => shouldRenderAction
        );

        return notEmptyData.length > 0;
    }

    /**
    * Add the actions items list
    *
    * @return jsx
    */
    renderActions(actions, definition) {
        const { expanded, globalOptions, emitEvent } = this.props,
              isGlobalOptionExpanded                 = expanded && globalOptions,
              style                                  = isGlobalOptionExpanded
                ? { position: 'absolute', top: '100px', right: '40px' }
                : null;

        return actions && actions.length > 0 ? <Action emitEvent={emitEvent} actions={actions} /> : null;
    }

    /**
    * Get actions of the metric
    *
    * @return html
    */
    getActions(definition, ref) {
        const { expanded }     = this.props,
            containsCollection = this.containsCollection(definition),
            fillActions        = true || this.shouldRenderAction(definition),
            exportToPng        = {
                label: 'Export to PNG',
                icon : <Icon type="download" />,
                cb   : () => { this.exportToPng(ref, definition.label); }
            },
            expand = {
                label: 'Expand',
                icon : <Icon type="fullscreen" />,
                cb   : () => { this.openExpand(definition); },
            },
            actions = fillActions ? (
                expanded
                    // Disable eslint for GLOBAL isIE
                    // eslint-disable-next-line no-undef
                    ? (containsCollection || isIE ? false : [exportToPng])
                    // eslint-disable-next-line no-undef
                    : (containsCollection || isIE ? [expand] : [exportToPng, expand])
            ) : [];

        return actions;
    }

    /**
    * Add a label to the current graph
    *
    * return JSX
    */
    decorateWithLabel(content, options) {
        const { definition, graphKey, size }    = options,
            { data, ref }                       = options,
            { label,  labelOnBottom, settings } = definition,
            { labelHeight, type }               = definition,
            { expanded, width }                 = this.props,
            isLoading                           = this.metricIsLoadingFromDefinitions(definition),
            labelHeightApplied                  = labelHeight || (expanded ? 40 : 20),
            { color }                           = settings,
            contentWithLabel                    = [],
            labelWithData                       = isLoading && label && label.indexOf('%total') >= 0
                ? (<Skeleton loading active paragraph={false} />)
                : (
                    label ? label.replace('%total', data ? data.total : 0) : null
                ),
            labelContent                        = (
                <Row height={labelHeightApplied} className="label" key={JSON.stringify(definition)}>
                    {data ? this.getLabelContent(labelWithData, labelHeightApplied, { color }) : null}
                </Row>
            ),
            labelAddFunction                    = labelOnBottom ? 'push' : 'unshift',
            className = `${definition.resource} ${definition.type} ${definition.serie || ''}`;

        // Push the content inside fragment
        contentWithLabel.push((
            <Row
                key="key"
                className={className}
            >
                {content}
            </Row>
        ));

        // Then, add the label at its perfect place.
        if (label) {
            contentWithLabel[labelAddFunction](labelContent);
        }

        return (
            <Row
                key="key"
                className="sub-metric"
                height={size.height}
                width={size.width}
            >
                {contentWithLabel}
            </Row>
        );
    }

    /**
    * Returns a boolean that indicate
    *
    * return boolean
    */
    metricIsLoadingFromDefinition(definition) {
        const { dataByConfig }   = this.state,
            key                  = JSON.stringify(definition);

        return _.isUndefined(dataByConfig[key]);
    }

    /**
    * Returns a boolean that indicate
    *
    * return boolean
    */
    metricIsLoadingFromDefinitions(definitions) {
        const { dataByConfig }   = this.state,
            definitionsContent   = definitions.content || definitions,
            isArrayOfDefinitions = _.isArray(definitionsContent);

        if (!isArrayOfDefinitions) {
            return this.metricIsLoadingFromDefinition(definitionsContent);
        }

        const loadingDefinitions = definitionsContent.map((definition) => {
            const definitionContent = definition.content || definition;
            return (_.isArray(definitionContent)
                ? this.metricIsLoadingFromDefinitions(definitionContent)
                : this.metricIsLoadingFromDefinition(definitionContent));
        }).filter(
            (metricIsLoading) => metricIsLoading
        );

        return loadingDefinitions.length > 0;
    }

    /**
    * Merge expand settings in definitions (Iterated function)
    *
    * return Graph Component
    */
    makeExpandMerge(definitions) {
        let newDefinitions = definitions;

        if (_.isArray(definitions)) {
            return _.map(definitions, (definition) => this.makeExpandMerge(definition));
        }

        if (definitions.expand) {
            newDefinitions = _.merge(definitions, definitions.expand);
            newDefinitions.expandDisabled = true;
        }

        return newDefinitions;
    }

    /**
    * Test if definition contain a collection (Iterated)
    *
    * @return html
    */
    containsCollection(definition) {
        const { expanded } = this.props;

        // Definition is array
        if (_.isArray(definition)) {
            const definitionBranchs = _.map(definition, (subDefinition) => this.containsCollection(subDefinition));
            return _.indexOf(definitionBranchs, true) !== -1;
        }

        // Definition in content
        if (definition.content) {
            return this.containsCollection(definition.content);
        }

        // Test is a collection
        return (
            definition.type === 'Inline'
            || definition.type === 'Grid'
            || definition.type === 'List'
        );
    }

    /**
    * Render collection
    *
    * @return html
    */
    renderCollection(definition, size, data, ref) {
        const {
                type,
                settings,
                pagination,
                entityType,
                exportUnitLabel,
            }                                          = definition,
            { entity, forceEmptyRemoval }              = this.props,
            { renderSuffix }                           = settings,
            newSettings                                = _.clone(settings),
            suffixTemplateKey                          = renderSuffix,
            { excludedDefinitions }                    = this.state,
            { limit }                                  = pagination || { limit: { max: 25 } },
            { max:maximum }                            = limit,
            { stats, content: series }                 = data || {},
            dataIsLoaded                               = data !== false,
            noData                                     = !data || (!data.content && !data.stats),
            isLoading                                  = this.metricIsLoadingFromDefinitions(definition),
            collectionData                             = isLoading
                ? _.times(_.get(limit, 'max', 10), () => ({                       // Loading case
                    type     : _.get(settings, 'type', false),
                    isLoading: true
                }))
                : (_.isArray(series) ? series.slice(0, maximum) : _.get(data, 'content', []));

        if (_.isArray(collectionData) && !collectionData.length && forceEmptyRemoval) {
            const key = JSON.stringify(definition);
            if (!excludedDefinitions.includes(key)) {
                excludedDefinitions.push(key);
                this.setState({
                    excludedDefinitions
                });
            }
            return false;
        }

        if (renderSuffix) {
            newSettings.renderSuffix = () => this.renderCollectionSuffix(stats[suffixTemplateKey], stats);
        }

        return this.decorateWithLabel(collectionData
            ? (dataIsLoaded && noData
                ? (
                    <NoData
                        color={settings.color}
                        text={_.get(definition, 'noData.text')}
                        icon={_.get(definition, 'noData.icon')}
                    />
                )
                : (
                    <Collection
                        mode={type.toLowerCase()}
                        entities={collectionData}
                        {...size}
                        {...newSettings}
                        entitySource={entity}
                        exportUnitLabel={exportUnitLabel}
                        actions={this.getActions(definition, ref)}
                    />
                )
            )
            : false,
        { definition, size, data });
    }

    /**
    * Render Collection suffix from template
    *
    * @param definition metrics definition
    *
    * @return boolean
    */
    renderCollectionSuffix(template, stats) {
        let returnString = template;

        _.each(stats, (val, key) => {
            const reg = new RegExp(`%${key}%`, 'g');
            returnString = returnString.replace(
                reg,
                `<b>${val}</b>`,
            );
        });

        return (
            <span
                className="suffix"
                dangerouslySetInnerHTML={{ __html: returnString }}
            />
        );
    }

    /**
    * Returns a boolean indicates if the metrics will be displayed or not
    *
    * @param definition metrics definition
    *
    * @return boolean
    */
    excludeMetricsFromRender(definitions) {
        const { excludedDefinitions } = this.state;

        if (!_.isArray(definitions)) {
            const definition = definitions.content || definitions; // Manage the "content"
            return excludedDefinitions.indexOf(JSON.stringify(definition)) !== -1;
        }

        const nonExcludedRenders = definitions.map(this.excludeMetricsFromRender).filter((exclude) => !exclude);

        return nonExcludedRenders.length === 0;
    }

    /**
    * Render subGraphs
    *    Iterated function
    *
    * @return html
    */
    renderSubGraphs(options) {
        const {
                graphKey,
                definition,
                renderRow,
                spacing
            }                      = options,
            { forceEmptyRemoval  } = this.props,
            { excludedDefinitions } = this.state,
            Layout                 = renderRow  ? Row : Col,
            subdivision            = _.isArray(definition) ? definition.length : 1,
            nbMetricsInculded      = _.map(
                definition,
                (subdefinition, index) => this.excludeMetricsFromRender(subdefinition.content || subdefinition)
            ).reduce(
                (accumulator, currentValue) => accumulator + (currentValue === false ? 1 : 0),
                0
            );

        return _.map(definition, (subdefinition, index) => {
            const subgraphContent = subdefinition.content || subdefinition,
                hasSubgraph       = _.isArray(subgraphContent),
                ref               = React.createRef(),
                key               = JSON.stringify(subgraphContent),
                actions           = this.getActions(subgraphContent, ref),
                hasActions        = _.keys(actions) > 0,
                isExcluded        = excludedDefinitions.indexOf(key) !== -1;

            if (forceEmptyRemoval && this.excludeMetricsFromRender(subgraphContent)) {
                return false;
            }

            return (
                <Layout
                    className="metric"
                    key={`${definition.id} ${index}`}
                    spacing={spacing || 0}
                    ratio={subdefinition.ratio || `1/${forceEmptyRemoval ? nbMetricsInculded : definition.length}`}
                >
                    { hasSubgraph
                        ? this.renderSubGraphs({
                            graphKey  : `${graphKey}-${index}`,
                            definition: subgraphContent,
                            renderRow : !renderRow,
                            spacing,
                        })
                        // Render one graph
                        : this.renderMetric(subgraphContent, graphKey, ref)}
                </Layout>
            );
        });
    }

    /**
    * Render Modal of the expandedMetric
    *
    * @return html
    */
    renderExpandedMetric() {
        const { expandedMetricConfig } = this.state,
            { globalOptions }          = this.props,
            modalMarginV               = document.documentElement.clientHeight * 0.05,
            w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0) - modalMarginV,
            h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0) - modalMarginV,
            metric      = this.getMetric(),
            labelHeight = metric.label ? 60 : 0,
            content     = [],
            safeProps   = _.omit(this.props, ['onDataLoaded']);

        if (!expandedMetricConfig) {
            return null;
        }

        return (
            <Modal
                title={null}
                footer={null}
                open
                width={w}
                height={h}
                onCancel={this.onCloseExpandedModal}
                wrapClassName="expanded-metric"
            >
                <div onClick={this.onClickExpandedModal}>
                    {this.getLabelContent(metric.label, labelHeight, {
                        color     : '#333',
                        fontWeight: 'bold'
                    })}
                    <Metrics
                        {...safeProps}
                        y={labelHeight / 2}
                        forcedDefinition={globalOptions ? false : expandedMetricConfig}
                        width={w - modalMarginV}
                        height={h - modalMarginV - (labelHeight * 1.5)}
                        spacing={20}
                        expanded
                    />
                    <div style={{ width: w, height: labelHeight / 2 }} />
                </div>
            </Modal>
        );
    }

    /**
    * Implement the data fetcher for each Metrics
    *
    * @return JSX
    */
    decorateWithFetcher(content) {
        const { search, entity, parameters } = this.props,
            definition                       = this.getDefinition(true),
            searchId                         = search ? search.get('id') : '',
            entityId                         = entity ? entity.id : '',
            definitionHash                   = hashString(JSON.stringify(definition)),
            key                              = `${searchId}${entityId}${definitionHash}`;

        return false;
    }

    /**
    * Render the filters button
    *
    * @return html
    */
    renderFiltersButton() {
        const { currentFilters } = this.state;

        return currentFilters && _.keys(currentFilters).length > 0 ? (
            <div className="reset-filters" onClick={this.onClickResetFilter}>
                <Icon
                    id="goBack"
                    height={14}
                    width={14}
                    style={{ color: 'red' }}
                />
                <span>Reset filters</span>
            </div>
        ) : null;
    }

    /**
    * Render the graph
    *
    * @return html
    */
    renderMultipleGraph() {
        const {
                spacing,
                globalOptions,
            }             = this.props,
            definition    = this.getDefinition(true),
            content       = [],
            ref           = React.createRef();

        content.push((
            <Row
                {...this.props}
                spacing={spacing}
                className="rel"
            >
                {this.renderSubGraphs({
                    spacing,
                    definition,
                    graphKey : 'sub',
                    renderRow: false,
                })}
                {this.renderExpandedMetric()}
            </Row>
        ));

        return (
            <div
                className={`metrics ${globalOptions ? 'globalActions' : ''}`}
            >
                {[
                    content,
                    globalOptions ? this.renderActions(this.getActions(definition, ref), definition) : null,
                ]}
            </div>
        );
    }

    /**
    * Render simple graph
    *
    * @return html
    */
    renderSimpleGraph() {
        const {
                spacing,
                globalOptions,
                height,
                width,
                y
            }             = this.props,
            definition    = this.getDefinition(true),
            content       = [],
            ref           = React.createRef();

        content.push((
            <div
                className="metric"
                style={{
                    position: 'relative',
                    top     : y,
                    height  : `${height}px`
                }}
            >
                {this.renderMetric(definition, 'main', ref, {
                    width,
                    height,
                    y
                })}
                {this.renderExpandedMetric()}
            </div>
        ));

        return [
            content
        ];
    }

    /**
    * Render the graph
    *
    * @return html
    */
    render() {
        const definition      = this.getDefinition(true),
            ref               = React.createRef(),
            { offset }        = this.state, // Offset should reflect the margin
            { tags, metrics } = this.state, // Tags from knowledge for dependency test
            content           = [];

        // No definition, return empty
        if (!_.keys(definition).length || _.isUndefined(tags)) {
            return false;
        }

        // Render multiple graphs
        return [
            this.decorateWithFetcher(
                _.isArray(definition) ? this.renderMultipleGraph() : this.renderSimpleGraph()
            ),
            this.renderFiltersButton()
        ];
    }

}

// Validate properties for current Component
Metrics.propTypes = {
    metric           : PropTypes.shape({}),
    learnKnowledge   : PropTypes.func.isRequired,
    metricsFrom      : PropTypes.string.isRequired, // eslint-disable-line
    metricsKey       : PropTypes.string.isRequired, // eslint-disable-line
    height           : PropTypes.number.isRequired, // eslint-disable-line
    width            : PropTypes.number.isRequired, // eslint-disable-line
    bookmarks        : ImmutablePropTypes.map.isRequired,
    search           : PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]), // eslint-disable-line
    entity           : PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]), // eslint-disable-line
    navigateTo       : PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    parameters       : PropTypes.shape({}),
    expanded         : PropTypes.bool,
    globalOptions    : PropTypes.bool,
    forceEmptyRemoval: PropTypes.bool,
    fixedBySelection : PropTypes.bool,
    forcedDefinition : PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    onUpdateFilters  : PropTypes.func,
    registerCallback : PropTypes.func,
};

// Declare default properties
Metrics.defaultProps = {
    search           : false,
    entity           : false,
    metric           : {},
    navigateTo       : false,
    parameters       : {},
    forcedDefinition : false,
    expanded         : false,
    globalOptions    : false,
    forceEmptyRemoval: false,
    fixedBySelection : true,
    onUpdateFilters  : () => {},
    registerCallback : () => {}
};

/**
 * Bind the store to to component
 */
const mapStateToProps = (state) => {
    const bookmarks = state.get('bookmarks').map;

    return {
        bookmarks
    };
};
export default connect(mapStateToProps, {
    learnKnowledge: learn,
    emitEvent
})(Metrics);

