import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import _                    from 'lodash';

import { Icon, Action }     from 'helpers';

/**
* Render the header content of the card
*
* @return JSX
*/
class ExpertHeader extends Component {

    /**
    * Constructor, initialize the state
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'render');

        this.state = {
            callbacks: {}
        };
    }

    /**
    * Make sure the component has loaded the patent
    * If not, try to requeue the ajax call.
    *
    */
    componentDidUpdate() {
        const { entity, registerMetadata } = this.props;

        registerMetadata({ title: entity.label, actions: this.renderExpertActions() });
    }

    /**
    * Render expert action
    *
    *  @return JSX
    */
    renderExpertActions() {
        const { addToClipboard, entity } = this.props,
            { activeGroupSection }       = this.props,
            from    = { model: entity, componentName: 'Expert' },
            actions = {
                addToClipboard: {
                    icon: <Icon
                        type="clipboard-add"
                        height={18}
                        folder="/shortcuts/"
                        className="add-to-clipboard"
                    />,
                    cb   : addToClipboard,
                    label: `Add «${activeGroupSection}» to export clipboard`
                }
            };

        return (
            <div className="action-model">
                <Action
                    from={from}
                    {...this.props}
                    actions={actions}
                />
            </div>
        );
    }

    /**
    * Render expert header
    *
    */
    render() {
        const { entity  } = this.props;

        if (!entity) {
            return (
                <div className="heading">
                    <div className="heading-top" />
                    <div className="logo">
                        <Icon id="expert" width={70}
                            height={70} borderColor="#FFFFFF"
                            discShaped
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className="heading">
                <div className="heading-left" />
                <div className="logo">
                    <Icon
                        className="expert-logo"
                        id="expert"
                        folder="/entities/"
                        width={81}
                        height={81}
                        borderSize={15}
                        borderColor="#ffffff"
                        discShaped
                    />
                </div>
                <div className="content">
                    <h2>{entity.label}</h2>
                </div>
            </div>
        );
    }

}

ExpertHeader.propTypes = {
    addToClipboard  : PropTypes.func,
    registerMetadata: PropTypes.func,
    entity          : PropTypes.shape({
        label: PropTypes.string
    }),
};

ExpertHeader.defaultProps = {
    entity: null
};

export default ExpertHeader;
