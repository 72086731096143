import React, { Component } from 'react';
import { connect }          from 'react-redux';
import _                    from 'lodash';
import PropTypes            from 'prop-types';
import { learn }            from 'store/actions/knowledge';
import { Graph }            from 'helpers';
import { dataGet }          from 'utils/api';
import { htmlize }          from 'utils/text';
import ImmutablePropTypes   from 'react-immutable-proptypes';

import './assets/why.less';

const KPIel = [
    { id: 'experience', label: 'Experience', navigation: { type: 'anchor', key: 'section-why-experience' } },
    { id: 'momentum', label: 'Momentum', navigation: { type: 'anchor', key: 'section-why-momentum' } },
    { id: 'network', label: 'Network & Influence', navigation: { type: 'anchor', key: 'section-why-networkinfluence' } },
    { id: 'patent_activity', label: 'Patenting Activity', navigation: { type: 'anchor', key: 'section-why-patenting-activity' } },
    { id: 'scientific_activity', label: 'Scientific Activity', navigation: { type: 'anchor', key: 'section-why-scientific-activity' } }, // eslint-disable-line max-len
    { id: 'buzz', label: 'Buzz', orgunitsOnly: true, navigation: { type: 'anchor', key: 'section-why-buzz' } },
];

/**
* Draw Id cards reference
*
*/
class Why extends Component {

    /**
    * Instantiate the ROW
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'addToClipboard', 'renderKpiRow', 'navigateTo');

        this.state = {
            kpiIsLoading: false,
            dataIsLoaded: false,
            kpiData     : false
        };
    }

    /**
    * Learn the knowledge
    */
    componentDidMount() {
        this.getData();
    }

    /**
    * When component is updating
    */
    componentDidUpdate() {
    }

    /**
    * Triggered when the component is unmounted
    *
    * @return void
    */
    componentWillUnmount() {
        // Clear all setTimeout
        if (this.fetchPromise) {
            this.fetchPromise.cancel();
        }

        return false;
    }


    /**
     * Set this.fetchPromise with data get
     *
     * @returns void
     */
    setFetchPromise() {
        const {
                model, onNoKpiData, context,
                registerCallbacks, element
            }    = this.props,
            path = !model ? '' : `/${model.type}s/${model.id}/Kpi`;

        // Request API to obtain metric data
        this.fetchPromise = dataGet(`${path}`, { data: { idq: context.id } })
            .then(
                ({ status, body, isCancelled }) => {
                    // Prevent memory leaks
                    if (isCancelled) { return false; }

                    // Metrics not ready
                    if (status.code === 206) {
                        setTimeout(() => this.setState({
                            kpiIsLoading: false
                        }),
                        5000);
                        return false;
                    }

                    // Metrics no data
                    if (status.code !== 200) {
                        this.setState({
                            kpiIsLoading: false,
                            dataIsLoaded: true,
                        });

                        // Call back
                        onNoKpiData();

                        return false;
                    }

                    // Metric Ready
                    this.setState({
                        kpiIsLoading: false,
                        dataIsLoaded: true,
                        kpiData     : body,
                    });

                    registerCallbacks('addToClipboard', this.addToClipboard, element);

                    return false;
                }
            );
    }

    /**
    * Get data from data api
    *
    * @params options object { nextProps, state }
    *
    * @return object { nextProps, state}
    */
    getData() {
        const { context, model }                    = this.props,
            { kpiIsLoading, kpiData, dataIsLoaded } = this.state;

        if (
            _.isNull(model)
            || kpiIsLoading
            || kpiData !== false
            || dataIsLoaded
        ) { return; }

        // If we have no search, there is no data to load.
        if (!context) {
            this.setState({
                dataIsLoaded: true,
                kpiIsLoading: false,
                kpiData     : false
            });
            return;
        }

        // Set the loading state
        this.setState({
            kpiIsLoading: true,
            kpiData     : false,
        });

        this.setFetchPromise();
    }

    /**
    * GetHasNoKpi
    *
    * return @boolean
    */
    hasNoKpi() {
        const { kpiData, dataIsLoaded } = this.state,
            { stats }                   = kpiData || {},
            noValue                     = _.values(stats).reduce((cumul, kpi) => cumul && kpi.value === null, true);

        return dataIsLoaded && noValue;
    }

    /**
    * AddToClipboard (specific for section)
    *
    * @params options
    *
    * @return JSX
    */
    addToClipboard(options) {
        // Get only enabled subSections
        const {
                elements, addToClipboard, addAnalyseListToClipboard,
                model
            }               = this.props,
            { kpiData }     = this.state,
            { type }        = options,
            hasNoKpi        = this.hasNoKpi(),
            KPIList         = this.getKpiList(),
            kpiIds          = KPIList.filter(
                kpi => kpiData.stats && kpiData.stats[kpi.id] && kpiData.stats[kpi.id].value
            ).map((kpi) => kpi.id),
            analyseElements = kpiIds.map(
                kpiId => elements.find((el) => el.category === 'Area/Analyse' &&  el.key === `${model.type}-kpi-${kpiId}`)
            );

        // Add element image to clipboard
        if (type === 'element' && !hasNoKpi) {
            // Use addToClipboard of /helper/Element with selfExport
            addToClipboard({ ...options, selfExport: true, context: true });
        }

        // Add lists to clipboard
        if (type === 'list') {
            addAnalyseListToClipboard({ ...options, context: true }, analyseElements.map((el) => el.id));
        }
    }

    /**
    * Get the callback to retrieve the insight content
    *
    * @return JSX
    */
    getInsightContentCB(KpiId) {
        const { kpiData } = this.state,
            stats = kpiData && kpiData.stats[KpiId] ? kpiData.stats[KpiId] : null;

        if (stats) {
            stats.filledInsight = stats.insight;
        }

        _.each(stats, (val, key) => {
            const reg = new RegExp(`%${key}%`, 'g');
            stats.filledInsight = stats.filledInsight.replace(reg, `<b>${val}</b>`);
        });

        return stats ? stats.filledInsight : null;
    }

    /**
    * Return the KPI list
    *
    * @return array
    */
    getKpiList() {
        const { model } = this.props,
            kpiList     = model && model.type !== 'expert' ? KPIel : KPIel.filter((kpi) => !kpi.orgunitsOnly);

        return kpiList;
    }

    /**
    * Render the why content
    *
    * @return JSX
    */
    renderKpiGraph(KpiId) {
        const { kpiData, dataIsLoaded } = this.state,
            score                     = dataIsLoaded ? (!kpiData ? 1 : kpiData.stats[KpiId].value) : 1,
            data                      = { serie: false, stats: { score } };

        return (
            <Graph
                type="Gauge"
                dataIsLoaded={dataIsLoaded}
                innerRadius={0.9}
                data={data}
                steps={12}
                innerPadding={0}
                emptyStepSize={1}
                angleStart={0}
                angleEnd={Math.PI * 2}
                color="#ff6400"
                gradientColor="#ff6400"
                emptyColor="#e0e0e0"
                needleSize={0}
                fontSize={0}
                icon={`kpi/${KpiId}`}
                noLabel
            />
        );
    }

    /**
    * Navigate to tab
    *
    * @return void
    */
    navigateTo(e) {
        const { navigateTo }  = this.props,
            { currentTarget } = e,
            { dataset }       = currentTarget;

        if (navigateTo) {
            navigateTo(dataset.to);
        }

        return false;
    }

    /**
    * Render a whole KPI row
    *
    */
    renderKpiRow(content) {
        const { kpiData }    = this.state,
            kpiId            = content.id,
            kpiNavigationKey = content.navigation.key,
            hasCallback      = kpiData.stats && kpiData.stats[kpiId] && kpiData.stats[kpiId].value,
            classNames       = ['why-kpi', 'graphs-display', !hasCallback ? 'no-data' : ''];

        return (
            <div
                key={content.label}
                className={classNames.join(' ')}
                data-to={kpiNavigationKey}
                onClick={hasCallback ? this.navigateTo : undefined}
            >
                <div className="kpi-graph">
                    {this.renderKpiGraph(kpiId)}
                </div>
                <div className="kpi-label">
                    {content.label}
                </div>
                <div className="kpi-insight">
                    {htmlize(this.getInsightContentCB(kpiId))}
                </div>
            </div>
        );
    }

    /**
    * Render KPIs
    *
    * @return JSX
    */
    renderKPIs() {
        const {
                forwardedRef, model,
            }             = this.props,
            hasNoKpi      = this.hasNoKpi();

        return (
            <div ref={forwardedRef} className="why-element">
                {hasNoKpi
                    ? (
                        <div className="no-kpi">
                            {`We did not find any activity of ${model.label} on this topic.`}
                        </div>
                    ) : (
                        <div className="kpi">
                            <div className="why">
                                {_.map(this.getKpiList(), row => this.renderKpiRow(row))}
                            </div>
                        </div>
                    )}
            </div>
        );
    }

    /**
    * Render all the references as a list
    *
    * @return JSX
    */
    render() {
        const {
            model,
        } = this.props;

        if (!model) {
            //! model && 'loading' />
            return null;
        }

        return this.renderKPIs();
    }

}

Why.propTypes ={
    forwardedRef             : PropTypes.any,
    addAnalyseListToClipboard: PropTypes.func,
    addToClipboard           : PropTypes.func,
    element                  : PropTypes.shape({}).isRequired,
    elements                 : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    navigateTo               : PropTypes.func,
    onNoKpiData              : PropTypes.func,
    registerCallbacks        : PropTypes.func,
    model                    : PropTypes.shape({
        id   : PropTypes.string,
        type : PropTypes.string,
        label: PropTypes.string,
    }),
    context: PropTypes.shape({
        id: PropTypes.string
    }),
};

Why.defaultProps = {
    model: false,
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(Why);

