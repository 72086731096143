/**
* Knowledge actions, learn & use knowledge
*
* @use: import Knowledge from './store/actions/knowledge'
*
*       Knowledge.learn('countries')
*          .then((countries) => {});
*/

import _               from 'lodash';
import { List }        from  'immutable';
import * as types      from './types/knowledge';
import * as Api        from '../../core/utils/api';
import { pluralize }   from '../../core/utils/text';

// Store resolve for books that we are currently/already learning
let  endpointsLoadingPool = [];
const knowledgeLoadingPool = {};
const knowledgeRunningXHR  = {};

// Store the tries count by slice
const triesCountBySlice    = {};
const maxTry               = 10;

// Store API endpoints (must be sent to insight API to be resolved)
const apiEndpoints   = ['tag', 'element', 'model', 'model-column'];


/**
 * Action library, register actions to obtain member from API
 */
const Actions = {

    /**
    * Action to reset the knowledge local database
    *
    * @param  {object}   version The current (distant) version to set
    *
    * @return {Promise}
    *
    */
    resetVersion: (version) => ({ type: types.RESET_VERSION, payload: version }),

    /**
    * Action to store a new knowledge list
    *
    * @param  {object}   parameters The type of the knowledge and the list
    *
    * @return {Promise}
    *
    */
    storeNewKnowledge: (parameters) => ({ type: types.NEW_KNOWLEDGE_ACQUIRED, payload: parameters }),

    /**
    * Action to store a new knowledge endpoints list
    *
    * @param  {object}   parameters The type of the knowledge endpoints and the list
    *
    * @return {Promise}
    *
    */
    storeNewKnowledgeEndpoints: (parameters) => ({ type: types.NEW_KNOWLEDGE_ENDPOINTS_ACQUIRED, payload: parameters }),

};

/**
* Ensure that store version are the same of the distant one
*
* @return void
*/
const enforceKnowledgeVersion = (dispatch, getState) => {
    const localVersion  = process.env.ORBIT_KNOWLEDGE_VERSION,
        cacheVersion    = localStorage.getItem('CACHE_VERSION'),
        currentCacheKey = `${localVersion}####${cacheVersion}`,
        storeVersion    = getState().get('knowledge').version;

    if (!storeVersion) {
        // Init temporary first slice
        dispatch(Actions.resetVersion(currentCacheKey));
        return true;
    }

    if (currentCacheKey === storeVersion) {
        return true;
    }
    dispatch(Actions.resetVersion(currentCacheKey));

    // Wait storage in stored locally
    setTimeout(() => {
        document.location.reload();
    }, 500);

    return true;
};

/**
* Learn from Knowledge then teach about the beautifull things you've leaned.
*
* @return {Promise}
*/
export const learnSingleBook = (params, dispatch, getState) => {
    const {type, endpoints }   = params,
        endpointsPluralized    = endpoints.map(endpoint => pluralize(endpoint));

    // Be sure that local & distant Knowledge have same versions
    enforceKnowledgeVersion(dispatch, getState);

    // Then create a promise to spread knowledge
    return new Promise((resolve, reject) => {
        /**
         * Return the wanted slice of knowledge from the library
         */
        const kebabType   = _.kebabCase(type),
            endpoint      = endpointsPluralized.size > 0
                && endpointsPluralized.find(
                    endP => endP === kebabType || endP === pluralize(kebabType)
                ),
            getKnowledge  = (bookType) => getState().get('knowledge').library.get(bookType),
            knowledgeBook = getKnowledge(endpoint);

        // Ignore unnecessary endpoint
        if(endpoints && !endpoint) {
            resolve();
            return;
        }

        // The book is already in the library, return it.
        if (List.isList(knowledgeBook) && knowledgeBook.size > 0) {  // Knowledge must be a List with some data
            resolve(knowledgeBook);
            return;
        }

        // Create a area in pool to store the learning state
        if (_.isUndefined(knowledgeLoadingPool[endpoint])) {
            knowledgeLoadingPool[endpoint] = [];
        }

        // Check if we reached the max number of attempts for API request for a type
        triesCountBySlice[endpoint] = triesCountBySlice[endpoint] ?? 0;
        if(triesCountBySlice[endpoint] >= maxTry) {
            knowledgeLoadingPool[endpoint] = [];
            return;
        }

        // Store the resolver in the knowledgeLoadingPool
        knowledgeLoadingPool[endpoint].push(resolve);

        // And return if the XHR is already fired
        if (knowledgeRunningXHR[endpoint]) {
            return;
        }

        triesCountBySlice[endpoint]+= 1;

        // Otherwise perform a scan of the world knowledges to obtain the book
        knowledgeRunningXHR[endpoint]=true;
        Api.get('/knowledges', { data: { type } })
            .then(({ body }) => {
                dispatch(Actions.storeNewKnowledge({ type: endpoint, list: body }));
                const book = new List(body);

                // Drain the pool
                if (!_.isEmpty(body) && knowledgeLoadingPool[endpoint].length > 0) {
                    knowledgeLoadingPool[endpoint].forEach((resolveCb) => resolveCb(book));
                }
            })
            .catch(reject)
            .finally(() => {
                knowledgeRunningXHR[endpoint]=false;
            });
    });
};

/**
 *
 * @returns
 */
export const getKnowledgeEndpoints = (dispatch, getState) => {
    // Then create a promise to spread knowledge
    return new Promise((resolve) => {
        endpointsLoadingPool.push(resolve);

        const knowledge   = getState().get('knowledge'),
            { endpoints } = knowledge,
            /**
            * To resolve all learn knowledge endpoints
            */
            resolveAll    = (results) => {
                const pool = _.clone(endpointsLoadingPool);
                endpointsLoadingPool = [];
                pool.forEach(endpointsResolve => {
                    endpointsResolve(results);
                });
            };

        if (List.isList(endpoints) && endpoints.size > 0) {
            resolveAll(endpoints);
            return;
        }

        if (endpointsLoadingPool.length > 1) {
            return;
        }

        Api.get('/knowledges')
            .then(({ body }) => {
                if(!body || body.length === 0) {
                    console.error('Error loading knowledge endpoints');
                    resolveAll(new List());
                    return;
                }
                // Merging front and pluralized back knowledge endpoints
                const dataEndpoints = body.map((endpoint) => _.kebabCase(endpoint)),
                    newEndpoints    = apiEndpoints.concat(dataEndpoints).sort();

                dispatch(Actions.storeNewKnowledgeEndpoints({ list: newEndpoints }));
                resolveAll(new List(newEndpoints));
            })
            .catch(() => {
                resolveAll(new List());
            });
    });
};


/**
* Learn from Knowledge then teach about the beautifull things you've leaned.
*
* @return {Promise}
*/
export const learn = (books, fail) => async (dispatch, getState) => {
    // Learn must have a single argument!
    if (fail) { throw new Error('Learn knowledge must have a single argument. Please use an Array!'); }

    const booksAsArray      = _.isArray(books) ? books : [books],
        endpoints           = await getKnowledgeEndpoints(dispatch, getState), // Wait until the endpoints knowlegde are recovered
        camelCasedBooksName = booksAsArray.map(_.camelCase),
        booksPromises       = booksAsArray.map((type) => learnSingleBook({type, endpoints}, dispatch, getState));

    // Return all promises books
    return new Promise((resolve) => {
        // Learn worldwide knowledge
        Promise.all(
            booksPromises
        ).then(
            (knowledges) => resolve(_.zipObject(camelCasedBooksName, knowledges))
        );
    });
};

export default { learn };
