/**
 * Display the dashboard of the App
 *
 * @return Component
 */

import _                                            from 'lodash';
import React, { Component }                         from 'react';
import { Skeleton, Modal }                          from 'antd';
import PropTypes                                    from 'prop-types';
import ImmutablePropTypes                           from 'react-immutable-proptypes';
import { Helmet }                                   from 'react-helmet';
import { connect }                                  from 'react-redux';
import { pluralize, makeStrClassName }              from 'utils/text';
import { getYearsFromCustomString }                 from 'utils/date';
import { dataGet }                                  from 'utils/api';
import { makeCancelable, makeCancelableAll }        from 'utils/promise';
import { deepEqual, objectFilter }                  from 'utils/object';
import { getSubElementsFilterByCategory }           from 'utils/elements';
import { learn }                                    from 'store/actions/knowledge';
import { emitEvent }                                from 'store/actions/sockets';
import CollectionSentence                           from 'helpers/Collection/CollectionSentence';
import {
    Row,
    Col,
    Icon,
    Element,
    Filters,
    Guider,
    LazyLoad,
}                                                   from 'helpers';
import {
    isSearchMustBeRefreshed,
    refreshQuery,
}                                                   from 'store/actions/navigation';

import {
    storeNodePreferences,
    getNodePreferences,
}                        from 'store/actions/navigation/nodesPreferences';

import './Analyse/assets/analyse.less';

const disableUrlFilters = true;

/**
* Metrics class: Display some metrics about specific dashboard graph data
*
* {"id":"AnalysisOrgUnits","data":null,"pagination":null,"map":null}
*/
class Analyse extends Component {

    // TODO: Rename component name Analyse to FilterableCollection ?
    /**
    * Construct metrics
    *
    */
    constructor(props) {
        super(props);

        _.bindAll(this,
            'storePagination', 'storeFiltersFromCollection', 'onClickResetFilter',
            'renderCollection', 'registerListCollectionActions', 'registerCallbacks', 'openGuide',
            'onUpdateMetricsFilters', 'onUpdateGlobalFilters', 'onGraphsDataLoaded', 'onCollectionLoaded',
            'goToDataset', 'getFilters', 'closeGuide', 'closeHelpNetwork', 'getListResource', 'registerGraphCollectionActions',
            'onUpdatePaginationOrder',
        );

        this.collection       = null;
        this.scrollTimeout    = null;
        this.defaultTimeframe = this.getDefaultTimeframe();

        this.state = {
            filtersForData    : {},
            metrics           : null,
            resources         : null,
            metricData        : null,
            filtersForMetrics : {},
            filtersByMetrics  : this.defaultTimeframe,
            globalFilters     : {},
            dynamicFilters    : null,
            filtersPosition   : 0,
            // Elements actions
            list_actions      : {},
            entities          : null,
            callbacks         : {},
            fixedBySelection  : this.analyseIsFixedBySelection(),
            helpNetworkVisible: false,
            firstCall         : true,
            isFiltersLoading  : false,
            dynamicFiltersPool: [],
        };
        this.cancelableDatasetPromises  = null;
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const {
                learnKnowledge,
                element
            }            = this.props,
            { category } = element;

        // Make sure that the user is not in the middle of the page at startup
        window.scrollTo(0, 0);

        learnKnowledge(['help-metrics', 'filters', 'entities', 'resources'])
            .then(this.setState.bind(this));

        // Fet totals of the list
        this.fetchListTotals();

        // Set filters from url params
        this.setDefaultFilters();

        // Update filters from a 'parent' search (originalId)
        this.updateFiltersFromAnalyseFilters();

        if (category === 'Area/Analyse') {
            // Fetch totals for dataset list links
            this.fetchDatasetTotals();
        }
    }


    /**
    * Launch side effects
    *
    * @return boolean
    */
    componentDidUpdate() {
        const { restrictedFilters } = this.state,
            newRestrictedFilters    = this.getRestrictedFilters();

        // Update restricted filters
        if (!deepEqual(newRestrictedFilters, restrictedFilters, false)) {
            this.setState({ restrictedFilters: newRestrictedFilters });
        }

        this.manageOutdatedSearch();
    }

    /**
    * Component will unmount
    *
    * @return void
    */
    componentWillUnmount() {
        this.cancelableDatasetPromises && this.cancelableDatasetPromises.cancel();
    }

    /**
    * Get state for props and previous state
    *
    * Calculate :
    *          - data
    *          - graph data
    *          - size of the chart only
    *
    * @params nextProps object New props received
    * @params prevState object Previous state
    *
    * @return void
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const {
                filtersForData, lastFiltersForData,
                filtersByMetrics: lastFiltersByMetrics,
                dynamicFilters,
                dynamicFiltersPool,
            } = prevState,
            filtersByMetricsRaw   =  _.omitBy(filtersForData, (value, filterKey) => filterKey.substr(-7) !== '(graph)'),
            filtersByMetrics      =  _.mapKeys( // Remove string '(graph)'
                filtersByMetricsRaw,
                (value, filterKey) => filterKey.substr(0, filterKey.length - 7)
            );

        // Update filtersByMetrics when filtersByMetrics change
        if (JSON.stringify(filtersByMetrics) !== JSON.stringify(lastFiltersByMetrics)) {
            prevState.filtersByMetrics  = filtersByMetrics;
        }

        // Update filtersForMetrics when filtersForData change
        if (JSON.stringify(filtersForData)!== JSON.stringify(lastFiltersForData)) {
            prevState.filtersForMetrics = Analyse.updateFiltersForMetrics(prevState);
        }

        return {
            ...prevState,
            lastFiltersForData: filtersForData,
            dynamicFiltersPool: Analyse.updatedDynamicFiltersPool(dynamicFiltersPool, dynamicFilters)
        };
    }


    /**
     * Get New filterForMetrics
     *
     * @param {object} prevState
     *
     * @returns object
     */
    static updateFiltersForMetrics(prevState) {
        const {
                firstCall, filtersForData
            }               = prevState,
            dateFilters     = ['timeframe', 'npltimeframe', 'pubtimeframe'],
            { location }    = window,
            { href }        = location,
            hrefSplit       = href?.split('?'),
            base            = hrefSplit && hrefSplit.length > 0 && hrefSplit[0],
            queryString     = Object.keys(filtersForData).map(
                key => key + '='
                    + (_.isArray(filtersForData[key])
                        ? '['
                            + filtersForData[key].map(value => encodeURIComponent(value.toString())).join(',')
                            + ']'
                        : encodeURIComponent(filtersForData[key])
                    )
            ).join('&');

        // Save filters in url
        if (!disableUrlFilters) {
            window.location.href = base + (queryString ? '?' + queryString : '');
        }

        return _.omitBy(filtersForData, (value, filterKey) => {
            // Remove timeframe graph filters data (timeframe don not be send in xhr for fetching graph data)
            const rootFilterKey = filterKey.endsWith('(graph)') && filterKey.substr(0, filterKey.length - 7);
            return (firstCall && filterKey.endsWith('(graph)'))
                || (rootFilterKey && dateFilters.includes(rootFilterKey));
        });
    }


    /**
     * Get dynamicFiltersPool updated or not
     *
     * @param {array} dynamicFiltersPool
     * @param {array} dynamicFilters
     *
     * @returns array
     */
    static updatedDynamicFiltersPool(dynamicFiltersPool, dynamicFilters) {
        // TODO: dirty fix prevent duplicated filters !
        const newDynamicFiltersPool = {
                ...dynamicFiltersPool,
                ...(_.mapValues(dynamicFilters, (filterslist) => {
                    return _.map(filterslist, filtervalue => ({
                        ...filtervalue,
                        value: null
                    }));
                }))
            },
            poolNotChange = deepEqual(dynamicFiltersPool, newDynamicFiltersPool, false);

        return poolNotChange ?  dynamicFiltersPool : newDynamicFiltersPool;
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb) {
        const { registerCallbacks } = this.props,
            { callbacks }           = this.state,
            /** Return empty object */
            defaultCb               = () => {};

        callbacks[action] = cb || defaultCb;

        this.setState({ callbacks });

        registerCallbacks(action, cb);
    }


    /**
     * Reset all collections
     */
    resetCollections() {
        const { listCollectionActions, graphCollectionActions } = this.state;

        if (listCollectionActions?.reset) {
            listCollectionActions.reset();
        }

        if (graphCollectionActions?.reset) {
            graphCollectionActions.reset();
        }
    }


    /**
     * Update filters from analysesFilters (in store) to get filters from parent query
     */
    updateFiltersFromAnalyseFilters() {
        const {  model }   = this.props,
            { callbacks }  = this.state,
            { setFilters } = callbacks,
            { filters }    = this.getPreferences(),
            { type }       = model,
            isQuery        = type === 'query';

        if (isQuery && setFilters && filters) {
            // Set filters in Filters component
            setFilters(filters);
        }
    }

    /**
    * Get node id
    *
    * @returns {string}
    */
    getNodeId() {
        const { model, node } = this.props,
            { modules }       = node,
            { id }            = model,
            idNode            = `${id}/${modules.join('/')}`;

        return idNode;
    }

    /**
    * Get preferences (filters and sort) of this analyse from the store
    *
    * @returns {object}
    */
    getPreferences() {
        const { getNodePreferences } = this.props,
            idNode                   = this.getNodeId(),
            preferences              = getNodePreferences && getNodePreferences(idNode);

        return preferences;
    }

    /**
    * Fetch dataset totals
    *
    * @return void
    */
    fetchDatasetTotals() {
        const {
                entities, model,
                resources, context,
            }                 = this.props,
            models            = this.getResourceModels(),
            idq               = context && context.type === 'query' ? context.id : null,
            entitiesTypes     = _.map(models, 'type'),
            datasetEntities   = entities.filter((entity) => entitiesTypes.indexOf(entity.id) >= 0).toJS(),
            // Fetch dataset list collection to get the big total
            datasetPromises   = datasetEntities.map((entity) => {
                const datasetResourceId = _.isString(entity.dataset_resource)
                        ? entity.dataset_resource
                        : entity.dataset_resource[model.type],
                    datasetResource = resources.find((res) => res.id === datasetResourceId),
                    apiUri    = datasetResource && `/${pluralize(model.type)}/${model.id}/${datasetResource.path}`;

                // No dataset_resource in entity configuration for this model
                if (!apiUri) {
                    return null;
                }

                const promise = dataGet(apiUri, {data: { limit: { min: 0, max: 2 }, idq }});
                return makeCancelable(
                    new Promise((resolve) => promise.then(
                        // Use dataget response to resolve Promise.all
                        (response) => {
                            resolve({
                                entity,
                                total: parseInt(response.headers ? response.headers.get('x-pagination-total') : 0, false)
                            });
                        },
                        // Error case
                        (/* { isCancelled } */) => {
                            resolve({ entity, total: false });
                            return false;
                        }
                    )),
                    () => {
                        promise.cancel();
                    }
                );
            });

        this.cancelableDatasetPromises = makeCancelableAll(datasetPromises);
        this.cancelableDatasetPromises.then((datasetTotals) => {
            if (datasetTotals.length > 0) {
                this.setState({
                    datasetTotals: datasetTotals.filter(datasetTotal => !!datasetTotal) // Filter null datasetTotal
                });
            }
        });
    }

    /**
    * Fetch list totals
    *
    * @return void
    */
    fetchListTotals() {
        const {
                model, context
            }        = this.props,
            resource = this.getListResource(),
            idq      = context && context.type === 'query' ? context.id : null,
            apiUri   = resource && `/${pluralize(model.type)}/${model.id}/${resource.path}`,
            promise  = dataGet(apiUri, {data: { limit: { min: 0, max: 2 }, idq }});

        promise.then((response) => {
            const { headers } = response,
                pagination    = {};

            // Extract pagination from headers
            headers?.forEach((value, key) => {
                const splitedKey = key.split('x-pagination-');
                if (splitedKey.length <= 1) {
                    return;
                }
                pagination[splitedKey[1]] = key === 'x-pagination-total'
                    ? parseInt(value, 10)
                    : (key === 'x-pagination-totalbytype' ? JSON.parse(value) : value);
            });

            this.setState({ baseCollectionPagination: pagination });
        });
    }


    /**
    * Triggered when filters has been updated
    *
    */
    onUpdateMetricsFilters(newFilters) {
        const {
                globalFilters, firstCall,
                filtersByMetrics
            }                   = this.state,
            filtersKeys         = _.keys(newFilters),
            isTimeframeFilter   = filtersKeys.includes('timeframe'),
            newFiltersByMetrics = objectFilter(
                {   ...(firstCall && isTimeframeFilter ?  this.defaultTimeframe : filtersByMetrics),
                    ...newFilters
                },
                (key, values) => values.length > 0
            );

        this.resetCollections();

        // Store filters to get from an other analyse with key like {searchId}/technology-landscape/top-academics
        this.storeFilters({
            ...globalFilters,
            ..._.mapKeys(newFiltersByMetrics, (value, key) => `${key}(graph)`)}
        );

        this.setState({
            filtersForData      : this.getFilters(globalFilters, newFiltersByMetrics),
            collectionPagination: null,
            firstCall           : false,
        });
    }

    /**
     * Check if current filters are outdated for the current search
     *
     * @param {func} The callback to be triggered when the navigation has been updated.
     *
     * @return self
     */
    async manageOutdatedSearch(cb) {
        const {
                isSearchMustBeRefreshed, refreshQuery,
                model, node,
            }           = this.props,
            { modules } = node,
            isOutdated  = await isSearchMustBeRefreshed();

        // Search isn't outdated, continue the main process
        if (!model || !isOutdated) {
            cb && cb();
            return false;
        }

        refreshQuery({ modules });

        return true;
    }

    /**
     * Store filters in redux
     *
     * @param {object} filters
     * @param {string} id
     */
    storeFilters(filters, id) {
        const {
                storeNodePreferences,
                node, model,
            }           = this.props,
            { modules } = node,
            path        = `${(id || model.id)}/${modules.join('/')}`;

        storeNodePreferences(path, { filters });
    }

    /**
    * Triggered when filters has been updated
    *
    */
    onUpdateGlobalFilters(newFiltersValues, options) {   // eslint-disable-line max-lines-per-function
        const {
                model, emitEvent
            }                   = this.props,
            {
                callbacks,
                globalFilters,
            }                   = this.state,
            { firstCall }       = options || {},
            { type }            = model,
            { triggerCallback } = callbacks,
            newFiltersByMetrics = {
                ...(firstCall
                    ? this.defaultTimeframe
                    : {}
                )
            },
            newFilters                             = _.omitBy({
                ...globalFilters,
                ...newFiltersValues
            }, v => _.isEmpty(v)),
            filtersForData = this.getFilters(newFilters, newFiltersByMetrics),
            /** Prepare a callback. */
            cb = () => {
                window.scrollTo(0, 0);

                // Reset metrics states
                if (triggerCallback) {
                    requestAnimationFrame(() => {
                        triggerCallback('resetGraph');
                    });
                }

                this.resetCollections();

                // Update the state with filters updated.
                this.setState({
                    collectionPagination: null,
                    filtersForData,
                    globalFilters       : newFilters,
                    dynamicFilters      : {},
                    // Disable the fixedBySelection to actualise graphs (Global filters are changed)
                    fixedBySelection    : false,
                    firstCall           : !!firstCall,
                });
            };

        emitEvent({
            module: this.getElementType(),
            name  : 'add-filters',
            data  : newFiltersValues
        });

        this.setState({ isFiltersLoading: true });

        // Store filters to get from an other analyse with key like {searchId}/technology-landscape/top-academics
        this.storeFilters(newFilters);

        // Do not manageOutdatedSearch for other entities
        if (type !== 'query') {
            return cb();
        }

        // Prevent wrong tag to be returned
        this.manageOutdatedSearch(cb, newFilters).then(
            // Cb call in manageOutdatedSearch : Update some filter without reloading the request
        );
    }

    /**
    * Set filters by url parameters or by stored filters
    */
    setDefaultFilters() {
        const { module: nodesModule, node } = this.props,
            { filters: nodeFilters }        = this.getPreferences(),
            { urlsParams }                  = node || {},
            moduleUrlsParams                = (urlsParams && urlsParams[nodesModule]) || null,
            filters                         = nodeFilters || moduleUrlsParams,
            baseFilters                     = _.omitBy(filters, (values, key) => key.endsWith('(graph)')),
            graphFilters                    = _.mapKeys(
                _.omitBy(filters, (values, key) => !key.endsWith('(graph)')),
                (value, key) => key.replace('(graph)', '')
            );

        this.onUpdateGlobalFilters(baseFilters, { firstCall: true });

        if (_.keys(graphFilters).length > 0) {
            this.applyUrlGraphFilters = () => this.onUpdateMetricsFilters(graphFilters);
        }
    }

    /**
    * Check in the element to know if analyse graphs are fixed by selection
    *
    * @return string
    */
    analyseIsFixedBySelection() {
        const { element } = this.props;

        // The fixedBySelection is true by default, can be disable by notFixedBySelection=true
        return !_.get(element, 'configuration.notFixedBySelection', false);
    }

    /**
    * Obtain the graph definition from the current state
    *
    * @return object
    */
    getGraphLayout() {
        const  { elements, element }    = this.props,
            { configuration, category } = element,
            graphLayout                 = category === 'Area/Analyse' ? 'graph' : 'key-facts';

        return elements.find((el) => el.id === configuration.elements[graphLayout]);
    }

    /**
    * Obtain the list definition from the current state
    *
    * @return object
    */
    getListElement() {
        const  { elements, element }   = this.props,
            { configuration } = element,
            listElement       = elements.find((el) => el.id === configuration.elements.list),
            extendedElement   = {
                ...listElement,
                resource: {
                    ...listElement.resource,
                    pagination: {
                        limit: { min: 0, max: 25 } // Extend analyse default pagination
                    }
                }
            };

        return extendedElement;
    }

    /**
    * Obtain the Dataset resource definition that permit to request the data API
    *
    * @return object
    */
    getDatasetResource() {
        const { element }     = this.props,
            { resources }     = this.state,
            { configuration } = element;

        return resources ? resources.find((obj) => obj.id === configuration.dataset) || false : false;
    }

    /**
    * Create the filter object for Metrics & Collection
    *
    * @return object
    */
    getFilters(globalFilters, filtersByMetrics) {
        const graphFilters = _.mapKeys(filtersByMetrics, (value, key) => `${key}(graph)`);

        return {...globalFilters, ...graphFilters};
    }

    /**
    * On graph data loaded (trigger on each graphs loaded)
    *
    * @param string metricKey  The key of the loaded metric
    * @param object metricData The graph data tree on loaded event
    *
    * @returns void
    */
    onGraphsDataLoaded(metricKey, metricData) {
        const { fixedBySelection }    = this.state,
            analyseIsFixedBySelection = this.analyseIsFixedBySelection(),
            // Check if one graph at least is loading from metricData tree
            aGraphIsLoading           = _.values(metricData).reduce((cumul, d) => cumul || d.isLoading, false);

        // Store the metric data in the state
        this.setState({ metricData });

        // FixedBySelection in state must be false just one time for analyse with analyseIsFixedBySelection
        // (useful for reloading graph on changing global filters)
        if (analyseIsFixedBySelection && !fixedBySelection && !aGraphIsLoading) {
            this.setState({ fixedBySelection: true });
        }

        // Apply graph filter of urls after first render
        //  (to have the goof graph with the selection, not the graph already filtered)
        //  The function applyUrlGraphFiltersTimeout() is set only once (Component mount)
        clearTimeout(this.applyUrlGraphFiltersTimeout);
        if (this.applyUrlGraphFilters) {
            this.applyUrlGraphFiltersTimeout = setTimeout(() => {
                this.applyUrlGraphFilters && this.applyUrlGraphFilters();
                this.applyUrlGraphFilters = null;
            }, 1000);
        }
    }

    /**
    * Store dynamic filters for later use
    */
    storeFiltersFromCollection(dynamicFilters) {
        const { dynamicFilters: previousCollectionFilters, isFiltersLoading } = this.state;

        if (isFiltersLoading) {
            this.setState({ isFiltersLoading: false });
        }
        // Test dynamicFilters change
        if (!deepEqual(previousCollectionFilters, dynamicFilters)) {
            // Clone to prevent change in state (Collection pointer)
            this.setState({ dynamicFilters: _.clone(dynamicFilters) });
        }
    }

    /**
    * On update pagination order
    *
    * @param {object} sorts
    */
    onUpdatePaginationOrder(sorts) {
        const { storeNodePreferences } = this.props,
            { model, node }             = this.props,
            { modules: nodeModules }    = node,
            modules                     = nodeModules.join('/'),
            { id }                      = model || {},
            idNode                      = `${id}/${modules}`;

        storeNodePreferences(idNode, { sorts });

        this.resetCollections();

        // Force Analyse to make pagination from the store
        this.setState({
            collectionPagination: null
        });
    }

    /**
    * Store pagination returned by collection
    *
    * @return void
    */
    storePagination(pagination) {
        this.setState({
            collectionPagination: pagination
        });
    }

    /**
     * Get filters to render if !hidden in knowledges.
     *
     * @return array of filters OR empty array if no filters
     */
    getFiltersToRender() {
        const { filters: filtersDef }    = this.state,
            resource                     = this.getListResource(),
            { filters: resourceFilters } = resource,
            filtersToRender              = filtersDef && resourceFilters
                ? resourceFilters.filter((filterKey) => {
                    const filterDef = filtersDef.find((filterDef) => filterDef.id === filterKey),
                        { hidden }  = filterDef;

                    return !hidden;
                })
                : resourceFilters;

        return filtersToRender || [];
    }


    /**
     * Get list resource from props
     *
     * @return {object} resource
     */
    getListResource() {
        const  { getListResource } = this.props,
            element                = this.getListElement(),
            resource               = getListResource(element);

        return resource;
    }


    /**
     * Return models of resource
     *   => models types who can be return by the resource
     */
    getResourceModels() {
        const { models } = this.getListResource();

        return models || [];
    }


    /**
    * Let's go to the dataset
    *
    * @return void
    */
    goToDataset(e) {
        const { navigateTo } = this.props,
            { target } = e;

        navigateTo(target.dataset.model);
    }

    /**
    * Render the collection sentence
    *
    * @return JSX
    */
    renderSentence() {
        const { element, model, context } = this.props,
            {
                collectionPagination,
                baseCollectionPagination,
                dynamicFilters,
                filtersForData,
                dynamicFiltersPool,
            }                             = this.state,
            resource                      = this.getListResource(),
            { settings }                  = context || model || {};

        return baseCollectionPagination && resource && (
            <CollectionSentence
                key={`sentence-${element.key}`}
                resource={resource}
                parameters={filtersForData}
                pagination={collectionPagination}
                basePagination={baseCollectionPagination}
                sentenceSuffix={this.renderNetworkGraphHelp()}
                onClickResetFilter={this.onClickResetFilter}
                dynamicFilters={dynamicFilters}
                dynamicFiltersPool={dynamicFiltersPool}
                searchFilters={settings}
            />
        );
    }

    /**
    * Render links to the datasets
    *
    * {/* totalWithoutFilters ? ` (${totalWithoutFilters} ${entity.label_plural})` : null}
    *
    * @return jsx
    */
    renderDatasetLink() {
        const { datasetTotals } = this.state,
            filleDdatasetTotals = datasetTotals?.filter(datasetTotal => datasetTotal?.total > 0);

        return datasetTotals && (
            <span className="to-dataset">
                {filleDdatasetTotals && filleDdatasetTotals.length > 0 && (
                    <span>
                        <span className="label">Linked data set</span>:&nbsp;
                        {datasetTotals.map((datasetTotal) => {
                            const { entity, total } =  datasetTotal || {};

                            return total > 0 ? [
                                <span key={entity.id} className="model"
                                    data-model={entity.id} onClick={this.goToDataset}
                                >
                                    {`${total} ${entity.label_plural.toLowerCase()}`}
                                </span>,
                                <span key={`${entity.id}-separator`} className="separator">, </span>
                            ] : null;
                        })}
                    </span>
                )}
                {!filleDdatasetTotals && <Skeleton active paragraph={false} />}
            </span>
        );
    }

    /**
    * Render the title part for the metrics
    *
    * @return jsx
    */
    renderTitle() {
        const { element } = this.props;

        return (
            <Row className="topbar" key="title">
                <span className="title">{element.label}</span>
            </Row>
        );
    }

    /**
    * Register list callbacks
    *
    * @param object actions Collection actions callback to register
    *
    * @return self
    */
    registerListCollectionActions(actions) {
        this.setState({
            listCollectionActions: actions
        });
    }



    /**
    * Register list callbacks
    *
    * @param object actions Collection actions callback to register
    *
    * @return self
    */
    registerGraphCollectionActions(actions) {
        this.setState({
            graphCollectionActions: actions
        });
    }



    /**
    * Triggered when the collection has been loaded
    *
    * @return
    */
    onCollectionLoaded(element, data) {
        const { pagination } = data;

        if (!pagination) {
            this.storePagination({ total: 0 });
            return;
        }

        this.storePagination(pagination);
    }

    /**
    * Render the help Modal
    *
    * @return JSX
    */
    renderHelpBtn() {
        const { help } = this.props;

        if (!help) {
            return false;
        }

        return (
            <span className="help" onClick={this.openGuide}>
                <Icon
                    id="info"
                    height={20}
                    title="Help"
                />
            </span>
        );
    }

    /**
    * Render the guide modal
    *
    * @return JSX
    */
    openGuide(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            guideEnabled: true,
            hovered     : false,
        });

        return false;
    }

    /**
    * Render the guide modal
    *
    * @return JSX
    */
    closeGuide(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            guideEnabled: false,
            hovered     : false,
        });

        return false;
    }

    /**
    * Render the help modal restriction
    *
    * @return JSX
    */
    openHelpNetwork(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            helpNetworkVisible: true,
            hovered           : false,
        });

        return false;
    }

    /**
    * Close the helper restricted
    *
    * @return JSX
    */
    closeHelpNetwork(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            helpNetworkVisible: false,
            hovered           : false,
        });

        return false;
    }

    /**
    * Render the Help Restrited at network
    *
    * @return JSX
    */
    renderHelpNetwork() {
        const { helpNetworkVisible } = this.state,
            networkGraphElements     = this.getGraphElementsFromType('Graph/Network');

        if(networkGraphElements.length < 1) {
            return;
        }

        const { pagination } = networkGraphElements[0].resource,
            { limit }        = pagination || {},
            { max }          = limit || {max: 300};

        return (
            <Modal
                key="helper-restricted-modal"
                wrapClassName="helper-restricted-modal"
                title={false}
                footer={null}
                onCancel={this.closeHelpNetwork}
                width={560}
                open={helpNetworkVisible}
            >
                <div className="text">
                    For performance reasons, the collaboration network graph is limited to the {max}
                    &nbsp;most collaborative organizations of your search.
                    Hence you may not find in the graph the organizations with the least relations. <br />
                    <br />
                    If you wish to display the excluded organizations, please use the filters on
                    the right in order to narrow your search.
                </div>
            </Modal>
        );
    }

    /**
    * Render the guide modal
    *
    * @return JSX
    */
    renderGuide(content) {
        const {
                help,
                width,
                height,
                color,
                element,
            }                    = this.props,
            { statsForInsights } = this.state,
            { guideEnabled }     = this.state,
            { filledInsight }    = statsForInsights || {},
            { label }            = element,
            horizontalGraph      = width * 0.9 > height;

        return (
            <Guider
                horizontalGraph={horizontalGraph}
                closeModal={this.closeGuide}
                help={help}
                label={label}
                insight={filledInsight}
                insightColor={color}
                hideGraph
                visible={guideEnabled}
            >
                {content}
            </Guider>
        );
    }

    /**
     * Render help button for networkgraph
     *
     * @returns JSX
     */
    renderNetworkGraphHelp() {
        const networkGraphElements  = this.getGraphElementsFromType('Graph/Network');

        return networkGraphElements.length > 0 && (
            <div className="help-restricted" onClick={(e) => this.openHelpNetwork(e)}>
                <Icon
                    id="info"
                    height={14}
                    width={14}
                    title="Help"
                />
            </div>
        );
    }

    /**
    * Get the DOM selectors for list or detailed list
    *
    * @returns {object}
    */
    getScrollSelectors() {
        const contentSelector = '#browser-accordion .item.active .analyse-dataset > div',
            scrollSelector    = '#browser-accordion .item.active .analyse-dataset';

        return {
            contentSelector,
            scrollSelector,
        };
    }

    /**
    * Render the collection of content
    *
    * @return JSX
    */
    renderCollection() {
        const {
                model, navigateTo, context,
                bookmarks, tags,
            } = this.props,
            {
                listCollectionActions,
                collectionPagination,
                filtersForData,
                dynamicFilters
            }            = this.state,
            element      = this.getListElement(),
            { sorts }    = this.getPreferences();

        return (
            <Col className="collection-list" y={0}
                nonBinding
            >
                <div className="before-collection">
                    {this.renderSentence()}
                    {this.renderDatasetLink()}
                </div>
                <LazyLoad
                    getScrollSelectors={this.getScrollSelectors}
                    pixelDetect={30}
                    loadNextCb={listCollectionActions?.loadNextRange}
                    pagination={collectionPagination}
                >
                    <Element
                        element={element}
                        model={model}
                        context={context}
                        parameters={filtersForData}
                        navigateTo={navigateTo}
                        bookmarks={bookmarks}
                        tags={tags}
                        registerActions={this.registerListCollectionActions}
                        onDataLoaded={this.onCollectionLoaded}
                        onFilterListLoaded={this.storeFiltersFromCollection}
                        onUpdatePaginationOrder={this.onUpdatePaginationOrder}
                        dynamicFilters={dynamicFilters}
                        sort={sorts}
                        getScrollSelectors={this.getScrollSelectors}
                    />
                </LazyLoad>
            </Col>
        );
    }

    /**
    * Render meta title
    *
    * @return boolean
    */
    renderMeta() {
        const { element } = this.props;

        return (
            <Helmet>
                <title>{element.label}</title>
            </Helmet>
        );
    }


    /**
     *
     */
    onClickResetFilter(filter = false) {
        const {
                callbacks,
                filtersForData,
            }                  = this.state,
            {
                setFilters,
                triggerCallback
            }                  = callbacks,
            isAGraphFilter     = filter && filter.substr(-7) === '(graph)',
            cleanedFiltersForData = _.mapValues(filtersForData, (values, filterKey) => {
                const cleaningGraphFilter = !isAGraphFilter && filterKey.substr(-7) === '(graph)';
                return !filter || filter === filterKey || cleaningGraphFilter
                    ? false
                    : values;
            }),
            newFiltersForData   = _.omitBy(cleanedFiltersForData, (values) => values === false);

        this.setState({
            filtersForData: {
                ...newFiltersForData
            },
            firstCall           : false,
            collectionPagination: null,
        });

        this.resetCollections();

        if (triggerCallback) {
            requestAnimationFrame(() => {
                if (!isAGraphFilter) {
                    // TODO: remove setFilters callback (remove values state in Filters component)
                    setFilters && setFilters(cleanedFiltersForData); // To set globalFilter
                    triggerCallback('resetGraph'); // Reset all graphs
                }
                isAGraphFilter && triggerCallback('resetGraph', { filter }); // Reset only the graph with the filter
            });
        }
    }


    /**
    * Get query filters definition from knowledge
    *
    * @returns array
    */
    getQueryFiltersDefinition() {
        const { filters } = this.state;

        return filters?.filter(filter => filter.isQueryFilter).toArray();
    }


    /**
    * Get query filters from settings
    */
    getSettingsFilters() {
        const {
                model, context,
            }                 = this.props,
            { settings }      = context || model || {},
            queryFiltersDef   = this.getQueryFiltersDefinition(),
            queryFiltersDefId = _.map(queryFiltersDef, 'id');

        return _.pick(settings, queryFiltersDefId);
    }


    /**
    * Get filters keys matching with search filters definition and models in resource definition
    *
    * @returns {object}
    */
    getRestrictedFilters() {
        const resource        = this.getListResource(),
            {
                models,
                filters: resourceFilters,
            }                 = resource || {},
            modelsTypes       = _.map(models, 'type'),
            settingsFilters   = this.getSettingsFilters(),
            queryFiltersDefs  = this.getQueryFiltersDefinition(),
            restrictedFilters = {};

        _.forIn(settingsFilters, (value, key) => {
            const filterDef = queryFiltersDefs.find(def => def.id === key);

            _.forEach(modelsTypes, modelType => {
                const filterKeys     = filterDef.filterByEntityType[modelType],
                    activeFilterKeys = _.intersection(resourceFilters, filterKeys);

                _.forEach(activeFilterKeys, filterKey => {
                    restrictedFilters[filterKey] = value;
                });
            });
        });

        return restrictedFilters;
    }


    /**
    * Render the filter part
    *
    * @return JSX
    */
    renderFilters() {
        const { element }     = this.props,
            {
                baseCollectionPagination, globalFilters,
                dynamicFilters, isFiltersLoading, restrictedFilters
            }                 = this.state,
            { configuration } = element,
            { hideFilters }   = configuration || {},
            resource          = this.getListResource(),
            filtersToRender   = this.getFiltersToRender();

        // Hide filters when we don't have any.
        if (!filtersToRender || filtersToRender.length === 0) {
            return null;
        }

        return (
            <Filters
                values={globalFilters}
                resource={resource}
                dynamicFilters={dynamicFilters}
                onChange={this.onUpdateGlobalFilters}
                registerCallbacks={this.registerCallbacks}
                dynamicFiltersThresholdReached={
                    baseCollectionPagination && Boolean(baseCollectionPagination['dynamic-filters-threshold-reached'])
                }
                restrictedFilters={restrictedFilters}
                toHide={hideFilters}
                isFiltersLoading={isFiltersLoading}
            />
        );
    }

    /**
     * Get the default timeframe of the analyse
     *  (function is in ELEMENT cause it's use by addToCliboard fox xls export)
     *
     * @returns object
     */
    getDefaultTimeframe() {
        const {
                getDefaultTimeframeFromAnalyseElement,
                element, model, context
            }                = this.props,
            { settings }     = context || model || {},
            { dateFilter }   = settings || {},
            elementTimeframe = getDefaultTimeframeFromAnalyseElement(element),
            elTimeframe      = getDefaultTimeframeFromAnalyseElement(element, false),
            modelTimeFrame   = dateFilter && getYearsFromCustomString(dateFilter),
            [start, end]     = modelTimeFrame || [],
            timeframe        = elTimeframe.timeframe || elTimeframe.nplTimeframe;

        // Clean up the timeframe (on intersection: timeframe of a search with the default timeframe)
        if (modelTimeFrame && start > timeframe?.start || end < timeframe?.end) {
            return {};
        }

        return elementTimeframe;
    }


    /**
    * Analyse has graph
    *
    * @return boolean
    */
    hasGraph() {
        const graphElement    = this.getGraphLayout(),
            { configuration } = graphElement || {},
            { elements }      = configuration || {};

        return elements && elements.length > 0;
    }


    /**
    * Get elements graph
    */
    getGraphElements() {
        const { elements } = this.props;

        return getSubElementsFilterByCategory(elements, ['Collection/', 'Graph/'], this.getGraphLayout());
    }

    /**
    * Get element graph from a type
    */
    getGraphElementsFromType(type) {
        const graphElements = this.getGraphElements();

        return graphElements.filter(graphElement => graphElement.category === type);
    }

    /**
    * Render the metrics helper
    *
    * @return JSX
    */
    renderGraphLayout() {
        const {
                model, module, element,
                context, navigateTo, bookmarks,
                tags
            }                        = this.props,
            {
                fixedBySelection, filtersForMetrics,
                firstCall, filtersByMetrics
            }                        = this.state,
            { configuration, label } = element,
            { graphPadding }         = configuration,
            padding                  = _.isUndefined(graphPadding) ? 20 : graphPadding,
            graphElements            = this.getGraphElements(),
            networkGraphElements     = graphElements.filter(graphElement => graphElement.category === 'Graph/Network'),
            graphNames               = _.uniq(graphElements.map(
                graphElement => graphElement.category.replace(/(?:Collection|Graph)\/(.*)/, 'has-$1-element').toLowerCase()
            )),
            classNames               = [
                'content-box', 'graphics', 'rel', `metric-${module}`,
                ...graphNames
            ],
            { sorts } = this.getPreferences();

        return this.hasGraph() ? (
            <Element
                key="metric"
                className={makeStrClassName(classNames)}
                element={this.getGraphLayout()}
                parentLabel={label}
                model={model}
                context={context}
                margin={10}
                padding={padding}
                parameters={filtersForMetrics}
                registerCallbacks={this.registerCallbacks}
                registerActions={this.registerGraphCollectionActions}
                onDataLoaded={this.onGraphsDataLoaded}
                onUpdateFilters={this.onUpdateMetricsFilters}
                navigateTo={navigateTo}
                bookmarks={bookmarks}
                tags={tags}
                filtersByMetrics={filtersByMetrics}
                fixedBySelection={fixedBySelection}
                disableExpand={networkGraphElements.length > 0}
                firstCall={firstCall}
                sort={sorts}
                disableSort
            />
        ) : null;
    }

    /**
     * Get element type ( analyse or dataset)
     *
     * @returns string
     */
    getElementType() {
        const { element } = this.props,
            { category } = element;

        return category === 'Area/Analyse' ? 'analyse' : 'dataset';
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                model,
            }                       = this.props,
            { metricData,
                filters: filtersDef
            }                       = this.state,
            elementType             = this.getElementType(),
            listMustBeRendered      = elementType !== 'analyse' || (!this.hasGraph() || metricData);

        // If there's no metrics or filters definitions
        if (!model || !filtersDef) {
            return false;
        }

        return (
            <>
                {this.renderMeta()}
                {this.renderGuide()}
                {this.renderHelpNetwork()}
                <Col
                    className={`${elementType} analyse-dataset`}
                >
                    <Row>
                        <Col>
                            {this.renderGraphLayout()}
                            {this.renderHelpBtn()}

                            <Row className="content-box collection">
                                {
                                    // If is a Dataset it can be generated in parallel with graphics generation
                                    // If is an Analyse, some graphs may have preset filters and then applied to the list.
                                    listMustBeRendered ? this.renderCollection() : null
                                }
                            </Row>
                        </Col>
                        <Col
                            label="Use display options to refine data visualization"
                            className="content-box filters sticky"
                        >
                            {this.renderFilters()}
                        </Col>
                    </Row>
                </Col>
            </>
        );
    }

}

Analyse.propTypes = {
    bookmarks                            : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    getNodePreferences                   : PropTypes.func,
    storeNodePreferences                 : PropTypes.func,
    color                                : PropTypes.any,
    getDefaultTimeframeFromAnalyseElement: PropTypes.func,
    height                               : PropTypes.number,
    help                                 : PropTypes.any,
    isSearchMustBeRefreshed              : PropTypes.func,
    learnKnowledge                       : PropTypes.func.isRequired,
    module                               : PropTypes.string.isRequired,
    navigateTo                           : PropTypes.func,
    refreshQuery                         : PropTypes.func,
    registerCallbacks                    : PropTypes.func,
    getListResource                      : PropTypes.func,
    emitEvent                            : PropTypes.func,
    resources                            : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    tags                                 : PropTypes.any,
    width                                : PropTypes.number,
    elements                             : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    entities                             : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    model                                : PropTypes.object,
    node                                 : PropTypes.shape({
        modules   : PropTypes.array,
        module    : PropTypes.string,
        urlsParams: PropTypes.object,
    }),
    context: PropTypes.shape({
        id  : PropTypes.any,
        type: PropTypes.string
    }),
    element: PropTypes.shape({
        key          : PropTypes.string,
        label        : PropTypes.any,
        category     : PropTypes.string,
        configuration: PropTypes.shape({
            dataset      : PropTypes.any,
            crunchbase   : PropTypes.bool,
            fullPageGraph: PropTypes.bool,
            graphPadding : PropTypes.number,
            elements     : PropTypes.shape({
                graph: PropTypes.any,
                list : PropTypes.any
            })
        }),
    }),
};

Analyse.defaultProps = {
    height: 0,
    width : 0
};

/**
* Bind the store to to component
*/
const mapStateToProps = (state) => {
    return {
        knowledge: state.getIn('knowledge'),
        resource : state.getIn('resource'),
    };
};

export default connect(mapStateToProps, {
    getNodePreferences,
    storeNodePreferences,
    refreshQuery,
    isSearchMustBeRefreshed,
    learnKnowledge: learn,
    emitEvent,
})(Analyse);
