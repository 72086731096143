/**
 * Main Insight layout
 *
 * @return Component
 */
import _                                    from 'lodash';
import React, { PureComponent }             from 'react';
import ImmutablePropTypes                   from 'react-immutable-proptypes';
import { connect }                          from 'react-redux';
import { withRouter, Link }                 from 'react-router-dom';
import PropTypes                            from 'prop-types';
import { capitalize }                       from 'utils/text';
import { Row }                              from 'helpers/FlexLayout';
import { Popover, Tooltip, Modal, Button }  from 'antd';
import Sentence                             from 'helpers/Search/Sentence';
import InsightSearch                        from 'helpers/Search/InsightSearch';
import Edit                                 from 'helpers/Search/Edit';
import View                                 from 'helpers/Search/View';
import ModelAction                          from 'helpers/Action/Model';
import { Icon }                             from 'helpers';
import Report                               from 'helpers/Report.jsx';
import { openSettings }                     from 'store/actions/newsletters';
import { getUserViewItemFromModel }         from 'store/actions/userView';
import {
    getSearch, activateShortcut, navigateToSearch,
    openOrgunitsCollection, query, navigateTo
}                                           from 'store/actions/navigation';
import { fetchReport }                      from 'store/actions/navigation/report';
import { emitEvent }                        from 'store/actions/sockets';
import { learn }                            from 'store/actions/knowledge';
import {
    fetchPlatforms,
    getDefaultPlatformOfService,
}                                           from 'store/actions/auth/platforms.js';
import News                                 from './Header/News';
import QuickAccess                          from './Header/QuickAccess';
import UserMenu                             from './Header/UserMenu';
import SmartFilter                          from 'helpers/Search/Inputs/Smart/Filters';

import notification                         from 'helpers/notification.js';

// Import the header stylesheet
import './Header/assets/header.less';

/**
 * The header CC for Insight, this should be smart.
 *
 *
 */
class Header extends PureComponent {

    /**
    * Construct the Component
    *
    * @param object props The provided props
    *
    * @return void
    */
    constructor(props) {
        super(props);
        _.bindAll(this, 'updateSearchFromParams', 'registerEditSearchCallbacks', 'openTopicInsightSettings',
            'toggleSearch', 'openSearchOrgunits', 'updateSearchFromParams', 'onChangeFilter', 'renderBookmarkIcon');

        this.state = {
            showSearchTooltip  : false,
            editSearch         : false,
            editSearchCallbacks: {},
            settings           : {},
            queryIsLoading     : false,
        };
    }

    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        const {
            learnKnowledge, fetchReport, fetchPlatforms
        } = this.props;
        fetchReport();
        learnKnowledge(['filters', 'tags']).then(this.setState.bind(this));

        // Init fetching platforms and token
        fetchPlatforms();
    }


    /**
     * Update search settings state
     *
     * @return void
     */
    componentDidUpdate(prevProps) {
        const { search } = this.props;

        if (search !== prevProps.search) {
            const { settings } = search;
            this.setState({settings, queryIsLoading: false});
        }
    }


    /**
    * Get the search entity
    *
    * @return object
    */
    getSearchEntity() {
        const { search } = this.props;

        return search;
    }

    /**
    * Toggle the search hover state
    *
    * @return void
    */
    toggleSearch(open) {
        this.setState({ showSearchTooltip: open });
    }

    /**
    * Open the orgunit collection if it's necessary
    *
    * @return void
    */
    openSearchOrgunits() {
        const { openOrgunitsCollection } = this.props;

        openOrgunitsCollection();
    }


    /**
     * Check if is a insight search
     *
     * @returns boolean
     */
    isInsightSearch() {
        const entity     = this.getSearchEntity(),
            { settings } = entity,
            { weeks }    = settings || {};

        return weeks && weeks.length > 0;
    }


    /**
     * Check if is a express search
     *
     * @returns boolean
     */
    isExpressSearch() {
        const entity = this.getSearchEntity(),
            { mode } = entity;

        return mode === 'express';
    }

    /**
     * Check if is a intelligence search
     *
     * @returns boolean
     */
    isIntelligenceSearch() {
        const entity = this.getSearchEntity(),
            { mode } = entity;

        return mode === 'intelligence';
    }

    /**
     * Return the icon star
     *
     * @returns JSX
     */
    renderBookmarkIcon() {
        const { getUserViewItemFromModel } = this.props,
            userViewItem                   = getUserViewItemFromModel(this.getSearchEntity(), 'bookmark', { ignoreState: true }),
            isBookmarked                   = !!userViewItem;

        return (
            <Icon
                id="bookmarked"
                color={isBookmarked ? '#CA9A00' : '#10285d'}
                width={19}
            />
        );
    }

    /**
    * Render the bookmark button
    *
    * @return
    */
    renderBookmarkBtn() {
        const { search } = this.props;

        if (!search) {
            return false;
        }

        return (
            <ModelAction
                key={search.id}
                clickAction={['add_bookmark']}
                onlyActions={['bookmark']}
                entity={search}
                forceRender
                childrenRender={this.renderBookmarkIcon}
            />
        );
    }

    /**
    * Render smart sentence
    *
    * @return JSX
    */
    renderClassicSearch() {
        const entity = this.getSearchEntity();

        return (
            <div className="sentence">
                {entity.label}
            </div>
        );
    }


    /**
     * Get props for Sentence Component
     * @param {boolean} lookingFor
     * @param {boolean} notEllipsed
     * @returns object
     */
    getSentenceProps(lookingFor, notEllipsed) {
        const entity        = this.getSearchEntity(),
            isInsightSearch  = this.isInsightSearch();

        return {
            ...entity,
            lookingFor,
            notEllipsed,
            inputs : entity.concept,
            expand : entity.settings && entity.settings.expand,
            filters: entity.settings && {
                dateFilter           : entity.settings.dateFilter,
                countryFilter        : entity.settings.countryFilter,
                bookmarkFolderForTags: entity.settings.bookmarkFolderForTags,
            },
            stamp: isInsightSearch && <InsightSearch />,
        };
    }


    /**
    * Render sentence
    *
    * @return JSX
    */
    renderSentence(lookingFor = true, notEllipsed = false) {
        const { showSearchTooltip } = this.state,
            entity                  = this.getSearchEntity(),
            { mode }                = entity,
            isInsightSearch         = this.isInsightSearch(),
            SentenceComponant       = Sentence[mode],
            sentenceProps           = this.getSentenceProps(lookingFor, notEllipsed),
            sentence                = (
                <SentenceComponant
                    key="sentence"
                    {...sentenceProps}
                />
            ),
            sentenceNotEllipsed     = (
                <SentenceComponant
                    key="sentence-notEllipsed"
                    {...sentenceProps}
                    notEllipsed={true}
                />
            );

        return (
            <div className="sentence-content"
                onMouseEnter={() => this.toggleSearch(true)}
                onMouseLeave={() => this.toggleSearch(false)}
                onClick={() => this.setState({ editSearch: true })}
            >
                {
                    (mode === 'classic')
                        ? (
                            <>
                                {this.renderClassicSearch()}
                                {isInsightSearch && <InsightSearch />}
                            </>
                        )
                        : (
                            <Tooltip
                                overlayClassName="topbar-search-tooltip"
                                overlayStyle={{maxWidth: '800px'}}
                                title={[sentenceNotEllipsed]}
                                open={showSearchTooltip}
                            >
                                {sentence}
                            </Tooltip>
                        )
                }
            </div>
        );
    }

    /**
    * Is there some companies ?
    *
    * @return bool
    */
    companiesInputsCount() {
        const entity        = this.getSearchEntity(),
            { concept }     = entity,
            companiesInputs = concept.filter((input) => input.type === 'orgunit');

        return companiesInputs.length;
    }


    /**
     * Open topic insight setting
     *
     */
    openTopicInsightSettings() {
        const {
                openSettings, activateShortcut, newsletters
            } = this.props,
            newsletter       = newsletters.find((newsL) => newsL.key === 'topic-insights') || {};

        // Close tooltip
        this.setState({ editSearch: false });

        // Open newsletter settings
        newsletter.key && openSettings(newsletter.key);
        activateShortcut('newsletters', { key: newsletter.key});
    }

    /**
     * Get search classes
     *
     * @returns {array}
     */
    getSearchClasses() {
        const entity                   = this.getSearchEntity(),
            isInsightSearch            = this.isInsightSearch(),
            { settings }               = entity,
            { expand: boostIsEnabled } = settings || false,
            classes                    = ['search'];

        if (boostIsEnabled) {
            classes.push('boost-enabled');
        }

        if (isInsightSearch) {
            classes.push('is-insight');
        }

        return classes;
    }

    /**
    * Render profile button
    *
    * @return JSX
    */
    renderProfiles() {
        const companiesInputsCount = this.companiesInputsCount();

        if (!companiesInputsCount) {
            return (
                <Icon type="solution" className="disabled"
                    height={18}
                />
            );
        }

        return (
            <Tooltip title="View profile(s)">
                <Icon
                    type="solution"
                    height={18}
                    onClick={this.openSearchOrgunits}
                />
            </Tooltip>
        );
    }


    /**
    * Get query filters from knowledge
    *
    * @returns {array}
    */
    getQueryFilters() {
        const { bookmarksFoldersOuter } = this.props,
            { filters }                 = this.state,
            queryFilters                = filters?.toArray().filter(filter => filter.isQueryFilter),
            entity                      = this.getSearchEntity(),
            { settings }                = entity,
            { expand: boostIsEnabled } = settings || false,
            { bookmarkFolderForTags }   = settings || {},
            modelFromOuter              = bookmarkFolderForTags && bookmarksFoldersOuter?.get(bookmarkFolderForTags),
            settingsKeys                = _.keys(settings);

        // Add boost on option
        boostIsEnabled && queryFilters?.unshift({
            id  : 'expand',
            text: 'BOOST ON'
        });

        // Add folder option
        queryFilters?.push({
            id  : 'bookmarkFolderForTags',
            icon: modelFromOuter ? 'deleted-folder' : 'folder',
        });

        return _.filter(queryFilters, queryFilter => _.includes(settingsKeys, queryFilter.id));
    }

    /**
     *
     * @param {*} event
     */
    onChangeFilter(filterKey, value) {
        const {
                query, emitEvent
            }            = this.props,
            entity       = this.getSearchEntity(),
            { settings } = this.state,
            newSettings  = {
                ...settings,
                [filterKey]: value
            };

        this.setState({ settings: newSettings, queryIsLoading: true });

        emitEvent({
            module: 'header',
            name  : 'quick-search-filter',
            data  : {
                filterKey,
            }
        });

        query({
            ...entity,
            settings: newSettings
        });

        return;
    }

    /**
    * Render query filters
    *
    * @returns JSX
    */
    renderQueryFilters() {
        const { settings, queryIsLoading } = this.state || {},
            queryFilters                   = this.getQueryFilters();

        return settings && _.map(queryFilters, queryFilter => (
            <Popover
                key={`popover-${queryFilter.id}`}
                content={!queryIsLoading && (
                    <SmartFilter
                        className="popover-filter"
                        filterKey={queryFilter.id}
                        filterValue={settings[queryFilter.id]}
                        onChangeFilter={this.onChangeFilter}
                        options={{showDelete: true}}
                    />
                )}
                getPopupContainer={trigger => trigger?.parentNode?.parentNode?.parentNode}
                destroyTooltipOnHide
                placement="bottom"
            >
                <div key={queryFilter.id} className="filter-on">
                    {queryFilter?.text}
                    <Icon id={queryFilter.icon} height={14}
                        color="#008200"
                    />
                </div>
            </Popover>
        ));
    }


    /**
    * Render search
    *
    * @return void
    */
    renderSearch() {
        const entity                   = this.getSearchEntity(),
            isInsightSearch            = this.isInsightSearch(),
            classNames                 = this.getSearchClasses(),
            isEditableSearch           = !this.isIntelligenceSearch() && !this.isExpressSearch() && !isInsightSearch;

        if (!entity) {
            return false;
        }

        return (
            <div className={classNames.join(' ')}>
                <div className="sentence-wrapper">
                    {this.renderSentence()}
                    {this.renderQueryFilters()}
                </div>
                <div className="buttons">
                    <Tooltip title={isEditableSearch ? 'Edit search' : 'View search'}
                        onClick={() => this.setState({ editSearch: true })}
                    >
                        <Icon height={17}
                            type={isEditableSearch ? 'edit' : 'info-circle'}
                        />
                    </Tooltip>
                    {!isInsightSearch && this.renderBookmarkBtn()}
                    {this.renderProfiles()}
                </div>
                {this.renderSearchEditModal()}
            </div>
        );
    }

    /**
    * Update the current search with the one edited in popin
    *
    * @return void
    */
    updateSearchFromParams() {
        const { query }             = this.props,
            { editSearchCallbacks } = this.state,
            parameters              = editSearchCallbacks.submit(),
            { concept, mode }       = parameters || {},
            isSmart                 = mode === 'smart',
            hasConcept              = concept ? concept.filter((data) => data.type === 'concept').length > 0 : false;

        if(isSmart && !hasConcept) {
            notification({
                message    : 'Invalid search',
                description: 'Please add at least one concept to your search.',
                type       : 'error'

            });
            return;
        }
        this.setState({
            editSearch: false
        });

        // Perform Search
        query(
            parameters
        );
    }

    /**
    * Register edit search callback
    *
    * @param string action The action to Register
    * @param func   cb     The callback to trigger
    *
    * @return JSX
    */
    registerEditSearchCallbacks(action, cb) {
        const { editSearchCallbacks } = this.state;

        editSearchCallbacks[action] = cb;

        this.setState({
            editSearchCallbacks
        });
    }



    /**
    * Render the bookmark edit popin
    *
    * @return self
    */
    renderSearchEditModal() {       // eslint-disable-line  max-lines-per-function
        const entity         = this.getSearchEntity(),
            { mode }         = entity,
            { editSearch }   = this.state,
            isInsightSearch  = this.isInsightSearch(),
            isEditableSearch = !this.isIntelligenceSearch() && !this.isExpressSearch() && !isInsightSearch,
            /**
             * Cancel the search edition
             */
            onCancel              = () => this.setState({ editSearch: false }),
            ModelContentComponant = isEditableSearch ? Edit : View,
            footer                = [];

        if (!isEditableSearch) {
            footer.push(
                <Button key="cancel" type="primary"
                    onClick={onCancel}
                >
                    Ok
                </Button>
            );
        }

        if (!editSearch) {
            return false;
        }

        if (isEditableSearch) {
            footer.push(
                <Button key="submit" type="primary"
                    onClick={this.updateSearchFromParams}
                >
                    Launch my search
                </Button>
            );
        }

        if (!isEditableSearch) {
            footer.unshift(
                <div className="informations">
                    {isInsightSearch
                        ? (
                            <span>
                                Edit your search in the Insight Feed settings
                                <a onClick={this.openTopicInsightSettings}>
                                    &nbsp;
                                    <Icon
                                        className="settings"
                                        id="cog"
                                        height={12}
                                        color="#13285c"
                                        title="Monitor Searches settings"
                                    />
                                </a>
                            </span>
                        )
                        : `You can not edit this search in Innosabi Insight, please use Orbit ${capitalize(mode)}`}
                </div>
            );
        }

        return (
            <Modal
                className="search-edit-view insight-modal"
                title={isEditableSearch ? 'Edit search' : 'View search'}
                width={1300}
                onCancel={onCancel}
                footer={footer}
                open
            >
                <ModelContentComponant
                    search={entity}
                    sentence={!isEditableSearch && this.renderSentence(false, true)}
                    registerCallbacks={this.registerEditSearchCallbacks}
                />
            </Modal>
        );
    }


    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                search, getDefaultPlatformOfService
            }              = this.props,
            { tags }       = this.state,
            env            = process.env.ORBIT_ENV || false,
            displayEnv     = env && env !== 'PRD',
            yoomapPlatform = getDefaultPlatformOfService('yoomap'),
            { url }        = yoomapPlatform || {};

        return (
            <Row
                TagName="header"
                id="global-header"
                className="sticky"
            >
                <div className="logo left">
                    <Link to="/">
                        <Icon id="insight" alt="Insight"
                            width={140} height={40}
                        />
                    </Link>
                    {false && displayEnv && (
                        <span className={`env ${env}`}>{env}</span>
                    )}
                </div>
                {this.renderSearch()}
                <div className="right">
                    <QuickAccess {...this.props} tags={tags} />
                    <News />
                    <UserMenu
                        yoomapUrl={url}
                    />
                    <Report
                        search={search}
                    />
                </div>
            </Row>
        );
    }

}

/**
* Validate properties for current Component
*/
Header.propTypes = {
    search                     : PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    location                   : PropTypes.object.isRequired,
    learnKnowledge             : PropTypes.func,
    height                     : PropTypes.number,
    fetchReport                : PropTypes.func,
    openOrgunitsCollection     : PropTypes.func.isRequired,
    openSettings               : PropTypes.func,
    activateShortcut           : PropTypes.func,
    fetchPlatforms             : PropTypes.func,
    emitEvent                  : PropTypes.func,
    getDefaultPlatformOfService: PropTypes.func,
    getUserViewItemFromModel   : PropTypes.func,
    newsletters                : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
    query                      : PropTypes.func.isRequired,
    bookmarksFoldersOuter      : PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
};

Header.defaultProps = {
    height: 0
};

/**
 */
const mapStateToProps = (state) => {
    const search = getSearch(state),
        auth     = state.get('auth');

    return {
        search               : search || false,
        bookmarksFoldersOuter: state.getIn(['userView', 'bookmark_folder', 'outerModels']),
        newsletters          : state.get('newsletters').map,
        newslettersState     : state.get('newsletters').state,
        platforms            : auth.get('platforms'),
    };
};

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    fetchReport,
    query,
    navigateTo,
    navigateToSearch,
    openSettings,
    activateShortcut,
    learnKnowledge: learn,
    getUserViewItemFromModel,
    openOrgunitsCollection,
    fetchPlatforms,
    getDefaultPlatformOfService,
    emitEvent,
})(withRouter(Header));
