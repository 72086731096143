import _                                     from 'lodash';

// Root SVG
import  academics                from    './assets/academics.svg';
import  collectionretract        from    './assets/collection-retract.svg';
import  handholdingupamagnifier  from    './assets/hand-holding-up-a-magnifier.svg';
import  error                    from    './assets/error.svg';
import  squareAdd                from    './assets/square-add.svg';
import  leaders                  from    './assets/leaders.svg';
import  retractarrow             from    './assets/retract-arrow.svg';
import  rightarrow               from    './assets/right-arrow.svg';
import  expandarrow              from    './assets/expand-arrow.svg';
import  options                  from    './assets/options.svg';
import  info                     from    './assets/info.svg';
import  infoSolid                from    './assets/infoSolid.svg';
import  infoBubble               from    './assets/infoBubble.svg';
import  history                  from    './assets/history.svg';
import  world                    from    './assets/world.svg';
import  partOf                   from    './assets/part-of.svg';
import  acquiredBy               from    './assets/acquired-by.svg';
import  goBack                   from    './assets/go-back.svg';
import  close                    from    './assets/close.svg';
import  cog                      from    './assets/cog.svg';
import  target                   from    './assets/target.svg';
import  fit                      from    './assets/fit.svg';
import  magnify                  from    './assets/magnify.svg';
import  orbitExpress             from    './assets/orbit-express.svg';
import  orbitIntelligence        from    './assets/orbit-intelligence.svg';
import  sortAscending            from    './assets/sort-ascending.svg';
import  sortDescending           from    './assets/sort-descending.svg';
import  innovationNewsletter     from    './assets/innovation-newsletter.svg';
import  insight                  from    './assets/insight.svg';
import  logoInsight              from    './assets/logo-insight.svg';
import  logoInsightWithout       from    './assets/logo-insight-without-background.svg';
import  rotateRight              from    './assets/rotate-right.svg';
import  rotateLeft               from    './assets/rotate-left.svg';
import  warning                  from    './assets/warning.svg';

import  folder                   from    './assets/folder.svg';
import  deletedFolder            from    './assets/deleted-folder.svg';
import  bookmark                 from    './assets/bookmark.svg';
import  bookmarkOutlined         from    './assets/bookmark-outlined.svg';
import  account                  from    './assets/account.svg';
import  medal                    from    './assets/medal.svg';
import  medalOutlined            from    './assets/medal-outlined.svg';
import  edit                     from    './assets/edit.svg';

import  monitored                from    './assets/monitored.svg';
import  bookmarked               from    './assets/bookmarked.svg';
import  bookmarkedOutlined       from    './assets/bookmarked-outlined.svg';
import  holder                   from    './assets/holder.svg';
import  plus                     from    './assets/plus.svg';
import  bubbleCheck              from    './assets/bubble-check.svg';
import  bubbleWarning            from    './assets/bubble-warning.svg';
import  ai                       from    './assets/ai.svg';
import  copy                     from    './assets/copy.svg';
import  listView                 from    './assets/list-view.svg';
import  detailedListView         from    './assets/detailed-list-view.svg';


// Home SVG
import  onboarding               from    './assets/home/onboarding.svg';
import  breakingNews             from    './assets/home/breaking-news.svg';
import  lastSearches             from    './assets/home/last-searches.svg';
import  rss                      from    './assets/home/rss.svg';
import  assignement              from    './assets/home/assignement.svg';
import  movie                    from    './assets/home/movie.svg';
import  study                    from    './assets/home/study.svg';
import  infoBubbleHome           from    './assets/home/infoBubble.svg';


// A import  orgunitPng               from    './assets/orgunit.png';
import  document                 from    './assets/entities/document.svg';
import  megaphone                from    './assets/megaphone.svg';
import  megaphoneRedDot          from    './assets/megaphone-red-dot.svg';

// Graph SVG
import  trendArrow               from    './assets/graph/trendArrow.svg';
import  wipe                     from    './assets/graph/wipe.svg';

// Shortcuts SVG
import  clipboardShortcut        from    './assets/shortcuts/clipboard.svg';
import  clipboardAddShortcut     from    './assets/shortcuts/clipboard-add.svg';
import  emptyClipboard           from    './assets/shortcuts/empty-clipboard.svg';
import  companiesShortcut        from    './assets/shortcuts/companies.svg';
import  searchesShortcut         from    './assets/shortcuts/searches.svg';
import  newslettersShortcut      from    './assets/shortcuts/newsletters.svg';
import  downloadsShortcut        from    './assets/shortcuts/downloads.svg';
import  reportsShortcut          from    './assets/shortcuts/reports.svg';
import  bookmarksShortcut        from    './assets/shortcuts/bookmarks.svg';
import  exportRawData            from     './assets/shortcuts/export-raw-data.svg';

// File type
import  binType                  from    './assets/file-type/bin.svg';
import  pngType                  from    './assets/file-type/png.svg';
import  pdfType                  from    './assets/file-type/pdf.svg';
import  pptType                  from    './assets/file-type/ppt.svg';
import  xlsType                  from    './assets/file-type/xls.svg';
import  xlsxType                 from    './assets/file-type/xlsx.svg';
import  csvType                  from    './assets/file-type/csv.svg';
import  zipType                  from    './assets/file-type/zip.svg';

//  Actions
import  expandAction             from    './assets/actions/expand.svg';
import  packageAction            from    './assets/actions/package.svg';
import  link                     from    './assets/actions/link.svg';
import  deleteAction             from    './assets/actions/delete.svg';
import  refresh                  from    './assets/actions/refresh.svg';
import  send                     from    './assets/actions/send.svg';
import  signIn                   from    './assets/actions/sign-in.svg';
import  filter                   from    './assets/actions/filter.svg';
import  moveAction               from    './assets/actions/move.svg';
import  rocket                   from    './assets/actions/rocket.svg';
import  rocketManage             from    './assets/actions/rocket-manage.svg';

// Graph shape SVG
import  incremental              from    './assets/graph/shape/incremental.svg';
import  disruptive               from    './assets/graph/shape/disruptive.svg';
import  academic                 from    './assets/graph/shape/academic.svg';
import  industrial               from    './assets/graph/shape/industrial.svg';
import  domestic                 from    './assets/graph/shape/domestic.svg';
import  worldwide                from    './assets/graph/shape/worldwide.svg';
import  littleNetwork            from    './assets/graph/shape/little-network.svg';
import  mediumNetwork            from    './assets/graph/shape/medium-network.svg';
import  bigNetwork               from    './assets/graph/shape/big-network.svg';
import  localStrategy            from    './assets/graph/shape/market-strategy/local-strategy.png';
import  mainStrategy             from    './assets/graph/shape/market-strategy/main-strategy.png';
import  globalStrategy           from    './assets/graph/shape/market-strategy/global-strategy.png';
import  academicAndIndustrial    from    './assets/graph/shape/academic-and-industrial.svg';

// Entites
import query                     from    './assets/entities/query.svg';
import scidoc                    from    './assets/entities/scidoc.svg';
import project                   from    './assets/entities/project.svg';
import clinicaltrial             from    './assets/entities/clinicaltrial.svg';
import patent                    from    './assets/entities/patent.svg';
import webdoc                    from    './assets/entities/webdoc.svg';
import expert                    from    './assets/entities/expert.svg';
import people                    from    './assets/entities/people.svg';
import university                from    './assets/entities/university.svg';
import orgunit                   from    './assets/entities/orgunit.svg';
import orgunits                  from    './assets/entities/orgunits.svg';
import news                      from    './assets/entities/news.svg';

// Graph RaceLine SVG
import  winner                  from    './assets/graph/race-line/winner.svg';
import  featuredMarker          from    './assets/graph/race-line/star.svg';

// Graph Bet SVG
import  token                   from    './assets/graph/bet/token.svg';

// No data icons
import  stackedTokens           from    './assets/nodata/stacked-tokens.svg';
import  nodataClinicaltrial     from    './assets/nodata/clinical-trial.svg';

// Technical Domains
import  tc_TEXTILE_AND_PAPER_MACHINES            from './assets/technical-domains/TEXTILE AND PAPER MACHINES.svg';
import  tc_COMPUTER_TECHNOLOGY                   from './assets/technical-domains/COMPUTER TECHNOLOGY.svg';
import  tc_OPTICS                                from './assets/technical-domains/OPTICS.svg';
import  tc_TELECOMMUNICATIONS                    from './assets/technical-domains/TELECOMMUNICATIONS.svg';
import  tc_HANDLING                              from './assets/technical-domains/HANDLING.svg';
import  tc_AUDIO_VISUAL_TECHNOLOGY               from './assets/technical-domains/AUDIO-VISUAL TECHNOLOGY.svg';
import  tc_CONTROL                               from './assets/technical-domains/CONTROL.svg';
import  tc_OTHER_SPECIAL_MACHINES                from './assets/technical-domains/OTHER SPECIAL MACHINES.svg';
import  tc_MEASUREMENT                           from './assets/technical-domains/MEASUREMENT.svg';
import  tc_IT_METHODS_FOR_MANAGEMENT             from './assets/technical-domains/IT METHODS FOR MANAGEMENT.svg';
import  tc_ELECTRICAL_MACHINERY_APPARATUS_ENERGY from './assets/technical-domains/ELECTRICAL MACHINERY, APPARATUS, ENERGY.svg';
import  tc_DIGITAL_COMMUNICATION                 from './assets/technical-domains/DIGITAL COMMUNICATION.svg';
import  tc_OTHER_CONSUMER_GOODS                  from './assets/technical-domains/OTHER CONSUMER GOODS.svg';
import  tc_BASIC_MATERIALS_CHEMISTRY             from './assets/technical-domains/BASIC MATERIALS CHEMISTRY.svg';
import  tc_SURFACE_TECHNOLOGY_COATING            from './assets/technical-domains/SURFACE TECHNOLOGY, COATING.svg';
import  tc_MEDICAL_TECHNOLOGY                    from './assets/technical-domains/MEDICAL TECHNOLOGY.svg';
import  tc_MACHINE_TOOLS                         from './assets/technical-domains/MACHINE TOOLS.svg';
import  tc_SEMICONDUCTORS                        from './assets/technical-domains/SEMICONDUCTORS.svg';
import  tc_MECHANICAL_ELEMENTS                   from './assets/technical-domains/MECHANICAL ELEMENTS.svg';
import  tc_MATERIALS_METALLURGY                  from './assets/technical-domains/MATERIALS, METALLURGY.svg';
import  tc_CHEMICAL_ENGINEERING                  from './assets/technical-domains/CHEMICAL ENGINEERING.svg';
import  tc_MACROMOLECULAR_CHEMISTRY_POLYMERS     from './assets/technical-domains/MACROMOLECULAR CHEMISTRY, POLYMERS.svg';
import  tc_FURNITURE_GAMES                       from './assets/technical-domains/FURNITURE, GAMES.svg';
import  tc_TRANSPORT                             from './assets/technical-domains/TRANSPORT.svg';
import  tc_CIVIL_ENGINEERING                     from './assets/technical-domains/CIVIL ENGINEERING.svg';
import  tc_BASIC_COMMUNICATION_PROCESSES         from './assets/technical-domains/BASIC COMMUNICATION PROCESSES.svg';
import  tc_ENGINES_PUMPS_TURBINES                from './assets/technical-domains/ENGINES, PUMPS, TURBINES.svg';
import  tc_ANALYSIS_OF_BIOLOGICAL_MATERIALS      from './assets/technical-domains/ANALYSIS OF BIOLOGICAL MATERIALS.svg';
import  tc_ENVIRONMENTAL_TECHNOLOGY              from './assets/technical-domains/ENVIRONMENTAL TECHNOLOGY.svg';
import  tc_ORGANIC_FINE_CHEMISTRY                from './assets/technical-domains/ORGANIC FINE CHEMISTRY.svg';
import  tc_THERMAL_PROCESSES_AND_APPARATUS       from './assets/technical-domains/THERMAL PROCESSES AND APPARATUS.svg';
import  tc_BIOTECHNOLOGY                         from './assets/technical-domains/BIOTECHNOLOGY.svg';
import  tc_FOOD_CHEMISTRY                        from './assets/technical-domains/FOOD CHEMISTRY.svg';
import  tc_MICRO_STRUCTURE_AND_NANO_TECHNOLOGY   from './assets/technical-domains/MICRO-STRUCTURE AND NANO-TECHNOLOGY.svg';
import  tc_PHARMACEUTICALS                       from './assets/technical-domains/PHARMACEUTICALS.svg';

// KPI SVG
import  buzz                    from    './assets/kpi/buzz.svg';
import  experience              from    './assets/kpi/experience.svg';
import  momentum                from    './assets/kpi/momentum.svg';
import  network                 from    './assets/kpi/network.svg';
import  patentactivity          from    './assets/kpi/patent_activity.svg';
import  scientificactivity      from    './assets/kpi/scientific_activity.svg';

// ID CARDS SVG
import  corporateactivity               from    './assets/id-cards/corporate-activity.svg';
import  idcard                          from    './assets/id-cards/idcard.svg';
import  lastnews                        from    './assets/id-cards/last-news.svg';
import  linkedin                        from    './assets/id-cards/linkedin.svg';
import  marker                          from    './assets/id-cards/marker.svg';
import  networkinfluence                from    './assets/id-cards/network-influence.svg';
import  patentingactivity               from    './assets/id-cards/patenting-activity.svg';
import  presentation                    from    './assets/id-cards/presentation.svg';
import  scientificactivityIdcards       from    './assets/id-cards/scientific-activity.svg';
import  twitter                         from    './assets/id-cards/twitter.svg';
import  web                             from    './assets/id-cards/web.svg';
import  why                             from    './assets/id-cards/why.svg';
import  email                           from    './assets/id-cards/email.svg';
import  team                            from    './assets/id-cards/team.svg';
import  revenueRange                    from    './assets/id-cards/revenue-range.svg';
import  foundedDate                     from    './assets/id-cards/founded-date.svg';

// USER MENU SVG
import  orbitStartup                    from    './assets/user-menu/orbit-startup.svg';

// USER MENU PNG
import  consultingnavy                  from    './assets/user-menu/consulting-navy.png';
import  datascopenavy                   from    './assets/user-menu/data-scope-navy.png';
import  innovationservicesnavy          from    './assets/user-menu/innovation-services-navy.png';
import  productsupportnavy              from    './assets/user-menu/product-support-navy.png';
import  questelacademynavy              from    './assets/user-menu/questel-academy-navy.png';
import  consentmanagementnavy           from    './assets/user-menu/consent-management-navy.png';

// Countries flags PNG
import  countryAD   from    './assets/countries-96x64/AD.png';
import  countryAE   from    './assets/countries-96x64/AE.png';
import  countryAF   from    './assets/countries-96x64/AF.png';
import  countryAG   from    './assets/countries-96x64/AG.png';
import  countryAI   from    './assets/countries-96x64/AI.png';
import  countryAL   from    './assets/countries-96x64/AL.png';
import  countryAM   from    './assets/countries-96x64/AM.png';
import  countryAN   from    './assets/countries-96x64/AN.png';
import  countryAO   from    './assets/countries-96x64/AO.png';
import  countryAQ   from    './assets/countries-96x64/AQ.png';
import  countryAR   from    './assets/countries-96x64/AR.png';
import  countryAS   from    './assets/countries-96x64/AS.png';
import  countryAT   from    './assets/countries-96x64/AT.png';
import  countryAU   from    './assets/countries-96x64/AU.png';
import  countryAW   from    './assets/countries-96x64/AW.png';
import  countryAX   from    './assets/countries-96x64/AX.png';
import  countryAZ   from    './assets/countries-96x64/AZ.png';
import  countryBA   from    './assets/countries-96x64/BA.png';
import  countryBB   from    './assets/countries-96x64/BB.png';
import  countryBD   from    './assets/countries-96x64/BD.png';
import  countryBE   from    './assets/countries-96x64/BE.png';
import  countryBF   from    './assets/countries-96x64/BF.png';
import  countryBG   from    './assets/countries-96x64/BG.png';
import  countryBH   from    './assets/countries-96x64/BH.png';
import  countryBI   from    './assets/countries-96x64/BI.png';
import  countryBJ   from    './assets/countries-96x64/BJ.png';
import  countryBL   from    './assets/countries-96x64/BL.png';
import  countryBM   from    './assets/countries-96x64/BM.png';
import  countryBN   from    './assets/countries-96x64/BN.png';
import  countryBO   from    './assets/countries-96x64/BO.png';
import  countryBQ   from    './assets/countries-96x64/BQ.png';
import  countryBR   from    './assets/countries-96x64/BR.png';
import  countryBS   from    './assets/countries-96x64/BS.png';
import  countryBT   from    './assets/countries-96x64/BT.png';
import  countryBV   from    './assets/countries-96x64/BV.png';
import  countryBW   from    './assets/countries-96x64/BW.png';
import  countryBY   from    './assets/countries-96x64/BY.png';
import  countryBZ   from    './assets/countries-96x64/BZ.png';
import  countryCA   from    './assets/countries-96x64/CA.png';
import  countryCC   from    './assets/countries-96x64/CC.png';
import  countryCD   from    './assets/countries-96x64/CD.png';
import  countryCF   from    './assets/countries-96x64/CF.png';
import  countryCG   from    './assets/countries-96x64/CG.png';
import  countryCH   from    './assets/countries-96x64/CH.png';
import  countryCI   from    './assets/countries-96x64/CI.png';
import  countryCK   from    './assets/countries-96x64/CK.png';
import  countryCL   from    './assets/countries-96x64/CL.png';
import  countryCM   from    './assets/countries-96x64/CM.png';
import  countryCN   from    './assets/countries-96x64/CN.png';
import  countryCO   from    './assets/countries-96x64/CO.png';
import  countryCR   from    './assets/countries-96x64/CR.png';
import  countryCU   from    './assets/countries-96x64/CU.png';
import  countryCV   from    './assets/countries-96x64/CV.png';
import  countryCW   from    './assets/countries-96x64/CW.png';
import  countryCX   from    './assets/countries-96x64/CX.png';
import  countryCY   from    './assets/countries-96x64/CY.png';
import  countryCZ   from    './assets/countries-96x64/CZ.png';
import  countryDE   from    './assets/countries-96x64/DE.png';
import  countryDJ   from    './assets/countries-96x64/DJ.png';
import  countryDK   from    './assets/countries-96x64/DK.png';
import  countryDM   from    './assets/countries-96x64/DM.png';
import  countryDO   from    './assets/countries-96x64/DO.png';
import  countryDZ   from    './assets/countries-96x64/DZ.png';
import  countryEC   from    './assets/countries-96x64/EC.png';
import  countryEE   from    './assets/countries-96x64/EE.png';
import  countryEG   from    './assets/countries-96x64/EG.png';
import  countryEH   from    './assets/countries-96x64/EH.png';
import  countryEP   from    './assets/countries-96x64/EP.png';
import  countryER   from    './assets/countries-96x64/ER.png';
import  countryES   from    './assets/countries-96x64/ES.png';
import  countryET   from    './assets/countries-96x64/ET.png';
import  countryFI   from    './assets/countries-96x64/FI.png';
import  countryFJ   from    './assets/countries-96x64/FJ.png';
import  countryFK   from    './assets/countries-96x64/FK.png';
import  countryFM   from    './assets/countries-96x64/FM.png';
import  countryFO   from    './assets/countries-96x64/FO.png';
import  countryFR   from    './assets/countries-96x64/FR.png';
import  countryGA   from    './assets/countries-96x64/GA.png';
import  countryGB   from    './assets/countries-96x64/GB.png';
import  countryGD   from    './assets/countries-96x64/GD.png';
import  countryGE   from    './assets/countries-96x64/GE.png';
import  countryGF   from    './assets/countries-96x64/GF.png';
import  countryGG   from    './assets/countries-96x64/GG.png';
import  countryGH   from    './assets/countries-96x64/GH.png';
import  countryGI   from    './assets/countries-96x64/GI.png';
import  countryGL   from    './assets/countries-96x64/GL.png';
import  countryGM   from    './assets/countries-96x64/GM.png';
import  countryGN   from    './assets/countries-96x64/GN.png';
import  countryGP   from    './assets/countries-96x64/GP.png';
import  countryGQ   from    './assets/countries-96x64/GQ.png';
import  countryGR   from    './assets/countries-96x64/GR.png';
import  countryGS   from    './assets/countries-96x64/GS.png';
import  countryGT   from    './assets/countries-96x64/GT.png';
import  countryGU   from    './assets/countries-96x64/GU.png';
import  countryGW   from    './assets/countries-96x64/GW.png';
import  countryGY   from    './assets/countries-96x64/GY.png';
import  countryHK   from    './assets/countries-96x64/HK.png';
import  countryHM   from    './assets/countries-96x64/HM.png';
import  countryHN   from    './assets/countries-96x64/HN.png';
import  countryHR   from    './assets/countries-96x64/HR.png';
import  countryHT   from    './assets/countries-96x64/HT.png';
import  countryHU   from    './assets/countries-96x64/HU.png';
import  countryID   from    './assets/countries-96x64/ID.png';
import  countryIE   from    './assets/countries-96x64/IE.png';
import  countryIL   from    './assets/countries-96x64/IL.png';
import  countryIM   from    './assets/countries-96x64/IM.png';
import  countryIN   from    './assets/countries-96x64/IN.png';
import  countryIO   from    './assets/countries-96x64/IO.png';
import  countryIQ   from    './assets/countries-96x64/IQ.png';
import  countryIR   from    './assets/countries-96x64/IR.png';
import  countryIS   from    './assets/countries-96x64/IS.png';
import  countryIT   from    './assets/countries-96x64/IT.png';
import  countryJE   from    './assets/countries-96x64/JE.png';
import  countryJM   from    './assets/countries-96x64/JM.png';
import  countryJO   from    './assets/countries-96x64/JO.png';
import  countryJP   from    './assets/countries-96x64/JP.png';
import  countryKE   from    './assets/countries-96x64/KE.png';
import  countryKG   from    './assets/countries-96x64/KG.png';
import  countryKH   from    './assets/countries-96x64/KH.png';
import  countryKI   from    './assets/countries-96x64/KI.png';
import  countryKM   from    './assets/countries-96x64/KM.png';
import  countryKN   from    './assets/countries-96x64/KN.png';
import  countryKP   from    './assets/countries-96x64/KP.png';
import  countryKR   from    './assets/countries-96x64/KR.png';
import  countryKW   from    './assets/countries-96x64/KW.png';
import  countryKY   from    './assets/countries-96x64/KY.png';
import  countryKZ   from    './assets/countries-96x64/KZ.png';
import  countryLA   from    './assets/countries-96x64/LA.png';
import  countryLB   from    './assets/countries-96x64/LB.png';
import  countryLC   from    './assets/countries-96x64/LC.png';
import  countryLI   from    './assets/countries-96x64/LI.png';
import  countryLK   from    './assets/countries-96x64/LK.png';
import  countryLR   from    './assets/countries-96x64/LR.png';
import  countryLS   from    './assets/countries-96x64/LS.png';
import  countryLT   from    './assets/countries-96x64/LT.png';
import  countryLU   from    './assets/countries-96x64/LU.png';
import  countryLV   from    './assets/countries-96x64/LV.png';
import  countryLY   from    './assets/countries-96x64/LY.png';
import  countryMA   from    './assets/countries-96x64/MA.png';
import  countryMC   from    './assets/countries-96x64/MC.png';
import  countryMD   from    './assets/countries-96x64/MD.png';
import  countryME   from    './assets/countries-96x64/ME.png';
import  countryMF   from    './assets/countries-96x64/MF.png';
import  countryMG   from    './assets/countries-96x64/MG.png';
import  countryMH   from    './assets/countries-96x64/MH.png';
import  countryMK   from    './assets/countries-96x64/MK.png';
import  countryML   from    './assets/countries-96x64/ML.png';
import  countryMM   from    './assets/countries-96x64/MM.png';
import  countryMN   from    './assets/countries-96x64/MN.png';
import  countryMO   from    './assets/countries-96x64/MO.png';
import  countryMP   from    './assets/countries-96x64/MP.png';
import  countryMQ   from    './assets/countries-96x64/MQ.png';
import  countryMR   from    './assets/countries-96x64/MR.png';
import  countryMS   from    './assets/countries-96x64/MS.png';
import  countryMT   from    './assets/countries-96x64/MT.png';
import  countryMU   from    './assets/countries-96x64/MU.png';
import  countryMV   from    './assets/countries-96x64/MV.png';
import  countryMW   from    './assets/countries-96x64/MW.png';
import  countryMX   from    './assets/countries-96x64/MX.png';
import  countryMY   from    './assets/countries-96x64/MY.png';
import  countryMZ   from    './assets/countries-96x64/MZ.png';
import  countryNA   from    './assets/countries-96x64/NA.png';
import  countryNC   from    './assets/countries-96x64/NC.png';
import  countryNE   from    './assets/countries-96x64/NE.png';
import  countryNF   from    './assets/countries-96x64/NF.png';
import  countryNG   from    './assets/countries-96x64/NG.png';
import  countryNI   from    './assets/countries-96x64/NI.png';
import  countryNL   from    './assets/countries-96x64/NL.png';
import  countryNO   from    './assets/countries-96x64/NO.png';
import  countryNP   from    './assets/countries-96x64/NP.png';
import  countryNR   from    './assets/countries-96x64/NR.png';
import  countryNU   from    './assets/countries-96x64/NU.png';
import  countryNZ   from    './assets/countries-96x64/NZ.png';
import  countryOM   from    './assets/countries-96x64/OM.png';
import  countryPA   from    './assets/countries-96x64/PA.png';
import  countryPE   from    './assets/countries-96x64/PE.png';
import  countryPF   from    './assets/countries-96x64/PF.png';
import  countryPG   from    './assets/countries-96x64/PG.png';
import  countryPH   from    './assets/countries-96x64/PH.png';
import  countryPK   from    './assets/countries-96x64/PK.png';
import  countryPL   from    './assets/countries-96x64/PL.png';
import  countryPM   from    './assets/countries-96x64/PM.png';
import  countryPN   from    './assets/countries-96x64/PN.png';
import  countryPR   from    './assets/countries-96x64/PR.png';
import  countryPS   from    './assets/countries-96x64/PS.png';
import  countryPT   from    './assets/countries-96x64/PT.png';
import  countryPW   from    './assets/countries-96x64/PW.png';
import  countryPY   from    './assets/countries-96x64/PY.png';
import  countryQA   from    './assets/countries-96x64/QA.png';
import  countryRE   from    './assets/countries-96x64/RE.png';
import  countryRO   from    './assets/countries-96x64/RO.png';
import  countryRS   from    './assets/countries-96x64/RS.png';
import  countryRU   from    './assets/countries-96x64/RU.png';
import  countryRW   from    './assets/countries-96x64/RW.png';
import  countrySA   from    './assets/countries-96x64/SA.png';
import  countrySB   from    './assets/countries-96x64/SB.png';
import  countrySC   from    './assets/countries-96x64/SC.png';
import  countrySD   from    './assets/countries-96x64/SD.png';
import  countrySE   from    './assets/countries-96x64/SE.png';
import  countrySG   from    './assets/countries-96x64/SG.png';
import  countrySH   from    './assets/countries-96x64/SH.png';
import  countrySI   from    './assets/countries-96x64/SI.png';
import  countrySJ   from    './assets/countries-96x64/SJ.png';
import  countrySK   from    './assets/countries-96x64/SK.png';
import  countrySL   from    './assets/countries-96x64/SL.png';
import  countrySM   from    './assets/countries-96x64/SM.png';
import  countrySN   from    './assets/countries-96x64/SN.png';
import  countrySO   from    './assets/countries-96x64/SO.png';
import  countrySR   from    './assets/countries-96x64/SR.png';
import  countrySS   from    './assets/countries-96x64/SS.png';
import  countryST   from    './assets/countries-96x64/ST.png';
import  countrySV   from    './assets/countries-96x64/SV.png';
import  countrySX   from    './assets/countries-96x64/SX.png';
import  countrySY   from    './assets/countries-96x64/SY.png';
import  countrySZ   from    './assets/countries-96x64/SZ.png';
import  countryTC   from    './assets/countries-96x64/TC.png';
import  countryTD   from    './assets/countries-96x64/TD.png';
import  countryTF   from    './assets/countries-96x64/TF.png';
import  countryTG   from    './assets/countries-96x64/TG.png';
import  countryTH   from    './assets/countries-96x64/TH.png';
import  countryTJ   from    './assets/countries-96x64/TJ.png';
import  countryTK   from    './assets/countries-96x64/TK.png';
import  countryTL   from    './assets/countries-96x64/TL.png';
import  countryTM   from    './assets/countries-96x64/TM.png';
import  countryTN   from    './assets/countries-96x64/TN.png';
import  countryTO   from    './assets/countries-96x64/TO.png';
import  countryTR   from    './assets/countries-96x64/TR.png';
import  countryTT   from    './assets/countries-96x64/TT.png';
import  countryTV   from    './assets/countries-96x64/TV.png';
import  countryTW   from    './assets/countries-96x64/TW.png';
import  countryTZ   from    './assets/countries-96x64/TZ.png';
import  countryUA   from    './assets/countries-96x64/UA.png';
import  countryUG   from    './assets/countries-96x64/UG.png';
import  countryUK   from    './assets/countries-96x64/UK.png';
import  countryUM   from    './assets/countries-96x64/UM.png';
import  countryUS   from    './assets/countries-96x64/US.png';
import  countryUY   from    './assets/countries-96x64/UY.png';
import  countryUZ   from    './assets/countries-96x64/UZ.png';
import  countryVA   from    './assets/countries-96x64/VA.png';
import  countryVC   from    './assets/countries-96x64/VC.png';
import  countryVE   from    './assets/countries-96x64/VE.png';
import  countryVG   from    './assets/countries-96x64/VG.png';
import  countryVI   from    './assets/countries-96x64/VI.png';
import  countryVN   from    './assets/countries-96x64/VN.png';
import  countryVU   from    './assets/countries-96x64/VU.png';
import  countryWF   from    './assets/countries-96x64/WF.png';
import  countryWO   from    './assets/countries-96x64/WO.png';
import  countryWS   from    './assets/countries-96x64/WS.png';
import  countryYE   from    './assets/countries-96x64/YE.png';
import  countryYT   from    './assets/countries-96x64/YT.png';
import  countryZA   from    './assets/countries-96x64/ZA.png';
import  countryZM   from    './assets/countries-96x64/ZM.png';
import  countryZW   from    './assets/countries-96x64/ZW.png';

// Infografics
import dashboard from './assets/infographics/005-infographic-1.svg';

// Report
import reportLogo from './assets/report/reports.svg';
import reportSlideVisible from './assets/report/group-3.svg';
import reportSlideSelect from './assets/report/square-edit-outline-3.svg';
import reportviewGallery from './assets/report/view-gallery.svg';
import reportViewList from './assets/report/view-list.svg';


// Manage ids for imports
const importSVG = {
    '/': {
        academics,
        goBack,
        'collection-open'                : link,
        'collection-retract'             : collectionretract,
        'hand-holding-up-a-magnifier'    : handholdingupamagnifier,
        error,
        'square-add'                     : squareAdd,
        leaders,
        'retract-arrow'                  : retractarrow,
        'right-arrow'                    : rightarrow,
        'expand-arrow'                   : expandarrow,
        options,
        info,
        'info-solid'                     : infoSolid,
        'info-bubble'                    : infoBubble,
        history,
        close,
        magnify,
        cog,
        target,
        fit,
        'orbit-express'                  : orbitExpress,
        'orbit-intelligence'             : orbitIntelligence,
        'part-of'                        : partOf,
        'acquired-by'                    : acquiredBy,
        'sort-ascending'                 : sortAscending,
        'sort-descending'                : sortDescending,
        'innovation-newsletter'          : innovationNewsletter,
        insight,
        'logo-insight'                   : logoInsight,
        'logo-insight-without-background': logoInsightWithout,
        folder,
        'deleted-folder'                 : deletedFolder,
        bookmark,
        'bookmark-outlined'              : bookmarkOutlined,
        monitored,
        bookmarked,
        'bookmarked-outlined'            : bookmarkedOutlined,
        megaphone,
        account,
        medal,
        'medal-outlined'                 : medalOutlined,
        edit,
        'megaphone-red-dot'              : megaphoneRedDot,
        delete                           : deleteAction,
        'sign-in'                        : signIn,
        send,
        'rotate-right'                   : rotateRight,
        'rotate-left'                    : rotateLeft,
        holder,
        plus,
        move                             : moveAction,
        rocket,
        'rocket-manage'                  : rocketManage,
        warning,
        'bubble-check'                   : bubbleCheck,
        'bubble-warning'                 : bubbleWarning,
        world,
        ai,
        copy,
        'list-view'                      : listView,
        'detailed-list-view'             : detailedListView,
    },
    '/infographics/': {
        dashboard
    },
    '/graph/': {
        trendArrow,
        wipe,
    },
    '/home/': {
        onboarding,
        'breaking-news': breakingNews,
        'last-searches': lastSearches,
        rss,
        assignement,
        movie,
        study,
        infoBubbleHome,
    },
    '/graph/shape/': {
        incremental,
        disruptive,
        academic,
        industrial,
        domestic,
        worldwide,
        bigNetwork,
        mediumNetwork,
        littleNetwork,
        academicAndIndustrial,
    },
    '/entities/': {
        orgunit,
        query,
        scidoc,
        project,
        clinicaltrial,
        patent,
        webdoc,
        expert,
        people,
        university,
        orgunits,
        news,
        folder,
        bookmark_folder: folder,
    },
    '/graph/race-line/': {
        winner,
        featuredMarker
    },
    '/shortcuts/': {
        folder,
        clipboard        : clipboardShortcut,
        'clipboard-add'  : clipboardAddShortcut,
        'empty-clipboard': emptyClipboard,
        companies        : companiesShortcut,
        downloads        : downloadsShortcut,
        newsletters      : newslettersShortcut,
        searches         : searchesShortcut,
        reports          : reportsShortcut,
        bookmarks        : bookmarksShortcut,
        'export-raw-data': exportRawData,
    },
    '/file-type/': {
        bin : binType,
        png : pngType,
        pdf : pdfType,
        ppt : pptType,
        pptx: pptType,
        xls : xlsType,
        xlsx: xlsxType,
        csv : csvType,
        zip : zipType,
    },
    '/actions/': {
        expand : expandAction,
        package: packageAction,
        link,
        refresh,
        filter,
    },
    '/graph/bet/': {
        token,
    },
    '/nodata/': {
        stackedTokens,
        'clinical-trial': nodataClinicaltrial,
    },
    '/technical-domains/': {
        'TEXTILE AND PAPER MACHINES'             : tc_TEXTILE_AND_PAPER_MACHINES,
        'COMPUTER TECHNOLOGY'                    : tc_COMPUTER_TECHNOLOGY,
        OPTICS                                   : tc_OPTICS,
        TELECOMMUNICATIONS                       : tc_TELECOMMUNICATIONS,
        HANDLING                                 : tc_HANDLING,
        'AUDIO-VISUAL TECHNOLOGY'                : tc_AUDIO_VISUAL_TECHNOLOGY,
        CONTROL                                  : tc_CONTROL,
        'OTHER SPECIAL MACHINES'                 : tc_OTHER_SPECIAL_MACHINES,
        MEASUREMENT                              : tc_MEASUREMENT,
        'IT METHODS FOR MANAGEMENT'              : tc_IT_METHODS_FOR_MANAGEMENT,
        'ELECTRICAL MACHINERY, APPARATUS, ENERGY': tc_ELECTRICAL_MACHINERY_APPARATUS_ENERGY,
        'DIGITAL COMMUNICATION'                  : tc_DIGITAL_COMMUNICATION,
        'OTHER CONSUMER GOODS'                   : tc_OTHER_CONSUMER_GOODS,
        'BASIC MATERIALS CHEMISTRY'              : tc_BASIC_MATERIALS_CHEMISTRY,
        'SURFACE TECHNOLOGY, COATING'            : tc_SURFACE_TECHNOLOGY_COATING,
        'MEDICAL TECHNOLOGY'                     : tc_MEDICAL_TECHNOLOGY,
        'MACHINE TOOLS'                          : tc_MACHINE_TOOLS,
        SEMICONDUCTORS                           : tc_SEMICONDUCTORS,
        'MECHANICAL ELEMENTS'                    : tc_MECHANICAL_ELEMENTS,
        'MATERIALS, METALLURGY'                  : tc_MATERIALS_METALLURGY,
        'CHEMICAL ENGINEERING'                   : tc_CHEMICAL_ENGINEERING,
        'MACROMOLECULAR CHEMISTRY, POLYMERS'     : tc_MACROMOLECULAR_CHEMISTRY_POLYMERS,
        'FURNITURE, GAMES'                       : tc_FURNITURE_GAMES,
        TRANSPORT                                : tc_TRANSPORT,
        'CIVIL ENGINEERING'                      : tc_CIVIL_ENGINEERING,
        'BASIC COMMUNICATION PROCESSES'          : tc_BASIC_COMMUNICATION_PROCESSES,
        'ENGINES, PUMPS, TURBINES'               : tc_ENGINES_PUMPS_TURBINES,
        'ANALYSIS OF BIOLOGICAL MATERIALS'       : tc_ANALYSIS_OF_BIOLOGICAL_MATERIALS,
        'ENVIRONMENTAL TECHNOLOGY'               : tc_ENVIRONMENTAL_TECHNOLOGY,
        'ORGANIC FINE CHEMISTRY'                 : tc_ORGANIC_FINE_CHEMISTRY,
        'THERMAL PROCESSES AND APPARATUS'        : tc_THERMAL_PROCESSES_AND_APPARATUS,
        BIOTECHNOLOGY                            : tc_BIOTECHNOLOGY,
        'FOOD CHEMISTRY'                         : tc_FOOD_CHEMISTRY,
        'MICRO-STRUCTURE AND NANO-TECHNOLOGY'    : tc_MICRO_STRUCTURE_AND_NANO_TECHNOLOGY,
        PHARMACEUTICALS                          : tc_PHARMACEUTICALS,
    },
    '/kpi/': {
        buzz,
        experience,
        momentum,
        network,
        patent_activity    : patentactivity,
        scientific_activity: scientificactivity,
    },
    '/id-cards/': {
        'corporate-activity' : corporateactivity,
        idcard,
        'last-news'          : lastnews,
        linkedin,
        marker,
        'network-influence'  : networkinfluence,
        'patenting-activity' : patentingactivity,
        presentation,
        'scientific-activity': scientificactivityIdcards,
        twitter,
        web,
        why,
        email,
        team,
        'revenue-range'      : revenueRange,
        'founded-date'       : foundedDate,
    },
    '/report/': {
        reportLogo,
        reportSlideSelect,
        reportSlideVisible,
        reportviewGallery,
        reportViewList
    }

};

const importPNG = {
    '/': {
        // A orgunit            : orgunitPng,  // SVG exist => svg bypass the png
        document,
    },
    '/user-menu/': {
        consulting           : consultingnavy,
        'data-scope'         : datascopenavy,
        'innovation-services': innovationservicesnavy,
        'product-support'    : productsupportnavy,
        'questel-academy'    : questelacademynavy,
        'orbit-startup'      : orbitStartup,
        'consent-management' : consentmanagementnavy,
    },
    '/graph/shape/': {
        localStrategy,
        mainStrategy,
        globalStrategy,
    },
    '/countries-96x64/': {
        AD: countryAD,
        AE: countryAE,
        AF: countryAF,
        AG: countryAG,
        AI: countryAI,
        AL: countryAL,
        AM: countryAM,
        AN: countryAN,
        AO: countryAO,
        AQ: countryAQ,
        AR: countryAR,
        AS: countryAS,
        AT: countryAT,
        AU: countryAU,
        AW: countryAW,
        AX: countryAX,
        AZ: countryAZ,
        BA: countryBA,
        BB: countryBB,
        BD: countryBD,
        BE: countryBE,
        BF: countryBF,
        BG: countryBG,
        BH: countryBH,
        BI: countryBI,
        BJ: countryBJ,
        BL: countryBL,
        BM: countryBM,
        BN: countryBN,
        BO: countryBO,
        BQ: countryBQ,
        BR: countryBR,
        BS: countryBS,
        BT: countryBT,
        BV: countryBV,
        BW: countryBW,
        BY: countryBY,
        BZ: countryBZ,
        CA: countryCA,
        CC: countryCC,
        CD: countryCD,
        CF: countryCF,
        CG: countryCG,
        CH: countryCH,
        CI: countryCI,
        CK: countryCK,
        CL: countryCL,
        CM: countryCM,
        CN: countryCN,
        CO: countryCO,
        CR: countryCR,
        CU: countryCU,
        CV: countryCV,
        CW: countryCW,
        CX: countryCX,
        CY: countryCY,
        CZ: countryCZ,
        DE: countryDE,
        DJ: countryDJ,
        DK: countryDK,
        DM: countryDM,
        DO: countryDO,
        DZ: countryDZ,
        EC: countryEC,
        EE: countryEE,
        EG: countryEG,
        EH: countryEH,
        EP: countryEP,
        ER: countryER,
        ES: countryES,
        ET: countryET,
        FI: countryFI,
        FJ: countryFJ,
        FK: countryFK,
        FM: countryFM,
        FO: countryFO,
        FR: countryFR,
        GA: countryGA,
        GB: countryGB,
        GD: countryGD,
        GE: countryGE,
        GF: countryGF,
        GG: countryGG,
        GH: countryGH,
        GI: countryGI,
        GL: countryGL,
        GM: countryGM,
        GN: countryGN,
        GP: countryGP,
        GQ: countryGQ,
        GR: countryGR,
        GS: countryGS,
        GT: countryGT,
        GU: countryGU,
        GW: countryGW,
        GY: countryGY,
        HK: countryHK,
        HM: countryHM,
        HN: countryHN,
        HR: countryHR,
        HT: countryHT,
        HU: countryHU,
        ID: countryID,
        IE: countryIE,
        IL: countryIL,
        IM: countryIM,
        IN: countryIN,
        IO: countryIO,
        IQ: countryIQ,
        IR: countryIR,
        IS: countryIS,
        IT: countryIT,
        JE: countryJE,
        JM: countryJM,
        JO: countryJO,
        JP: countryJP,
        KE: countryKE,
        KG: countryKG,
        KH: countryKH,
        KI: countryKI,
        KM: countryKM,
        KN: countryKN,
        KP: countryKP,
        KR: countryKR,
        KW: countryKW,
        KY: countryKY,
        KZ: countryKZ,
        LA: countryLA,
        LB: countryLB,
        LC: countryLC,
        LI: countryLI,
        LK: countryLK,
        LR: countryLR,
        LS: countryLS,
        LT: countryLT,
        LU: countryLU,
        LV: countryLV,
        LY: countryLY,
        MA: countryMA,
        MC: countryMC,
        MD: countryMD,
        ME: countryME,
        MF: countryMF,
        MG: countryMG,
        MH: countryMH,
        MK: countryMK,
        ML: countryML,
        MM: countryMM,
        MN: countryMN,
        MO: countryMO,
        MP: countryMP,
        MQ: countryMQ,
        MR: countryMR,
        MS: countryMS,
        MT: countryMT,
        MU: countryMU,
        MV: countryMV,
        MW: countryMW,
        MX: countryMX,
        MY: countryMY,
        MZ: countryMZ,
        NA: countryNA,
        NC: countryNC,
        NE: countryNE,
        NF: countryNF,
        NG: countryNG,
        NI: countryNI,
        NL: countryNL,
        NO: countryNO,
        NP: countryNP,
        NR: countryNR,
        NU: countryNU,
        NZ: countryNZ,
        OM: countryOM,
        PA: countryPA,
        PE: countryPE,
        PF: countryPF,
        PG: countryPG,
        PH: countryPH,
        PK: countryPK,
        PL: countryPL,
        PM: countryPM,
        PN: countryPN,
        PR: countryPR,
        PS: countryPS,
        PT: countryPT,
        PW: countryPW,
        PY: countryPY,
        QA: countryQA,
        RE: countryRE,
        RO: countryRO,
        RS: countryRS,
        RU: countryRU,
        RW: countryRW,
        SA: countrySA,
        SB: countrySB,
        SC: countrySC,
        SD: countrySD,
        SE: countrySE,
        SG: countrySG,
        SH: countrySH,
        SI: countrySI,
        SJ: countrySJ,
        SK: countrySK,
        SL: countrySL,
        SM: countrySM,
        SN: countrySN,
        SO: countrySO,
        SR: countrySR,
        SS: countrySS,
        ST: countryST,
        SV: countrySV,
        SX: countrySX,
        SY: countrySY,
        SZ: countrySZ,
        TC: countryTC,
        TD: countryTD,
        TF: countryTF,
        TG: countryTG,
        TH: countryTH,
        TJ: countryTJ,
        TK: countryTK,
        TL: countryTL,
        TM: countryTM,
        TN: countryTN,
        TO: countryTO,
        TR: countryTR,
        TT: countryTT,
        TV: countryTV,
        TW: countryTW,
        TZ: countryTZ,
        UA: countryUA,
        UG: countryUG,
        UK: countryUK,
        UM: countryUM,
        US: countryUS,
        UY: countryUY,
        UZ: countryUZ,
        VA: countryVA,
        VC: countryVC,
        VE: countryVE,
        VG: countryVG,
        VI: countryVI,
        VN: countryVN,
        VU: countryVU,
        WF: countryWF,
        WO: countryWO,
        WS: countryWS,
        YE: countryYE,
        YT: countryYT,
        ZA: countryZA,
        ZM: countryZM,
        ZW: countryZW,
    }

};

export const svg = {};
export const png = {};

_.each(importSVG, (imports, folder) => {
    _.each(imports, (imp, id) => {
        svg[`${folder}${id}`] = imp;
    });
});

_.each(importPNG, (imports, folder) => {
    _.each(imports, (imp, id) => {
        png[`${folder}${id}`] = imp;
    });
});

export default { svg, png };
