/**
 * Insight capture module
 *
 * @return Component
 */
import _                              from 'lodash';
import React, { Component  }          from 'react';
import PropTypes                      from 'prop-types';
import ImmutablePropTypes             from 'react-immutable-proptypes';
import { connect }                    from 'react-redux';
import { learn }                      from 'store/actions/knowledge';
import { dataGet }                    from 'core/utils/api';
import Element                        from 'helpers/Element';

import './Capture/assets/capture.less';

/**
 * Element module.
 *
 */
class ElementManager extends Component {

    /**
    * Initialize the login form
    *
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'updateElements', 'registerCallbacks', 'triggerCallback');

        this.state = {
            elements : [],
            callbacks: {},
        };

        window.animationIsDisabled = () => true;
        window.isCapture           = () => true;
        window.triggerCallback     = this.triggerCallback;
    }

    /**
     * Trigger a regisred callback of elements
     *
     * @param {string} action
     * @returns
     */
    triggerCallback(elementId, action) {
        const { callbacks } = this.state;

        return callbacks[elementId]
            && callbacks[elementId].triggerCallback
            && callbacks[elementId].triggerCallback(action);
    }


    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerCallbacks(action, cb, element) {
        const { callbacks }           = this.state,
            /** Return a empty object */
            defaultCb               = () => {};

        if (!element) {
            return;
        }

        if (!callbacks[element.id]) {
            callbacks[element.id] = {};
        }

        callbacks[element.id][action] = cb || defaultCb;

        this.setState({ callbacks });
    }

    /**
    * When component is ready, load the orgunit
    */
    async componentDidMount() {
        const { learnKnowledge } = this.props;

        learnKnowledge(['tags']).then(
            this.setState.bind(this)
        );

        if (!_.isFunction(window.getCaptureData)) {
            return;
        }

        const captures       = await window.getCaptureData(),
            elements         = captures.map((capture) => capture.element),
            completeElements = await this.injectCompleteModels(elements) || [];

        this.setState({
            elements: completeElements  // Extract element from capture (file, element) object
        });

        document.body.classList.add('capture-element');
    }

    /**
    * When component has updated
    */
    componentDidUpdate() {
        document.body.classList.add('capture-element');
    }

    /**
    * Update elements from textarea
    *
    * @return self
    */
    async updateElements(e) {
        try {
            const { target } = e,
                captures         = JSON.parse(target.value),
                elements         = captures.map((capture) => capture.element) || [],
                completeElements = await this.injectCompleteModels(elements) || [];

            this.setState({
                elements: completeElements,
            });
        } catch (error) {
            console.log('error: ', error);
        }
    }

    /**
     * Recover and inject complete models
     */
    async injectCompleteModels(elements) {
        const elementsWithProps = elements.map((element) => {
                if (!element.props) {
                    element.props = element.properties;
                }
                return element;
            }),
            modelIds = elementsWithProps.map((element) => element.props.model.id) || [],
            request  = await dataGet('/entities', { data: { ids: modelIds }}), // Recover all models in once
            entities = request.body || [];

        return elementsWithProps.map((element) => {
            const modelId = element.props.model.id,
                model     = entities.find(
                    entity => (entity.entity?.id || entity.id) === modelId
                ).entity || element.props.model;

            return {
                ...element,
                properties: { ...element.properties, model },
                props     : { ...element.props,      model }
            };
        });
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        const {
                bookmarksStats,
                bookmarksList
            } = this.props,
            { elements, tags } = this.state,
            modelsAreLoaded    = bookmarksStats?.get('modelsAreLoaded');

        if (!elements) {
            return false;
        }

        let yOffset = 0;
        return (
            <div className={`captures${modelsAreLoaded ? ' bookmarksAreLoaded' : ''}`}>
                <div className="elements-definition">
                    <textarea onChange={this.updateElements}>{JSON.stringify(elements)}</textarea>
                </div>
                <div className="capture-viewer">
                    {elements.map(({ properties, id }) => {
                        // Prepare element render
                        const element = (
                            <div
                                id={`c${id}`}
                                style={{
                                    height  : properties.height,
                                    width   : properties.width,
                                    position: 'absolute',
                                    top     : yOffset,
                                }}
                            >
                                <Element
                                    key={`c${id}`}
                                    registerCallbacks={this.registerCallbacks}
                                    {...properties}
                                    bookmarks={bookmarksList}
                                    tags={tags}
                                    hideRowLabel
                                />
                            </div>
                        );

                        // Shift down element
                        yOffset += properties.height;

                        // Return element render
                        return element;
                    })}
                </div>
            </div>
        );
    }

}

ElementManager.propTypes = {
    bookmarks     : PropTypes.any,
    learnKnowledge: PropTypes.func,
    bookmarksList : PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.bool]),
    bookmarksStats: PropTypes.oneOfType([ImmutablePropTypes.map, PropTypes.bool]),
};

/**
 * Bind the store to to component
 */
/**
 */
const mapStateToProps = (state) => ({
    bookmarksList : state.getIn(['userView', 'bookmark', 'list']),
    bookmarksStats: state.getIn(['userView', 'bookmark', 'stats']),
});

/**
 * Bind Dispatcher to the component props
 */
export default connect(mapStateToProps, {
    learnKnowledge: learn,
})(ElementManager);
