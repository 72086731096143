import _                    from 'lodash';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { connect }          from 'react-redux';
import {
    Icon, Graph,
    Row, Col,
    IButton
}                           from 'helpers';
import {
    Modal,
    Checkbox,
    Radio
}                           from 'antd';
import { learn }            from '../../store/actions/knowledge';

import './assets/customcountries.less';

/**
 * CustomCountriesModal filter
 */
class CustomCountriesModal extends Component {

    /**
    * Constructor, setting up the state.
    */
    constructor(props) {
        super(props);

        _.bindAll(this, 'onClickOnGroupCheckbox', 'onCountryClick', 'applySelection', 'closeModal');

        this.state = {
            activeGroup: null,
            // Store current selected ids here
            selectedIds: null,
            // Store knowledge there
            countries  : null,
            continents : null,
        };
    }

    /**
    * Get continents
    *
    * @return JSX
    */
    getContinents() {
        const { continents, countries } = this.state,
            { items }                   = this.props,
            countriesArray = countries ? _.map(countries.toJSON(), (obj) => _.merge(obj, {
                value: _.find(items, { id: obj.id })?.value || '-'
            })) : [],
            countriesByContinent        = _.groupBy(
                _.sortBy(countriesArray, 'label'),
                (country) => _.get(country.continent, 0, 'none')
            );

        return continents && countries
            ? _.map(
                continents.toJSON(),
                (continent) => ({ ...continent, children: countriesByContinent[continent.id] })
            ) : [];
    }

    /**
    * Extract selected ids from markets state then create a worldmap serie
    *
    * @return array
    */
    getSelectedIdsForWorldmap() {
        const { selectedIds } = this.state;

        return _.map(
            selectedIds,
            (country) => ({ id: country, value: '1' })
        );
    }

    /**
    * When component is ready.
    *
    * @return JSX
    */
    componentDidMount() {
        const { selectedIds }  = this.props,
            { learnKnowledge } = this.props;

        this.setState({
            selectedIds
        });

        // Learn all needed Knowledges, then fill the state with collections
        learnKnowledge(['countries', 'continents'])
            .then(this.setState.bind(this));
    }


    /**
    * When closeModal
    */
    closeModal() {
        const { onCloseModal } = this.props;

        onCloseModal();
    }

    /**
    * Apply election and close modal
    */
    applySelection() {
        const { onCloseModal } = this.props,
            { selectedIds }    = this.state;

        onCloseModal({ selectedIds });
    }

    /**
    * When user checks an entire group of value
    */
    onClickOnGroupCheckbox(groupId, e) {
        if (groupId === null) {
            this.updateState([]);
            return;
        }

        const { options }         = this.props,
            { restrictedFilters } = this.props,
            { selectedIds }       = this.state,
            groups                = this.getContinents(),
            allItemIds            = _.map(groups.find((group) => group.id === groupId).children, 'id'),
            authorizedItemIds     = restrictedFilters
                ? _.intersection(allItemIds, restrictedFilters)
                : allItemIds;

        let newSelection = [...selectedIds];

        // Append the new values
        if (e.target.checked) {
            newSelection = newSelection.concat(authorizedItemIds);
        }

        // Remove all the values
        if (!e.target.checked) {
            newSelection = selectedIds.filter(selectedId => authorizedItemIds.indexOf(selectedId) === -1);
        }

        // Manage exclusion of other groups, if required.
        if (options && options.excludingGroup) {
            const allItems     = this.getAllItems(),
                allGroupIds    = _.map(_.filter(allItems, { groupId }), 'id');

            newSelection = _.intersection(allGroupIds, newSelection);
        }

        // Make sure we do not have duplicates
        this.setState({ selectedIds: _.uniq(newSelection) });
    }

    /**
    * When user checks an item in the filter
    */
    onClickOnCheckbox(check, e) {
        const { options }   = this.props,
            { selectedIds } = this.state;

        let newSelection = [...selectedIds];

        // Append the new value
        if (e.target.checked) {
            newSelection.push(check);
        }

        // Remove the old value
        if (!e.target.checked) {
            newSelection = selectedIds.filter((selectedId) => selectedId !== check);
        }

        // Manage exclusion of other groups, if required.
        if (options && options.excludingGroup) {
            const allItems     = this.getAllItems(),
                currentGroupId = _.find(allItems, { id: check }).groupId,
                allGroupIds    = _.map(_.filter(allItems, { groupId: currentGroupId }), 'id');

            newSelection = _.intersection(allGroupIds, newSelection);
        }

        this.setState({ selectedIds: newSelection });
    }

    /**
    * When click on country (GeoMap)
    */
    onCountryClick(countryId) {
        const { selectedIds }     = this.state,
            { restrictedFilters } = this.props;

        // Before updating state, check if countryId is in restricted filters
        if (restrictedFilters && !_.includes(restrictedFilters, countryId)) {
            return;
        }

        // Toggle countryId
        this.setState({ selectedIds: _.xor(selectedIds, [countryId]) });
    }

    /**
    * When user expands/retract a group.
    *
    * @return JSX
    */
    onClickonGroupCaret(group) {
        const { activeGroup } = this.state;

        if (group === activeGroup) {
            this.setState({ activeGroup: null });
            return;
        }

        this.setState({ activeGroup: group });
    }

    /**
    * Render custom countries selector modal
    *
    * @return JSX
    */
    render() { // eslint-disable-line  max-lines-per-function
        const continents          = this.getContinents(),
            { restrictedFilters } = this.props;

        return (
            <Modal
                key="custom-countries-modal"
                wrapClassName="custom-countries-modal"
                title="Select countries"
                footer={null}
                width={1048}
                height={640}
                bodyStyle={{padding: 0}}
                open
                onCancel={this.closeModal}
                destroyOnClose
            >
                <Row classn>
                    <Col
                        className="selector filter filter-market grouped-list"
                        width={312}
                        height={528}
                    >
                        {_.map(continents, (continent) => this.renderContinentGroup(continent))}
                    </Col>
                    <Col
                        className="map"
                        width={675}
                        height={528}
                    >
                        <Graph
                            type="GeoMap"
                            dataIsLoaded
                            data={{ content: this.getSelectedIdsForWorldmap() }}
                            color="var(--primary-color)"
                            fontSize={16}
                            noLabel
                            countryClick={this.onCountryClick}
                            restrictedFilters={restrictedFilters}
                        />
                    </Col>
                </Row>
                <Row className="buttons">
                    <IButton
                        label="Apply"
                        className="apply"
                        fontSize={12}
                        onClick={() => this.applySelection()}
                    />
                </Row>
            </Modal>
        );
    }

    /**
    * Render a single group, managing the display state of the input.
    *
    * @return JSX
    */
    renderContinentGroup(group) {
        const { activeGroup } = this.state,
            className         = activeGroup === group.id ? 'active' : 'inactive';
        return (
            <div className="group-filter" key={`group-${group.id}`}>
                <div className="group-label">
                    {group.id !== null && (
                        <span className={`expand ${className}`}>
                            <Icon
                                width={9}
                                color="#ccc"
                                type="retract-arrow"
                                onClick={() => { this.onClickonGroupCaret(group.id); }}
                            />
                        </span>
                    )}
                    {this.renderGroupInput(group)}
                </div>
                {activeGroup === group.id && this.renderGroupItems(group)}
            </div>
        );
    }


    /**
     * Get group state: checked, disabled, indeterminate
     *
     * @param {object} group
     *
     * @returns {object}
     */
    getGroupState(group) {
        const { restrictedFilters } = this.props,
            { selectedIds }         = this.state,
            restricted              = restrictedFilters
                ? _.intersection(restrictedFilters, _.map(group.children, 'id'))
                : _.map(group.children, 'id'),
            checked                 = group.id !== null
                && restricted.length === _.intersection(selectedIds, restricted).length
                && restricted.length > 0,
            indeterminate           = !checked && _.intersection(selectedIds, _.map(group.children, 'id')).length > 0,
            disabled                = restrictedFilters
                && !group.children.filter(child => restrictedFilters?.includes(child.id)).length;

        return {
            checked,
            disabled,
            indeterminate,
        };
    }


    /**
    * Render the group input, choosing Checkbox or Radio according to props.
    *
    * @return JSX
    */
    renderGroupInput(group) {
        const { options }   = this.props,
            { selectedIds } = this.state,
            {
                checked,
                disabled,
                indeterminate,
            }               = this.getGroupState(group);

        if (options && options.excludingGroup) {
            return (
                <Radio
                    key={`label-${group.id}`}
                    checked={(selectedIds.length === 0 && group.id === null) || (checked || indeterminate)}
                    onChange={(e) => this.onClickOnGroupCheckbox(group.id, e)}
                    className={group.id === null ? 'is-not-a-group' : ''}
                >
                    <span className="filter-label" title={group.label}>{group.label}</span>
                </Radio>
            );
        }

        return (
            <Checkbox
                key={`label-${group.id}`}
                checked={checked}
                indeterminate={indeterminate}
                onChange={(e) => this.onClickOnGroupCheckbox(group.id, e)}
                disabled={disabled}
            >
                <span className="filter-label" title={group.label}>{group.label}</span>
            </Checkbox>
        );
    }

    /**
    * Render content of a group.
    *
    * @return JSX
    */
    renderGroupItems(group) {
        if (group.children.length === 0) {
            return null;
        }

        const { selectedIds }     = this.state,
            { restrictedFilters } = this.props;

        return (
            <div className="group-checkboxes">
                {_.map(group.children, (child) => (
                    <Checkbox
                        key={`child-${child.id}`}
                        checked={selectedIds.indexOf(child.id) !== -1}
                        onChange={(e) => this.onClickOnCheckbox(child.id, e)}
                        disabled={restrictedFilters && !restrictedFilters.includes(child.id)}
                    >
                        <span className="filter-label" title={child.label}>{child.label}</span>
                        <span className="filter-count">{child.value}</span>
                    </Checkbox>
                ))}
            </div>
        );
    }

}

CustomCountriesModal.propTypes = {
    learnKnowledge: PropTypes.func.isRequired,
    onCloseModal  : PropTypes.func,
    items         : PropTypes.array,
    options       : PropTypes.shape({
        excludingGroup: PropTypes.any
    }),
    restrictedFilters: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
    selectedIds      : PropTypes.array
};

CustomCountriesModal.defaultProps = {
    onCloseModal: _.noop,
    selectedIds : [],
};

/**
 * Bind the store to to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(CustomCountriesModal);
