import _                       from 'lodash';
import React, { Component }    from 'react';
import PropTypes               from 'prop-types';
import {
    Checkbox, Skeleton, Radio,
}                              from 'antd';
import {
    IButton,
    Icon,
    LimitChildrenDisplay
}                              from 'helpers';
import { connect }             from 'react-redux';
import {
    hyphenate,
    makeStrClassName,
    str2DomFormat,
}                              from 'utils/text';
import { learn }               from '../../store/actions/knowledge';


import Tags from './List/Tags';

const ListRenderer = {
    tag: Tags
};

/**
 * List filter
 */
class List extends Component {

    static IS_DEBOUNCED = true;

    /**
    * Constructor, init the state
    */
    constructor(props) {
        const { options, filterKey } = props;

        super(props);

        _.bindAll(this, 'onClickOnCheckbox', 'onClickOnAllCheckbox',
            'getSelectableValues', 'resetSelectedValues', 'onApply');

        this.state = {
            key        : options && options.knowledge ? options.knowledge: filterKey,
            selectedIds: false,
            lastItems  : false,
        };
    }

    /**
    * When component is ready, learn knowledge to display values.
    */
    componentDidMount() {
        const { learnKnowledge } = this.props,
            { key }              = this.state;

        this.itemsPool = {};

        learnKnowledge(key)
            .then(this.setState.bind(this));
    }

    /**
    * When the component did update
    *
    * @return void
    */
    componentDidUpdate(prevProps) {
        const { items, value } = this.props,
            { lastItems }      = this.state,
            { selectedIds }    = this.state;

        if (items && items !== lastItems) {
            this.setState({ lastItems: items });
        }

        if(_.isEmpty(value) && prevProps.value.length > 0) {
            this.setState({ selectedIds: false });
        }

        // Put selected values from props
        if(!_.isEmpty(value) && selectedIds === false) {
            this.setState({ selectedIds: value });
        }

        this.memorizeItems();
    }

    /**
     * Store all items seen to reuse labels
     *
     * @returns void
     */
    memorizeItems() {
        const { items } = this.props;

        _.forEach(items, item => {
            this.itemsPool[item.id] = { ...item, value: false };
        });
    }

    /**
    * Event launched when the user changes the value of the checkboxes.
    */
    onClickOnCheckbox(check, e) {
        const { singleValue } = this.props,
            { selectedIds }   = this.state,
            selectableValues  = this.getSelectableValues(),
            checkAllValue     = this.getCheckAllValue();

        // TODO: remove let
        let newSelection = selectedIds ? [...selectedIds] : [];

        // Append the new value
        if (e.target.checked) {
            newSelection.push(check);
        }

        // Remove the old value
        if (!e.target.checked) {
            newSelection = selectedIds.filter((selectedId) => selectedId !== check);
        }

        // Only one the clicked value (singleValue)
        if (singleValue && e.target.checked) {
            newSelection = selectedIds[0] === check ? [] : [check];
        }

        // If we selected all, check everything but the first
        if (checkAllValue && newSelection.indexOf(selectableValues[0].id) !== -1) {
            this.updateState(
                _.chain(selectableValues)
                    .map('id')
                    .filter((id) => id !== checkAllValue && id !== check)
                    .value()
            );
            return;
        }

        // If we select all, then we select only first
        if (checkAllValue && newSelection.length === selectableValues.length - 1) {
            this.updateState([checkAllValue]);
            return;
        }

        this.updateState(newSelection);
    }

    /**
     * Reset selected values
     *
     * @returns void
     */
    resetSelectedValues() {
        this.updateState([]);
    }

    /**
    * Handle the click on the All Checkbox, adjusting the state accordingly.
    */
    onClickOnAllCheckbox(e) {
        this.updateState(e.target.checked ? [this.getCheckAllValue()] : []);
    }

    /**
    * Find and order, if needed, the values to display.
    *
    * @return array
    */
    getSelectableValues() {
        const { key } = this.state,
            items     = this.getItems(),
            hasItems  = items?.length > 0,
            itemsById =  _.keyBy(items, 'id'),
            // Get filter definition from knowledge
            // CamelCase (learKnowledge) store it with camelCase in state
            { [_.camelCase(key)]: knowledgeDef } = this.state;

        if (_.isArray(knowledgeDef) || _.isObject(knowledgeDef)) {
            const filtersAsArray  = !_.isArray(knowledgeDef) ? knowledgeDef.toArray() : knowledgeDef,
                filtersWithValues = filtersAsArray.map(  // TODO: make a function !!!!!
                    (filter) => ({
                        ...filter,
                        value: itemsById
                            ? (
                                itemsById[filter.id]
                                    ? itemsById[filter.id].value
                                    : (hasItems ? 0 : null)
                            ) : null
                    })
                ),
                orderedValues     = _.orderBy(filtersWithValues, ['order', 'value', 'label'], ['asc', 'desc', 'asc']);

            return orderedValues.length !== 0 ? orderedValues : null;
        }

        return items || null;
    }

    /**
    * Get the first checkall value
    * Will use the first in order if not found.
    *
    * @return JSX
    */
    getCheckAllValue() {
        const selectableValues = this.getSelectableValues(),
            checkValue = _.find(selectableValues, { checkAll: true });

        if (checkValue) {
            return checkValue.id;
        }

        return null;
    }

    /**
    * Update the state.
    */
    updateState(selectedIds) {
        const { filterKey, onChange } = this.props;

        this.setState({ selectedIds });

        if (!this.mustRenderApplyButton()) {
            onChange(filterKey, selectedIds);
        }
    }


    /**
    * Apply dynamic filters. Triggering onChange from parent.
    */
    onApply() {
        const { filterKey, onChange } = this.props,
            { selectedIds }           = this.state;

        onChange(filterKey, selectedIds);
    }

    /**
     *
     */
    getSelectedIdsToRender() {
        const { value }     = this.props,
            { selectedIds } = this.state;

        return selectedIds || value || [];
    }

    /**
     * Render Checkbox or Select component depending on the value of singleValue.
     *
     * @param {object} option
     * @returns JSX
     */
    renderCheckboxOrSelectComponent(option) {
        const {
                singleValue, isFiltersLoading,
            }                   = this.props,
            selectedIdsToRender = this.getSelectedIdsToRender(),
            items               = this.getItems(),
            checkAllValue       = this.getCheckAllValue(),
            SelectComponent     = singleValue ? Radio : Checkbox,
            allChecked          = checkAllValue && selectedIdsToRender.length === 1 && selectedIdsToRender[0] === checkAllValue,
            emptyString         = isFiltersLoading || !items ? '' : '-',
            {
                id, description,
                label, checkAll, value,
            }               = option,
            title           = description || label,
            filterValue     = value,
            valueToRender   = value > 0 ? filterValue : emptyString,
            content         = (
                <>
                    <span className="filter-label" title={title}>
                        {label}
                    </span>
                    <span className="filter-count" data-qa-key={str2DomFormat(label)}>{valueToRender}</span>
                </>
            );

        if (checkAllValue && checkAll && !singleValue) {
            return (
                <Checkbox key="checkAllValue" onChange={this.onClickOnAllCheckbox}
                    checked={allChecked}
                >
                    {content}
                </Checkbox>
            );
        }

        return (
            <SelectComponent key={id} className={hyphenate(label)}
                checked={allChecked || selectedIdsToRender.indexOf(id) !== -1}
                onChange={(e) => this.onClickOnCheckbox(id, e)}
            >
                {content}
            </SelectComponent>
        );
    }

    /**
     * Render checkboxes or selects
     *
     * @param {object} params
     * @returns JSX
     */
    renderCheckboxesOrSelectComponents(params) {
        const { Renderer }   = params,
            selectableValues = this.getSelectableValues();

        // Renderer just for TAG !
        return !Renderer && (
            <LimitChildrenDisplay className="filter-elements" tolerance={1}>
                {_.map(selectableValues, this.renderCheckboxOrSelectComponent.bind(this))}
            </LimitChildrenDisplay>
        );
    }


    /**
     * Check if the button apply must be showed
     *
     * @returns boolean
     */
    mustRenderApplyButton() {
        const { key }    = this.state,
            knowledgeDef = _.get(this.state, key),
            isDynamic    = _.isUndefined(knowledgeDef);

        return isDynamic;
    }

    /**
     * Changed when selectedIds are different from values
     *
     * @returns boolean
     */
    getChangedState() {
        const { value }     = this.props,
            { selectedIds } = this.state;

        return _.xor(selectedIds, value).length > 0;
    }


    /**
     * Render the button apply
     * @returns JSX
     */
    renderApplyButton() {
        const changed = this.getChangedState();

        return this.mustRenderApplyButton() && (
            <IButton label="Apply" onClick={this.onApply}
                className="apply" bgColor="#10285D"
                labelColor="#FFFFFF" hoverBgColor="#58698E"
                fontSize={12}
                disabled={!changed}
            />
        );
    }

    /**
     * Render the button apply
     *
     * @returns JSX
     */
    renderResetFilters() {
        const {
                singleValue, filterLabel
            }               = this.props,
            { selectedIds } = this.state;

        return singleValue && selectedIds.length > 0 ? (
            <div
                className="reset-filters"
                onClick={this.resetSelectedValues}
            >
                <Icon id="goBack" height={14}
                    width={14} color="var(--primary-color)"
                />
                <span>{`Reset ${filterLabel}`}</span>
            </div>
        ) : null;
    }

    /**
     * Get all items
     *
     * @returns array
     */
    getItems() {
        const { items, value } = this.props,
            itemsKeys          = _.map(items, item => item.id),
            selectedIdsToAdd   = _.difference(value || [], itemsKeys),
            _items             = _.cloneDeep(items) || [];

        if (items === false) {
            return false;
        }

        return _items.concat(
            selectedIdsToAdd.map(
                id => {
                    const item = this.itemsPool[id];
                    return item
                        && {
                            id,
                            label: this.itemsPool[id].label
                        };
                }
            ).filter(item => !!item)
        );
    }


    /**
    * Render the filter List
    *
    * @return JSX
    */
    render() {
        const {
                isFiltersLoading,
                filterLabel,
            }                  = this.props,
            selectedIds        = this.getSelectedIdsToRender(),
            { key, lastItems } = this.state,
            items              = this.getItems(),
            selectableValues   = this.getSelectableValues(),
            Renderer           = ListRenderer[key] || false,
            classNames         = [
                'filter-list', key, {'is-loading': isFiltersLoading, 'has-checkall': this.getCheckAllValue()},
                filterLabel
            ],
            className          = makeStrClassName(classNames);


        if (items === false && lastItems === false) {
            return (
                <div className="filter-list">
                    {_.times(5, (index) => (
                        <Skeleton key={index} paragraph={null}
                            active
                        />
                    ))}
                </div>
            );
        }

        return (
            <div className={className} data-qa-key={str2DomFormat(filterLabel)}>
                {Renderer && (
                    <Renderer key={key} values={selectableValues}
                        onChange={this.onClickOnCheckbox} selectedIds={selectedIds}
                    />
                )}
                {this.renderCheckboxesOrSelectComponents({ Renderer })}
                {this.renderApplyButton()}
                {this.renderResetFilters()}
            </div>
        );
    }

}

List.propTypes = {
    filterKey       : PropTypes.string.isRequired,
    filterLabel     : PropTypes.any,
    items           : PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.bool]),
    learnKnowledge  : PropTypes.func.isRequired,
    onChange        : PropTypes.func.isRequired,
    singleValue     : PropTypes.bool,
    isFiltersLoading: PropTypes.bool,
    value           : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.arrayOf(PropTypes.number)
    ]).isRequired,
    options: PropTypes.shape({
        knowledge: PropTypes.any
    }),
};

List.defaultProps = {
    items      : null,
    options    : null,
    singleValue: false,
    toogleValue: false,
};

/**
 * Bind the store to component
 */
const mapStateToProps = ({ knowledge }) => ({ knowledge });

export default connect(mapStateToProps, {
    learnKnowledge: learn
})(List);

