/**
 * Main Insight search input
 *
 * @return Component
 */
import _                                 from 'lodash';
import React, { Component }              from 'react';
import { withRouter }                    from 'react-router-dom';
import PropTypes                         from 'prop-types';
import { connect }                       from 'react-redux';
import { Modal, Switch }                 from 'antd';
import { query, openOrgunitsCollection } from 'store/actions/navigation';
import HelpGeneric                       from '../HelpGeneric';
import { makeStrClassName }              from 'utils/text';

import './assets/input.less';

// Import Inputs renderer
import Inputs from './Inputs';


/**
* The header CC for Insight, this should be smart.
*
*/
class Input extends Component {

    /**
    * Construct the Component
    *
    * @param object props The provided props
    *
    * @return void
    */
    constructor(props) {
        super(props);
        _.bindAll(this, 'onSearch', 'switchTo', 'onSelectSwitchMode',  'confirmSwitch', 'registerInputCallbacks');

        this.state = {
            mode          : 'smart',
            switchModeTo  : false,
            inputCallbacks: {}
        };
    }

    /**
    * Launch side effects
    *
    * @return boolean
    */
    componentDidUpdate() {
        return false;
    }

    /**
    * Create the state from properties
    *
    * @return object
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        const { search } = nextProps,
            { edited }   = prevState;

        if (search && !edited) {
            prevState.mode = search.mode;
        }

        return prevState;
    }

    /**
    * On click on search
    *
    * @return false
    */
    onSearch(parameters) {
        const { query }  = this.props,
            { onSearch } = this.props;

        // Perform Search
        query(
            parameters,                             // Send params
            (model) => {
                this.navigateToSearch(model);       // Then, view results
            }
        );

        onSearch();
    }

    /**
    * Open the search
    *
    * @return void
    */
    navigateToSearch(model) {
        const { history } = this.props;
        history.push(`/search/${model.id}`);
    }

    /**
    * Open the orgunit collection if it's necessary
    *
    * @return void
    */
    openOrgunitCollection(model) {
        const { openSearchOrgunitsCollection } = this.props;

        openSearchOrgunitsCollection(model);
    }


    /**
    * Switch to mode
    *
    * @return void
    */
    onSelectSwitchMode(checked) {
        const { inputCallbacks } = this.state,
            key                  = checked ? 'classic' : 'smart';

        // Input has been edited, open confirm
        if (inputCallbacks['has-value']()) {
            this.setState({
                switchModeTo: key,
                edited      : true
            });
            return;
        }

        // Switch to mode
        this.switchTo(key);
    }

    /**
    * Confirm the switch mode
    *
    * @return void
    */
    confirmSwitch(accept) {
        const { switchModeTo } = this.state;

        // Refuse remove switchModeTo bit
        if (!accept) {
            return this.setState({
                switchModeTo: false
            });
        }

        // Accept switch the mode
        this.switchTo(switchModeTo);
    }

    /**
    * Set the state with the new mode
    *
    * @param string mode The new mode
    *
    * @return void
    */
    switchTo(mode) {
        this.setState({
            inputCallbacks: { 'has-value': () => false },
            switchModeTo  : false,
            mode,
            edited        : true
        });
    }

    /**
    * Register the callbacks
    *
    * @param string The action name
    * @param func   The reset callback to store
    *
    * @return void
    */
    registerInputCallbacks(action, cb) {
        const { registerCallbacks } = this.props,
            { inputCallbacks }      = this.state,
            /** Return empty object */
            defaultCb               = () => {};

        inputCallbacks[action] = cb || defaultCb;

        this.setState({ inputCallbacks });

        registerCallbacks(action, cb);
    }

    /**
    * Render the switch confirm popin
    *
    * @return JSX
    */
    renderSwitchConfirm() {
        const { switchModeTo } = this.state,
            { mode }           = this.state,
            title              = mode === 'smart'
                ? 'Switch to classic search'
                : 'Switch to smart search',
            content            = mode === 'smart'
                ? 'By switching to "Classic search" you will loose all your "Smart search" input and filter.'
                : 'By switching to "Smart search" you will loose the content of your "Classic search".';

        if (!switchModeTo) {
            return false;
        }

        return (
            <Modal
                open
                onOk={() => this.confirmSwitch(true)}
                onCancel={() => this.confirmSwitch(false)}
                title={title}
                wrapClassName="insight-modal"
                width={440}
            >
                {content}
            </Modal>
        );
    }

    /**
    * Render the mode switcher
    *
    * @return JSX
    */
    renderSwitcher() {
        const { switcher }   = this.props,
            { mode }         = this.state,
            isClassic        = mode === 'classic',
            smartClassName   = ['mode', {active: !isClassic}],
            classicClassName = ['mode', {active: isClassic}];

        if (!switcher) {
            return false;
        }

        return (
            <>
                <span className="separator" />

                <span
                    className={makeStrClassName(smartClassName)}
                    onClick={() => this.onSelectSwitchMode(false)}
                >
                    Smart
                </span>

                <Switch className="ant-switch-extra-small" checked={isClassic}
                    onChange={this.onSelectSwitchMode} size="small"
                />

                <span
                    className={makeStrClassName(classicClassName)}
                    onClick={() => this.onSelectSwitchMode(true)}
                >
                    Classic
                </span>

                <HelpGeneric id="help-autocomplete" />
            </>
        );
    }

    /**
    * Render the input component
    *
    * @return JSX
    */
    renderInput() {
        const {
                search,
                submit,
                openOrgunitsCollection,
                onUpdateInputs,
                mustHaveOneConcept
            }        = this.props,
            { mode } = this.state,
            concepts = search ? search.concept : false,
            Input    = Inputs[mode] || false;

        if (!Input) {
            return false;
        }

        return (
            <div className="search-input">
                <Input
                    search={search}
                    value={concepts}
                    switcher={this.renderSwitcher()}
                    registerCallbacks={this.registerInputCallbacks}
                    onUpdateInputs={onUpdateInputs}
                    mustHaveOneConcept={mustHaveOneConcept}
                    onSubmit={submit ? this.onSearch : false}
                    openOrgunitsCollection={openOrgunitsCollection}
                />
            </div>
        );
    }

    /**
    * Render the main layout
    *
    * @return html
    */
    render() {
        return (
            <>
                {this.renderInput()}
                {this.renderSwitchConfirm()}
            </>
        );
    }

}

/**
* Validate properties for current Component
*/
Input.propTypes = {
    mode                        : PropTypes.string.isRequired,
    onSearch                    : PropTypes.func,
    openOrgunitsCollection      : PropTypes.any,
    openSearchOrgunitsCollection: PropTypes.func,
    query                       : PropTypes.func,
    registerCallbacks           : PropTypes.func,
    submit                      : PropTypes.bool,
    switcher                    : PropTypes.bool,
    onUpdateInputs              : PropTypes.func,
    mustHaveOneConcept          : PropTypes.bool,
    search                      : PropTypes.oneOfType([
        PropTypes.shape({
            id     : PropTypes.string,
            mode   : PropTypes.string,
            concept: PropTypes.array,
        }),
        PropTypes.bool
    ]),
    history: PropTypes.object,
};

Input.defaultProps = {
    registerCallbacks: () => {},
    onUpdateInputs   : () => {},
    search           : false,
    height           : 20,
    mode             : 'Smart',
    submit           : true,
    onSearch         : _.noop,
    switcher         : true,
};

/**
* Bind the store to to component
*/
const mapStateToProps = (state) => ({
    member: state.getIn(['auth', 'member']),
});

export default connect(mapStateToProps, {
    openOrgunitsCollection,
    query,
})(withRouter(Input));

