import React, { Component }            from 'react';
import PropTypes                       from 'prop-types';
import _                               from 'lodash';
import { Tooltip, Popover }            from 'antd';
import { htmlize, addBr, brToNewline } from  'utils/text';

// CSS
import './TextEdit/assets/main.less';
/**
* Manage to display a textedit zone
*
*/
class TextEdit extends Component {

    /**
    * Instanciate the component
    */
    constructor(props) {
        super(props);

        const {
            registerEditModeCb,
        }          = props;

        this.state = {
            mode        : 'text',
            editText    : '',
            originalText: '',
            // TODO: Remove saved state
            saved       : false,
        };

        this.inputRef   = React.createRef();
        this.labelRef   = React.createRef();

        _.bindAll(this,
            'inputKeyDown', 'onChange', 'modeChange', 'onCancel',
            'onSave', 'editFocusAndAdjustHeight', 'selectAllText'
        );

        // To store this.modeChange function on parent component
        registerEditModeCb && registerEditModeCb(this.modeChange);
    }

    /**
    * Update component props
    *
    * @params nextProps object New props received
    * @params prevState object Previous state
    *
    * @return state
    */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.originalText !== nextProps.text) {
            return {
                ...prevState,
                mode        : 'text',
                editText    : nextProps.text,
                originalText: nextProps.text    // To know when the edit text must be reset

            };
        }
        return null;
    }


    /**
    * Triggered when the component is ready
    *
    * @return void
    */
    componentDidMount() {
        window.requestAnimationFrame(this.selectAllText);
    }

    /**
     * Called after each render
     */
    componentDidUpdate(prevProps, prevState) {
        const { mode } = this.state,
            modeEditChanged = !!((mode === 'edit' && mode !== prevState.mode));

        if (modeEditChanged) {
            window.requestAnimationFrame(this.selectAllText);
            window.requestAnimationFrame(this.editFocusAndAdjustHeight);
        }
    }

    /**
    * Trigger inpur or textarea value change
    *
    * @return void
    */
    onChange(event) {
        const { maxLength } = this.props,
            text            = event.target.value,
            truncatedText   = (maxLength > 0 && text.length > maxLength) ? text.substring(0, maxLength) : text;

        this.setState({ editText: this.textOutputFormatter(truncatedText) });
        this.editFocusAndAdjustHeight();
    }

    /**
    * Trigger when keyboard touch is put
    */
    inputKeyDown(event) {
        if (event.key == 'Enter') {
            this.onSave();
        }
        if (event.key == 'Escape') {
            this.onCancel(event);
        }
    }

    /**
    * Switch between mode text and edit
    *
    * @return void
    */
    modeChange() {
        const { mode } = this.state,
            modeNew    = (mode === 'text') ? 'edit' : 'text';

        this.setState({ mode: modeNew });
    }

    /**
    * Trigger on Save the text
    *
    * @return void
    */
    onSave() {
        const { onSave,
                forceEditModeCb } = this.props,
            { editText, saved,
                originalText }    = this.state;

        if (!editText) {
            return;
        }

        // Return if the text is not changed
        // TODO: Allow user to save with "New Folder" default text ?
        if (editText == originalText) {
            return;
        }

        !saved && onSave(editText);

        this.setState({
            saved: true
        });

        if (forceEditModeCb) {
            forceEditModeCb({ event: 'submit', payload: editText });
        }

        this.modeChange();
    }

    /**
    * Trigger on modal close
    *
    * @return void
    */
    onCancel(event) {
        const { forceEditModeCb } = this.props,
            { originalText } = this.state;

        this.setState({
            editText: originalText
        });

        if (forceEditModeCb) {
            forceEditModeCb({ event: 'cancel' });
        }

        event.preventDefault();
        this.modeChange();
    }

    /**
     *  Getting the input DOM element.
     * */
    getInputDomElement() {
        const { current } = this.inputRef;

        return current;
    }


    /**
     * Where el is the DOM element you'd like to test for visibility
     * @param {object} element Ref of the dom element
     * @returns
     */
    isElHidden(element) {
        const { offsetParent } = element.current || {};

        return (offsetParent === null || _.isUndefined(offsetParent));
    }

    /**
    * Focus the edit area : input or textarea
    * Adjust the size of height of textarea to see full text
    *
    * @return void
    */
    editFocusAndAdjustHeight() {
        const { type } = this.props,
            element    = this.getInputDomElement();

        if (!element) {
            return;
        }

        element.focus();

        if (type !== 'input') {
            element.style.height = '100px';
            element.style.height = (10 + element.scrollHeight) + 'px';
        }
    }

    /**
     * Select all text of the input
     */
    selectAllText() {
        const element = this.getInputDomElement();

        element?.select();
    }

    /**
    * Render the footer of the component
    *
    * @return html
    */
    renderFooter() {
        return (
            <div className="actions">
                <div className="cancel" onMouseDown={this.onCancel}>
                    Cancel
                </div>
                <div className="save" onMouseDown={this.onSave}>
                    Ok
                </div>
            </div>
        );
    }


    /**
     *
     * @param {string} text
     */
    textInputFormatter(text) {
        return brToNewline(text);
    }


    /**
     *
     * @param {string} text
     */
    textOutputFormatter(text) {
        return addBr(text);
    }


    /**
     * Render popover content
     */
    renderContent() {
        const { type, maxLength } = this.props,
            { editText }          = this.state;
        return (
            <div className="editArea">
                {type === 'input' ? (
                    <input
                        ref={this.inputRef}
                        type="text"
                        value={this.textInputFormatter(editText)}
                        onChange={this.onChange}
                        onKeyDown={this.inputKeyDown}
                        autoFocus={true}
                    />
                ) : (
                    <textarea
                        ref={this.inputRef}
                        value={this.textInputFormatter(editText)}
                        onChange={this.onChange}
                        autoFocus={true}
                    />
                )}
                {
                    maxLength > 0 && (
                        <div className="maxLength">
                            {editText.length}/{maxLength}
                        </div>
                    )
                }
                {this.renderFooter()}
            </div>
        );
    }

    /**
     * Render label
     *
     * @returns html
    */
    renderLabel() {
        const { disableClick } = this.props,
            { editText }       = this.state,
            classNames         = ['text', !disableClick ? 'clickable' : ''];

        return (
            <span className={classNames.join(' ')} ref={this.labelRef}>
                {
                    React.isValidElement(editText)
                        ? editText
                        : htmlize(addBr(editText))
                }
            </span>
        );
    }

    /**
    * Render the component
    *
    * @return Component
    */
    render() {
        const {
                tooltip,
                forceEditModeCb
            }               = this.props,
            { mode, saved } = this.state,
            isLabelVisible  = !this.isElHidden(this.labelRef),
            visibility      = isLabelVisible && (forceEditModeCb || mode !== 'text');

        return saved
            ? this.renderLabel()
            : (
                <Popover
                    content={this.renderContent()}
                    title=""
                    trigger="click"
                    open={visibility}
                    placement="bottomLeft"
                    onOpenChange={this.modeChange}
                    zIndex={1051}
                    destroyTooltipOnHide
                >
                    <span className="text-edit">
                        <Tooltip placement="top" title={tooltip}>
                            {this.renderLabel()}
                        </Tooltip>
                    </span>
                </Popover>
            );
    }

}

TextEdit.propTypes = {
    maxLength         : PropTypes.number,
    text              : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type              : PropTypes.string,
    tooltip           : PropTypes.string,
    onSave            : PropTypes.func,
    registerEditModeCb: PropTypes.func,
    forceEditModeCb   : PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    disableClick      : PropTypes.bool,
};

TextEdit.defaultProps = {
    maxLength   : 50,
    text        : '',
    type        : 'input',
    position    : 'dialog',
    tooltip     : '',
    onSave      : () => { },
    disableClick: false,
};

export default TextEdit;
